import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useFilters = (mapper) => {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => {
    return mapper.fromQueryStringToQuery(location.search);
  }, [location.search, mapper]);

  const onChangeQuery = useCallback(
    (newQuery) => {
      history.replace({
        pathname: location.pathname,
        search: mapper.fromQueryToQueryString({ ...query, ...newQuery }),
      });
    },
    [history, location.pathname, mapper, query]
  );

  return {
    query,
    onChangeQuery,
  };
};
