import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { GeneralSettingsAPI } from 'src/modules/generalSettings/api/GeneralSettingsAPI';

const GeneralSettingsContext = createContext();

const useProvideGeneralSettings = (text) => {
  const [imagesBaseURL, setImagesBaseURL] = useState([]);

  const getImagesBaseURL = useCallback(async () => {
    try {
      const res = await GeneralSettingsAPI.getImagesBaseURL();
      const url = res?.data?.imageURL || '';
      setImagesBaseURL(url);
    } catch (e) {
      message.error(text('imagesBaseUrlError'));
    }
  }, []);

  useEffect(() => {
    getImagesBaseURL();
  }, []);

  return {
    imagesBaseURL
  };
};

export const GeneralSettingsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.generalSettings', t);

  const generalSettings = useProvideGeneralSettings(text);

  return (
    <GeneralSettingsContext.Provider value={generalSettings}>
      {children}
    </GeneralSettingsContext.Provider>
  );
};

export const useGeneralSettings = () => useContext(GeneralSettingsContext);
