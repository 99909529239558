import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Button, Select, Form } from 'antd';
import { message } from 'src/services/Messages.service';
import { useHistory, useParams } from 'react-router-dom';
import { useUploadTypeDetails } from 'src/modules/upload/provider/uploadTypeDetails.provider';
import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { BackButton } from 'src/components/BackButton';
import { PageTitle } from 'src/components/PageTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { FileUpload } from 'src/modules/upload/components/FileUpload';
import { UploadTypes } from 'src/modules/upload/domain/models/UploadTypes';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { Routes } from 'src/router/Routes.helper';
import { useDepartment } from 'src/modules/admin/provider/department.provider';
import { SelectDepartment, toDepartmentOption } from '../components/SelectDepartment';
import { DownloadButton } from 'src/components/buttons/DownloadButton';
import { getTemplateFile } from '../constants/sampleTemplateFiles';
import { SCOPE } from 'src/utils/scope-utils';
import { API_TENANT } from 'src/config';

const { TYPE_KEYS } = UploadTypes;
const { PLATFORM, TENANT, LOCALBUSINESS } = SCOPE;

function departmentOf (type) {
  switch (type) {
    case TYPE_KEYS.PRODUCT_FOOD:
      return 'food';
    case TYPE_KEYS.PRODUCT_EVENT:
      return 'event';
    case TYPE_KEYS.PRODUCT_FASHION:
      return 'fashion';
    default:
      return undefined;
  }
}

function requiresWarehouse (type) {
  const typesRequiringWarehouse = [
    TYPE_KEYS.STOCK,
    TYPE_KEYS.PRODUCT_FOOD,
    TYPE_KEYS.PRODUCT_EVENT,
    TYPE_KEYS.PRODUCT_FASHION
  ];
  return typesRequiringWarehouse.includes(type);
}

export const PageUploadTypeDetails = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.uploads/*', t);
  const FILE_TYPE_TITLE = textFrom('constants.fileType', t);

  const history = useHistory();
  const params = useParams();
  const { departments } = useDepartment();
  const [form] = Form.useForm();
  const { uploadTypeDetails, uploadFile, uploadZip, warehousesList, getWarehousesList } = useUploadTypeDetails();
  const [warehouseId, setWarehouseId] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [departmentKey, setDepartmentKey] = useState();
  const downloadCatalogFile = useDownloadFile({ onError (err) { message.error(err.response?.data?.detail || err.response?.statusText || err.message); } });
  const { userInfo, selectedUserScope, userDepartments } = useAuth();

  const isProductType = [
    TYPE_KEYS.PRODUCT_FOOD,
    TYPE_KEYS.PRODUCT_EVENT,
    TYPE_KEYS.PRODUCT_FASHION
  ].includes(params?.type);

  const isCatalogImagesType = params?.type === TYPE_KEYS.CATALOG_IMAGES;
  const templateFile = getTemplateFile(params?.type, departmentKey);

  const availableDepartments = useMemo(() => {
    if (selectedUserScope === PLATFORM || selectedUserScope === TENANT) {
      return departments.map(dept => dept.key) || [];
    }
    return userInfo?.localBusiness?.departments || [];
  }, [userInfo, selectedUserScope, departments]);

  useEffect(() => {
    if (params) {
      const department = departmentOf(params.type);
      getWarehousesList(department);
    }
  }, [params.type]);

  useEffect(() => {
    if (selectedUserScope === LOCALBUSINESS) {
      const dept = userDepartments?.[0];
      handleDepartmentSelection(dept);
      form.setFieldsValue({ department: dept });
    }
  }, [selectedUserScope]);

  // In case of type category or attribute set the warehouseId = params.type
  useEffect(() => {
    if (!requiresWarehouse(params.type)) {
      setWarehouseId(params.type);
    }
  }, [params.type]);

  /**
   * @description Upload File
   * @returns {Promise<void>}
   */
  const handleUpload = async () => {
    await form.validateFields();
    setUploadLoading(true);
    try {
      if (params.type === TYPE_KEYS.CATALOG_IMAGES) {
        const folder = userInfo?.localBusiness?.externalLocalBusinessId || API_TENANT;
        await uploadZip(uploadedFile, folder);
      } else {
        await uploadFile(warehouseId, uploadedFile);
      }
      setUploadLoading(false);
      message.success(text('fileSubmittedSuccessfully'));
    } catch (e) {
      setUploadLoading(false);
      message.error(e);
    }
  };

  const goToUploadList = () => {
    history.push(Routes.PATHS.UPLOADS_LIST);
  };

  const handleDepartmentSelection = (val) => {
    setDepartmentKey(val);
  };

  const warehouseOptions = useMemo(() => (
    warehousesList?.map(warehouse => ({ value: warehouse.warehouseIdentifier, label: warehouse.name }))
  ), [warehousesList]);

  return (
    <Form
      name='basic'
      form={form}
      initialValues={{
        warehouse: null,
        file: null
      }}
      onFinish={handleUpload}
      autoComplete='off'
      scrollToFirstError
    >
      <Row className='py-4'>
        <Col span={12}>
          <PageTitle>{FILE_TYPE_TITLE(params.type.toLowerCase())}</PageTitle>
          <BackButton
            title={text('backButton')}
            onClick={goToUploadList}
            className='pt-4 pb-4 text-sm'
          />
          <HelperText
            className='pt-4'
            text={text('formatHelperText', { format: uploadTypeDetails.formats })}
          />
          <HelperText
            className='pb-4'
            text={text('maxSizeHelperText', { size: uploadTypeDetails.max_wight })}
          />

          {isProductType &&
            <Row gutter={20} className='pb-4'>
              <Col span={12}>
                <SelectDepartment
                  label={text('form.department.label')}
                  onSelect={handleDepartmentSelection}
                  disabled={selectedUserScope === LOCALBUSINESS}
                  placeholder={text('form.department.placeholder')}
                  options={availableDepartments.map(toDepartmentOption)}
                />
              </Col>
            </Row>}

          <Row gutter={[16, 16]} className='pt-4'>
            {!isCatalogImagesType &&
              <Col>
                <DownloadButton
                  label={text('downloadTemplateButton')}
                  disabled={!templateFile || (isProductType && !departmentKey)}
                  fileName={templateFile?.name}
                  filePath={templateFile?.path}
                />
              </Col>}
            {/* Display Download catalog button only in case of product type */}
            {isProductType && (
              <Col>
                <Button
                  type='primary'
                  className='uppercase'
                  loading={downloadCatalogFile.isLoading}
                  onClick={() => downloadCatalogFile.call('/backoffice/export', 'catalog.xlsx')}
                >
                  {text('downloadCatalogButton')}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6}>
          {requiresWarehouse(params.type) && (
            <Form.Item
              className='warehouse-select'
              name='warehouse'
              label={text('form.warehouse.label')}
              rules={[{ required: true, message: text('form.warehouse.message') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                style={{ width: '100%' }}
                placeholder={text('form.warehouse.placeholder')}
                onChange={(value) => setWarehouseId(value)}
                allowClear
                options={warehouseOptions}
              />
            </Form.Item>
          )}

          {/* Drag and drop section */}
          <Form.Item name='file' rules={[{ required: true, message: '' }]}>
            <FileUpload
              accept={uploadTypeDetails.formats}
              fileSize={uploadTypeDetails.max_wight}
              acceptedFileTypes={uploadTypeDetails.formats}
              uploadedFile={uploadedFile}
              onUploadedFileChange={(file) => {
                setUploadedFile(file);
                form.setFieldsValue({ file: file });
              }}
            />
          </Form.Item>

          {/* Upload */}
          <div className='flex justify-end'>
            <Button
              loading={uploadLoading}
              className='uppercase'
              type='primary'
              htmlType='submit'
              style={{ marginTop: 16 }}
            >
              {text('form.uploadButton')}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
