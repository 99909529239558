import React from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

export const SectionTitle = ( { title, className }) => {
  return (
    <div className={className}>
      <Title level={5}>{title}</Title>
    </div>
  )
}
