import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { usePagination } from 'src/hooks/usePagination';
import { useFilters } from 'src/hooks/useFilters';
import { UploadsAPI } from 'src/modules/upload/api/UploadsApi';
import { FolderHistoryMapper } from 'src/modules/upload/domain/FolderHistory.mapper';
import { message } from 'src/services/Messages.service';

export const UploadsListContext = createContext();

const useProvideUploadsList = () => {
  const location = useLocation();
  const [folderHistory, setFolderHistory] = useState([]);
  const [pagination, setPagination] = usePagination();
  const [pendingFolderHistory, setPendingFolderHistory] = useState(false);
  const { query, onChangeQuery } = useFilters(FolderHistoryMapper);

  /**
   * @description Get upload history
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getFolderHistory = useCallback(async () => {
    try {
      setPendingFolderHistory(true);
      const res = await UploadsAPI.getFolderHistory(
        FolderHistoryMapper.fromQueryToPayload(query)
      );
      setFolderHistory(res.data.items);
      setPagination(res.data);
      return Promise.resolve();
    } catch (e) {
      message.error(e);
    } finally {
      setPendingFolderHistory(false);
    }
  }, [query, setPagination]);

  useEffect(() => {
    getFolderHistory();
  }, [location, getFolderHistory]);

  return {
    folderHistory,
    pendingFolderHistory,
    onChangeQuery,
    query,
    pagination,
    getFolderHistory
  };
};

export const UploadsListProvider = ({ children }) => {
  const uploadsList = useProvideUploadsList();
  return (
    <UploadsListContext.Provider value={uploadsList}>
      {children}
    </UploadsListContext.Provider>
  );
};

export const useUploadsList = () => useContext(UploadsListContext);
