export class FoodUpdatePayload {
  constructor(data) {
    this.names = data.names;
    this.descriptions = data.descriptions;
    this.macroCategory = { key: data.categories.macro.key };
    this.microCategory = { key: data.categories.micro.key };
    this.prices = {
      sellingPrice: data.price,
    };
    this.brand = data.brand;
  }
}
