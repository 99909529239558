import React, { useState } from "react";
import { Input } from "antd";

export const NumericInput = ({
  value,
  placeholder = "",
  onSubmit,
  maxLength = 25,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = (e) => {
    const updatedValue = e.target.value;
    const reg = new RegExp("^(0|[1-9][0-9]{0," + maxLength + "})$");
    if (
      (!isNaN(updatedValue) && reg.test(updatedValue)) ||
      updatedValue === ""
    ) {
      setInputValue(updatedValue);
    }
  };

  return (
    <Input
      style={{ width: "75%" }}
      value={inputValue}
      onBlur={(e) => onSubmit(e.target.value)}
      placeholder={placeholder}
      onChange={handleOnChange}
      onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
    />
  );
};
