import React, { useCallback } from 'react';
import { AdditionalProperty } from 'src/modules/products/components/AdditionalProperty';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const AVAILABLE_TYPES = [
  'covid-info',
  'bam-link',
  'instagram-page',
  'facebook-page',
  'cancellation-refund',
  'website-contacts',
  'phone-contacts',
  'email-contacts',
  'map',
  'analytics',
  'other'
];

export const AdditionalPropertiesList = ({
  value = [],
  onChange,
  languages,
  selectedLanguage
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.additionalPropertiesList', t);

  const onChangeProperty = (changedProperty) => {
    const updatedProperties = value.map((prevProperty) => {
      if (prevProperty.id !== changedProperty.id) {
        return prevProperty;
      }
      // changed first Select value
      if (prevProperty.type !== changedProperty.type) {
        // reset second Select value
        return {
          ...changedProperty,
          name: prepareFields(languages),
          description: prepareFields(languages),
          href: '',
          imageUrl: ''
        };
      }
      return changedProperty;
    });
    onChange(updatedProperties);
  };

  const onDelete = useCallback(
    (property) => {
      onChange(value.filter(({ id }) => id !== property.id));
    },
    [onChange, value]
  );

  const onAddProperty = useCallback(() => {
    onChange([
      ...value,
      {
        id: (value?.at(-1)?.id || 0) + 1,
        name: prepareFields(languages),
        description: prepareFields(languages),
        href: '',
        imageUrl: ''
      }
    ]);
  }, [onChange, value]);

  // TODO limit possible property values to what is not selected
  return (
    <div>
      {value.map((property) => (
        <AdditionalProperty
          key={property.id}
          className='pt-4 pb-4 border-b border-dashed'
          value={property}
          availableTypes={AVAILABLE_TYPES}
          onDelete={() => onDelete(property)}
          onChange={onChangeProperty}
          selectedLanguage={selectedLanguage}
        />
      ))}
      <div className='pt-4'>
        <Button type='primary' className='uppercase' onClick={onAddProperty}>
          {text('addNewProperty')}
        </Button>
      </div>
    </div>
  );
};

/**
 * @example: If the available languages are Italian and English, this function returns the following object: { it: '', en: '' }
 */
const prepareFields = (languages) => {
  const fields = {};
  languages.forEach(({ language }) => {
    fields[language] = '';
  });
  return fields;
};
