export function makeOption (value, label) {
  return {
    value: value,
    label: label || value
  };
}

export function attributeIsRemote (attribute) {
  return attribute.id !== undefined;
}

export function attributeIsLocal (attribute) {
  return attribute.id === undefined;
}

export function containsAttribute (attributes, attribute) {
  return attributes.some(attr => attr.attributeKey === attribute.attributeKey);
}

export function replaceAttribute (newAttribute, array) {
  const index = array.findIndex(el => el.attributeKey === newAttribute.attributeKey);
  if (index !== -1) {
    const newArray = [...array];
    newArray[index] = newAttribute;
    return newArray;
  }
  return array;
}

export function attributeCodeFormWasEdited (form, languages, initialTranslations) {
  if (!initialTranslations) {
    return true;
  }
  const translationsWereEdited = languages.some(({ language }) => (
    initialTranslations[language] !== form.getFieldValue(`attributeCodeTranslations-${language}`)
  ));
  if (translationsWereEdited) {
    return true;
  }
  return false;
}

export function attributeKeyFormWasEdited (form, languages, attribute) {
  const imageWasEdited = form.isFieldTouched(`attributeImage-${attribute.attributeKey}`);
  if (imageWasEdited) {
    return true;
  }
  const translationsWereEdited = languages.some(language => (
    form.isFieldTouched(`attributeTranslations-${attribute.attributeKey}-${language.language}`)
  ));
  if (translationsWereEdited) {
    return true;
  }
  return false;
}
