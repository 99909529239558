import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { LocationAPI } from 'src/modules/locations/api/LocationAPI';

export const LocationDetailsContext = createContext();

const useProvideLocationDetails = () => {
  const params = useParams();
  const [location, setLocation] = useState(null);

  /**
   * @description Get location details
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getLocationDetails = useCallback(async () => {
    try {
      const res = await LocationAPI.getLocation(params.id);
      setLocation(res.data);
    } catch (e) {
      console.log(e);
    }
  }, [params.id]);

  useEffect(() => {
    getLocationDetails(params.id);
  }, [params, getLocationDetails]);

  return {
    location,
    setLocation
  };
};

export const LocationsDetailsProvider = ({ children }) => {
  const product = useProvideLocationDetails();
  return (
    <LocationDetailsContext.Provider value={product}>
      {children}
    </LocationDetailsContext.Provider>
  );
};

export const useLocationDetails = () => useContext(LocationDetailsContext);
