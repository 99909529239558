import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Button, Select, message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { ANY_OPTION, toOption } from 'src/utils/utils';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useBrands } from 'src/modules/brands/provider/brands.provider';
import { BrandsAPI } from '../api/BrandsAPI';
import { useHistory } from 'react-router-dom';
import { BrandTable } from 'src/modules/brands/components/BrandTable';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { DeletionModal } from 'src/components/DeletionModal';
import { CenteredText } from 'src/components/CenteredText';
import { useDepartment } from 'src/modules/admin/provider/department.provider';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { SCOPE } from 'src/utils/scope-utils';
import { Chips } from 'src/components/Chips';
import { debounce } from 'lodash';

export const PageBrands = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.brands', t);
  const textFilter = textFrom('constants.filter', t);

  const {
    query,
    brands,
    setBrands,
    pagination,
    onChangeQuery,
    areBrandsLoading
  } = useBrands();
  const history = useHistory();
  const { departments } = useDepartment();
  const { selectedUserScope, userDepartments } = useAuth();
  const [brandToBeDeleted, setBrandToBeDeleted] = useState(undefined);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(undefined);

  const departmentOptions = useMemo(() => {
    if (departments.length > 0) {
      const availableDepartments =
        ((selectedUserScope === SCOPE.LOCALBUSINESS) && userDepartments)
          ? [departments.find(dept => dept.key === userDepartments[0])]
          : departments;

      const options = availableDepartments.map(dept => toOption(dept.key));

      return (selectedUserScope === SCOPE.LOCALBUSINESS)
        ? options
        : [ANY_OPTION].concat(options);
    }
  }, [departments, selectedUserScope, userDepartments]);

  useEffect(() => {
    if (selectedUserScope) {
      const dept =
        selectedUserScope === SCOPE.LOCALBUSINESS
          ? userDepartments[0]
          : ANY_OPTION.value;
      initFilters(dept);
    }
  }, [selectedUserScope, userDepartments]);

  const initFilters = (selectedDepartment) => {
    setSelectedDepartment(selectedDepartment);
    onChangeQuery({
      ...query,
      department: selectedDepartment
    });
  };

  const FILTERS = {
    BRAND: 'brand',
    DEPARTMENT: 'department'
  };
  const { BRAND, DEPARTMENT } = FILTERS;

  const navigateToCreateBrand = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.BRAND_CREATE));
  };

  const BrandSearch = () => (
    <SearchInput
      autoFocus
      defaultValue={query.brand}
      onChangeText={handleSearchBrand}
      placeholder={text('searchPlaceholder')}
    />
  );

  const handleSearchBrand = (searchedText) => {
    onChangeQuery({
      ...query,
      brand: searchedText
    });
  };

  const DepartmentSelect = () => (
    <Select
      className='brandsSelect'
      allowClear={selectedUserScope !== SCOPE.LOCALBUSINESS && selectedDepartment !== ANY_OPTION.value}
      style={{ width: 150 }}
      value={selectedDepartment}
      onChange={handleSelectDepartment}
      onClear={debounce(() => handleSelectDepartment(ANY_OPTION.value), 500)}
      placeholder={text('selectDepartmentPlaceholder')}
      options={departmentOptions}
    />
  );

  const handleSelectDepartment = (selectedDepartment) => {
    setSelectedDepartment(selectedDepartment);
    onChangeQuery({
      ...query,
      department: selectedDepartment
    });
  };

  const hideModal = () => {
    setIsDeletionModalVisible(false);
  };

  const CreateBrandButton = () => (
    <Button
      type='primary'
      className='uppercase'
      onClick={navigateToCreateBrand}
    >
      {text('createNewBrand')}
    </Button>
  );

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const handleEdit = (brand) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.BRAND_EDIT, { id: brand.id }));
  };

  const showDeletionModal = (brand) => {
    setBrandToBeDeleted(brand);
    setIsDeletionModalVisible(true);
  };

  const deleteBrand = async () => {
    const status = await BrandsAPI.deleteBrand(brandToBeDeleted.id);
    switch (status) {
      case 204:
        setBrands(brands.filter(brand => brand.id !== brandToBeDeleted.id));
        message.success(text('onDeleteOk'));
        break;
      case 400:
        message.error(text('onDeleteErrBrandWithProducts'));
        break;
      default:
        message.error(text('onDeleteErrUnknown'));
    }
    setBrandToBeDeleted(undefined);
  };

  const onTagRemove = (key) => {
    if (key === BRAND) {
      handleSearchBrand('');
    } else if (key === DEPARTMENT) {
      handleSelectDepartment(ANY_OPTION.value);
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('brands')}</PageTitle>
      <Row gutter={[16, 16]} className='pt-2'>
        <Col span={6}>
          <BrandSearch />
        </Col>
        <Col flex='auto'>
          <DepartmentSelect />
        </Col>
        <Col>
          <CreateBrandButton />
        </Col>
      </Row>
      {/* Filter Chips */}
      {(!!query?.brand || !!query?.department) &&
        <Row className='py-4'>
          <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!query.brands && +1) + (!!query.department && +1) })}:
            <Chips
              query={query}
              chipKeys={[BRAND, DEPARTMENT]}
              onTagRemove={onTagRemove}
            />
          </div>
        </Row>}

      {!areBrandsLoading && !brands?.length
        ? (<CenteredText>{text('noBrandsFound')}</CenteredText>)
        : (<BrandTable
            loading={areBrandsLoading}
            className='pt-8'
            tableData={brands}
            onEdit={handleEdit}
            onDelete={showDeletionModal}
            onTableChange={onTableChange}
            pagination={pagination}
           />)}

      <DeletionModal
        onOk={deleteBrand}
        visible={isDeletionModalVisible}
        onCancel={hideModal}
      >
        <div>{text('askConfirmation')}</div>
      </DeletionModal>
    </div>
  );
};
