import { Route } from 'react-router-dom';
import React from 'react';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { PageUnauthorized } from 'src/modules/auth/pages/Unauthorized';

// TODO can we unify this and PrivateRoute ?
export const AuthoritiesChecker = ({ children, neededAuthorities = [], ...rest }) => {
  const { checkIfUserHasAuthorities } = useAuth();

  return (
    <Route
      {...rest}
      render={() => (checkIfUserHasAuthorities(neededAuthorities)) ? children : <PageUnauthorized />}
    />
  );
};
