import React from 'react'
import { Card, Skeleton } from 'antd';

const { Meta } = Card;
export const ProductCard = ({ data, isLoading }) => {
    return (
        <>
            {!isLoading ? <Card
                className='min-h-full'
                hoverable
                cover={<img alt={data.name} src={data.coverPhotoUrl} />}>
                <Meta title={data.name} description={`${data.attributeVariants.color.localeName} - ${data.macroProductCategory.localeName} ${data.microProductCategory.localeName} - ${data.currency} ${data.acquisitionPrice}`} />
            </Card> :
                <Card>
                    <Skeleton.Image />
                    <Skeleton />
                </Card>
            }
        </>


    )
}
