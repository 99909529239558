import React, { useState } from 'react';
import { Form, Input, Button, Layout, notification, Typography, Row, Col } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { AppVersion } from 'src/components/AppVersion';
import mcubeLogoTransparent from 'src/images/logo-mcube_transparent.png';

const { Text } = Typography;

const SingleDetail = ({ label, value, styling = null }) => {
  return (
    <div className={styling || 'flex flex-col'}>
      <Text>{label}</Text>
      <Text className='text-center' strong>{value}</Text>
    </div>
  );
};

export const PageLogin = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.login', t);

  const history = useHistory();
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await auth.signIn(data);
      return history.push(Routes.PATHS.DASHBOARD);
    } catch (e) {
      form.resetFields();
      notification.error({
        message: text('cannotAuthenticate'),
        description: text('checkEmailAndPassword')
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout className='h-full'>
      <Layout.Content className='flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <img
            className='mb-8 h-24'
            src={mcubeLogoTransparent}
            alt='M-Cube'
          />
          <div className='pb-16 pt-10 px-12 w-96 bg-white rounded shadow'>
            <SingleDetail
              styling='flex flex-col items-center justify-center'
              label={text('welcomeBack')}
            />
            <Form
              name='normal_login'
              onFinish={onSubmit}
              autoComplete='off'
              layout='vertical'
              form={form}
            >
              <div className='flex flex-col items-center justify-center my-8'>
                <Row className='w-full'>
                  <Col className='w-full'>
                    <Form.Item
                      name='email'
                      rules={[
                        { required: true, message: text('emailEmpty') },
                        { type: 'email', message: text('emailInvalid') }
                      ]}
                    >
                      <Input
                        placeholder={text('email')}
                      />
                    </Form.Item>
                    <Form.Item
                      name='password'
                      rules={[
                        { required: true, message: text('passwordEmpty') }
                      ]}
                    >
                      <Input.Password
                        placeholder={text('password')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {/* Actions */}
              <div className='flex flex-col items-center justify-center'>
                <Button
                  loading={isLoading}
                  type='primary'
                  htmlType='submit'
                  className='login-form-button uppercase w-full'
                >
                  {text('signIn')}
                </Button>
              </div>
            </Form>
          </div>
          <Link
            className='my-6 underline'
            to={Routes.parseRouteParams(Routes.PATHS.FORGOT_PASSWORD)}
          >
            {text('forgotPwd')}
          </Link>
          <AppVersion />
        </div>
      </Layout.Content>
    </Layout>
  );
};
