import React, {
    useEffect,
    } from 'react';
import {
    Col,
    Row,
    Form,
    Input,
    Typography,
    } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

export const MaxPickupsForSlot = ({ form, userInfo }) => {

    const { t } = useTranslation();
    const text = textFrom('components.maxPickupsForSlot', t);

    useEffect(() => {
        const schedule = userInfo.localBusiness.schedule;
        const lunch = schedule.find(slot => slot.slotOfTheDay === "LUNCH");
        const dinner = schedule.find(slot => slot.slotOfTheDay === "DINNER");

        form.setFieldsValue({
            maxPickupsForSlotLunch: lunch?.capacity || null,
            maxPickupsForSlotDinner: dinner?.capacity || null,
        });
    }, [userInfo.localBusiness.schedule]);

    const viewMaxPickupsForSlotLunch = (
        <Form.Item
            label={text('maxPickupsForSlotLunch') + ':'}
            name='maxPickupsForSlotLunch'
            required={false}
            >
            <Input
                type='number'
                placeholder={text('infinite')}
                />
        </Form.Item>
    );

    const viewMaxPickupsForSlotDinner = (
        <Form.Item
            label={text('maxPickupsForSlotDinner') + ':'}
            name='maxPickupsForSlotDinner'
            required={false}
        >
            <Input
                type='number'
                placeholder={text('infinite')} 
            />
        </Form.Item>
    );

    return (
        <div form={form}>
            <div className="pb-8">
                    <Title level={5} className="pb-6 py-8">
                        {text('maxPickupsForSlot')}
                    </Title>
                    <Row gutter={[18, 18]} >
                        <Col xs={3}>{viewMaxPickupsForSlotLunch}</Col>
                        <Col xs={3}>{viewMaxPickupsForSlotDinner}</Col>
                    </Row>
            </div>
        </div>
    );
}