import React from 'react';
import { Col, Row, Tag } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { CenteredText } from 'src/components/CenteredText';
import { PageTitle } from 'src/components/PageTitle';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useTranslation } from 'react-i18next';
import { useTableHeight } from 'src/hooks/useTableHeight';
import { useVisualMerchandising } from 'src/modules/visualMerchandising/provider/visualMerchandisingList.provider';
import { VisualMerchandisingTable } from 'src/modules/upload/components/VisualMerchandisingTable';

export const PageVisualMerchandisingList = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.pageVisualMerchandisingList', t);
  const textFilter = textFrom('constants.filter', t);

  const { onFiltersChange, filteredVisualMerchandisingList, areVisualsLoading, filterValue, setFilterValue } = useVisualMerchandising();

  const handleVisualMerchandisingSearch = (value) => {
    setFilterValue(value);
    onFiltersChange('name', value);
  };

  const tableHeight = useTableHeight();

  const onTagRemove = () => {
    handleVisualMerchandisingSearch('');
  };

  return (
    <div>
      <div className='py-4'>
        <PageTitle>{text('visualMerchandising')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2 pb-4'>
          <Col span={6}>
            <SearchInput
              autoFocus
              placeholder={text('visualMerchandisingSearch')}
              onChangeText={handleVisualMerchandisingSearch}
            />
          </Col>
        </Row>
        {/* Filter Chips */}
        {filterValue &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: 1 })}:</div>
            <Tag
              className='cursorTag'
              closable
              onClose={onTagRemove}
              onClick={onTagRemove}
            >
              {filterValue}
            </Tag>
          </Row>}
      </div>

      {!areVisualsLoading && !filteredVisualMerchandisingList?.length
        ? (<CenteredText>{text('noMerchandisingFound')}</CenteredText>)
        : (<VisualMerchandisingTable
            loading={areVisualsLoading}
            scroll={{ y: tableHeight }}
            visuals={filteredVisualMerchandisingList}
           />)}
    </div>
  );
};
