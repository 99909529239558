import { useCallback, useState } from "react";
import { AxiosClient } from "src/api/AxiosClient";
import { useLatestRef } from "src/hooks/useLatestRef";
import fileDownload from "js-file-download";

function download(url, filename) {
  return AxiosClient.get(url, {
    responseType: "blob",
  }).then((res) => {
    fileDownload(res.data, filename);
    return res;
  });
}

export const useDownloadFile = ({ onError = () => {} } = {}) => {
  const onErrorRef = useLatestRef(onError);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const call = useCallback(
    (url, filename) => {
      setIsLoading(true);

      download(url, filename)
        .then(() => {
          setError(null);
          setIsLoading(false);
        })
        .catch((err) => {
          onErrorRef.current(err);
          setError(err);
          setIsLoading(false);
        });
    },
    [onErrorRef]
  );


  return { call, isLoading, error };
};
