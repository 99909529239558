export class UploadTypes {
  static get TYPE_KEYS () {
    return {
      PRODUCT_FOOD: 'product_food',
      PRODUCT_EVENT: 'product_event',
      PRODUCT_FASHION: 'product_fashion',
      CATEGORY: 'category',
      STOCK: 'stock',
      BRAND: 'brand',
      ATTRIBUTE: 'attribute',
      OUTFIT: 'outfit',
      CATALOG_IMAGES: 'catalog_images',
      COUPON: 'coupon',
      COLLECTION: 'collection'
    };
  }
}
