import React, { useMemo, useState } from 'react';
import { Col, Row, Table, Button } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const BaseTable = ({
  icon,
  title,
  items,
  loading,
  emptyText,
  onClickRow
}) => {
  const COLUMNS = [
    {
      width: '80%',
      title: title,
      render: name => name
    },
    {
      render: (_, row) =>
        <Button
          className='button-margin'
          type='primary'
          icon={icon}
          onClick={onClickRow?.bind(_, row)}
        />
    }
  ];

  return (
    <Table
      className='dual-list-selector'
      loading={loading}
      columns={COLUMNS}
      dataSource={items}
      pagination={false}
      locale={{ emptyText: emptyText }}
      size='small'
      scroll={{ y: '40vh' }}
    />
  );
};

const SourceTable = props => <BaseTable {...props} icon={<PlusOutlined />} />;

const TargetTable = props => <BaseTable {...props} icon={<DeleteOutlined />} />;

function isVisible (item, searchedItem, selectedItems) {
  const isSearched = item.toLowerCase().includes(searchedItem);
  const isAlreadySelected = selectedItems?.includes(item);
  return isSearched && !isAlreadySelected;
}

export const DualListSelector = ({
  isLoading,
  items,
  selectedItems,
  setSelectedItems,
  searchPlaceholder,
  sourceTitle,
  targetTitle
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.dualListSelector', t);

  const [searchedItem, setSearchedItem] = useState('');

  const searchItem = (text) => {
    setSearchedItem(text.toLowerCase().trim());
  };

  const selectItem = (newItem) => {
    setSelectedItems(items => [newItem, ...items]);
  };

  const deselectItem = (oldItem) => {
    setSelectedItems(items => items.filter(item => item !== oldItem));
  };

  const availableItems = useMemo(() =>
    items.filter(item => isVisible(item, searchedItem, selectedItems))
  , [items, searchedItem, selectedItems]);

  return (
    <>
      <Row gutter={16} className='pt-2'>
        <Col span={6}>
          <SearchInput
            onChangeText={searchItem}
            placeholder={searchPlaceholder}
          />
        </Col>
      </Row>

      <Row gutter={16} className='pt-6'>
        <Col span={12}>
          <SourceTable
            title={sourceTitle}
            emptyText={text('noItemsFound')}
            loading={isLoading}
            onClickRow={selectItem}
            items={availableItems}
          />
        </Col>

        <Col span={12}>
          <TargetTable
            title={targetTitle}
            emptyText={text('noItemsSelected')}
            loading={isLoading}
            onClickRow={deselectItem}
            items={selectedItems}
          />
        </Col>
      </Row>
    </>
  );
};
