import React, {
  useMemo,
  useRef,
  useCallback
} from 'react';
import { Row, Col, Tag, Table } from 'antd';
import { PageTitle } from 'src/components/PageTitle';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useFashionProducts } from 'src/modules/products/provider//fashionProducts.provider';
import { CustomMultiselect } from 'src/components/inputs/CustomMultiselect';
import { ProductsListPayload } from 'src/modules/products/api/payloads/ProductsListPayload';
import { ProductsQuery } from 'src/modules/products/domain/models/ProductsQuery';
import { useFilterTags } from 'src/hooks/useFilterTags';
import { Product } from 'src/services/Product.service';
import { CenteredText } from 'src/components/CenteredText';
import { CategoriesAPI } from 'src/modules/categories/api/CategoriesAPI';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { ProductStatusTag } from 'src/modules/products/components/ProductStatusTag';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

const { API_FILTERS_KEYS } = ProductsListPayload;
const { KEYS } = ProductsQuery;

export const ProductTable = ({ onAdd }) => {
  const header = useRef();
  const { t } = useTranslation();
  const text = textFrom('components.outfitProductTable', t);
  const textFilter = textFrom('constants.filter', t);
  const { userDepartments } = useAuth();

  const admittedFilters = ['text', 'macroCategory', 'microCategory'];

  const {
    query,
    products,
    availableFilters,
    pagination,
    onChangeQuery,
    areProductsLoading
  } = useFashionProducts();

  const filterTags = useFilterTags(
    query,
    availableFilters?.filters || {}
  );

  const getMacroCategories = useCallback((page) => {
    return CategoriesAPI.getMacroCategories(page, userDepartments[0]);
  }, []);

  const getMicroCategories = useCallback((page, macroKey) => {
    return CategoriesAPI.getMicroCategories(macroKey, page);
  }, []);

  /**
   * @description Full search - for the moment handles only ean and it is not an autocomplete
   * @param value
   */
  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      text: value,
      current: 1
    });
  };

  /**
   * This method is being called when are being applied the multiselect filter (category and subcategory) - Check the buttons inside the multiselect dropdown
   * @param key
   * @param values
   */
  const onChangeFilter = (key, values) => {
    onChangeQuery({
      ...query,
      [key]: values,
      current: 1
    });
  };

  /**
   * @description This method handle the remove of applied filters on Filters applied section - On the click of 'X' button this method is being called
   * @param tag
   */
  const onTagRemove = (tag) => {
    if (Array.isArray(query[tag.key])) {
      const updatedValues = query[tag.key].filter(
        (value) => tag.value !== value
      );
      onChangeQuery({
        ...query,
        [tag.key]: updatedValues.length ? updatedValues : null
      });
    } else {
      onChangeQuery({
        ...query,
        [tag.key]: null
      });
    }
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const usedFilters = useMemo(() => filterTags.filter((tag) => admittedFilters.includes(tag.key)), [filterTags]);

  /**
   * @description  Handle all the columns being displayed on the product table
   * @type {[{dataIndex: string[], width: string, title: string, render: (function(*, *))}, {dataIndex: string[], editable: boolean, width: string, inputType: string, title: string, render: (function(*, *))}, {inputOptions, dataIndex: string[], editable: boolean, inputType: string, title: string, render: (function(*, *): *)}, {inputOptions, dataIndex: string[], editable: boolean, inputType: string, title: string, render: (function(*, *): *)}, {inputOptions, dataIndex: string[], inputType: string, title: string, render: (function(*))}, null, null]}
   */
  const columns = useMemo(
    () => [
      {
        title: 'Image',
        dataIndex: ['coverPhotoUrl'],
        width: '7%',
        render: (_, record) => (
          <div className='flex items-center'>
            <img
              className='w-8 h-8 object-cover object-center'
              src={record.coverPhotoUrl}
            />
          </div>
        )
      },
      {
        title: text('name'),
        dataIndex: ['name'],
        inputType: 'text',
        width: '25%',
        render: (_, record) => record.name
      },
      {
        title: text('ean'),
        dataIndex: 'ean',
        width: '20%',
        render: (_, record) => record?.sku
      },
      {
        title: text('macro'),
        width: '17%',
        dataIndex: ['macroProductCategory'],
        render: (_, record) => record.macroProductCategory.label
      },
      {
        title: text('micro'),
        width: '17%',
        dataIndex: ['microProductCategory'],
        render: (_, record) => record.microProductCategory.label
      },
      {
        title: text('status'),
        width: '10%',
        dataIndex: ['published'],
        render: (status) => <ProductStatusTag status={status} />,
        inputType: 'published-select',
        inputOptions: Product.statusOptions
      },
      {
        align: 'center',
        render: (_, row) => {
          return (
            <PopoverActionMenu>
              <MenuAction onClick={onAdd.bind(_, row.id)} label={text('add')} />
            </PopoverActionMenu>
          );
        }
      }
    ],
    [getMacroCategories, getMicroCategories]
  );

  return (
    <div className='py-4'>
      <div ref={header}>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          {/* EAN Search */}
          <Col span={4}>
            <SearchInput
              placeholder={text('searchPlaceholder')}
              onChangeText={handleFullTextSearch}
              defaultValue={query.text}
            />
          </Col>

          {/* Macrocategory */}
          <Col span={4}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MACRO_CATEGORY]}
              values={query[KEYS.MACRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MACRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MACRO_CATEGORY, null)}
              placeholder={text('macro')}
              className='m-2'
              keyProperty='key'
              labelProperty='value'
            />
          </Col>

          {/* Microcategory */}
          <Col span={4}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MICRO_CATEGORY]}
              values={query[KEYS.MICRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MICRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MICRO_CATEGORY, null)}
              placeholder={text('micro')}
              className='m-2'
            />
          </Col>
        </Row>
        {usedFilters.length > 0 &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: filterTags.length })}:</div>
            {usedFilters.map((tag, i) => {
              return (
                <Tag
                  className='cursorTag'
                  key={`${tag.key}-${tag.label}-${i}`}
                  closable
                  onClose={() => onTagRemove(tag)}
                  onClick={() => onTagRemove(tag)}
                >
                  {tag.key === 'published'
                    ? Product.getStatus(tag.label)
                    : tag.label ?? tag.value}
                </Tag>
              );
            }
            )}
          </Row>}
      </div>

      {!areProductsLoading && !products?.length
        ? (<CenteredText>{text('noProductsFound')}</CenteredText>)
        : (
          <Row>
            <Col span={24}>
              <Table
                loading={areProductsLoading}
                dataSource={products}
                columns={columns}
                pagination={{ ...pagination, showSizeChanger: false }}
                onChange={onTableChange}
              />
            </Col>
          </Row>

          )}
    </div>
  );
}
;
