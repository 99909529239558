import { UploadTypes } from '../domain/models/UploadTypes';
import templateFileBrands from 'src/downloadable/template-brands.csv';
import templateFileStocks from 'src/downloadable/template-stocks.csv';
import templateFileOutfits from 'src/downloadable/template-outfits.csv';
import templateFileCoupons from 'src/downloadable/template-coupons.csv';
import templateFileCategories from 'src/downloadable/template-categories.csv';
import templateFileAttributes from 'src/downloadable/template-attributes.csv';
import templateFileCollections from 'src/downloadable/template-collections.csv';
import templateFileProductsFood from 'src/downloadable/template-products-food.csv';
import templateFileProductsEvent from 'src/downloadable/template-products-event.csv';
import templateFileProductsFashion from 'src/downloadable/template-products-fashion.csv';

const { TYPE_KEYS } = UploadTypes;

export function getTemplateFile (type, department) {
  switch (type) {
    case TYPE_KEYS.BRAND:
      return FileInfo.make('template-brands.csv', templateFileBrands);

    case TYPE_KEYS.STOCK:
      return FileInfo.make('template-stocks.csv', templateFileStocks);

    case TYPE_KEYS.COUPON:
      return FileInfo.make('template-coupons.csv', templateFileCoupons);

    case TYPE_KEYS.OUTFIT:
      return FileInfo.make('template-outfits.csv', templateFileOutfits);

    case TYPE_KEYS.CATEGORY:
      return FileInfo.make('template-categories.csv', templateFileCategories);

    case TYPE_KEYS.ATTRIBUTE:
      return FileInfo.make('template-attributes.csv', templateFileAttributes);

    case TYPE_KEYS.COLLECTION:
      return FileInfo.make('template-collections.csv', templateFileCollections);

    case TYPE_KEYS.PRODUCT_FOOD:
    case TYPE_KEYS.PRODUCT_EVENT:
    case TYPE_KEYS.PRODUCT_FASHION:
      switch (department) {
        case 'food':
          return FileInfo.make('template-products-food.csv', templateFileProductsFood);
        case 'event':
          return FileInfo.make('template-products-event.csv', templateFileProductsEvent);
        case 'fashion':
          return FileInfo.make('template-products-fashion.csv', templateFileProductsFashion);
      }
  }
}

class FileInfo {
  static make (name, path) {
    return { name, path };
  }
}
