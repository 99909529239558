import React, { useEffect } from 'react';
import { Table, Input, message, Form } from 'antd';
import { CategoriesAPI } from '../api/CategoriesAPI';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { nestify } from 'src/utils/objectManipulation';
import { SEPARATOR } from 'src/utils/constants';
import { ImageUpload } from 'src/modules/admin/component/ImageUpload';
import { UtilsAPI } from 'src/api/utils';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';
import { RankingInput } from './RankingInput';

export const CategoriesEditableTable = ({
  type, // macro | micro
  pagination,
  languages,
  setCategories,
  categories,
  form,
  selectedLanguage,
  loading,
  imageList,
  setImageList,
  onTableChange,
  onDelete
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.categoriesEditableTable', t);

  const onSave = async (index, imageList, values, form) => {
    const formValues = form.getFieldsValue(true); // Get all the values, even the unmounted ones
    const translationsPrefixed = nestify(formValues, [`${values.key}${SEPARATOR}localizedValues`]);
    const translations = translationsPrefixed?.[`${values.key}`]?.localizedValues;

    const otherImages = values.imageList?.slice(1) || [];
    const imageListPayload = imageList[index]?.imageUrl
      ? [{
          imageURL: imageList[index]?.imageUrl,
          active: true,
          sizeType: 'IMAGE_ORIGINAL'
        },
        ...otherImages]
      : values.imageList;
    const payload = {
      id: values?.id,
      key: values?.key,
      type: values?.type,
      localizedValues: translations,
      ranking: formValues[`${values.key}${SEPARATOR}ranking`],
      active: true,
      imageList: imageListPayload
    };

    if (values.id) {
      try {
        await CategoriesAPI.editCategory(payload);
        message.success(`Category ${payload.key} ${text('actionMenu.savedCorrectly')}`, 5);
      } catch (err) {
        if (err.response?.status === 409) {
          message.error(text('onEditErrDuplicate'));
        } else {
          message.error(text('onEditErrUnknown'));
        }
      }
    }
  };

  const handleLogoUpload = async (oldCategory, index, file) => {
    const res = await UtilsAPI.uploadImage(file);
    const newImageUrl = res?.data?.images?.[0]?.imageURL;

    if (newImageUrl) {
      setCategories(oldCategories => {
        const newCategories = [...oldCategories];
        const newCategory = { ...oldCategory, photoAttributes: newImageUrl };
        newCategories[index] = newCategory;
        return newCategories;
      });
      setImageList(oldImageList => {
        const newImageList = [...oldImageList];
        newImageList[index] = { imageUrl: newImageUrl };
        return newImageList;
      });
    } else {
      message.error(text('onImageUploadError'));
    }
  };

  const columns = [
    {
      title: text('image'),
      key: 'image',
      dataIndex: 'image',
      width: '10%',
      render: (_, row, index) => (
        <ImageUpload
          isDisabled={!row.editable}
          imageUrl={row.photoAttributes}
          onUpload={handleLogoUpload.bind(_, row, index)}
        />
      )
    },
    {
      title: text('key'),
      key: 'key',
      dataIndex: 'key',
      width: '20%',
      render: key => key
    },
    type === 'macro'
      ? {
          title: text('department'),
          key: 'department',
          dataIndex: 'department',
          width: '15%',
          render: dept => dept
        }
      : {
          title: text('macrocategory'),
          key: 'macrocategory',
          dataIndex: 'parentKey',
          width: '15%',
          render: macro => macro
        },
    {
      title: text('translation'),
      key: 'translation',
      dataIndex: 'translation',
      width: '40%',
      render: (_, category) => {
        return (
          <Form.Item
            style={{ marginBottom: '0' }}
            required={false}
            name={`${category.key}${SEPARATOR}localizedValues${SEPARATOR}${selectedLanguage}${SEPARATOR}name`}
          >
            <Input disabled={!category.editable} />
          </Form.Item>
        );
      }
    },
    {
      title: text('ranking'),
      key: 'ranking',
      dataIndex: 'ranking',
      width: '10%',
      render: (_, category) =>
        <RankingInput
          name={`${category.key}${SEPARATOR}ranking`}
          disabled={!category.editable}
        />
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: '5%',
      align: 'center',
      render: (_, values, index) => {
        const actionMenu = (
          <PopoverActionMenu>
            <MenuAction onClick={() => onSave(index, imageList, values, form)} label={text('save')} />
            <MenuAction onClick={() => onDelete(values)} label={text('delete')} />
          </PopoverActionMenu>
        );
        return values.editable ? actionMenu : null;
      }
    }
  ];

  useEffect(async () => {
    const extractLocalizedNames = {};
    languages.forEach((lang) => {
      const languageValue = categories.map((itm) => {
        return {
          [itm?.key]: itm?.localizedValues?.[lang?.language]?.name
        };
      });
      extractLocalizedNames[lang?.language] = [...languageValue];
    });

    const resultObj = {};
    for (const [key, value] of Object.entries(extractLocalizedNames)) {
      const object = value.reduce(
        (obj, item) => {
          return Object.assign(obj, { [Object.keys(item)[0]]: Object.values(item)[0] });
        }, {});
      resultObj[key] = object;
    }
    form.setFieldsValue({ names: { ...resultObj } });
  }, [categories, languages]);

  return (
    <Table
      style={{ width: '100%' }}
      className='pt-8'
      columns={columns}
      dataSource={categories}
      pagination={{ ...pagination, showSizeChanger: false }}
      loading={loading}
      onChange={onTableChange}
    />
  );
};
