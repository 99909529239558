import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Form, Input, Checkbox, Typography, Select } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { Option } = Select;

const HTTPS = 'https://';
const payloadURLParsingRule = /.*(:\/\/)/;
const DEFAULT_CONTENT_TYPE = 'application/json';
const DEFAULT_SSL_VERIFICATION = 'ON';

const SelectPayloadUrlAddonBefore = () => (
  <Select defaultValue={HTTPS}>
    <Option value={HTTPS}>{HTTPS}</Option>
  </Select>
);

export const WebhookForm = ({
  form,
  mode,
  buttons,
  onFinish,
  initialContexts
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.webhookForm', t);

  const parsePayloadUrlProtocol = () => {
    const payloadURLFormValue = form.getFieldsValue().payloadURL;
    const parsedPayloadURL = payloadURLFormValue.replace(payloadURLParsingRule, '');
    form.setFieldsValue({
      payloadURL: parsedPayloadURL
    });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      autoComplete='off'
      scrollToFirstError
      onFinish={onFinish}
      initialValues={{
        contentType: DEFAULT_CONTENT_TYPE,
        sslVerification: DEFAULT_SSL_VERIFICATION
      }}
    >
      <Row gutter={20} className='pt-2 mb-4'>
        <Col span={8}>
          <Form.Item
            name='payloadURL'
            label={text('payloadURL')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('payloadURLRequired') }]}
          >
            <Input
              onBlur={parsePayloadUrlProtocol}
              addonBefore={<SelectPayloadUrlAddonBefore />}
              placeholder={text('payloadURLPlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20} className='mb-4'>
        <Col span={4}>
          <Form.Item
            name='clientId'
            label={text('clientId')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('clientIdRequired') }]}
          >
            <Input
              placeholder={text('clientIdPlaceholder')}
              disabled={mode === 'edit'}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name='contentType'
            label={text('contentType')}
            className='w-full px-4 pb-4'
          >
            <Input
              placeholder={text('contentTypePlaceholder')}
              disabled
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name='sslVerification'
            label={text('sslVerification')}
            className='w-full px-4 pb-4'
          >
            <Input
              placeholder={text('sslVerificationPlaceholder')}
              disabled
            />
          </Form.Item>
        </Col>
      </Row>

      <Text>{text('whichWebhookEvents')}</Text>

      <ContextCheckboxes initValues={initialContexts} />

      {buttons}

    </Form>
  );
};

const OPTIONS = [
  'cart.payment-error',
  'order.status-transition',
  'backoffice-user.creation',
  'customer-user.creation'
];

const ContextCheckboxes = ({ initValues }) => {
  const { t } = useTranslation();
  const text = textFrom('components.webhookForm', t);

  const [values, setValues] = useState(initValues);

  useEffect(() => {
    setValues(initValues);
  }, [initValues]);

  const options = useMemo(() => (
    OPTIONS.map(opt => ({ value: opt, label: text(opt) }))
  ), [OPTIONS]);

  const onChange = option => e => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setValues(old => [...old, option]);
    } else {
      setValues(old => old.filter(v => v !== option));
    }
  };

  return (
    <Form.Item
      name='contexts'
      valuePropName='checked'
    >
      {values !== undefined &&
        <Checkbox.Group value={values}>
          {options.map(({ value, label }) => (
            <Row className='my-2' key={value}>
              <Checkbox
                value={value}
                onChange={onChange(value)}
                checked={values.includes(value)}
                defaultChecked={values.includes(value)}
              >
                {label}
              </Checkbox>
            </Row>
          ))}
        </Checkbox.Group>}
    </Form.Item>
  );
};
