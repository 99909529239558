import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export const HelperText = ({ text, className }) => {
  return (
    <div className={`${className}`}>
      <Text type="secondary">{text}</Text>
    </div>
  )
}
