import React, { useState, useCallback } from 'react';
import { Col, Row, message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { BackButton } from 'src/components/BackButton';
import { useHistory } from 'react-router-dom';
import { PageSection } from 'src/components/PageSection';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DangerButton } from 'src/components/buttons/DangerButton';
import { DeletionModal } from 'src/components/DeletionModal';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { PermissionSetsAPI } from '../api/PermissionSetsAPI';
import { PermissionSetForm } from '../component/PermissionSetForm';
import { usePermissionSets } from '../provider/permissionSets.provider';

export const PageEditPermissionSet = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.permission-sets/edit', t);

  const history = useHistory();
  const { permissionSet } = usePermissionSets();
  const [selectedAuths, setSelectedAuths] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const goToPermissionSetPage = useCallback(() => {
    history.push(Routes.PATHS.PERMISSION_SETS);
  }, []);

  const showModal = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const hideModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const deletePermissionSet = async () => {
    await PermissionSetsAPI.deletePermissionSet(permissionSet.code);
    goToPermissionSetPage();
  };

  const onFinish = async ({ setName, apiName, enabledByDefault }) => {
    if (selectedAuths.length === 0) {
      message.error(text('noSelectedAuthorizations'));
    } else {
      try {
        await PermissionSetsAPI.updatePermissionSet(
          apiName,
          selectedAuths,
          { en: setName },
          { en: setName }, // FIXME: Temporary workaround, as agreed with Giacomo
          enabledByDefault
        );
        message.success(text('onUpdateOk'));
        goToPermissionSetPage();
      } catch (e) {
        message.error(text('onUpdateErr'));
      }
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={goToPermissionSetPage}
      />

      <PageSection>{text('identification')}</PageSection>
      <PermissionSetForm
        permissionSet={permissionSet}
        selectedAuths={selectedAuths}
        setSelectedAuths={setSelectedAuths}
        onFinish={onFinish}
      >
        <Row gutter={20} className='mt-8'>
          <Col>
            <DangerButton label={text('delete')} onClick={showModal} />
          </Col>
          <Col flex='auto' className='text-right'>
            <DefaultButton label={text('cancel')} onClick={goToPermissionSetPage} />
          </Col>
          <Col>
            <SubmitButton label={text('updatePermissionSet')} />
          </Col>
        </Row>
      </PermissionSetForm>

      <DeletionModal
        visible={isModalVisible}
        onOk={deletePermissionSet}
        onCancel={hideModal}
        msgOnSuccess={text('onDeleteOk')}
        msgOnError={text('onDeleteErr')}
      >
        <div>{text('confirmDeletion')}</div>
      </DeletionModal>

    </div>
  );
};
