import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { EventsAPI } from 'src/modules/products/api/EventsAPI';
import { ProductsAPI } from '../api/ProductsAPI';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { WarehousesAPI } from 'src/modules/warehouses/api/WarehousesAPI';

export const eventDetailsContext = createContext();

const useProvideEventDetails = (text) => {
  const params = useParams();
  const [event, setEvent] = useState(null);
  const [availableStartDates, setAvailableStartDates] = useState([]);
  const [availableEventMicroCategories, setAvailableEventMicroCategories] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [initialVariantsCount, setInitialVariantsCount] = useState(0);
  const [warehouses, setWareHouses] = useState([]);

  /**
   * @description Get event details
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getEventDetails = async (id) => {
    try {
      const res = await EventsAPI.getProductVariant(id);
      if (res.data.data.length > 0) {
        // get data for each event variant
        const variants = [];
        for (let index = 0; index < res.data.data.length; index++) {
          const element = res.data.data[index];
          const eventDataRes = await EventsAPI.getProductDetails(
            id,
            element.id
          );
          const variant = eventDataRes.data;
          // This to manage properly variant's initial availability/seating qty editing
          const variantEnhanced = {
            ...eventDataRes.data,
            soldTickets: (+variant.initialAvailability - +(variant.availableSeatsQty)) || 0,
            originalInitialAv: variant.initialAvailability
          };
          variants.push(variantEnhanced);
        }
        setEvent(variants[0]);
        setProductVariants(variants);
        setInitialVariantsCount(variants.length);
      }
    } catch (e) {
      message.error(text('somethingWentWrong'));
    }
  };

  /**
   * @description Get warehouses
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getWarehouses = useCallback(async () => {
    const query = { limit: 1000, filters: { department: 'event' } };
    try {
      const res = await WarehousesAPI.getWarehouses(query);
      const warehouses = res?.data?.data?.items?.map((item) => ({
        ...item
      }));
      setWareHouses(warehouses);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getAvailableStartDates = async () => {
    try {
      const startDateRes = await EventsAPI.getAvailableStartDates();

      const startDates = (startDateRes?.data?.data?.items ?? []).map((item) => item.attributeKey);

      setAvailableStartDates(startDates);
    } catch (error) {
      console.error(error);
    }
  };

  const getAvailableEventMicroCategories = async () => {
    try {
      const microCategoriesRes = await EventsAPI.getAvailableEventMicroCategories();

      const microCategories = (microCategoriesRes?.data?.data?.items ?? []).map((item) => ({
        value: item.key,
        name: item.key,
        macroCategory: item.parentKey
      }));

      setAvailableEventMicroCategories(microCategories);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @description Add Product image - gallery section
   * @type {(function(*=, *=): Promise<AxiosResponse<*>|undefined>)|*}
   */
  const addProductGalleryImage = useCallback(async (productId, file) => {
    try {
      return await ProductsAPI.addProductGalleryImage(productId, file);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  useEffect(() => {
    if (params?.id) {
      getEventDetails(params.id);
    }
    getAvailableStartDates();
    getAvailableEventMicroCategories();
    getWarehouses();
  }, [params?.id]);

  return {
    event,
    setEvent,
    availableStartDates,
    availableEventMicroCategories,
    addProductGalleryImage,
    productVariants,
    setProductVariants,
    initialVariantsCount,
    warehouses
  };
};

export const EventDetailsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.eventDetails', t);

  const product = useProvideEventDetails(text);
  return (
    <eventDetailsContext.Provider value={product}>
      {children}
    </eventDetailsContext.Provider>
  );
};

export const useEventDetails = () => useContext(eventDetailsContext);
