const API_BASE_URL = window.REACT_APP_API_BASE_URL;
const LOGIN_API_BASE_URL = window.REACT_APP_LOGIN_API_BASE_URL;
const LOGIN_X_API_KEY = window.REACT_APP_LOGIN_X_API_KEY;
const USE_CENTRALIZED_LOGIN = window.REACT_APP_USE_CENTRALIZED_LOGIN;
const API_STENTLE_BASE_SUFFIX = window.REACT_APP_API_STENTLE_BASE_SUFFIX;
const API_MICROSERVICE_BASE_SUFFIX = window.REACT_APP_API_MICROSERVICE_BASE_SUFFIX;
const API_TENANT = window.REACT_APP_API_TENANT;
const LANGUAGE = window.REACT_APP_LANGUAGE;

export {
  API_BASE_URL,
  LOGIN_API_BASE_URL,
  LOGIN_X_API_KEY,
  USE_CENTRALIZED_LOGIN,
  API_STENTLE_BASE_SUFFIX,
  API_MICROSERVICE_BASE_SUFFIX,
  API_TENANT,
  LANGUAGE
};
