import React, { useState } from 'react';
import { Modal, message } from 'antd';

export const DeletionModal = ({
  onOk,
  visible,
  onCancel,
  msgOnError,
  msgOnSuccess,
  children
}) => {
  const [isDeletionPending, setIsDeletionPending] = useState(false);

  const handleOk = async () => {
    setIsDeletionPending(true);
    try {
      await onOk();
      if (msgOnSuccess) {
        message.success(msgOnSuccess);
      }
    } catch (e) {
      if (msgOnError) {
        message.error(msgOnError);
      }
    } finally {
      setIsDeletionPending(false);
      onCancel();
    }
  };

  return (
    <Modal
      onOk={handleOk}
      onCancel={onCancel}
      visible={visible}
      confirmLoading={isDeletionPending}
    >
      {children}
    </Modal>
  );
};
