import React, { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { useLocalBusiness } from '../provider/localBusiness.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { LocalBusinessAPI } from '../api/localBusinessAPI';
import { message } from 'src/services/Messages.service';
import { Routes } from 'src/router/Routes.helper';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { LocalBusinessForm } from '../component/LocalBusinessForm';
import { PageTitle } from 'src/components/PageTitle';
import { LOCALBUSINESS_FORM, getCheckedTags } from '../utils/utils';

function flattenLocalizedValues (obj) {
  const newObj = {};
  Object.entries(obj || {}).forEach(([k, v]) => { newObj[k] = v?.value; });
  return newObj;
}

export const PageEditLocalBusiness = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.localbusinesses/edit', t);
  const departmentText = textFrom('entities.departments', t);

  const [form] = useForm();
  const history = useHistory();
  const { localBusiness, categories } = useLocalBusiness();

  const navigateToLocalBusinessesPage = () => {
    history.push(Routes.PATHS.LOCAL_BUSINESSES);
  };

  useEffect(() => {
    if (localBusiness && categories.length > 0) {
      initializeForm(localBusiness);
    }
  }, [localBusiness, categories]);

  const initializeForm = (localBusiness) => {
    form.setFieldsValue({
      affiliatedCategory: localBusiness.microCategory?.key,
      backofficeUserEmail: localBusiness.email,
      city: localBusiness.address?.city,
      department: departmentText(localBusiness.departments[0].key),
      externalBusinessId: localBusiness.externalLocalBusinessId,
      localBusinessName: localBusiness.name,
      parentCategory: localBusiness.macroCategory?.key,
      streetAddress: localBusiness.address?.streetAddress,
      stripeId: localBusiness.paymentServiceAccounts?.stripeId,
      taxID: localBusiness.taxID,
      zipCode: localBusiness.address?.postalCode
    });
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton
          label={text('cancel')}
          onClick={navigateToLocalBusinessesPage}
        />
      </Col>
      <Col>
        <SubmitButton
          label={text('updateLocalBusiness')}
        />
      </Col>
    </Row>
  );

  const onFinish = async ({
    affiliatedCategory,
    city,
    externalBusinessId,
    localBusinessName,
    parentCategory,
    streetAddress,
    taxID,
    zipCode
  }) => {
    const getCheckedTagsAt = getCheckedTags(form, LOCALBUSINESS_FORM.KEY, LOCALBUSINESS_FORM.CHECKED);

    const checkedGeneralTags = getCheckedTagsAt(LOCALBUSINESS_FORM.GENERAL_TAGS);
    const checkedParentCategoryTags = getCheckedTagsAt(LOCALBUSINESS_FORM.MACRO_TAGS);
    const checkedAffiliatedCategoryTags = getCheckedTagsAt(LOCALBUSINESS_FORM.MICRO_TAGS);

    const updatedLocalBusinessData = { ...localBusiness };

    updatedLocalBusinessData.names = {
      en: localBusinessName,
      it: localBusinessName
    };

    updatedLocalBusinessData.greetings = flattenLocalizedValues(updatedLocalBusinessData.greetings);
    updatedLocalBusinessData.descriptions = flattenLocalizedValues(updatedLocalBusinessData.descriptions);
    updatedLocalBusinessData.name = localBusinessName;
    updatedLocalBusinessData.taxID = taxID;
    updatedLocalBusinessData.address.city = city;
    updatedLocalBusinessData.address.postalCode = zipCode;
    updatedLocalBusinessData.address.streetAddress = streetAddress;
    updatedLocalBusinessData.externalLocalBusinessId = externalBusinessId;
    updatedLocalBusinessData.macroCategoryKey = (parentCategory && parentCategory !== 'any') ? parentCategory : null;
    updatedLocalBusinessData.microCategoryKey = (affiliatedCategory && affiliatedCategory !== 'any') ? affiliatedCategory : null;
    updatedLocalBusinessData.tags = checkedGeneralTags.concat(checkedParentCategoryTags).concat(checkedAffiliatedCategoryTags);
    try {
      await LocalBusinessAPI.updateLocalBusiness(localBusiness.id, updatedLocalBusinessData);
      message.success(text('editOK'));
      navigateToLocalBusinessesPage();
    } catch (e) {
      message.error(text('editKO'));
    }
  };

  const toDepartmentOption = dept => ({
    key: dept.key,
    value: dept.key,
    label: departmentText(dept.key)
  });

  const departmentOptions = useMemo(() => (
    localBusiness?.departments?.map(toDepartmentOption) || []
  ), [localBusiness]);

  return (
    <div className='py-4'>
      <PageTitle>{text('editLocalBusiness')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToLocalBusinessesPage}
        title={text('backToLocalBusinesses')}
      />

      <LocalBusinessForm
        form={form}
        mode='edit'
        buttons={buttons}
        departmentOptions={departmentOptions}
        onFinish={onFinish}
        originalTags={localBusiness?.tags}
      />

    </div>
  );
};
