import React, { useCallback, useMemo } from "react";
import { Table, Row, Col } from "antd";
import {
  sortableContainer,
  sortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { NumericInput } from "src/modules/warehouses/components/NumericInput";
import { MenuOutlined } from "@ant-design/icons";
import { useLatestRef } from "src/hooks/useLatestRef";

export const SortableTable = ({ data, columns, onReorder, isLoading, count, scroll }) => {
  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);
  const onReorderRef = useLatestRef(onReorder)


  const onSortEnd = useCallback((data) => ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      //   setFilteredVisualMerchandisingProducts(newData);
      onReorderRef.current(newData);
    }
  }, [onReorderRef]);

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd(data)}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = data.findIndex((x) => x.id === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const _columns = useMemo(
    () => [...columns, getPositionColumn(onSortEnd(data))],
    [columns, data, onSortEnd]
  );

  return (
    <div>
      <Table
        size="middle"
        loading={isLoading}
        pagination={false}
        dataSource={data}
        columns={_columns}
        rowKey="id"
        scroll={scroll}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      <Row className="flex justify-end">
        <Col className="text-center" span={6}>
          <div className="font-semibold text-base text-gray-600 mt-2">Count: {count}</div>
        </Col>
      </Row>
    </div>

  );
};

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const getPositionColumn = (onReorder) => ({
  title: "Position",
  dataIndex: "[id]",
  className: "drag-visible inputWidth",
  render: (text, record, index) => (
    <>
      <NumericInput
        value={index + 1}
        onSubmit={(value) =>
          onReorder({ oldIndex: index, newIndex: parseInt(value) - 1 })
        }
      />{" "}
      <span className="pl-2 anticonSvg">
        {" "}
        <DragHandle />
      </span>
    </>
  ),
});
