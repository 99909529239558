import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { useForm } from 'antd/lib/form/Form';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DangerButton } from 'src/components/buttons/DangerButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { useWarehouses } from '../provider/warehouses.provider';
import { DeletionModal } from 'src/components/DeletionModal';
import { WarehousesAPI } from '../api/WarehousesAPI';
import { WarehouseForm } from '../components/WarehouseForm';
import { useTranslation } from 'react-i18next';

export const PageEditWarehouse = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.warehouses/edit', t);

  const [form] = useForm();
  const history = useHistory();
  const { warehouse } = useWarehouses();
  const [isPending, setIsPending] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigateToWarehousesPage = () => {
    history.push(Routes.PATHS.WAREHOUSES);
  };

  useEffect(() => {
    if (warehouse) {
      initializeForm(warehouse);
    }
  }, [warehouse]);

  const initializeForm = (warehouse) => {
    form.setFieldsValue({
      name: warehouse.name,
      type: warehouse.type,
      companyName: warehouse.company,
      taxId: warehouse.taxId,
      ecommerce: warehouse.ecommerce,
      companyAddress: warehouse.address?.streetAddress,
      companyEmail: warehouse.email,
      priority: warehouse.fulfillmentPriority,
      warehouseIdentifier: warehouse.warehouseIdentifier,
      externalWarehouseIdentifier: warehouse.externalWarehouseIdentifier
    });
  };

  const onFinish = async (values) => {
    setIsPending(true);
    const data = {
      name: values.name,
      type: values.type,
      company: values.companyName,
      taxId: values.taxId,
      address: { streetAddress: values.companyAddress },
      email: values.companyEmail,
      ecommerce: values.ecommerce,
      fulfillmentPriority: values.priority,
      warehouseIdentifier: values.warehouseIdentifier,
      externalWarehouseIdentifier: values.externalWarehouseIdentifier
    };
    try {
      await WarehousesAPI.updateWarehouse(warehouse.id, data);
      message.success(text('onUpdateOK'));
      navigateToWarehousesPage();
    } catch (e) {
      message.error(text('onUpdateKO'));
    } finally {
      setIsPending(false);
    }
  };

  const showDeletionModal = () => {
    setIsModalVisible(true);
  };

  const hideDeletionModal = () => {
    setIsModalVisible(false);
  };

  const confirmWarehouseDeletion = async () => {
    await WarehousesAPI.deleteWarehouse(warehouse.id);
    navigateToWarehousesPage();
  };

  const buttons = (
    <div className='flex w-full mt-12'>
      <DangerButton
        label={text('delete')}
        onClick={showDeletionModal}
      />
      <DefaultButton
        className='ml-auto mr-4'
        label={text('cancel')}
        onClick={navigateToWarehousesPage}
      />
      <SubmitButton
        label={text('saveChanges')}
        loading={isPending}
        disabled={isPending}
      />
    </div>
  );

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={navigateToWarehousesPage}
      />

      <WarehouseForm
        form={form}
        buttons={buttons}
        onFinish={onFinish}
        initialEcommerceIsChecked={warehouse?.ecommerce}
        mode='edit'
      />

      <DeletionModal
        visible={isModalVisible}
        onOk={confirmWarehouseDeletion}
        onCancel={hideDeletionModal}
        msgOnSuccess={text('onDeleteOk')}
        msgOnError={text('onDeleteErr')}
      >
        <div>{text('confirmDeletion')}</div>
      </DeletionModal>
    </div>
  );
};
