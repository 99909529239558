import React, { useState, useMemo, useCallback } from 'react';
import { Row, Col } from 'antd';
import { Chips } from 'src/components/Chips';
import { textFrom } from 'src/utils/textFrom';
import { useCategory } from '../provider/categories.provider';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { CategoriesTable } from './CategoriesTable';

function categoryToSection (category) {
  const names = {};
  Object.entries(category.localizedValues).forEach(([k, v]) => { names[k] = v.name; });
  return {
    id: category.id,
    code: category.key,
    type: 'CATALOG',
    names: names,
    bannerMobile: { imageURL: category.photoAttributes },
    call: {
      type: 'IN',
      ids: [category.key]
    }
  };
}

export const CategorySelector = ({
  type = 'macro', /* macro | micro */
  selectedItems,
  setSelectedItems
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.lookbookHomepage', t);

  const [selectedCategories, setSelectedCategories] = useState([]);

  const {
    query,
    macros,
    areMacrosLoading,
    macrosPagination,
    micros,
    areMicrosLoading,
    microsPagination,
    onChangeQuery
  } = useCategory();

  const handleSearchCategory = (searchedText) => {
    onChangeQuery({
      ...query,
      search: searchedText
    });
  };

  const onClearSearch = () => {
    handleSearchCategory('');
  };

  const CategorySearch = () => (
    <SearchInput
      defaultValue={query.search}
      onChangeText={handleSearchCategory}
      placeholder={text('searchByName')}
    />
  );

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const alreadyPresent = useCallback(category => (
    selectedItems.some(item => item.id === category.id)
  ), [selectedItems]);

  const availableCategories = useMemo(() => (
    type === 'macro'
      ? macros.filter(macro => !alreadyPresent(macro))
      : micros.filter(micro => !alreadyPresent(micro))
  ), [macros, micros, alreadyPresent]);

  const addCategoriesToHomepage = () => {
    const newItems = selectedCategories.map(categoryToSection);
    setSelectedItems(items => items.concat(newItems));
    setSelectedCategories([]);
  };

  return (
    <>
      <Row gutter={16} justify='space-between' align='bottom' className='py-4'>
        <Col span={6}>
          {type === 'macro' ? text('searchMacros') : text('searchMicros')}
          <CategorySearch />
        </Col>
        <Col>
          <PrimaryButton
            label={type === 'macro' ? text('addSelectedMacros') : text('addSelectedMicros')}
            onClick={addCategoriesToHomepage}
          />
        </Col>
      </Row>
      {!!query?.search &&
        <Row className='py-4'>
          <div className='pr-2'>{text('appliedFilter')}:
            <Chips
              query={query}
              chipKeys={['search']}
              onTagRemove={onClearSearch}
            />
          </div>
        </Row>}
      <CategoriesTable
        loading={type === 'macro' ? areMacrosLoading : areMicrosLoading}
        categories={availableCategories}
        pagination={type === 'macro' ? macrosPagination : microsPagination}
        onTableChange={onTableChange}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
    </>
  );
};
