import React from 'react';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

export const LookbookHomepageTable = ({
  loading,
  sections,
  rowSelection
}) => {
  const { t, i18n } = useTranslation();
  const text = textFrom('pages.lookbookHomepage', t);
  const language = i18n.language;

  const COLUMNS = [
    {
      title: text('coverImage'),
      dataIndex: ['bannerDesktop', 'imageURL'],
      render: (url) => <ImageThumbnail url={url} />,
      align: 'center',
      width: '15%'
    },
    {
      title: text('name'),
      dataIndex: ['names', `${language}`],
      render: (name) => <div>{name}</div>
    },
    {
      title: text('type'),
      dataIndex: 'type',
      render: (type) => <div>{type}</div>
    }
  ];

  return (
    <Table
      rowKey='id'
      loading={loading}
      columns={COLUMNS}
      dataSource={sections}
      rowSelection={rowSelection}
      pagination={false}
    />
  );
};

const ImageThumbnail = ({ url }) => (
  <div className='centered'>
    <div className='staticImage'>
      <img src={url} />
    </div>
  </div>
);
