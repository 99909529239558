import { Filters } from "../services/Filters.service";

export class PayloadListAbstract {
  text;
  pageNumber;
  limit;
  filters;

  // Removes empty objects and falsy values from instance
  getCleanPayload() {
    return Filters.removeEmptyObjects({
      ...this,
      filters: Filters.removeFalsyValues(this.filters),
    });
  }
}
