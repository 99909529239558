import { Dom } from "src/services/Dom";
import { useState, useEffect } from "react";
import { useDebounce } from "./useDebounce";
import { useLatestRef } from "./useLatestRef";

const noop = () => {};

export function useWindowSize({
  includeScrollbars = true,
  onResize = noop,
  onResizeEnd = noop,
  callOnMount = false,
} = {}) {
  const onResizeRef = useLatestRef(onResize);
  const [windowSize, setWindowSize] = useState(
    Dom.getWindowSize(includeScrollbars)
  );

  const onResizeEndDebounced = useDebounce(onResizeEnd, 75);

  useEffect(() => {
    const listener = () => {
      const windowSize = Dom.getWindowSize(includeScrollbars);
      setWindowSize(windowSize);
      onResizeRef.current(windowSize);
      onResizeEndDebounced(windowSize);
    };

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [onResizeRef, includeScrollbars, onResizeEndDebounced]);

  useEffect(() => {
    if (callOnMount) {
      onResizeRef.current(Dom.getWindowSize(includeScrollbars));
      onResizeEndDebounced(Dom.getWindowSize(includeScrollbars));
    }
  }, [callOnMount, onResizeEndDebounced, onResizeRef, includeScrollbars]);

  return windowSize;
}
