import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useFilters } from 'src/hooks/useFilters';
import { usePagination } from 'src/hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { PermissionSetsAPI } from '../api/PermissionSetsAPI';
import { PermissionSetsMapper } from '../domain/PermissionSets.mapper';
import { useParams, useLocation } from 'react-router-dom';

const PermissionSetsContext = createContext();

const useProvidePermissionSets = (text) => {
  const params = useParams();
  const location = useLocation();
  const [permissionSet, setPermissionSet] = useState();
  const [permissionSets, setPermissionSets] = useState([]);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(PermissionSetsMapper);
  const [arePermissionSetsLoading, setArePermissionSetsLoading] = useState(false);

  const getPermissionSets = useCallback(async () => {
    setArePermissionSetsLoading(true);
    const permissionSetQuery = { ...query };
    try {
      const res = await PermissionSetsAPI.getPermissionSets(PermissionSetsMapper.fromQueryToPayload(permissionSetQuery));
      setPermissionSets(res?.data?.items || []);
      setPagination(res?.data || {});
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrievePermissionSets'));
      return Promise.reject(e);
    } finally {
      setArePermissionSetsLoading(false);
    }
  }, [query, setPagination, location]);

  const getPermissionSet = useCallback(async () => {
    try {
      const res = await PermissionSetsAPI.getPermissionSet(params.id);
      setPermissionSet(res?.data);
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrievePermissionSet'));
      return Promise.reject(e);
    }
  }, [params.id]);

  useEffect(() => {
    getPermissionSets();
  }, [location.search, getPermissionSets]);

  useEffect(() => {
    if (params.id) {
      getPermissionSet();
    }
  }, [params.id]);

  return {
    query,
    permissionSet,
    permissionSets,
    pagination,
    setPermissionSets,
    onChangeQuery,
    getPermissionSets,
    arePermissionSetsLoading
  };
};

export const PermissionSetsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.permissionSets', t);

  const permissionSets = useProvidePermissionSets(text);

  return (
    <PermissionSetsContext.Provider value={permissionSets}>
      {children}
    </PermissionSetsContext.Provider>
  );
};

export const usePermissionSets = () => useContext(PermissionSetsContext);
