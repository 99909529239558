import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Tag } from 'antd';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useLocalBusiness } from '../provider/localBusiness.provider';
import { Routes } from 'src/router/Routes.helper';
import { PageTitle } from 'src/components/PageTitle';
import { LocalBusinessTable } from '../component/LocalBusinessTable';

export const PageLocalBusinesses = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.localbusinesses', t);
  const textFilter = textFrom('constants.filter', t);

  const {
    query,
    localBusinesses,
    pagination,
    onChangeQuery,
    areLocalBusinessesLoading
  } = useLocalBusiness();

  const [searchText, setSearchText] = useState(null);
  const history = useHistory();

  useEffect(() => {
    onChangeQuery({
      ...query,
      text: searchText
    });
  }, [searchText]);

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const onStartEditing = (localBusiness) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.LOCAL_BUSINESSES_EDIT, { id: localBusiness.id }));
  };

  const onTagRemove = () => {
    handleFullTextSearch('');
  };

  const handleFullTextSearch = (value) => {
    setSearchText(value);
  };

  const navigateToCreateLocalBusiness = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.LOCAL_BUSINESSES_CREATE));
  };

  return (
    <>
      <div className='py-4 bg-gray-100 page-filters'>
        <PageTitle>{text('localbusinesses')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={8}>
            <SearchInput
              autoFocus
              placeholder={text('searchPlaceholder')}
              onChangeText={handleFullTextSearch}
            />
          </Col>

          <Col span={16}>
            <div className='flex justify-end'>
              <Button
                className='uppercase'
                type='primary'
                onClick={navigateToCreateLocalBusiness}
              >
                {text('createNewLocalBusiness')}
              </Button>
            </div>
          </Col>
        </Row>
        {/* Filter Chips */}
        {searchText &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: 1 })}:</div>
            <Tag
              className='cursorTag'
              closable
              onClose={onTagRemove}
              onClick={onTagRemove}
            >
              {searchText}
            </Tag>
          </Row>}
      </div>
      {areLocalBusinessesLoading || localBusinesses?.length > 0
        ? (
          <LocalBusinessTable
            loading={areLocalBusinessesLoading}
            localBusinesses={localBusinesses}
            onEdit={onStartEditing}
            pagination={pagination}
            onTableChange={onTableChange}
          />)
        : (
          <div className='flex items-center  justify-center mt-4'>
            <p>{text('noLocalBusinessFound')}</p>
          </div>
          )}
    </>
  );
};
