import React, { useCallback } from 'react';
import { Table, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { Product } from 'src/services/Product.service';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { ProductStatusTag } from './ProductStatusTag';
import { ProductsListPayload } from '../api/payloads/ProductsListPayload';
import { MenuAction, PopoverActionMenu } from 'src/components/PopoverActionMenu';

const { API_FILTERS_KEYS } = ProductsListPayload;

export const FoodTable = (props) => {
  const { t } = useTranslation();
  const text = textFrom('components.foodTable', t);

  const [form] = Form.useForm();
  const history = useHistory();

  const onToggleAvailability = (record) => {
    props.handleToggleAvailability([record.id], !record.availabilityTotal);
  };

  const onToggleStatus = (record) => {
    props.handleToggleStatus(record.id, record.published);
  };

  const handleDuplicate = useCallback(
    (record) => {
      history.push(`/app/products/${record.id}`, { product: record, mode: 'duplicate' });
    },
    [history]
  );

  const columns = [
    {
      title: '',
      dataIndex: ['coverPhotoUrl'],
      width: '10%',
      render: (_, record) => (
        <div className='flex items-center'>
          <img
            className='w-10 object-cover object-center'
            src={record.coverPhotoUrl}
          />
        </div>
      )
    },
    {
      title: text('name'),
      dataIndex: ['name'],
      editable: true,
      inputType: 'text',
      width: '25%',
      render: (_, record) => record.name
    },
    {
      title: text('macro'),
      dataIndex: ['macroProductCategory', 'key'],
      render: (_, record) => record.macroProductCategory.localeName,
      editable: true,
      inputType: 'select',
      inputOptions: props.availableFilters.filters[API_FILTERS_KEYS.MACRO_CATEGORY]
    },
    {
      title: text('micro'),
      dataIndex: ['microProductCategory', 'key'],
      render: (_, record) => record.microProductCategory.localeName,
      editable: true,
      inputType: 'select',
      inputOptions: props.availableFilters.filters[API_FILTERS_KEYS.MICRO_CATEGORY]
    },
    {
      title: text('status'),
      dataIndex: ['published'],
      render: (status) => <ProductStatusTag status={status} />,
      inputType: 'published-select',
      editable: true,
      inputOptions: Product.statusOptions
    },
    {
      title: text('availability'),
      dataIndex: ['availabilityTotal'],
      editable: true,
      inputType: 'text',
      render: (_, record) => record.availabilityTotal > 0 ? 'Available' : 'Unavailable'
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 200,
      align: 'center',
      render: (_, record) => {
        return (
          <PopoverActionMenu>
            <MenuAction onClick={() => props.onEdit(record)} label={text('edit')} />
            <MenuAction onClick={() => onToggleAvailability(record)} label={`${record.availabilityTotal ? text('makeUnavailable') : text('makeAvailable')}`} />
            <MenuAction onClick={() => onToggleStatus(record)} label={`${record.published === 'Published' ? text('changeToDraft') : text('changeToPublished')}`} />
            <MenuAction onClick={() => handleDuplicate(record)} label={text('duplicate')} />
            <MenuAction onClick={() => props.onDelete(record)} label={text('delete')} />
          </PopoverActionMenu>
        );
      }
    }
  ];

  return (
    <Form form={form} component={false}>
      <Table
        loading={props.loading}
        className={props.onRowClick ? 'clickable' : ''}
        rowKey='id'
        scroll={{ y: window.innerHeight - 350 }}
        dataSource={props.products}
        columns={columns}
        pagination={{ ...props.pagination, showSizeChanger: false }}
        onChange={props.onTableChange}
      />
    </Form>
  );
};
