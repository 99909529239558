import React, { useState, useEffect, useContext, useCallback, createContext } from 'react';
import { message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { OrdersAPI } from 'src/modules/orders/api/OrdersAPI';
import { useFilters } from 'src/hooks/useFilters';
import { useLocation } from 'react-router-dom';
import { OrdersMapper } from 'src/modules/orders/domain/Orders.mapper';
import { usePagination } from 'src/hooks/usePagination';

const FashionOrdersContext = createContext();

const useProvideFashionOrders = () => {
  const auth = useAuth();
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(OrdersMapper);
  const [areOrdersLoading, setAreOrdersLoading] = useState(false);

  const getOrders = useCallback(async () => {
    setAreOrdersLoading(true);
    const filters = {
      department: ['fashion']
    };
    const businessId = auth?.userInfo?.localBusiness?.id;
    if (businessId) {
      filters.local_business_id = businessId;
    }

    try {
      const res = await OrdersAPI.getOrders({
        filters,
        limit: query?.pageSize || 10,
        pageNumber: query?.current > 0 ? +query.current : 0,
        orderingList: [
          {
            key: 'update_date',
            value: 'descending'
          }
        ],
        text: query.text || ''
      });
      setOrders(res.data.facetPage.content);
      setPagination({
        itemsPerPage: res.data.facetPage.size,
        totalItems: res.data.facetPage.totalElements,
        startIndex: res.data.facetPage.number * res.data.facetPage.size
      });
    } catch (e) {
      message.error('Failed to retrieve orders!');
    } finally {
      setAreOrdersLoading(false);
    }
  }, [query, setPagination, location.pathname]);

  useEffect(() => {
    getOrders();
  }, [location.search, getOrders]);

  return {
    query,
    orders,
    setOrders,
    pagination,
    onChangeQuery,
    areOrdersLoading,
    refreshData: getOrders
  };
};

export const FashionOrdersProvider = ({ children }) => {
  const orders = useProvideFashionOrders();
  return (
    <FashionOrdersContext.Provider value={orders}>{children}</FashionOrdersContext.Provider>
  );
};

export const useFashionOrders = () => useContext(FashionOrdersContext);
