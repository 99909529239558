import React from 'react';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

const makeColumns = (text, onEdit /*, onDelete */) => [ // DELETE is disabled purposefully
  {
    title: text('clientId'),
    dataIndex: 'clientId',
    key: 'clientId',
    render: (cliendId) => cliendId
  },
  {
    title: text('tenant'),
    dataIndex: 'tenant',
    key: 'tenant',
    render: (tenant) => tenant
  },
  {
    title: text('contexts'),
    dataIndex: 'contexts',
    key: 'contexts',
    render: (contexts) => {
      return (
        <>
          {contexts.map(context => (
            <div key={context}>
              {text(context)}
            </div>
          ))}
        </>
      );
    }
  },
  {
    title: '',
    dataIndex: 'operation',
    align: 'center',
    width: 200,
    render: (_, record) => {
      return (
        <PopoverActionMenu>
          <MenuAction onClick={onEdit.bind(_, record)} label={text('edit')} />
          {/* <MenuAction onClick={onDelete.bind(_, row)} label={text('delete')} /> */}
        </PopoverActionMenu>
      );
    }
  }
];

export const WebhookTable = ({
  loading,
  onEdit,
  onDelete,
  webhooks,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.webhooks', t);

  const COLUMNS = makeColumns(text, onEdit, onDelete);

  return (
    <Table
      loading={loading}
      columns={COLUMNS}
      dataSource={webhooks}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
      rowClassName='cursor-pointer'
    />
  );
};
