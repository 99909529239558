import { AxiosClient } from "src/api/AxiosClient";
import { CatalogModel } from "src/modules/visualMerchandising/domain/models/CatalogModel";
import { ProductsOrderModel } from "src/modules/visualMerchandising/domain/models/ProductsOrderModel";

export class VisualMerchandisingAPI {
    /**
     * @description Get All visuals
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getVisualMerchandisingList() {
        return AxiosClient.get(`/visuals`)
    }

    /**
     * @desc Request to get product catalog for a selected visual
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    static async getVisualMerchandisingProducts(data) {
        const payload = new CatalogModel(data)
        return AxiosClient.post('/catalog', payload)
    }

    static async updateProductsOrder (products, filters, params) {
        const payload = new ProductsOrderModel(products, filters, params.visualMerchandising)
        return AxiosClient.put(`/visuals/${params.VisualMerchandisingId}`, payload)
    }
}
