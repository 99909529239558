import React, { useState, useMemo, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Form,
  Modal,
  Button,
  Select,
  message
} from 'antd';

import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { CategoriesAPI } from '../api/CategoriesAPI';
import { CategoriesEditableTable } from '../component/CategoriesEditableTable';
import { useFilters } from 'src/hooks/useFilters';
import { CategoriesMapper } from '../domain/categories.mapper';
import { usePagination } from 'src/hooks/usePagination';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { flatten } from 'src/utils/objectManipulation';
import { PageTitle } from 'src/components/PageTitle';
import { DeletionModal } from 'src/components/DeletionModal';
import { SEPARATOR, KEY_REGEX } from 'src/utils/constants';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { SCOPE } from 'src/utils/scope-utils';
import { toOption } from 'src/utils/utils';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { Chips } from 'src/components/Chips';
import { RankingInput } from '../component/RankingInput';

const { LOCALBUSINESS } = SCOPE;

const FILTERS = {
  SEARCH: 'search'
};

function collectTranslations (allTranslations, category) {
  allTranslations[`${category.key}${SEPARATOR}localizedValues`] = category.localizedValues;
  return allTranslations;
}

const initializeMultiLangForms = (form, categories) => {
  const allTranslations = categories.reduce(collectTranslations, {});
  if (categories) {
    form.setFieldsValue({
      ...flatten(allTranslations)
    });
  }
};

export const PageCreateMacroCategories = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.categories/create', t);
  const [form] = useForm();
  const { selectedUserScope, userDepartments, canCreateMacrocategory } = useAuth();
  const { languages, defaultLanguage } = useLanguages();
  const [categories, setCategories] = useState([]);
  const [parents, setParents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [indexImg, setIndexImg] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [macroToBeDeleted, setMacroToBeDeleted] = useState(undefined);

  const [pagination, setPagination] = usePagination();

  const { query, onChangeQuery } = useFilters(CategoriesMapper);

  useEffect(async () => {
    // SET DEP
    const data = await CategoriesAPI.getCategories(['DEPARTMENT'], 1, 100);
    if (data?.items?.length > 0) {
      setParents(data.items);
    } else {
      setParents([]);
    }
  }, []);

  const departmentOptions = useMemo(() => {
    if (parents.length > 0) {
      const availableDepartments =
        ((selectedUserScope === LOCALBUSINESS) && userDepartments)
          ? [parents.find(dept => dept.key === userDepartments[0])]
          : parents;
      return availableDepartments.map(dept => toOption(dept.key));
    }
  }, [parents, selectedUserScope, userDepartments]);

  useEffect(() => {
    if (selectedUserScope === LOCALBUSINESS) {
      const defaultDept = userDepartments?.[0];
      if (defaultDept) {
        form.setFieldsValue({ parentKey: defaultDept });
      }
    }
  }, [selectedUserScope, userDepartments]);

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  useEffect(async () => {
    // SET MACRO
    setLoading(true);
    const data = await CategoriesAPI.getCategories(['ROOT'], +query?.current, +query?.pageSize, userDepartments[0], query?.search);
    const macros = data?.items || [];
    setCategories(macros);
    setImageList(macros.map(el => ({ imageUrl: el.photoAttributes })));
    setPagination(data);
    setLoading(false);
    onChangeQuery();
  }, [query]);

  useEffect(() => {
    if (defaultLanguage) {
      form.setFieldsValue({ language: defaultLanguage.label });
      setSelectedLanguage(defaultLanguage?.language);
    }
  }, [form, defaultLanguage]);

  useEffect(() => {
    initializeMultiLangForms(form, categories);
    categories.forEach(category => {
      form.setFieldsValue({ [`${category.key}${SEPARATOR}ranking`]: category.ranking });
    });
  }, [categories]); // FIXME: should 'form' be added to the dependencies array?

  const insertImageUrlToCategory = (index) => {
    const copyImageList = [...imageList];
    copyImageList[index] = { imageUrl: imageUrl };
    setImageList(copyImageList);
    setImageUrl('');
  };

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguage(languageId);
  };

  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      search: value,
      current: 1
    });
  };

  const onFinish = async ({ category, parentKey, ranking }) => {
    const payload = {
      key: category,
      type: 'ROOT',
      ranking,
      parentKey
    };
    try {
      await CategoriesAPI.createCategory(payload);
      window.location.reload(false);
    } catch (err) {
      if (err.response?.status === 409) {
        message.error(text('onCreateErrDuplicate'));
      } else {
        message.error(text('onCreateErrUnknown'));
      }
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIndexImg(null);
    insertImageUrlToCategory(indexImg);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setImageUrl(null);
    setIndexImg(null);
  };

  const onDelete = async () => {
    if (macroToBeDeleted.id) {
      try {
        await CategoriesAPI.deleteCategory(macroToBeDeleted.id);
        message.success(text('onDeleteOk'));
        window.location.reload(false);
      } catch (e) {
        if (e.response?.status === 406) {
          message.error(text('onDeleteErrInUse'));
        } else {
          message.error(text('onDeleteErrUnknown'));
        }
      }
    }
  };

  const showDeletionModal = (macro) => {
    setMacroToBeDeleted(macro);
    setIsDeletionModalVisible(true);
  };

  const LANG = 'it';

  const clearSearch = () => {
    onChangeQuery({
      ...query,
      search: undefined
    });
  };

  const onTagRemove = (key) => {
    if (key === FILTERS.SEARCH) {
      clearSearch();
    }
  };

  const MacrocategorySearch = () => (
    <SearchInput
      defaultValue={query.search}
      onChangeText={handleFullTextSearch}
      placeholder={text('searchPlaceholder')}
    />
  );

  return (
    <>
      <div className='py-4'>
        <Form
          name='basic'
          onFinish={onFinish}
          // onValuesChange={onValuesChange}
          autoComplete='off'
          layout='vertical'
          form={form}
          className='max-w-8xl'
        >
          <PageTitle>{text('macrocategories')}</PageTitle>
          <Row gutter={20} className='pt-2' style={{ alignItems: 'flex-start' }}>
            <Col xs={6}>
              <LanguageSelect
                onSelect={handleLanguageSelection}
              />
            </Col>
          </Row>
          {canCreateMacrocategory &&
            <Row gutter={20} style={{ marginTop: '3rem', minHeight: '6rem' }}>
              <Col xs={6}>
                <Form.Item
                  label={text('form.createCategory.label')}
                  name='category'
                  rules={[
                    { required: true, message: text('form.createCategory.message') },
                    {
                      message: text('form.createCategory.keyInvalid'),
                      pattern: KEY_REGEX
                    }
                  ]}
                >
                  <Input
                    placeholder={text('form.createCategory.placeholder')}
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <Form.Item
                  label={text('form.selectDepartment.label')}
                  name='parentKey'
                  rules={[{ required: true, message: text('form.selectDepartment.message') }]}
                >
                  <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    placeholder={text('form.selectDepartment.placeholder')}
                    showSearch
                    options={departmentOptions}
                  />
                </Form.Item>
              </Col>
              <Col xs={6}>
                <RankingInput
                  name='ranking'
                  label={text('ranking')}
                />
              </Col>
              <Col xs={6}>
                <p />
                <Button
                  type='primary'
                  htmlType='submit'
                  className='uppercase mt-4'
                >
                  {text('createCategory')}
                </Button>
              </Col>
            </Row>}
          <Row gutter={20} style={{ alignItems: 'flex-end', marginTop: '3rem' }}>
            <Col xs={6}>
              <MacrocategorySearch />
            </Col>
          </Row>

          {(!!query?.search) &&
            <Row className='py-4'>
              <div className='pr-2'>{text('appliedFilter', { count: +(!!query.search && +1) })}:
                <Chips
                  query={query}
                  chipKeys={[FILTERS.SEARCH]}
                  onTagRemove={onTagRemove}
                />
              </div>
            </Row>}

          <Row gutter={20} style={{ alignItems: 'flex-end', marginTop: '1rem' }}>
            <CategoriesEditableTable
              lang={LANG}
              type='macro'
              categories={categories}
              setCategories={setCategories}
              setIsModalOpen={setIsModalOpen}
              languages={languages}
              pagination={pagination}
              form={form}
              selectedLanguage={selectedLanguage}
              imageList={imageList}
              setImageList={setImageList}
              loading={loading}
              onDelete={showDeletionModal}
              onTableChange={onTableChange}
            />
          </Row>
        </Form>
      </div>
      <Modal title='Insert image url' visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input type='url' value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
      </Modal>

      <DeletionModal
        onOk={onDelete}
        visible={isDeletionModalVisible}
        onCancel={() => setIsDeletionModalVisible(false)}
      >
        <div>{text('askConfirmation')}</div>
      </DeletionModal>

    </>
  );
};
