import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { CollectionsAPI } from '../api/CollectionsAPI';
import { usePagination } from 'src/hooks/usePagination';
import { useFilters } from 'src/hooks/useFilters';
import { CollectionGroupsMapper } from '../domain/CollectionGroups.mapper';
import { useLocation, useParams } from 'react-router-dom';
import { hasMoreThanXCharacters } from 'src/utils/utils';

const CollectionGroupsContext = createContext();

const useProvideCollectionGroups = (text) => {
  const location = useLocation();
  const params = useParams();
  const [collectionGroups, setCollectionGroups] = useState([]);
  const [areGroupsLoading, setAreGroupsLoading] = useState(false);
  const [groupsPagination, setGroupsPagination] = usePagination();
  const [previousSearch, setPreviousSearch] = useState(undefined);
  const { query: groupsQuery, onChangeQuery: onChangeGroupsQuery } = useFilters(CollectionGroupsMapper);

  const [collectionGroupDetail, setCollectionGroupDetail] = useState(null);

  const getCollectionGroups = useCallback(async (groupsQuery) => {
    const query = { ...groupsQuery };
    setAreGroupsLoading(true);
    try {
      const res = await CollectionsAPI.getCollectionGroups(CollectionGroupsMapper.fromQueryToPayload(query));
      setCollectionGroups(res?.data?.items || []);
      setGroupsPagination(res?.data || {});
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveGroups'));
      return Promise.reject(e);
    } finally {
      setAreGroupsLoading(false);
    }
  }, [groupsQuery, location]);

  const getCollectionGroupDetail = useCallback(async (id) => {
    try {
      const res = await CollectionsAPI.getCollectionGroupDetail(id);
      setCollectionGroupDetail(res?.data ?? {});
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, [params.id]);

  useEffect(() => {
    if ([undefined, null].includes(previousSearch) || hasMoreThanXCharacters(groupsQuery.text, 2) || hasMoreThanXCharacters(previousSearch, 2)) {
      getCollectionGroups(groupsQuery);
    }
    setPreviousSearch(groupsQuery.text);
  }, [location.search, groupsQuery]);

  useEffect(() => {
    if (params.id) {
      getCollectionGroupDetail(params.id);
    }
  }, [params.id]);

  return {
    collectionGroups,
    areGroupsLoading,
    groupsPagination,
    groupsQuery,
    onChangeGroupsQuery,
    collectionGroupDetail,
    refreshCollectionGroups: getCollectionGroups
  };
};

export const CollectionGroupsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.collections', t);

  const collectionGroups = useProvideCollectionGroups(text);

  return (
    <CollectionGroupsContext.Provider value={collectionGroups}>
      {children}
    </CollectionGroupsContext.Provider>
  );
};

export const useCollectionGroups = () => useContext(CollectionGroupsContext);
