import React, { useMemo, useState } from "react";
import { Checkbox } from "antd";
import { SearchInput } from "src/components/inputs/SearchInput";

export const InputMultiSelectSearch = ({
  items,
  values,
  onChange,
  placeholder,
  className,
  showBorder = true,
  searchProperty = "key",
  keyProperty = "key",
  labelProperty = "value",
}) => {
  const [search, setSearch] = useState("");
  const filteredItems = useMemo(() => {
    return items?.filter((item) => item[searchProperty].includes(search));
  }, [items, search, searchProperty]);

  return (
    <div className={`multi-select ${className || ""}`}>
      <SearchInput
        className="mb-4"
        placeholder={placeholder}
        onChangeText={setSearch}
      />
      <Checkbox.Group
        className="w-full max-h-44 overflow-y-scroll"
        onChange={onChange}
        value={values}
      >
        {filteredItems?.map((item) => (
          <div className="mb-2" key={item[keyProperty]}>
            <Checkbox value={item[keyProperty]}>{item[labelProperty]}</Checkbox>
          </div>
        ))}
      </Checkbox.Group>
      {showBorder && <hr className="-mx-6 mt-2" />}
    </div>
  );
};
