import React from 'react';
import { Table } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

export const CustomerTable = ({
  loading,
  onEdit,
  onAnonymize,
  customers,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.customers', t);
  const { canAnonymizeCustomerData } = useAuth();

  const COLUMNS = [
    {
      title: text('firstName'),
      dataIndex: 'givenName',
      render: (firstName) => <div>{firstName}</div>
    },
    {
      title: text('lastName'),
      dataIndex: 'familyName',
      render: (lastName) => <div>{lastName}</div>
    },
    {
      title: text('email'),
      dataIndex: 'primaryEmail',
      render: (email) => <div>{email}</div>
    },
    {
      title: text('billingAddress'),
      dataIndex: 'defaultBillingAddress',
      render: (billingAddress) => <div>{billingAddress}</div>
    },
    {
      align: 'center',
      render: (_, row) => (
        <PopoverActionMenu>
          <MenuAction onClick={onEdit.bind(_, row)} label={text('edit')} />
          {canAnonymizeCustomerData &&
            <MenuAction onClick={onAnonymize.bind(_, row)} label={text('anonymize')} />}
        </PopoverActionMenu>
      )
    }
  ];

  return (
    <Table
      loading={loading}
      columns={COLUMNS}
      dataSource={customers}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
    />
  );
};
