import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { useTableHeight } from 'src/hooks/useTableHeight';
import { ProductStatusTag } from './ProductStatusTag';
import { MenuAction, PopoverActionMenu } from 'src/components/PopoverActionMenu';

export const FashionTable = ({
  data,
  loading,
  pagination,
  onTableChange,
  onEdit,
  onDelete,
  onUpdateStatus
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.fashionTable', t);

  const tableHeight = useTableHeight();

  const columns = [
    {
      title: '',
      dataIndex: ['coverPhotoUrl'],
      width: '5%',
      render: (_, record) => (
        <div className='flex items-center'>
          <img
            className='w-8 h-8 object-cover object-center'
            src={record.coverPhotoUrl}
          />
        </div>
      )
    },
    {
      title: text('name'),
      dataIndex: ['name'],
      width: '20%',
      render: name => name
    },
    {
      title: text('macro'),
      width: '15%',
      dataIndex: ['macroProductCategory', 'label'],
      render: macro => macro
    },
    {
      title: text('micro'),
      width: '15%',
      dataIndex: ['microProductCategory', 'label'],
      render: micro => micro
    },
    {
      title: text('status'),
      width: '10%',
      dataIndex: ['published'],
      render: (status) => <ProductStatusTag status={status} />
    },
    {
      title: text('variants'),
      dataIndex: 'variantsNumber',
      width: '10%',
      align: 'right'
    },
    // TODO Replace the creation date with update date -> For the moment is binded the creation date
    {
      title: text('lastEdit'),
      width: '15%',
      dataIndex: ['updateDate'],
      key: 'updateDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) =>
        moment(a.updateDate).unix() - moment(b.updateDate).unix(),
      render: (text) => <Moment format='DD MMM YYYY' date={text} />
    },
    {
      width: 200,
      align: 'center',
      render: (_, record) => (
        <PopoverActionMenu>
          {onEdit &&
            <MenuAction
              label={text('edit')}
              testId='edit-action'
              onClick={() => onEdit(record)}
            />}
          {onUpdateStatus &&
            <MenuAction
              label={record.published === 'Published' ? text('changeToDraft') : text('changeToPublished')}
              testId='publish-unpublish-action'
              onClick={() => onUpdateStatus(record)}
            />}
          {onDelete &&
            <MenuAction
              label={text('delete')}
              testId='delete-action'
              onClick={() => onDelete(record)}
            />}
        </PopoverActionMenu>
      )
    }
  ];

  return (
    <Table
      sticky
      scroll={{ y: tableHeight }}
      loading={loading}
      dataSource={data}
      columns={columns}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
    />
  );
};
