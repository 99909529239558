import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useOutfits } from 'src/modules/outfits/provider/outfits.provider';
import { OutfitTable } from 'src/modules/outfits/components/OutfitTable';

export const SelectedOutfits = ({ linkedOutfits, setLinkedOutfits }) => {
  const { t } = useTranslation();
  const text = textFrom('components.selectedOutfit', t);
  const { onChangeQuery, areOutfitsLoading } = useOutfits();

  const [selectedOutfitKeys, setSelectedOutfitKeys] = useState([]);

  const pagination = {
    position: 'topRight',
    pageSize: 10
  };

  const removeSelectedOutfitCollections = () => {
    const remainingOutfits = linkedOutfits.filter(outfit => !selectedOutfitKeys.includes(outfit.id));
    setLinkedOutfits(remainingOutfits);
    setSelectedOutfitKeys([]);
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  return (
    <>
      <Row justify='end'>
        <Col>
          <Button
            type='primary'
            className='uppercase mt-5'
            onClick={removeSelectedOutfitCollections}
          >
            {text('remove')}
          </Button>
        </Col>
      </Row>
      <OutfitTable
        isEditModeOn
        className='pt-8'
        outfits={linkedOutfits}
        pagination={pagination}
        loading={areOutfitsLoading}
        onTableChange={onTableChange}
        selectedOutfitKeys={selectedOutfitKeys}
        setSelectedOutfitKeys={setSelectedOutfitKeys}
      />
    </>
  );
};
