import React, { useMemo } from 'react';
import { Table } from 'antd';

export const ProductsEditableTable = ({
  loading,
  columnsProps,
  pagination,
  onTableChange,
  products,
  rowSelection
}) => {
  const columns = useMemo(() => {
    return columnsProps.map(col => {
      return {
        id: col.id,
        key: col.key,
        title: col.title,
        width: col.width,
        parentKey: col.parentKey,
        dataIndex: col.dataIndex,
        render: (text, column) => {
          return (
            <div>
              {col.key === 'image'
                ? (
                  <img src={column.coverPhotoUrl} className='w-8 h-8 object-cover object-center' />
                  )
                : (
                    text || column.microProductCategory.businessKey
                  )}
            </div>
          );
        }
      };
    });
  }, [columnsProps]);

  return (
    <Table
      style={{ width: '100%' }}
      loading={loading}
      columns={columns}
      dataSource={products}
      rowSelection={rowSelection}
      pagination={pagination}
      rowKey='id'
      className='ProductsEditableTableCategories'
      onChange={onTableChange}
    />
  );
};
