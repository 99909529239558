import React, { useState, useEffect, useMemo } from 'react';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { message, Button, Result } from 'antd';

import { ProductTable } from '../components/OutfitProductTable';
import { VariantTable } from '../components/OutfitVariantTable';
import { SelectedVariantTable } from '../components/OutfitSelectedVariantTable';
import { OutfitForm } from '../components/OutfitForm';

import { Routes } from 'src/router/Routes.helper';
import { SEPARATOR } from 'src/utils/constants';
import { nestify } from 'src/utils/objectManipulation';

import { useForm, useWatch } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { PageTitle } from 'src/components/PageTitle';

import { useOutfits } from '../provider/outfits.provider';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { BackButton } from 'src/components/BackButton';

export const PageCreateOutfits = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.outfits/create', t);

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [isCreatingOutfit, setIsCreatingOutfit] = useState();
  const [selectedVariantKeys, setSelectedVariantKeys] = useState([]);
  const [selectedOutfitKeys, setSelectedOutfitKeys] = useState([]);

  const [form] = useForm();
  const history = useHistory();
  const { languages, defaultLanguage } = useLanguages();
  const { userInfo } = useAuth();

  const { selectedProduct, setSelectedProduct, outfitVariants, setOutfitVariants, createOutfit } = useOutfits();

  const INITIAL_STATUS = 'Draft';

  const currentDescription = useWatch(`descriptions${SEPARATOR}${selectedLanguage}`, form);

  useEffect(() => {
    setSelectedLanguage(defaultLanguage?.language);
  }, [defaultLanguage]);

  useEffect(() => {
    if (defaultLanguage) {
      form.setFieldsValue({ language: defaultLanguage.label });
    }
  }, [form, defaultLanguage]);

  useEffect(() => {
    form.setFieldsValue({ status: INITIAL_STATUS });
  }, [form]);

  const requiredLanguage = useMemo(() => {
    return languages.find(language => language.defaultLanguage);
  }, [languages]);

  const onFinish = async () => {
    form.validateFields();
    const values = form.getFieldsValue(true); // Get all the values, even the unmounted ones
    const { names, descriptions } = nestify(values, ['names', 'descriptions']);

    const { externalId, priority, status, image } = values;

    if (!names?.[requiredLanguage.language]) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('name') }));
      return;
    }

    if (!descriptions?.[requiredLanguage.language]) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('description') }));
      return;
    }

    if (outfitVariants.length === 0) {
      message.error(text('noOutfitSelected'));
      return;
    }

    const outfitData = {
      externalId: externalId,
      descriptions: descriptions,
      names: names,
      coverImage: image,
      imageList: [image],
      variantIdList: outfitVariants.map((itm) => itm.id),
      productEanList: outfitVariants.map((itm) => itm.sku),
      published: status === 'Published' ? 1 : 0,
      priority: priority,
      localBusiness: {
        id: userInfo?.localBusiness?.id
      }
    };
    setIsCreatingOutfit(true);
    const res = await createOutfit(outfitData);
    if (res?.data) {
      navigateToOutfitsPage();
    }
    setIsCreatingOutfit(false);
  };

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguage(languageId);
  };

  const navigateToOutfitsPage = () => {
    history.push(Routes.PATHS.OUTFITS);
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>
      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToOutfitsPage}
        title={text('backButton')}
      />

      {/* SECTION 1 -> form */}
      <OutfitForm
        handleLanguageSelection={handleLanguageSelection}
        onFinish={onFinish}
        selectedLanguage={selectedLanguage}
        currentDescription={currentDescription}
        navigateToOutfitsPage={navigateToOutfitsPage}
        form={form}
        mode='create'
        title={text('pageTitle')}
      />

      {/* SECTION 2 -> selected variant */}
      <PageTitle>{text('pageTitleSelected')}</PageTitle>
      {outfitVariants.length > 0
        ? (
          <SelectedVariantTable
            selectedVariantKeys={selectedVariantKeys}
            setSelectedVariantKeys={setSelectedVariantKeys}
            selectedOutfitKeys={selectedOutfitKeys}
            setSelectedOutfitKeys={setSelectedOutfitKeys}
            selectedLanguage={selectedLanguage}
          />)
        : (
          <Result
            className='border bg-white mb-24 mt-4'
            title={text('noOutfitSelected')}
          />
          )}

      {/* SECTION 3 -> choose product THEN variant */}
      {selectedProduct
        ? (
          <VariantTable
            selectedVariantKeys={selectedVariantKeys}
            setSelectedVariantKeys={setSelectedVariantKeys}
            selectedLanguage={selectedLanguage}
            setOutfitVariants={setOutfitVariants}
          />)
        : (
          <ProductTable
            onAdd={setSelectedProduct}
          />
          )}

      <div className='flex items-center w-full justify-end'>
        <Button
          type='primary'
          htmlType='submit'
          className='uppercase ml-2 my-4'
          onClick={onFinish}
          loading={isCreatingOutfit}
          disabled={isCreatingOutfit}
        >
          {text('createButton')}
        </Button>
      </div>
    </div>
  );
};
