import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { SCOPE } from 'src/utils/scope-utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { ANY_OPTION } from 'src/utils/utils';

export const Chips = ({ query, chipKeys, specialChips, onTagRemove }) => {
  const [tags, setTags] = useState([]);
  const { selectedUserScope } = useAuth();

  useEffect(() => {
    if (query) {
      const newTags = Object.entries(query).map(entry => {
        const [key, value] = entry;
        if (chipKeys.includes(key) && value) {
          const special = specialChips?.find(chip => chip.key === key);
          const tag = {
            key: key,
            value: value
          };
          if (special) {
            tag.value = special.value;
          }
          if (selectedUserScope === SCOPE.LOCALBUSINESS) {
            if (tag.key === 'department') {
              tag.isLocked = true;
            }
          }
          if (value === ANY_OPTION.value) {
            tag.isLocked = true;
          }
          return tag;
        } else {
          return false;
        }
      });
      const filteredTags = newTags.filter(tag => tag);
      setTags(filteredTags);
    }
  }, [query, chipKeys, specialChips, setTags]);

  return (
    <>
      {tags?.map(tag => (
        (!tag.isHidden &&
          <Tag
            className={tag.isLocked ? '' : 'cursorTag'}
            key={`${tag.key}-${tag.value}`}
            closable={!tag.isLocked}
            onClose={tag.isLocked ? undefined : () => onTagRemove(tag.key)}
            onClick={tag.isLocked ? undefined : () => onTagRemove(tag.key)}
          >
            {tag.value}
          </Tag>)
      ))}
    </>
  );
};
