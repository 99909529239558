import { Filters } from 'src/services/Filters.service';

export class DownloadsMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const query = new URLSearchParams(qs);
    const downloadQueryModel = {
      current: query?.get('current'),
      pageSize: query?.get('pageSize')
    };
    return downloadQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: query?.current > 0 ? +query.current : 1,
      limit: query.pageSize
    };
    return payload;
  }
}
