import {useState} from "react";
import {useWindowSize} from "src/hooks/useWindowSize";

export const useTableHeight = () => {
    const [tableHeight, setTableHeight] = useState(0);

    useWindowSize({
        callOnMount: true,
        onResizeEnd(windowSize) {
            const pageFilters = document.querySelector(".page-filters");
            const tableHeader = document.querySelector("thead");
            const tableHeight =
                windowSize.height -
                (pageFilters?.clientHeight || 0) -
                (tableHeader?.clientHeight || 0) -
                60;
            setTableHeight(tableHeight);
        },
    });

    return tableHeight;
};
