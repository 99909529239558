import { Filters } from 'src/services/Filters.service';
import { OutfitCollectionsQuery } from './models/CollectionsQuery';

export class OutfitCollectionsMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = OutfitCollectionsQuery;
    const query = new URLSearchParams(qs);
    const outfitCollectionsModel = new OutfitCollectionsQuery();
    outfitCollectionsModel.current = query?.get('current');
    outfitCollectionsModel.limit = query?.get('limit');
    outfitCollectionsModel.pageSize = query?.get('pageSize');
    outfitCollectionsModel.text = query?.get(KEYS.TEXT);
    return outfitCollectionsModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: query?.current > 0 ? +query.current : 1,
      limit: query.pageSize || 10,
      filters: {
        text: query?.text
      }
    };
    return payload;
  }
}
