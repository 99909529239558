import React from 'react';
import { Table, Checkbox } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';
import { firstLetterUppercase } from 'src/utils/utils';

const makeColumns = (text, translateLanguage, onSetAsDefault, onDelete) => [
  {
    width: '5%',
    title: text('defaultLanguage'),
    dataIndex: 'defaultLanguage',
    key: 'defaultLanguage',
    render: (defaultLanguage) => {
      return (<div style={{ textAlign: 'center' }}><Checkbox checked={defaultLanguage} disabled /></div>);
    }
  },
  {
    width: '40%',
    title: text('label'),
    dataIndex: 'language',
    key: 'language',
    render: (value) => firstLetterUppercase(translateLanguage(value))
  },
  {
    width: '40%',
    title: text('isocode'),
    dataIndex: 'language',
    key: 'languageCode',
    render: (value) => value
  },
  {
    title: '',
    dataIndex: 'operation',
    align: 'center',
    width: 200,
    render: (_, row) => (
      !row?.defaultLanguage && (
        <PopoverActionMenu>
          <MenuAction onClick={onSetAsDefault.bind(_, row?.language)} label={text('makeDefault')} />
          <MenuAction onClick={onDelete.bind(_, row?.language)} label={text('delete')} />
        </PopoverActionMenu>)
    )
  }
];

export const LanguageTable = ({
  loading,
  onSetAsDefault,
  onDelete,
  languages
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.languages', t);
  const translateLanguage = textFrom('languages', t);

  const COLUMNS = makeColumns(text, translateLanguage, onSetAsDefault, onDelete);

  return (
    <Table
      rowClassName='cursor-pointer'
      loading={loading}
      columns={COLUMNS}
      dataSource={languages}
    />
  );
};
