import { Filters } from 'src/services/Filters.service';
import { OutfitsQuery } from './models/OutfitsQuery';

export class OutfitsMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = OutfitsQuery;
    const query = new URLSearchParams(qs);
    const outfitQueryModel = new OutfitsQuery();
    outfitQueryModel.current = query?.get('current');
    outfitQueryModel.pageSize = query?.get('pageSize');
    outfitQueryModel.text = query?.get(KEYS.TEXT);
    return outfitQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: query?.current > 0 ? +query.current : 1,
      limit: query.pageSize || 10,
      filters: {
        text: query?.text
      }
    };
    return payload;
  }
}
