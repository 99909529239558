export class StorageService {
  static get KEYS () {
    return {
      USER: 'user',
      USER_DEPARTMENTS: 'userDepartments',
      USER_ROLE: 'userRole',
      USER_INFO: 'userInfo',
      USER_AUTHORITIES: 'userAuthorities',
      USER_IS_LOGGED: 'userIsLogged',
      USER_SCOPE: 'userScope',
      SELECTED_USER_SCOPE: 'selectedUserScope'
    };
  }

  static getItem (key) {
    return window.localStorage.getItem(key);
  }

  static setItem (key, item) {
    window.localStorage.setItem(key, JSON.stringify(item));
  }

  static removeItem (key) {
    window.localStorage.removeItem(key);
  }

  static safeParse (value) {
    try {
      return (JSON.parse(value));
    } catch (e) {
      return undefined;
    }
  }

  static getUser () {
    const user = StorageService.getItem(StorageService.KEYS.USER);
    return StorageService.safeParse(user);
  }

  static setUser (user) {
    StorageService.setItem(StorageService.KEYS.USER, user);
  }

  static deleteUser () {
    StorageService.removeItem(StorageService.KEYS.USER);
  }

  static getUserInfo () {
    const userInfo = StorageService.getItem(StorageService.KEYS.USER_INFO);
    return StorageService.safeParse(userInfo);
  }

  static setUserInfo (userInfo) {
    StorageService.setItem(StorageService.KEYS.USER_INFO, userInfo);
  }

  static deleteUserInfo () {
    StorageService.removeItem(StorageService.KEYS.USER_INFO);
  }

  static getUserRole () {
    const userRole = StorageService.getItem(StorageService.KEYS.USER_ROLE);
    return StorageService.safeParse(userRole);
  }

  static setUserRole (userRole) {
    StorageService.setItem(StorageService.KEYS.USER_ROLE, userRole);
  }

  static deleteUserRole () {
    StorageService.removeItem(StorageService.KEYS.USER_ROLE);
  }

  static getUserDepartments () {
    const userDepartments = StorageService.getItem(StorageService.KEYS.USER_DEPARTMENTS);
    return StorageService.safeParse(userDepartments);
  }

  static setUserDepartments (userDepartments) {
    StorageService.setItem(StorageService.KEYS.USER_DEPARTMENTS, userDepartments);
  }

  static deleteUserDepartments () {
    StorageService.removeItem(StorageService.KEYS.USER_DEPARTMENTS);
  }

  static getUserAuthorities () {
    const userAuthorities = StorageService.getItem(StorageService.KEYS.USER_AUTHORITIES);
    return StorageService.safeParse(userAuthorities);
  }

  static setUserAuthorities (userAuthorities) {
    StorageService.setItem(StorageService.KEYS.USER_AUTHORITIES, userAuthorities);
  }

  static deleteUserAuthorities () {
    StorageService.removeItem(StorageService.KEYS.USER_AUTHORITIES);
  }

  static getUserIsLogged () {
    const userIsLogged = StorageService.getItem(StorageService.KEYS.USER_IS_LOGGED);
    return StorageService.safeParse(userIsLogged);
  }

  static setUserIsLogged (userIsLogged) {
    StorageService.setItem(StorageService.KEYS.USER_IS_LOGGED, userIsLogged);
  }

  static deleteUserIsLogged () {
    StorageService.removeItem(StorageService.KEYS.USER_IS_LOGGED);
  }

  static setUserScope (userScope) {
    StorageService.setItem(StorageService.KEYS.USER_SCOPE, userScope);
  }

  static getUserScope () {
    const userScope = StorageService.getItem(StorageService.KEYS.USER_SCOPE);
    return StorageService.safeParse(userScope);
  }

  static deleteUserScope () {
    StorageService.removeItem(StorageService.KEYS.USER_SCOPE);
  }

  static setSelectedUserScope (selectedUserScope) {
    StorageService.setItem(StorageService.KEYS.SELECTED_USER_SCOPE, selectedUserScope);
  }

  static getSelectedUserScope () {
    const selectedUserScope = StorageService.getItem(StorageService.KEYS.SELECTED_USER_SCOPE);
    return StorageService.safeParse(selectedUserScope);
  }

  static deleteSelectedUserScope () {
    StorageService.removeItem(StorageService.KEYS.SELECTED_USER_SCOPE);
  }

  static getRestConfig () {
    const restConfig = window.localStorage.getItem(StorageService.KEYS.REST_CONFIG);
    return JSON.parse(restConfig);
  }

  static setRestConfig (restConfig) {
    window.localStorage.setItem(StorageService.KEYS.REST_CONFIG, JSON.stringify(restConfig));
  }

  static deleteRestConfig () {
    window.localStorage.removeItem(StorageService.KEYS.REST_CONFIG);
  }
}
