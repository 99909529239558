import React, { useCallback } from 'react';
import { Table, Form, Progress } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import Moment from 'react-moment';
import moment from 'moment';
import { EventStatusTag } from './ProductStatusTag';
import { Product } from 'src/services/Product.service';
import { MenuAction, PopoverActionMenu } from 'src/components/PopoverActionMenu';
import { Routes } from 'src/router/Routes.helper';
import { MODE } from '../../../utils/utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

const calculateProgressPercentage = (record) => {
  return Math.round((record.initialAvailability - record.availabilityTotal) / record.initialAvailability * 100);
};

const calculateProgressInfo = (record) => {
  return `${record.initialAvailability - record.availabilityTotal} / ${record.initialAvailability}`;
};

export const EventTable = ({
  loading,
  tableData,
  pagination,
  onTableChange,
  updateProductStatus,
  onEdit,
  onDelete
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.events/past', t);
  const [form] = Form.useForm();
  const history = useHistory();

  const { canCreateEvents } = useAuth();

  const handleDuplicate = useCallback(
    (record) => {
      const location = {
        mode: MODE.DUPLICATE
      };
      const params = {
        id: record.id
      };
      history.push(Routes.parseRouteParams(Routes.PATHS.EDIT_PRODUCT_EVENT, params), location);
    },
    [history]
  );

  const columns = [
    {
      title: text('table.cols.dateAndTime'),
      dataIndex: ['updateDate'],
      key: 'updateDate',
      render: (_, record) => {
        return (
          <div className='flex items-center space-x-4'>
            <span><Moment format='DD MMM YYYY' date={record.attributes.start_date.value} /></span>
            <span> {record.attributes.start_time.value} -{' '}
              {moment(`01/01/2022 ${record.attributes.start_time.value}`).add(+record.attributes.durationInMin.value, 'minutes').format('HH:mm')}
            </span>
          </div>
        );
      },
      width: '20%'
    },
    {
      title: text('table.cols.name'),
      dataIndex: ['name'],
      width: '25%',
      render: (_, record) => record.name
    },
    {
      title: text('table.cols.category'),
      dataIndex: ['microProductCategory'],
      render: (_, record) => record.microProductCategory.localeName
    },
    {
      title: text('table.cols.price'),
      dataIndex: ['sellingPrice'],
      render: (_, record) => `€ ${record.sellingPrice}`
    },
    {
      title: text('table.cols.soldTickets'),
      width: '15%',
      render: (_, record) => {
        return (
          <div className='flex items-center space-x-4'>
            <Progress
              showInfo={false}
              percent={calculateProgressPercentage(record)}
            />
            <p className='w-40 mb-0'>
              {calculateProgressInfo(record)}
            </p>
          </div>
        );
      }
    },
    {
      title: text('table.cols.status'),
      dataIndex: ['published'],
      render: (status) => <EventStatusTag status={status} />,
      inputType: 'published-select',
      editable: true,
      inputOptions: Product.statusOptions
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return (
          <PopoverActionMenu>
            <MenuAction onClick={onEdit.bind(_, record)} label={text('edit')} />
            {canCreateEvents &&
              <MenuAction onClick={handleDuplicate.bind(_, record)} label={text('duplicate')} />}
            <MenuAction onClick={updateProductStatus.bind(_, record)} label={record.published === 'Published' ? text('changeToDraft') : text('changeToPublished')} />
            <MenuAction onClick={onDelete.bind(_, record)} label={text('delete')} />
          </PopoverActionMenu>
        );
      }
    }
  ];

  return (
    <Form form={form} component={false}>
      <Table
        loading={loading}
        rowKey='id'
        scroll={{ y: window.innerHeight - 350 }}
        dataSource={tableData}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
        rowClassName={(record) => (record.isUpdatePending ? 'opacity-50' : '')}
      />
    </Form>
  );
};
