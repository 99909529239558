import { AxiosClient } from "src/api/AxiosClient";
import { FoodUpdatePayload } from "src/modules/products/api/payloads/FoodUpdatePayload";
import { LocationUpdatePayload } from "src/modules/products/api/payloads/LocationUpdatePayload";
import { LocationPayload } from "src/modules/products/api/payloads/LocationPayload";

export class LocationAPI {
  // ----------------------------------LOCATION CRUD --------------------------------------------
  /**
   * @description Search on Products
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  
  static async searchLocations(data) {
    return AxiosClient.post("/place/filter", data);
  }

  /**
   * @description Get all locations
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  
  static async getLocations(data) {
    return AxiosClient.get(`/place?limit=${data.limit}&pageNumber=${data.pageNumber}`);
  }

  /**
   * @description Inline editing of a product
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  // ASK RICCARDO
  static async updateLocation(id, data) {
    return AxiosClient.put(`/places/${id}`, new FoodUpdatePayload(data))
      .then((res) => res.data.data)
      .then((apiData) => ({
        ...apiData,
        macroProductCategory: {
          ...apiData.macroCategory,
          key: apiData.macroCategory.key,
          label: apiData.macroCategory.localeName,
        },
        microProductCategory: {
          ...apiData.microCategory,
          key: apiData.microCategory.key,
          label: apiData.microCategory.localeName,
        },
      }));
  }

  /**
   * @description Create Location
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async createLocation(data) {
    return AxiosClient.post(
      `/place`,
      new LocationPayload(data)
    ).then((res) => res);
  }

  /**
   * @description Update product - product details page
   * @param productId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateLocationDetails(locationId, data) {
    return AxiosClient.put(
      `/place/${locationId}`,
      new LocationUpdatePayload(data)
    ).then((res) => res.data.data);
  }

  /**
   * @description get the countries
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getCountries() {
    return AxiosClient.get(
      `/geo/countries`,
    ).then((res) => res.data.data);
  }

  /**
   * @description Delete product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteProduct(productId) {
    return AxiosClient.delete(`/products/${productId}`);
  }

  /**
   * @description Get details of a single product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  // ASK RICCARDO
  static async getLocation(productId) {
    return AxiosClient.get(`/place/${productId}`);
  }
}