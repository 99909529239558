import React, { useMemo } from "react";
import { Tag } from "antd";

const PRODUCT_STATUS_COLOR_MAP = {
  published: "cyan",
  draft: "volcano",
};

export const ProductStatusTag = ({ status }) => {
  const color = useMemo(() => {
    return PRODUCT_STATUS_COLOR_MAP[status];
  }, [status]);

  return <Tag color={color}>{status}</Tag>;
};

export const EventStatusTag = ({ status }) => {
  return (
    <div className="flex items-center space-x-2">
      <span className={`w-1.5 h-1.5 rounded-full ${status === "Published" ? "bg-green-500" : "bg-gray-400"}`}></span>
      <span>{status}</span>
    </div>
  );
};
