import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

// TODO can we unify this and AuthoritiesChecker ?
export const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user
          ? (
              children
            )
          : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
            )}
    />
  );
};
