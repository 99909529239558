import React, { useState, useEffect } from 'react';
import { Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import moment from 'moment';
import { CustomersAPI } from '../api/CustomersAPI';
import { CountriesUtils } from '../../../utils/utils';
import { useCustomers } from 'src/modules/admin/provider/customers.provider';
import { PageTitle } from 'src/components/PageTitle';
import { Routes } from 'src/router/Routes.helper';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { CustomerForm } from '../component/CustomerForm';
import { Gender } from '../utils/utils';

export const PageEditCustomer = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.customer/edit', t);

  const [form] = useForm();
  const history = useHistory();
  const { customer } = useCustomers();
  const { getCountriesList, getDistrictListByCountry } = CountriesUtils();

  const countriesList = getCountriesList();
  const [isLoading, setIsLoading] = useState(false);
  const [billingDistrictsList, setBillingDistrictsList] = useState([]);
  const [shippingDistrictsList, setShippingDistrictsList] = useState([]);

  const goToCustomersPage = () => {
    history.push(Routes.PATHS.CUSTOMERS);
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton
          onClick={goToCustomersPage}
          label={text('cancel')}
        />
      </Col>
      <Col>
        <SubmitButton
          loading={isLoading}
          disabled={isLoading}
          label={text('submit')}
        />
      </Col>
    </Row>
  );

  const getCustomerBillingData = (customer) => {
    if (customer?.billingAddressList?.length > 0) {
      return customer?.billingAddressList[0];
    } else {
      return {};
    }
  };

  const getCustomerShippingData = (customer) => {
    if (customer?.shippingAddressList?.length > 0) {
      return customer?.shippingAddressList[0];
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (customer) {
      const billingData = getCustomerBillingData(customer);
      const {
        country: bCountry,
        postalCode: bPostalCode,
        state: bState,
        city: bCity,
        streetAddress: bStreetAddress,
        streetNumber: bStreetNumber
      } = billingData;
      const bc = countriesList.find(c => (c.key === bCountry || c.value === bCountry));
      setBillingDistrictsList(getDistrictListByCountry(bc?.key));
      const shippingData = getCustomerShippingData(customer);
      const {
        country: sCountry,
        postalCode: sPostalCode,
        state: sState,
        city: sCity,
        streetAddress: sStreetAddress,
        streetNumber: sStreetNumber
      } = shippingData;
      const sc = countriesList.find(c => (c.key === sCountry || c.value === sCountry));
      setShippingDistrictsList(getDistrictListByCountry(sc?.key));
      form.setFieldsValue({
        customerFirstName: customer.givenName,
        customerLastName: customer.familyName,
        customerBirthDate: moment(new Date(customer.birthDate)),
        customerGender: (!customer.gender) ? Gender.NA : customer.gender,
        customerTelNumber: customer.telephone,
        customerEmail: customer.primaryEmail,
        customerBillingCountry: bCountry,
        customerBillingStateOrProvince: bState,
        customerBillingCity: bCity,
        customerBillingAddress: bStreetAddress,
        customerBillingStreetNumber: bStreetNumber,
        customerBillingZipCode: bPostalCode,
        customerShippingCountry: sCountry,
        customerShippingStateOrProvince: sState,
        customerShippingCity: sCity,
        customerShippingAddress: sStreetAddress,
        customerShippingStreetNumber: sStreetNumber,
        customerShippingZipCode: sPostalCode
      });
    }
  }, [form, customer]);

  const onFinish = async ({
    customerFirstName,
    customerLastName,
    customerBirthDate,
    customerGender,
    customerTelNumber,
    customerEmail,
    customerBillingCountry,
    customerBillingStateOrProvince,
    customerBillingCity,
    customerBillingAddress,
    customerBillingStreetNumber,
    customerBillingZipCode,
    customerShippingCountry,
    customerShippingStateOrProvince,
    customerShippingCity,
    customerShippingAddress,
    customerShippingStreetNumber,
    customerShippingZipCode
  }) => {
    const updatedCustomerData = {
      primaryEmail: customerEmail,
      username: customerEmail,
      givenName: customerFirstName,
      familyName: customerLastName,
      birthDate: Number(moment(customerBirthDate).format('x')),
      gender: customerGender,
      telephone: customerTelNumber
    };
    const updatedBillingData = {
      city: customerBillingCity,
      country: customerBillingCountry,
      state: customerBillingStateOrProvince,
      familyName: customerLastName,
      givenName: customerFirstName,
      phone: customerTelNumber,
      postalCode: customerBillingZipCode,
      streetAddress: customerBillingAddress,
      streetNumber: customerBillingStreetNumber
    };
    const updatedShippingData = {
      city: customerShippingCity,
      country: customerShippingCountry,
      state: customerShippingStateOrProvince,
      familyName: customerLastName,
      givenName: customerFirstName,
      phone: customerTelNumber,
      postalCode: customerShippingZipCode,
      streetAddress: customerShippingAddress,
      streetNumber: customerShippingStreetNumber
    };
    setIsLoading(true);
    try {
      const billingData = getCustomerBillingData(customer);
      const shippingData = getCustomerShippingData(customer);
      await CustomersAPI.updateCustomer(customer.id, updatedCustomerData);
      if (billingData?.id) {
        await CustomersAPI.updateCustomerBillingData(customer.id, billingData.id, updatedBillingData);
      } else {
        await CustomersAPI.addBillingDataToCustomer(customer.id, updatedBillingData);
      }
      if (shippingData?.id) {
        await CustomersAPI.updateCustomerShippingData(customer.id, shippingData.id, updatedShippingData);
      } else {
        await CustomersAPI.addShippingDataToCustomer(customer.id, updatedShippingData);
      }
      message.success(text('customerEditSuccess'));
      goToCustomersPage();
    } catch (e) {
      message.error(text('customerEditError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={goToCustomersPage}
      />

      <CustomerForm
        form={form}
        mode='edit'
        buttons={buttons}
        onFinish={onFinish}
        billingDistrictsList={billingDistrictsList}
        setBillingDistrictsList={setBillingDistrictsList}
        shippingDistrictsList={shippingDistrictsList}
        setShippingDistrictsList={setShippingDistrictsList}
      />

    </div>
  );
};
