import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row, Input, Select } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { KEY_REGEX } from 'src/utils/constants';
import { WysiwygEditor } from 'src/components/inputs/WysiwygEditor';
import { useTranslation } from 'react-i18next';
import { ImageGalleryBrand } from './ImageGalleryBrand';
import { useDepartment } from 'src/modules/admin/provider/department.provider';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { SCOPE } from 'src/utils/scope-utils';

export const BrandForm = ({
  form,
  buttons,
  imageGallery,
  setImageGallery,
  initialDescription,
  onFinish
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.brandForm', t);
  const departmentText = textFrom('entities.departments', t);

  const { departments } = useDepartment();
  const { selectedUserScope, userDepartments } = useAuth();
  const [isGalleryRequired, setIsGalleryRequired] = useState(true);
  const [isGalleryDisabled, setIsGalleryDisabled] = useState(false);

  useEffect(() => {
    if (imageGallery.length > 0) {
      const defaultLogoLinkValue = {
        name: 'logoLink',
        value: null,
        errors: []
      };
      form.setFields([defaultLogoLinkValue]);
    }
  }, [imageGallery]);

  const handleDescriptionChange = (name, value) => {
    form.setFieldsValue({
      [name]: value
    });
  };

  const onGalleryUpdate = (file, fileList) => {
    const newFileList = [...fileList];
    form.setFieldsValue({
      gallery: newFileList
    });
    setImageGallery(newFileList);
  };

  const handleLogoLinkChange = () => {
    const logoLink = form.getFieldValue().logoLink;
    if (logoLink && isGalleryRequired) {
      setIsGalleryRequired(false);
      setIsGalleryDisabled(true);
    } else if (!logoLink && !isGalleryRequired) {
      setIsGalleryRequired(true);
      setIsGalleryDisabled(false);
    }
  };

  const toDepartmentOption = deptKey => ({
    key: deptKey,
    value: deptKey,
    label: departmentText(deptKey)
  });

  const departmentOptions = useMemo(() => {
    if (selectedUserScope === SCOPE.TENANT) {
      return departments?.map(dept => toDepartmentOption(dept.key)) || [];
    }
    return userDepartments?.map(toDepartmentOption) || [];
  }, [selectedUserScope, departments, userDepartments]);

  return (
    <Form
      form={form}
      layout='vertical'
      autoComplete='off'
      onFinish={onFinish}
    >
      <div>
        <Row gutter={20} className='pt-2 mb-4'>
          <Col xs={8}>

            <Form.Item
              name='brandName'
              label={text('name')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('nameRequired') }]}
            >
              <Input placeholder={text('namePlaceholder')} />
            </Form.Item>
          </Col>
          <Col xs={6}>

            <Form.Item
              name='key'
              label={text('key')}
              className='w-full px-4 pb-4'
              rules={[
                { required: true, message: text('keyRequired') },
                {
                  message: text('keyInvalid'),
                  pattern: KEY_REGEX
                }
              ]}
            >
              <Input placeholder={text('keyPlaceholder')} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            {/* Department */}
            <Form.Item
              name='department'
              label={text('department')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('departmentRequired') }]}
            >
              <Select
                bordered
                allowClear
                className='w-full'
                options={departmentOptions}
                placeholder={text('departmentPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} className='mb-4'>
          <Col xs={8}>
            {/* Description */}
            <Form.Item
              name='description'
              label={text('description')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('descriptionRequired') }]}
            >
              <WysiwygEditor
                name='description'
                initialValue={initialDescription}
                onChange={handleDescriptionChange}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Row gutter={20}>
              <Col>
                {/* Gallery */}
                <Form.Item
                  name='gallery'
                  label={text('gallery')}
                  rules={[{ required: isGalleryRequired, message: text('galleryRequired') }]}
                >
                  <ImageGalleryBrand
                    fileList={imageGallery}
                    isDisabled={isGalleryDisabled}
                    onGalleryUpdate={(file, fileList) => onGalleryUpdate(file, fileList)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20} className='mb-4'>
              <Col>
                {/* Logo Link */}
                <Form.Item
                  name='logoLink'
                  className='w-full'
                  rules={[{ required: imageGallery.length === 0, message: text('logoLinkRequired') }]}
                >
                  <Input
                    placeholder={text('logoLinkPlaceholder')}
                    disabled={imageGallery.length > 0}
                    onChange={handleLogoLinkChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xs={8} />
        </Row>
      </div>

      {buttons}

    </Form>
  );
};
