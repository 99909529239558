import React from 'react';
import { Form, Input, Button, Layout, message, Typography, Row, Col } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AccountsAPI } from 'src/modules/accounts/api/AccountsApi';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { BackButton } from 'src/components/BackButton';
import mcubeLogoTransparent from 'src/images/logo-mcube_transparent.png';
import { Routes } from 'src/router/Routes.helper';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;

const SingleDetail = ({ label, value, styling = null }) => {
  return (
    <div className={styling || 'flex flex-col'}>
      <Text>{label}</Text>
      <Text className='text-center' strong>{value}</Text>
    </div>
  );
};

export const PageForgotPassword = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.pageForgotPassword', t);
  const [form] = useForm();
  const history = useHistory();

  const onFinish = async (values) => {
    const { email } = values;
    try {
      await AccountsAPI.sendMeAnEmail(email);
      message.success(text('emailSent'));
    } catch (e) {
      message.error(text('emailNotSent'));
    }
  };

  const goToLoginPage = () => {
    history.push(Routes.PATHS.LOGIN);
  };

  return (
    <Layout className='h-full'>
      <Layout.Content className='flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <img
            className='mb-8 h-24'
            src={mcubeLogoTransparent}
            alt='M-Cube'
          />
          <div className='pb-16 pt-10 px-12 w-96 bg-white rounded shadow'>
            <BackButton
              iconChevron
              className='mb-4'
              onClick={goToLoginPage}
              title={text('backButton')}
            />
            <SingleDetail
              styling='flex flex-col items-center justify-center'
              label={text('forgotPwdTitle')}
              value={text('forgotPwdSubtitle')}
            />
            <Form
              name='basic'
              initialValues={{
                email: ''
              }}
              onFinish={onFinish}
              autoComplete='off'
              layout='vertical'
              form={form}
            >
              <div className='flex flex-col items-center justify-center my-8'>
                <Row className='w-full'>
                  <Col className='w-full'>
                    {/* Email */}
                    <Form.Item
                      name='email'
                      rules={[
                        { required: true, message: text('emailRequired') },
                        { type: 'email', message: text('emailInvalid') }
                      ]}
                    >
                      <Input placeholder={text('emailPlaceholder')} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {/* Actions */}
              <div className='flex flex-col items-center justify-center'>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='uppercase w-full'
                >
                  {text('sendMeAnEmail')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
