import { immerable } from 'immer';
import { Product } from 'src/services/Product.service';
import { ImageModel } from 'src/modules/orders/domain/models/ImageModel';

export class FoodVariantModel {
  [immerable] = true;

  constructor(apiVariant) {
    this.addedToCart = apiVariant.addedToCart;
    this.attributeVariants = {
      ...apiVariant.attributeVariants,
      size: {
        ...apiVariant.attributeVariants.size,
        label: apiVariant?.attributeVariants?.size?.localeName,
      },
    };
    this.attributes = apiVariant.attributes;
    this.availabilityTotal = apiVariant.availabilityTotal;
    this.catalogList = apiVariant.catalogList;
    this.creationDate = apiVariant.creationDate;
    this.depth = apiVariant.depth;
    this.hasCoverPhoto = apiVariant.hasCoverPhoto;
    this.height = apiVariant.height;
    this.id = apiVariant.id;
    this.loved = apiVariant.loved;
    this.manufacturerCode = apiVariant.manufacturerCode;
    this.names = apiVariant.names;
    this.numberOfComments = apiVariant.numberOfComments;
    this.numberOfLovers = apiVariant.numberOfLovers;
    this.numberOfProductSaving = apiVariant.numberOfProductSaving;
    this.numberOfProductSharing = apiVariant.numberOfProductSharing;
    this.photoGallery = apiVariant.photoGallery && {
      ...apiVariant.photoGallery,
      images: apiVariant.photoGallery.images.map(
        (image) => new ImageModel(image)
      ),
    };
    this.prices = apiVariant.prices;
    this.productCatalogMultipliers = apiVariant.productCatalogMultipliers;
    this.productCatalogPrices = apiVariant.productCatalogPrices;
    this.productCatalogScheduledPrices =
      apiVariant.productCatalogScheduledPrices;
    this.productDescriptionCompleteness =
      apiVariant.productDescriptionCompleteness;
    this.productId = apiVariant.productId;
    this.publicationDate = apiVariant.publicationDate;
    this.published = Product.getStatus(apiVariant.published);
    this.purchasable = apiVariant.purchasable;
    this.ranking = apiVariant.ranking;
    this.saved = apiVariant.saved;
    this.sku = apiVariant.sku;
    this.slug = apiVariant.slug;
    this.status = apiVariant.status;
    this.statusHistory = apiVariant.statusHistory;
    this.threshold = apiVariant.threshold;
    this.unitQuantity = apiVariant.unitQuantity;
    this.updateDate = apiVariant.updateDate;
    this.version = apiVariant.version;
    this.warehouseArrayList = apiVariant.warehouseArrayList;
    this.weight = apiVariant.weight;
    this.width = apiVariant.width;
  }
}
