import { Filters } from 'src/services/Filters.service';
import { StocksQuery } from 'src/modules/warehouses/domain/models/StocksQuery';
import { StocksListPayload } from 'src/modules/warehouses/api/payloads/StocksListPayload';

export class StocksMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = StocksQuery;
    const query = new URLSearchParams(qs);
    const StocksQueryModel = new StocksQuery();

    StocksQueryModel.ean = query.get('ean') || undefined;
    StocksQueryModel.current = query.get('current') || 1;
    StocksQueryModel.pageSize = query.get('pageSize') || 10;
    StocksQueryModel.warehouse = query.get(KEYS.WAREHOUSE) || undefined;
    StocksQueryModel.availability = query.get(KEYS.AVAILABILITY) || undefined;
    return StocksQueryModel;
  }

  static fromQueryToPayload (query) {
    const { API_FILTERS_KEYS } = StocksListPayload;
    const payload = new StocksListPayload();

    payload.ean = query.ean;
    payload.pageNumber = query?.current > 0 ? +query.current : 1;
    payload.limit = query.pageSize || 10;
    payload[API_FILTERS_KEYS.WAREHOUSE] = query.warehouse;
    payload[API_FILTERS_KEYS.AVAILABILITY] = query.availability;
    return payload.getCleanPayload();
  }
}
