export class ImageModel {
  constructor (data) {
    this.uid = data.id;
    this.name = data.filename;
    this.status = 'done';
    this.url = data.imageURL;
    this.info = {
      type: data.type
    };
  }
}
