import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Routes } from 'src/router/Routes.helper';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { AccountsAPI } from '../../accounts/api/AccountsApi';
import { PageTitle } from 'src/components/PageTitle';
import { getPermissionSetsFromDepartment } from 'src/utils/utils';
import { UserForm } from '../component/UserForm';
import { LocalBusinessAPI } from '../api/localBusinessAPI';

async function getPermissionSetsFromDepartmentBasedOnRole (userRole, lbId) {
  if (userRole === 'ROLE_LOCAL_BUSINESS_MANAGER') {
    const res = await LocalBusinessAPI.getLocalBusinessDetails(lbId);
    const deptKey = res?.data?.departments?.[0]?.key;
    return getPermissionSetsFromDepartment(deptKey);
  } else {
    return [];
  }
}

export const PageCreateUser = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.users/create', t);

  const [form] = useForm();
  const history = useHistory();
  const { userInfo, canManageUsers } = useAuth();

  useEffect(() => {
    if (userInfo) {
      const userLocalBusinessOption = !canManageUsers ? { value: userInfo?.localBusiness?.id, label: userInfo?.localBusiness?.name } : undefined;
      form.setFieldsValue({
        userLocalBusiness: userLocalBusinessOption
      });
    }
  }, [canManageUsers, userInfo]);

  const navigateToUsersPage = () => {
    history.push(Routes.PATHS.USERS);
  };

  const buttons = (
    <div className='flex items-center w-full '>
      <Button
        type='default'
        htmlType='button'
        className='uppercase mr-2 ml-auto'
        onClick={navigateToUsersPage}
      >
        {text('cancel')}
      </Button>
      <Button
        type='primary'
        htmlType='submit'
        className='uppercase ml-2'
      >
        {text('saveNewUser')}
      </Button>
    </div>
  );

  const onFinish = async (values) => {
    const {
      userFirstName,
      userLastName,
      username,
      userEmail,
      userRole,
      userLocalBusiness
    } = values;

    const permissionSets = await getPermissionSetsFromDepartmentBasedOnRole(userRole, userLocalBusiness.value);

    try {
      const newUserData = {
        type: userRole,
        username: username,
        givenName: userFirstName,
        familyName: userLastName,
        email: userEmail,
        localBusiness: {
          id: userLocalBusiness.value
        },
        permissionSets
      };
      await AccountsAPI.addNewUser(newUserData);
      message.success(text('createOK'));
      navigateToUsersPage();
    } catch (e) {
      if (e?.response?.status === 409) {
        message.error(text('createErrDuplicated'));
      } else {
        message.error(text('createErr'));
      }
    }
  };

  return (
    <div className='py-4'>

      <PageTitle>{text('createNewUser')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToUsersPage}
        title={text('backToUsers')}
      />

      <UserForm
        form={form}
        buttons={buttons}
        onFinish={onFinish}
        mode='create'
      />

    </div>
  );
};
