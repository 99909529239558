import React from 'react';
import { Form, Select } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { PageSection } from 'src/components/PageSection';
import { useTranslation } from 'react-i18next';

export const SelectDepartment = ({
  disabled,
  options,
  onSelect,
  textSource
}) => {
  const { t } = useTranslation();
  const text = textFrom(textSource, t);

  return (
    <>
      <PageSection>{text('department')}</PageSection>
      <Form.Item
        name='department'
        label={text('departmentLabel')}
      >
        <Select
          disabled={disabled}
          options={options}
          onSelect={onSelect}
          placeholder={text('departmentPlaceholder')}
        />
      </Form.Item>
    </>
  );
};
