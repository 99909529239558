import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Select, Input, InputNumber } from 'antd';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { ImageUpload } from 'src/modules/admin/component/ImageUpload';
import { TrimmedDescription } from '../../../components/TrimmedDescription';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { SEPARATOR } from 'src/utils/constants';
import { UtilsAPI } from '../../../api/utils';

export const OutfitForm = ({
  currentDescription,
  selectedLanguage,
  onFinish,
  handleLanguageSelection,
  form,
  mode = 'create'
}) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const text = textFrom('pages.outfits', t);
  const [logoUrl, setLogoUrl] = useState();

  const handleImageUpload = async (file) => {
    const res = await UtilsAPI.uploadImage(file);
    form.setFieldsValue({
      image: res?.data?.images[0].imageURL
    });
    setLogoUrl({ href: res?.data?.images[0].imageURL });
  };

  useEffect(() => {
    if (form?.getFieldsValue()?.image) {
      setLogoUrl({ href: form.getFieldsValue().image });
    }
  }, [form?.getFieldsValue()?.image]);

  return (
    <Form
      name='basic'
      onFinish={onFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
      className='max-w-8xl'
    >
      <div className='max-w-5xl'>
        <Row gutter={[16, 16]} className='pt-2 pb-8'>
          <Col xs={4}>
            <LanguageSelect
              label={text('form.language.chooseLanguage')}
              onSelect={handleLanguageSelection}
            />
          </Col>
        </Row>

        <Row gutter={20} className='mb-24'>

          <Col xs={4}>
            <Form.Item
              name='image'
              label={text('form.image.label')}
              rules={[{ required: true, message: text('form.image.message') }]}
            >
              <ImageUpload
                imageUrl={logoUrl?.href}
                onUpload={handleImageUpload}
                initialValue={undefined}
              />
            </Form.Item>
          </Col>

          <Col xs={8}>
            {mode === 'create' &&
              <Form.Item
                name='externalId'
                label={text('form.id.label')}
                rules={[{ required: true, message: text('form.id.message') }]}
                initialValue={undefined}
              >
                <Input
                  placeholder={text('form.id.placeholder')}
                />
              </Form.Item>}
            <NameInput selectedLanguage={selectedLanguage} />
            <>
              <DescriptionInput selectedLanguage={selectedLanguage} />
              {currentDescription &&
                <TrimmedDescription description={currentDescription} />}
            </>
          </Col>

          <Col xs={6}>
            <Form.Item
              name='status'
              label={text('form.status.label')}
              rules={[{ required: true, message: text('form.status.message') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                style={{ width: '100%' }}
                initialValue='Draft'
              >
                <Option value='Draft'>{text('form.status.optionDraft')}</Option>
                <Option value='Published'>{text('form.status.optionPublished')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              type='number'
              name='priority'
              label={text('form.priority.label')}
              rules={[{ required: true, message: text('form.priority.message') }]}
              initialValue={0}
            >
              <InputNumber
                placeholder={text('form.priority.placeholder')}
              />
            </Form.Item>
          </Col>

        </Row>
      </div>
      <div />

    </Form>
  );
};

const NameInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.outfits', t);

  return (
    <Form.Item
      required
      name={`names${SEPARATOR}${selectedLanguage}`}
      label={<span>{text('form.name.label')}</span>}
      rules={[{ required: true, message: text('form.name.message') }]}
      initialValue={undefined}
    >
      <Input.TextArea
        rows='1'
        showCount
        maxLength={100}
        placeholder={text('form.name.placeholder')}
      />
    </Form.Item>
  );
};

const DescriptionInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.outfits', t);

  return (
    <Form.Item
      required
      name={`descriptions${SEPARATOR}${selectedLanguage}`}
      label={<span>{text('form.description.label')}</span>}
      rules={[{ required: true, message: text('form.description.message') }]}
      initialValue={undefined}
    >
      <Input.TextArea
        rows='6'
        showCount
        maxLength={1500}
        placeholder={text('form.description.placeholder')}
      />
    </Form.Item>
  );
};
