import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useFilters } from 'src/hooks/useFilters';
import { usePagination } from 'src/hooks/usePagination';
import { LocationAPI } from 'src/modules/locations/api/LocationAPI';
import { LocationsMapper } from 'src/modules/locations/domain/Locations.mapper';
import { message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

export const LocationContext = createContext();

const useProviderLocation = () => {
  const [locations, setLocations] = useState([]);
  const [availableFilters, setAvailableFilters] = useState({ filters: [] });
  const [areLocationsLoading, setAreLocationsLoading] = useState(false);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(LocationsMapper);

  const auth = useAuth();

  let rangeFilter = {
    key: 'start_dt',
    start: '1970-01-01T08:30:00Z',
    end: '2099-01-01T23:59:59.000Z'
  };

  if (query.startDate && query.endDate) {
    rangeFilter = {
      key: 'start_dt',
      start: query.startDate,
      end: query.endDate
    };
  }

  const searchQuery = {};

  /**
   * @description Get list of products
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getLocations = useCallback(async () => {
    setAreLocationsLoading(true);
    const initialCustomFilters = [
      {
        ...searchQuery,
        department: 'event'
      }
    ];
    const filters = [...initialCustomFilters];
    const businessId = auth?.userInfo?.localBusiness?.id;
    if (businessId) {
      filters[0].local_business_id = businessId;
    }
    try {
      const res = await LocationAPI.searchLocations({
        limit: query.pageSize || 10,
        pageNumber: +query.current || 1,
        ranges: [rangeFilter],
        filters,
        orderingList: null,
        text: query.text || ''
      });
      const items = res.data.items.map((item) => ({
        ...item
      }));
      setLocations(items);
      setAvailableFilters(res.data.availableFilters);
      setPagination(res.data);
      return Promise.resolve();
    } catch (e) {
      message.error('Something went wrong!');
    } finally {
      setAreLocationsLoading(false);
    }
  }, [query, setPagination]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const statusOptions = [
    {
      value: 'draft',
      name: 'Draft'
    },
    {
      value: 'published',
      name: 'Published'
    }
  ];

  return {
    query,
    locations,
    setLocations,
    availableFilters,
    pagination,
    onChangeQuery,
    refreshData: getLocations,
    statusOptions,
    areLocationsLoading
  };
};

export const LocationProvider = ({ children }) => {
  const location = useProviderLocation();
  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocations = () => useContext(LocationContext);
