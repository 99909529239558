export class ProductsQuery {
  text;
  current;
  pageSize;
  brand;
  macroCategory;
  microCategory;
  published;

  static get KEYS() {
    return {
      MACRO_CATEGORY: "macroCategory",
      MICRO_CATEGORY: "microCategory",
      BRAND: "brand",
      TEXT: "text",
      PUBLISHED: "published"
    };
  }
}
