import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import { TenantAPI } from '../api/tenantAPI';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

export const TenantContext = createContext();

const useProvideTenant = () => {
  const { t } = useTranslation();
  const text = textFrom('providers.tenant', t);

  const [config, setConfig] = useState('');
  const [configIsLoading, setConfigIsLoading] = useState(false);

  const getConfig = useCallback(async () => {
    setConfigIsLoading(true);
    try {
      const getTenantConfigRes = await TenantAPI.getConfig();
      setConfig(getTenantConfigRes?.data);
      Promise.resolve();
    } catch (e) {
      message.error(text('getConfigKO'));
      Promise.reject(e);
    } finally {
      setConfigIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getConfig();
  }, []);

  return {
    config,
    configIsLoading,
    getConfig
  };
};

export const TenantProvider = ({ children }) => {
  const tenant = useProvideTenant();

  return (
    <TenantContext.Provider value={tenant}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);
