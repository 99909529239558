import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { getCorrectMinutesAndHours, minutesToObj } from '../../../utils/utils';
const { Option } = Select;

export const DurationPicker = ({ handleChange, overMidnight, initialData, startTime }) => {
  const [duration, setDuration] = useState({
    days: 0,
    hours: 0,
    minutes: 0
  });

  const [disabledInput, setDisabledInputs] = useState({
    days: false,
    hours: false,
    minutes: false
  });

  const [hoursOptions, setHoursOptions] = useState([]);
  const [minutesOptions, setMinutesOptions] = useState([]);

  useEffect(() => {
    if (initialData?.attributes?.start_time) {
      setDisabledInputs({
        days: false,
        hours: false,
        minutes: false
      });
      const [h, m] = initialData.attributes.start_time.split(':');
      const date = new Date();
      date.setHours(h);
      date.setMinutes(m);
      date.setSeconds(0);
      const duration = minutesToObj(initialData.attributes.durationInMin);
      const d = getCorrectMinutesAndHours(date);
      if (d.selectedTime) {
        setHoursOptions(d.hours);
        setMinutesOptions(+d.selectedTime.hours + +duration.hours !== 23 ? d.minutes : d.minutesDelta);
      }
      setDuration({
        days: 0,
        hours: duration.hours,
        minutes: duration.minutes
      });
    }
  }, [initialData]);

  useEffect(() => {
    durationHandler(duration);
  }, [duration, startTime]);

  useEffect(() => {
    if (overMidnight) {
      setDuration({
        days: 0,
        hours: 0,
        minutes: 0
      });
    }
  }, [overMidnight]);

  const durationHandler = (durationPayload) => {
    const durationOptions = handleChange(durationPayload);
    if (durationOptions) {
      const { hours, minutes, minutesDelta, selectedTime } = durationOptions;
      setHoursOptions(hours);
      setMinutesOptions(+selectedTime.hours + +duration.hours !== 23 ? minutes : minutesDelta);
    }
  };

  const onChange = (value, input) => {
    setDuration(prevState => ({
      ...prevState,
      [input]: value
    }));
  };

  return (
    <div className='flex items-center space-x-2'>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        value={duration.hours}
        onChange={(value) => onChange(+value, 'hours')}
        disabled={disabledInput.hours}
      >
        {hoursOptions.map(hour => (
          <Option value={hour} key={hour}>
            {hour} {hour === 1 ? 'hour' : 'hrs'}
          </Option>
        ))}
      </Select>
      <Select
        getPopupContainer={trigger => trigger.parentNode}
        value={duration.minutes}
        onChange={(value) => onChange(+value, 'minutes')}
        disabled={disabledInput.minutes}
      >
        {minutesOptions.map(minute => (
          <Option value={minute} key={minute}>
            {minute} min
          </Option>
        ))}
      </Select>
    </div>
  );
};
