import React, { useState } from 'react';
import { message, Upload, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';

const MAX_MEGABYTES = 3;
const VALID_FORMATS = ['image/jpeg', 'image/png', 'image/svg+xml'];

export const ImageUpload = ({
  imageUrl,
  onUpload,
  isDisabled
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const showPreview = () => {
    setIsPreviewOpen(true);
  };

  const hidePreview = () => {
    setIsPreviewOpen(false);
  };

  const handleUploadStart = (info) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
    }
  };

  const handleUploadEnd = async ({ file }) => {
    try {
      await onUpload(file);
    } catch (err) {
      message.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='uploaded-image-overlay'>
        <Upload
          disabled={isDisabled}
          listType='picture-card'
          showUploadList={false}
          beforeUpload={isFileValid}
          onChange={handleUploadStart}
          customRequest={handleUploadEnd}
        >
          {imageUrl
            ? <UploadedImage url={imageUrl} isDisabled={isDisabled} />
            : <UploadButton isLoading={isLoading} isDisabled={isDisabled} />}
        </Upload>
        {imageUrl && <Icons onPreview={showPreview} isDisabled={isDisabled} />}
      </div>

      <PreviewModal
        imageUrl={imageUrl}
        onCancel={hidePreview}
        isVisible={isPreviewOpen}
      />
    </>
  );
};

const UploadButton = ({ isLoading, isDisabled }) => (
  <div className={isDisabled ? 'disabled-area' : ''}>
    {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
    <div>
      Upload
    </div>
  </div>
);

const UploadedImage = ({ url, isDisabled }) => {
  let cssClass = 'uploaded-image';
  cssClass += isDisabled ? ' disabled-area' : '';
  return (
    <img
      src={url}
      className={cssClass}
    />
  );
};

const Icons = ({ onPreview, isDisabled }) => (
  <>
    <EyeOutlined
      onClick={onPreview}
      className='uploaded-image-icon uploaded-image-icon-preview'
    />
    {!isDisabled &&
      <EditOutlined
        className='uploaded-image-icon uploaded-image-icon-edit'
      />}
  </>
);

const PreviewModal = ({ imageUrl, isVisible, onCancel }) => (
  <Modal
    footer={null}
    title='Image Preview'
    visible={isVisible}
    onCancel={onCancel}
  >
    <img src={imageUrl} />
  </Modal>
);

const isFileValid = (file) => {
  const isFormatValid = VALID_FORMATS.includes(file.type);
  if (!isFormatValid) {
    message.error('Invalid file format! Valid formats: ', VALID_FORMATS.join(', '));
  }
  const isSizeValid = file.size / 1024 / 1024 < MAX_MEGABYTES;
  if (!isSizeValid) {
    message.error(`File must be smaller than ${MAX_MEGABYTES} MB!`);
  }
  return isFormatValid && isSizeValid;
};
