import isEmpty from "lodash/isEmpty";
import { Filters } from "src/services/Filters.service";
import { LocationQuery } from "src/modules/locations/domain/models/LocationQuery";
import { ProductsListPayload } from "src/modules/products/api/payloads/ProductsListPayload";
import { Product } from "src/services/Product.service";

export class LocationsMapper {
  static fromQueryToQueryString(query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery(qs) {
    const query = new URLSearchParams(qs);
    const locationsQueryModel = new LocationQuery();
    locationsQueryModel.text = query.get("text") || undefined;
    locationsQueryModel.current = query.get("current") || undefined;
    locationsQueryModel.pageSize = query.get("pageSize") || undefined;
    return locationsQueryModel;
  }

  static fromQueryToPayload(query) {
    const payload = new ProductsListPayload();
    payload.text = query.text;
    payload.pageNumber = +query.current === 0 ? 0 : +query.current - 1;
    payload.limit = query.pageSize || 10;
    payload.filters = Filters.removeFalsyValues({
      published: Product.getStatusBooleanValue(query.published)?.toString(),
      department: 'food' // TODO fix this
    });
    payload.filters = isEmpty(payload.filters) ? undefined : payload.filters;
    return payload;
  }
}
