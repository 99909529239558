import { useCallback, useState } from "react";
import { AntPagination } from "src/domain/Pagination";

export const usePagination = (initialState = {}) => {
  const [pagination, setPagination] = useState(initialState);

  const handlePagination = useCallback((payload) => {
    setPagination(new AntPagination(payload));
  }, []);

  return [pagination, handlePagination];
};
