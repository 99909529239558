import React from 'react';
import { Form, Input } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

export const InputAttributeCodeTranslation = ({
  textSource,
  disabled,
  language
}) => {
  const { t } = useTranslation();
  const text = textFrom(textSource, t);

  return (
    <Form.Item
      name={[`attributeCodeTranslations-${language}`]}
      label={text('translation')}
    >
      <Input
        disabled={disabled}
      />
    </Form.Item>
  );
};
