import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useTenant } from '../provider/tenant.provider';

export const PageFlagFeaturesConfig = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.flag', t);
  const [updatedConfig, setUpdatedConfig] = useState({});
  const [codeLines, setCodeLines] = useState(0);

  const { config } = useTenant();
  const preElement = useRef();

  useEffect(() => {
    if (config?.tenant) {
      setUpdatedConfig(config);
    }
  }, [config]);

  useEffect(() => {
    if (preElement?.current !== undefined) {
      setCodeLines(preElement?.current?.innerHTML.split(/\r\n|\r|\n/).length);
    }
  }, [updatedConfig]);

  const calculateLines = useCallback((l) => {
    if (l > 0) {
      const htmlLines = [];
      for (let i = 0; i < l; i++) {
        htmlLines.push(<pre key={i}>{i + 1}</pre>);
      }
      return htmlLines;
    }
  }, [codeLines]);

  const printFormattedLines = useCallback(() => {
    return JSON.stringify(updatedConfig, null, 3);
  }, [updatedConfig]);

  return (
    <>
      <div className='py-4'>
        <PageTitle>{text('pageTitle')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col>
            <p>{text('description.text_1')}</p>
            <ul>
              <li>
                • {text('description.list_1')}
              </li>
              <li>
                • {text('description.list_2')}
              </li>
            </ul>
            <p>{text('description.text_2')}</p>
            <p>{text('description.text_3')}</p>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='px-3 py-4'>
          <div className='bg-white p-3 linesCounter' xs={1}>
            {calculateLines(codeLines)}
          </div>
          <Col className='bg-white p-3' xs={18}>
            {updatedConfig?.tenant && (
              <pre ref={preElement}>{printFormattedLines()}</pre>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
