import React, { useState, useEffect } from 'react';
import { Collapsible } from 'src/components/Collapsible';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { SelectedOutfits } from './SelectedOutfits';
import { SearchOutfits } from './SearchOutfits';

export const OutfitCollectionCollapsibles = ({
  outfits,
  linkedOutfits,
  setLinkedOutfits
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.outfitCollectionCollapsibles', t);

  const [outfitsToShow, setOutfitsToShow] = useState([]);

  useEffect(() => {
    if (outfits.length > 0 && linkedOutfits) {
      const newOutfitsToShow = [];
      outfits.forEach(outfit => {
        if (linkedOutfits.length === 0 || !(linkedOutfits.find(linkedOutfit => linkedOutfit.id === outfit.id))) {
          newOutfitsToShow.push(outfit);
        }
      });
      setOutfitsToShow(newOutfitsToShow);
    }
  }, [linkedOutfits, outfits]);

  return (
    <>
      <Collapsible
        title={text('selectedCollectionOutfit')}
        className='mb-8'
      >
        <SelectedOutfits
          linkedOutfits={linkedOutfits}
          setLinkedOutfits={setLinkedOutfits}
        />
      </Collapsible>
      <Collapsible
        title={text('searchCollectionOutfit')}
      >
        <SearchOutfits
          outfits={outfitsToShow}
          setLinkedOutfits={setLinkedOutfits}
        />
      </Collapsible>
    </>
  );
};
