import React, { useState } from 'react';
import { Row, Col, Select, Button, Input, Form, Typography, Modal, InputNumber } from 'antd';
import { ProductsAPI } from '../api/ProductsAPI';
import { message } from 'src/services/Messages.service';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
const { Title, Text } = Typography;

function payloadFrom (name, size, price) {
  return {
    names: {
      it: {
        locale: 'it',
        value: name
      },
      en: {
        locale: 'en',
        value: name
      }
    },
    attributeVariants: {
      size: {
        attributeCode: 'size',
        key: size
      }
    },
    prices: {
      sellingPrice: +price,
      retailPrice: +price
    },
    isLocalOnly: true
  };
}

function sizeOf (variant) {
  return variant.attributeVariants.size?.key;
}

export const ProductVariantsFood = ({ userRole, variants, productId, productVariants, setProductVariants, editMode, setNewVariantAdded }) => {
  const { t } = useTranslation();
  const text = textFrom('components.productVariantsFood', t);

  const [variantsList, setVariantsList] = useState([]);
  const [, setVariantsOptions] = useState([]);
  const [isAddingSize, setIsAddingSize] = useState(false);
  const [isDeletionUnderway, setIsDeletionUnderway] = useState(false);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [variantSizeToBeRemoved, setVariantSizeToBeRemoved] = useState(undefined);

  const { Option } = Select;
  const [form] = Form.useForm();

  const handleAddNew = async () => {
    setIsAddingSize(true);
    setVariantsList([...variantsList, Variant(variantsList.length)]);
  };

  const handleSave = async (index, event) => {
    event.preventDefault();
    const values = form.getFieldsValue();
    const { size, price, name } = values;

    if (!size) {
      message.error(text('sizeMissing'));
      return;
    }

    if (!price) {
      message.error(text('priceMissing'));
      return;
    }

    const sizeExist = productVariants.find(variant => (sizeOf(variant)) === size);

    if (sizeExist) {
      message.error(text('sizeAlreadyAdded'));
      return;
    }
    setIsAddingSize(false);
    if (editMode) {
      setNewVariantAdded(true);
    }

    const variantPayload = payloadFrom(name, size, price);

    setVariantsList([...variantsList].splice(index, 1));
    setProductVariants(variants => ([...variants, variantPayload]));
    form.setFieldsValue({
      size: null,
      price: null,
      availability: null
    });
  };

  const handleCancel = async (index, event) => {
    // TODO move it into the try and uncomment the API call
    setVariantsList([...variantsList].splice(index, 1));
    setIsAddingSize(false);
  };

  // TODO add debounce
  const handleSearch = async (value) => {
    // TODO add current variants options
    // TODO rework condition
    setVariantsOptions((prevState) => (!!value && value !== '') ? [...prevState, value] : prevState);
  };

  const handleFocus = () => {
    // TODO uncomment me after variants are done
    // setVariantsOptions([]);
  };

  const handleVariantUpdate = () => {
    // TODO Variant update
  };

  const showDeletionModal = (variantSize) => {
    setVariantSizeToBeRemoved(variantSize);
    setIsDeletionModalVisible(true);
  };

  const dismissDeletionModal = () => {
    setVariantSizeToBeRemoved(undefined);
    setIsDeletionModalVisible(false);
  };

  const confirmVariantDeletion = () => {
    const variant = productVariants.find(variant => sizeOf(variant) === variantSizeToBeRemoved);
    const { id: variantId, isLocalOnly: variantIsLocalOnly } = variant;
    const variantSize = sizeOf(variant);

    if (variantIsLocalOnly) {
      deleteVariantLocally(variantSize);
    } else {
      deleteVariantRemotely(variantSize, variantId);
    }
  };

  const deleteVariantLocally = (sizeToBeRemoved) => {
    setProductVariants(variants => variants.filter(variant => sizeOf(variant) !== sizeToBeRemoved));
    dismissDeletionModal();
  };

  const deleteVariantRemotely = async (variantSize, variantId) => {
    setIsDeletionUnderway(true);
    try {
      await ProductsAPI.deleteVariant(productId, variantId);
      deleteVariantLocally(variantSize);
    } catch (e) {
      message.error(e?.response?.data?.detail);
    } finally {
      setIsDeletionUnderway(false);
    }
  };

  // TODO index is not updated when deleted from array, use a property once the API is working
  const Variant = (index) => (
    <Form
      onFinish={handleVariantUpdate}
      form={form}
      initialValues={{
        size: null,
        price: null,
        availability: null,
        name: variants[index].name
      }}
      layout='vertical'
      autoComplete='off'
      scrollToFirstError
    >
      <Row gutter={[18, 18]} className='pb-4 pt-4'>
        <Col span={6}>
          <Form.Item name='size' label={text('size')} required rules={[{ required: true }]}>
            <Select
              getPopupContainer={trigger => trigger.parentNode}
              showSearch
              filterOption
              onSearch={handleSearch}
              onFocus={handleFocus}
              style={{ width: '100%' }}
              placeholder={text('searchOrAddVariants')}
            >
              {variants?.map((option) => (
                <Option key={option.attributeKey} value={option.attributeKey}>
                  {option.attributeKey}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name='price' label={text('price')} required rules={[{ required: true }]}>
            <Input
              placeholder={text('price')}
              addonBefore='€'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[18, 18]}>
        <Col offset={6} span={3}>
          <Button
            className='uppercase w-100'
            type='secondary'
            onClick={event => handleCancel(index, event)}
          >
            {text('cancel')}
          </Button>
        </Col>
        <Col span={3}>
          <Button
            type='default w-100'
            className='uppercase'
            onClick={event => handleSave(index, event)}
          >
            {text('addSize')}
          </Button>
        </Col>
      </Row>
    </Form>);

  return (
    <div>
      <Modal
        visible={isDeletionModalVisible}
        onOk={confirmVariantDeletion}
        onCancel={dismissDeletionModal}
        confirmLoading={isDeletionUnderway}
      >
        <div>{text('confirmDeletion')}</div>
      </Modal>
      <AllVariantsList
        productVariants={productVariants}
        setProductVariants={setProductVariants}
        showDeletionModal={showDeletionModal}
      />
      {variantsList.map((variant, i) => <div key={i}> {variant}</div>)}
      <Row gutter={[18, 18]}>
        {userRole !== 'superAdmin' && (
          <Button
            type='primary'
            className='uppercase ml-2 mt-4'
            onClick={handleAddNew}
            hidden={isAddingSize}
          >
            {text('addNewSize')}
          </Button>
        )}
      </Row>
    </div>
  );
};

const AllVariantsList = ({ productVariants, setProductVariants, showDeletionModal }) => {
  const { t } = useTranslation();
  const text = textFrom('components.productVariantsFood', t);

  const [editedVariantSize, setEditedVariantSize] = useState(undefined);
  const [editForm] = Form.useForm();

  const handleStartEditing = (variant) => {
    editForm.setFieldsValue({
      price: variant.prices.sellingPrice
    });
    setEditedVariantSize(sizeOf(variant));
  };

  const handleCancelEditing = () => {
    editForm.resetFields();
    setEditedVariantSize(undefined);
  };

  const handleEditVariant = () => {
    const { price } = editForm.getFieldsValue();
    if (price !== null) {
      setProductVariants(variants => variants.map(variant => {
        if (sizeOf(variant) === editedVariantSize) {
          return { ...variant, prices: { ...variant.prices, sellingPrice: price } };
        }
        return variant;
      }));
      editForm.resetFields();
      setEditedVariantSize(undefined);
    }
  };

  const remoteVariants = productVariants.filter(variant => !variant.isLocalOnly);

  return (
    <div className='max-w-4xl my-4'>
      {productVariants && productVariants.length > 0 &&
        <Row gutter={8} className='mb-4'>
          <Col xs={4}>
            <Title level={5}>{text('size')}</Title>
          </Col>
          <Col xs={4}>
            <Title level={5}>{text('price')}</Title>
          </Col>
        </Row>}
      {productVariants && productVariants.length > 0 &&
        productVariants.map((item) => (
          <Form key={item.id} form={editForm}>
            <Row gutter={8} key={item.id} className='mb-2 hover-class items-center'>
              <Col xs={4}>
                <Text>{sizeOf(item)}</Text>
              </Col>
              <Col xs={4}>
                {(sizeOf(item) !== editedVariantSize)
                  ? (<Text>{item.prices.sellingPrice}</Text>)
                  : (
                    <Form.Item name='price' required rules={[{ required: true }]}>
                      <InputNumber
                        min={0}
                        addonBefore='€'
                        placeholder={text('price')}
                        parser={str => str.replace(/,/g, '.')}
                      />
                    </Form.Item>)}
              </Col>
              {sizeOf(item) !== editedVariantSize &&
                <>
                  <Col xs={4}>
                    <Button
                      type='text'
                      htmlType='button'
                      className='uppercase hover-toggle'
                      onClick={() => handleStartEditing(item)}
                    >
                      {text('editSize')}
                    </Button>
                  </Col>
                  {(item.isLocalOnly || remoteVariants.length > 2) &&
                    <Col xs={6}>
                      <Button
                        danger
                        type='text'
                        htmlType='button'
                        className='uppercase mr-2 hover-toggle'
                        onClick={() => showDeletionModal(sizeOf(item))}
                      >
                        {text('removeSize')}
                      </Button>
                    </Col>}
                </>}
            </Row>
            {sizeOf(item) === editedVariantSize && (
              <Row gutter={[18, 18]}>
                <Col span={4} offset={4}>
                  <Button
                    type='secondary'
                    className='uppercase w-100'
                    onClick={() => handleCancelEditing()}
                  >
                    {text('cancel')}
                  </Button>
                </Col>
                <Col span={4}>
                  <Button
                    type='default'
                    className='uppercase'
                    onClick={() => handleEditVariant()}
                  >
                    {text('saveChanges')}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        ))}
    </div>
  );
};
