import React, { useEffect, useState } from 'react';
import { message } from 'src/services/Messages.service';
import { Row, Col, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { BackButton } from 'src/components/BackButton';
import { Routes } from 'src/router/Routes.helper';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { LocationAPI } from '../api/LocationAPI';
import { PageTitle } from 'src/components/PageTitle';
import { LocationForm } from '../components/LocationForm';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';

export const PageCreateLocations = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.locations/create', t);

  const history = useHistory();
  const [form] = Form.useForm();
  const { defaultLanguage } = useLanguages();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!defaultLanguage) return;
    form.setFieldsValue({ language: defaultLanguage.label });
  }, [form, defaultLanguage]);

  const navigateToLocationsPage = () => {
    history.push({ pathname: Routes.PATHS.LOCATIONS });
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton
          label={text('cancel')}
          onClick={navigateToLocationsPage}
        />
      </Col>
      <Col>
        <SubmitButton
          loading={isLoading}
          disabled={isLoading}
          label={text('createLocation')}
        />
      </Col>
    </Row>
  );

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const payload = { ...values, attributes: {} };
      await LocationAPI.createLocation(payload);
      message.success(text('locationCreateOK'));
      navigateToLocationsPage();
    } catch (e) {
      message.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToLocationsPage}
        title={text('back')}
      />

      <LocationForm
        form={form}
        buttons={buttons}
        onFinish={onFinish}
      />

    </div>
  );
};
