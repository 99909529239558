import React from 'react';
import { Table, Checkbox } from 'antd';
import { SCOPE } from 'src/utils/scope-utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useUsers } from '../provider/users.provider';
import { textFrom } from 'src/utils/textFrom';
import { isVerified } from 'src/utils/utils';
import { useTranslation } from 'react-i18next';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

export const UserTable = ({
  loading,
  onEdit,
  onRequestVerificationEmail,
  onDelete,
  onLoginAs,
  users,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.users', t);
  const { selectedUserScope, canLoginAs } = useAuth();
  const { roles } = useUsers();

  const getRoleLabel = (roleName) => {
    return roles.find(r => r.roleName === roleName)?.label;
  };

  const columns = [
    {
      title: text('name'),
      render: (_, user) => <div>{user.familyName} {user.givenName}</div>
    },
    {
      title: text('username'),
      key: 'username',
      dataIndex: 'username',
      render: (username) => <div>{username}</div>
    },
    {
      title: text('email'),
      key: 'email',
      dataIndex: 'email',
      render: (email) => <div>{email}</div>
    },
    {
      title: text('localBusiness'),
      key: ['localBusiness', 'name'],
      dataIndex: ['localBusiness', 'name'],
      isHidden: selectedUserScope === SCOPE.LOCALBUSINESS,
      render: (lbName) => <div>{lbName}</div>
    },
    {
      title: text('role'),
      key: 'type',
      dataIndex: 'type',
      render: (role) => <div>{getRoleLabel(role)}</div>
    },
    {
      title: text('active'),
      dataIndex: 'active',
      key: 'active',
      align: 'center',
      render: (active) => <Checkbox checked={active} disabled />
    },
    {
      title: '',
      dataIndex: 'operation',
      align: 'center',
      width: 200,
      render: (_, row) => {
        const userEmailVerificationStatus = row?.primaryEmailVerificationStatus;
        return (
          <PopoverActionMenu>
            <MenuAction onClick={onEdit.bind(_, row)} label={text('edit')} testId='edit-action' />
            {!isVerified(userEmailVerificationStatus) &&
              <MenuAction onClick={onRequestVerificationEmail.bind(_, row)} label={text('requestVerificationEmail')} />}
            {canLoginAs &&
              <MenuAction onClick={onLoginAs.bind(_, row)} label={text('logInAs')} />}
          </PopoverActionMenu>
        );
      }
    }
  ].filter(col => !col.isHidden);

  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={users}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
      rowClassName='cursor-pointer'
    />
  );
};
