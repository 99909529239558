import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Input, Form, Divider, Button, Select, Typography, Radio, Space, InputNumber, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useProductDetailsFood as useProductDetails } from 'src/modules/products/provider/productDetailsFood.provider';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { BackButton } from 'src/components/BackButton';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { WysiwygEditor } from 'src/components/inputs/WysiwygEditor';
import { ProductVariantsFood } from 'src/modules/products/components/ProductVariantsFood';
import { CategoriesFood as Categories } from 'src/modules/products/components/CategoriesFood';
import { ImageGalleryFood } from 'src/modules/products/components/ImageGalleryFood';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { Routes } from 'src/router/Routes.helper';
import { Product } from 'src/services/Product.service';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { FoodVariantModel as VariantModel } from 'src/modules/products/domain/models/FoodVariantModel';
import { StorageService } from 'src/services/Storage.service';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { flatten, nestify } from 'src/utils/objectManipulation';
import { SEPARATOR } from 'src/utils/constants';
import { DeletionModal } from 'src/components/DeletionModal';
import { SCOPE } from 'src/utils/scope-utils';

const { Option } = Select;
const COVER = 'cover';
const { PLATFORM, TENANT } = SCOPE;

export const PageProductDetailsFood = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.products/id', t);

  const history = useHistory();
  const [form] = Form.useForm();
  const { selectedUserScope } = useAuth();
  const {
    product,
    filteredProductVariants,
    getVariantAvailabilities,
    sizeAttributes,
    productVariants,
    setProductVariants
  } = useProductDetails();

  const { Link } = Typography;

  const [isSingleVariant, setIsSingleVariant] = useState(true);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const availabilityOptions = ['available', 'unavailable'];
  const [productAvailability, setProductAvailability] = useState(0);
  const [productGalleryFiles, setProductGalleryFiles] = useState([]);
  const [addedProductGalleryFiles, setAddedProductGalleryFiles] = useState([]);
  const [deletedProductGalleryFiles, setDeletedProductGalleryFiles] = useState([]);
  const [newVariantAdded, setNewVariantAdded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [mode, setMode] = useState('edit');
  const [user, setUser] = useState(null);
  const { languages, defaultLanguage } = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [isLinkVisible, setIsLinkVisible] = useState(false);

  useEffect(() => {
    if (mode === 'edit' && (selectedUserScope === PLATFORM || selectedUserScope === TENANT)) {
      setIsLinkVisible(false);
    } else {
      setIsLinkVisible(true);
    }
  }, [mode, selectedUserScope, setIsLinkVisible]);

  useEffect(() => {
    setSelectedLanguage(defaultLanguage.language);
  }, [defaultLanguage]);

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguage(languageId);
  };

  const requiredLanguage = useMemo(() => {
    return languages.find(language => language.defaultLanguage);
  }, [languages]);

  useEffect(() => {
    if (!defaultLanguage) {
      return;
    }
    form.setFieldsValue({ language: defaultLanguage.label });
  }, [product, form, defaultLanguage]);

  useEffect(() => {
    initializeMultiLangForm(form, product);
  }, [product]);

  useEffect(async () => {
    const user = await StorageService.getUser();
    setUser(user);
  }, []);

  const checkAvailability = async (productId, variantId) => {
    try {
      const res = await getVariantAvailabilities(productId, variantId);
      if (res.data && res.data.data.items.length > 0) {
        setProductAvailability(res.data.data.items[0].availability);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePricesAndAvailabilitiesOfAllVariants = async (product, variants, availability, user) => {
    const availabilitiesRes = await ProductsAPI.getVariantAvailabilities(product.id, variants.id);
    const warehouseAvailabilityId = availabilitiesRes?.data?.data?.items[0]?.id;
    if (warehouseAvailabilityId) {
      await ProductsAPI.updateVariantPrice(product, variants, availability, warehouseAvailabilityId, user.warehousesManaged[0]);
    }
  };

  const updateVariantPrice = async (product, variant, availability, user) => {
    const data = {
      price: variant.prices.sellingPrice,
      published: product.published,
      manufacturerCode: variant.manufacturerCode,
      size: {
        key: variant.attributeVariants.size
      }
    };
    const availabilitiesRes = await ProductsAPI.getVariantAvailabilities(product.id, variant.id);
    const warehouseAvailabilityId = availabilitiesRes?.data?.data?.items[0]?.id;
    const variantWithProductPublishedValue = { ...variant, published: product.published };
    await ProductsAPI.updateVariant(product.id, variantWithProductPublishedValue, data, VariantModel);
    if (warehouseAvailabilityId) {
      await ProductsAPI.updateVariantPrice(product, variant, availability, warehouseAvailabilityId, user.warehousesManaged[0]);
    }
  };

  useEffect(() => {
    setProductGalleryFiles(product?.photoGallery?.images || []);
  }, [product.photoGallery]);

  useEffect(() => {
    if (productVariants.length > 0 && productVariants.filter(item => (item.attributeVariants.size?.key ?? item.attributeVariants.size) !== 'no-size-food').length > 0) {
      setIsSingleVariant(false);
    }
  }, [productVariants]);

  useEffect(() => {
    if (product.id && filteredProductVariants.length > 0) {
      checkAvailability(product.id, filteredProductVariants[0].id);
    }
  }, [product.id, filteredProductVariants]);

  useEffect(() => {
    if (!product) return;
    form.setFieldsValue({
      names: product.names,
      descriptions: product.descriptions,
      status: product.published ? 'Published' : 'Draft',
      categories: {
        macro: {
          key: product.macroCategory.key,
          label: product.macroCategory.localeName
        },
        micro: {
          key: product.microCategory.key,
          label: product.microCategory.localeName
        },
        brand: {
          id: product.brand.id,
          key: product.brand.key,
          label: product.brand.name
        }
      },
      availability: productAvailability > 0 ? 'available' : 'unavailable',
      price: product.prices.sellingPrice
    });
  }, [form, product, productAvailability]);

  /**
   * @description Handle add image to gallery method
   * @param file
   */
  const handleAddedProductGalleryFile = (file) => {
    setAddedProductGalleryFiles((oldAddedProductFileList) => [...oldAddedProductFileList, file]);
  };

  /**
   * @description Handle delete image from gallery method
   * @param file
   */
  const handleDeleteProductGalleryFile = (file) => {
    if (addedProductGalleryFiles.find(addedFile => addedFile.uid === file.uid)) {
      setAddedProductGalleryFiles((oldAddedFileList) => oldAddedFileList.filter(addedFile => addedFile.uid !== file.uid));
    }
    if (file.uid.includes('rc-upload')) {
      return;
    }
    setDeletedProductGalleryFiles((oldDeletedFileList) => [...oldDeletedFileList, file]);
  };

  const handleGalleryUpdate = (file, fileList, type) => {
    let newGallery = [];
    if (type === 'cover') {
      const detailGalleryFiles = productGalleryFiles.filter(galleryFile => galleryFile.info.type !== COVER);
      const newCover = {
        ...fileList[0],
        info: {
          active: true,
          type: type
        }
      };
      newGallery = [...[newCover], ...detailGalleryFiles];
      const toDeleteCovers = productGalleryFiles.filter(file => file.info.type === COVER);
      toDeleteCovers.forEach(toDeleteCover => {
        handleDeleteProductGalleryFile(toDeleteCover);
      });
    } else {
      const coverGalleryFiles = productGalleryFiles.filter(galleryFile => galleryFile.info && galleryFile.info.type === COVER);
      const newDetailGalleryFiles = fileList.map(detailFileItem => {
        return {
          ...detailFileItem,
          info: {
            active: true,
            type: type
          }
        };
      });
      newGallery = [...coverGalleryFiles, ...newDetailGalleryFiles];
      if (file?.status === 'removed') {
        handleDeleteProductGalleryFile(file);
      }
    }
    setProductGalleryFiles(newGallery);
    if (file?.status !== 'removed') {
      handleAddedProductGalleryFile(file);
    }
  };

  const handleRadioChange = (event) => {
    if (isSingleVariant || mode !== 'edit') {
      setIsSingleVariant(event.target.value);
    }
  };

  const showDeletionModal = () => {
    setIsDeletionModalVisible(true);
  };

  const navigateToProductsPage = () => {
    history.push(Routes.PATHS.PRODUCTS_FOOD);
  };

  /**
   * @description Delete Product - redirect to products list after the product has been deleted successfully
   */
  const handleConfirmDeletion = async () => {
    try {
      await ProductsAPI.deleteProduct(product.id);
      navigateToProductsPage();
      message.success(text('onDeleteOk'));
    } catch (e) {
      message.error(e.response?.data?.detail);
    } finally {
      setIsDeletionModalVisible(false);
    }
  };

  const handleCancelDeletion = () => {
    setIsDeletionModalVisible(false);
  };

  const handleCancelEditProduct = () => {
    navigateToProductsPage();
  };

  const handleUpdateProduct = async (values) => {
    const formValues = form.getFieldsValue(true); // Get all the values, even the unmounted ones
    const {
      names,
      descriptions
    } = nestify(formValues, ['names', 'descriptions']);

    if (!names?.[requiredLanguage.language]?.value) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('name') }));
      return;
    }

    if (!descriptions?.[requiredLanguage.language]?.value) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('description') }));
      return;
    }

    const messageDuration = 0.75;
    setIsUpdating(true);

    values.brand = product.brand;
    const available = values.availability === 'available';
    const productStatusAsBoolean = Product.getStatusBooleanValue(values.status);
    setIsUpdating(true);
    try {
      if (mode === 'edit') {
        // TODO refactor me
        const minVariantPrice = !(isSingleVariant || productVariants.length === 0) ? productVariants.filter(item => item.prices.sellingPrice).reduce((prev, curr) => prev.prices.sellingPrice < curr.prices.sellingPrice ? prev : curr).prices.sellingPrice : values.price;
        // SE C'E' una nuova variante aggiunta
        if (newVariantAdded) {
          for (let i = 0; i < productVariants.length; i++) {
            if (productVariants[i].isLocalOnly) {
              try {
                productVariants[i].names = names;
                const variantRes = await ProductsAPI.createVariant(product.id, { ...productVariants[i] });
                await ProductsAPI.updateVariantGalleryFromProduct(product.id, variantRes.data.data.id);
                await ProductsAPI.updateProductVariantStatus(product.id, variantRes.data.data.id, productStatusAsBoolean);
                await ProductsAPI.updateProductAvailability(variantRes.data.data.id, available);

                const newVariant = { ...variantRes.data.data, availabilityTotal: available ? 1 : 0, published: productStatusAsBoolean };
                const arrayVariantModel = productVariants.filter(itm => itm instanceof VariantModel);
                arrayVariantModel.push(new VariantModel(newVariant));

                arrayVariantModel.forEach(async (variant) => {
                  updatePricesAndAvailabilitiesOfAllVariants(product, variant, available ? 1 : 0, user);
                });
              } catch (error) {
                message.error(text('onCreateErr'));
                break;
              }
            }
          }
        }

        // check if user added or deleted an image
        if (addedProductGalleryFiles.length > 0 || deletedProductGalleryFiles.length > 0) {
          const coverImage = productGalleryFiles.find(galleryFile => galleryFile.info.type === COVER);
          await ProductsAPI.updateProductCoverImage(product.id, coverImage, addedProductGalleryFiles, deletedProductGalleryFiles);
          const galleryImages = productGalleryFiles.filter(galleryFile => galleryFile.info.type !== COVER);
          await ProductsAPI.updateProductGallery(product.id, galleryImages, addedProductGalleryFiles, deletedProductGalleryFiles);
        }

        await ProductsAPI.updateProductStatus(product.id, productStatusAsBoolean);
        await ProductsAPI.updateProductAvailability(product.id, available);
        const newProduct = await ProductsAPI.updateProduct(product.id, { ...values, names, descriptions, price: isSingleVariant ? values.price : minVariantPrice, published: productStatusAsBoolean });

        if (isSingleVariant) {
          await ProductsAPI.updateVariantGalleryFromProduct(newProduct.id, productVariants[0].id);
          const newVariant = await ProductsAPI.updateSingleVariantPrice(newProduct, productVariants[0], VariantModel);
          await updatePricesAndAvailabilitiesOfAllVariants(newProduct, newVariant, available ? 1 : 0, user);
        } else {
          await productVariants.forEach((variant) => {
            if (variant.id) {
              ProductsAPI.updateVariantGalleryFromProduct(newProduct.id, variant.id);
            }
            if (!variant.isLocalOnly) {
              updateVariantPrice(newProduct, variant, available ? 1 : 0, user);
            }
            delete variant.isLocalOnly;
          });
        }
        setIsUpdating(false);
        message.success(text('onUpdateOk'), messageDuration);
      } else {
        // duplicate product
        if (addedProductGalleryFiles.length === 0) {
          message.error(text('coverImageMissing'));
          setIsUpdating(false);
          return;
        }
        const { names, descriptions, price, availability, categories: { micro, macro } } = values;
        // TODO refactor me
        const minVariantPrice = !(isSingleVariant || productVariants.length === 0) ? +productVariants.filter(item => item.prices.sellingPrice).reduce((prev, curr) => +prev.prices.sellingPrice < +curr.prices.sellingPrice ? prev : curr).prices.sellingPrice : price;
        const payload = {
          names: names,
          descriptions: descriptions,
          macroCategory: {
            key: macro.key
          },
          microCategory: {
            key: micro.key
          },
          brand: {
            key: 'no-brand'
          },
          department: {
            key: 'food'
          },
          prices: {
            sellingPrice: isSingleVariant || productVariants.length === 0 ? +price : minVariantPrice,
            retailPrice: isSingleVariant || productVariants.length === 0 ? +price : minVariantPrice
          }
        };

        const res = await ProductsAPI.createProduct(payload);
        await ProductsAPI.authorizeProduct(res.data.data.id);
        const coverImage = productGalleryFiles.find(galleryFile => galleryFile.info.type === COVER);
        await ProductsAPI.updateProductCoverImage(res.data.data.id, coverImage, addedProductGalleryFiles, deletedProductGalleryFiles);
        const galleryImages = productGalleryFiles.filter(galleryFile => galleryFile.info.type !== COVER);
        await ProductsAPI.updateProductGallery(res.data.data.id, galleryImages, addedProductGalleryFiles, deletedProductGalleryFiles);

        if (isSingleVariant || productVariants.length === 0) {
          // create default variant
          const variantRes = await ProductsAPI.createVariant(res.data.data.id, {
            names: names,
            attributeVariants: {
              size: {
                attributeCode: 'size',
                key: 'no-size-food'
              }
            },
            prices: {
              sellingPrice: +price,
              retailPrice: +price
            }
          });
          await ProductsAPI.updateVariantGalleryFromProduct(res.data.data.id, variantRes.data.data.id);
          await ProductsAPI.updateProductStatus(res.data.data.id, productStatusAsBoolean);
          await ProductsAPI.updateProductVariantStatus(res.data.data.id, variantRes.data.data.id, productStatusAsBoolean);
          await ProductsAPI.updateProductAvailability(variantRes.data.data.id, availability === 'available');
        } else {
          // create multiple variants
          const formatedVariants = productVariants.map(item => ({
            names: names,
            attributeVariants: {
              size: {
                attributeCode: 'size',
                key: item.attributeVariants.size.key
              }
            },
            prices: {
              sellingPrice: +item.prices.sellingPrice,
              retailPrice: +item.prices.sellingPrice
            }
          }));
          formatedVariants.forEach(async (variant) => {
            try {
              const variantRes = await ProductsAPI.createVariant(res.data.data.id, {
                ...variant
              });
              // HERE - modifica al id
              await updatePricesAndAvailabilitiesOfAllVariants(res.data.data, variantRes.data.data, available ? 1 : 0, user);
              await ProductsAPI.updateVariantGalleryFromProduct(res.data.data.id, variantRes.data.data.id);
              await ProductsAPI.updateProductVariantStatus(res.data.data.id, variantRes.data.data.id, productStatusAsBoolean);
              await ProductsAPI.updateProductAvailability(variantRes.data.data.id, availability === 'available');
            } catch (error) {
              setIsUpdating(false);
              message.error(text('somethingWentWrong'));
            }
          });
        }
        message.success(text('onCreateOk'), messageDuration);
        setIsUpdating(false);
      }
      navigateToProductsPage();
    } catch (e) {
      setIsUpdating(false);
      message.error(text('somethingWentWrong'));
    }
  };

  const handleGalleryOrderUpdate = (fileList) => {
    // console.log('Ordered file list', fileList)
  };

  /**
   * @description Form save
   * @param values
   */
  const onSubmit = (values) => {
    handleUpdateProduct(values);
  };

  return (
    <>
      <div className='py-4'>
        {/* Product Information Section */}
        <Form
          onFinish={onSubmit}
          form={form}
          initialValues={{
            price: null,
            categories: {
              macro: { key: null, label: null },
              micro: { key: null, label: null },
              brand: {
                value: null,
                label: null
              }
            },
            availability: product.availabilityTotal > 0 ? 'available' : 'unavailable',
            status: null
          }}
          layout='vertical'
          autoComplete='off'
          scrollToFirstError
        >
          {/* Product Name */}
          <Row gutter={[23, 23]} className='pt-8'>
            <Col span={18}>
              <div className='text-lg'>{product?.names?.[selectedLanguage]?.value}</div>
              <HelperText text={text('pageDescription')} />
              <BackButton
                iconChevron
                className='mt-4 mb-8'
                onClick={navigateToProductsPage}
                title={text('backToProducts')}
              />
            </Col>
            {isLinkVisible &&
              <Col span={6}>
                <div>
                  <Link
                    className='uppercase flex items-center justify-end'
                    onClick={() => {
                      setMode('duplicate');
                      // clear the files user must upload new ones
                      setDeletedProductGalleryFiles([]);
                      setAddedProductGalleryFiles([]);
                      setProductGalleryFiles([]);
                    }}
                  >
                    {text('duplicateProduct')}
                  </Link>
                </div>
              </Col>}
          </Row>

          <Row gutter={[16, 16]} className='pt-8'>
            <Col span={6}>
              <LanguageSelect
                onSelect={handleLanguageSelection}
              />
            </Col>
          </Row>

          {/* Name */}
          <Row gutter={[16, 16]} className='pt-4'>
            <Col span={6}>
              <NameInput selectedLanguage={selectedLanguage} />
              <DescriptionInput selectedLanguage={selectedLanguage} form={form} />
            </Col>
            {/* Status */}
            <Col span={18}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item name='status' label={text('status')} rules={[{ required: true, message: text('statusRequired') }]}>
                    <Select getPopupContainer={trigger => trigger.parentNode}>
                      {Product.statusOptions.map((status, i) => (
                        <Option key={`${status}-${i}`} value={status}>
                          {status}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name='availability' label={text('availability')} rules={[{ required: true }]}>
                    <Select getPopupContainer={trigger => trigger.parentNode}>
                      {availabilityOptions.map((availabilityOption, i) => (
                        <Option key={`${availabilityOption}-${i}`} value={availabilityOption}>
                          <span style={{ textTransform: 'capitalize' }}>{availabilityOption}</span>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className='pt-4'>
                <Col span={24}>
                  <Categories form={form} editMode />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className='pt-4'>
                <Col span={8}>
                  {isSingleVariant &&
                    <Form.Item
                      name='price'
                      label='Price'
                      rules={[
                        { required: isSingleVariant },
                        { type: 'number', min: 0 }
                      ]}
                    >
                      <InputNumber
                        addonBefore='€'
                        parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                        disabled={!isSingleVariant}
                        minValue={0}
                      />
                    </Form.Item>}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Categories */}
          <Row gutter={[16, 16]} className='pt-4'>
            <Col span={16} />
          </Row>
          {/* Prices */}
          <Row gutter={[16, 16]} className='pt-4'>
            {/* Description */}
          </Row>
          {/* Variants */}
          <Divider />
          <SectionTitle title={text('sizes')} className='pb-4' />
          <HelperText text={text('sizeHelperText')} className='mb-2' />
          <Radio.Group onChange={handleRadioChange} value={isSingleVariant} disabled={mode === 'edit'}>
            <Space direction='vertical'>
              <Radio value>{text('oneSize')}</Radio>
              <Radio value={false}>{text('multipleSizes')}</Radio>
            </Space>
          </Radio.Group>
          {!isSingleVariant &&
            <ProductVariantsFood
              variants={sizeAttributes}
              productId={product.id}
              onDeleteVariant={() => { }}
              onEditVariant={() => { }}
              productVariants={productVariants}
              setProductVariants={setProductVariants}
              editMode
              setNewVariantAdded={setNewVariantAdded}
            />}
          {/* Gallery */}
          <Divider />
          <SectionTitle title={text('gallery')} className='pb-4' />
          <ImageGalleryFood
            fileList={productGalleryFiles.filter(img => (img?.info?.type === 'cover'))}
            detailFileList={productGalleryFiles.filter(img => (!img.info || img?.info?.type !== 'cover'))}
            onGalleryUpdate={(file, fileList, type) => handleGalleryUpdate(file, fileList, type)}
            onGalleryOrderUpdate={(fileList) => handleGalleryOrderUpdate(fileList)}
          />
          <Divider />
          <Row gutter={[23, 23]} className='pt-8'>
            <Col span={16}>
              {/* Delete Product */}
              {mode === 'edit' &&
                <Button
                  type='danger'
                  htmlType='button'
                  className='uppercase mr-2'
                  onClick={showDeletionModal}
                >
                  {text('deleteProduct')}
                </Button>}
            </Col>
            <Col span={4}>
              {/* Cancel Edit Product */}
              <Button
                type='default'
                htmlType='button'
                className='uppercase mr-2 w-100'
                onClick={handleCancelEditProduct}
              >
                {mode === 'edit' ? text('cancelEdit') : text('cancelCreation')}
              </Button>
            </Col>
            <Col span={4}>
              {/* Update Product */}
              <Button
                type='primary'
                htmlType='submit'
                className='uppercase w-100'
                loading={isUpdating}
                disabled={isUpdating}
              >
                {mode === 'edit' ? text('updateProduct') : text('createProduct')}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>

      <DeletionModal
        visible={isDeletionModalVisible}
        onOk={handleConfirmDeletion}
        onCancel={handleCancelDeletion}
      >
        <div>{text('confirmDeletion')}</div>
      </DeletionModal>

    </>
  );
};

const NameInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.products/id', t);

  return (
    <Form.Item
      name={`names${SEPARATOR}${selectedLanguage}${SEPARATOR}value`}
      label={text('name')}
    >
      <Input placeholder={text('name')} />
    </Form.Item>
  );
};

const DescriptionInput = ({ selectedLanguage, form }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.products/id', t);

  return (
    <Form.Item
      name={`descriptions${SEPARATOR}${selectedLanguage}${SEPARATOR}value`}
      label={text('description')}
    >
      <WysiwygEditor
        name={`descriptions${SEPARATOR}${selectedLanguage}${SEPARATOR}value`}
        onChange={(name, val) => { form.setFieldsValue({ [name]: val }); }}
        initialValue={form.getFieldValue(`descriptions${SEPARATOR}${selectedLanguage}${SEPARATOR}value`)}
      />
    </Form.Item>
  );
};

const initializeMultiLangForm = (form, product) => {
  if (product) {
    form.setFieldsValue({
      ...flatten(product, ['names', 'descriptions'])
    });
  }
};
