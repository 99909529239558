import { AxiosClient } from 'src/api/AxiosClient';

export class PresentationConfigAPI {
  static async getConfig () {
    return AxiosClient.get('/backoffice/config/presentation');
  }

  static async saveBrandName (brandName) {
    return AxiosClient.patch('/backoffice/config/presentation', {
      brand: brandName,
    });
  };

  static async uploadBrandLogo(image) {
    const formData = new window.FormData();
    formData.append('file', image);

    return AxiosClient.post('/backoffice/config/presentation/upload/icon',
      formData
    );
  };

  static async uploadCssFile(file) {
    const formData = new window.FormData();
    formData.append('file', file);

    return AxiosClient.post('/backoffice/config/presentation/upload/css',
      formData
    );
  };
}
