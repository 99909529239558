import React, { useState } from 'react';
import { Col, Row, message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { PageSection } from 'src/components/PageSection';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { PermissionSetsAPI } from '../api/PermissionSetsAPI';
import { PermissionSetForm } from '../component/PermissionSetForm';

export const PageCreatePermissionSet = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.permission-sets/create', t);

  const history = useHistory();
  const [selectedAuths, setSelectedAuths] = useState([]);

  const goToPermissionSetPage = () => {
    history.push(Routes.PATHS.PERMISSION_SETS);
  };

  const onFinish = async ({ setName, apiName, enabledByDefault }) => {
    if (selectedAuths.length === 0) {
      message.error(text('noSelectedAuthorizations'));
    } else {
      try {
        await PermissionSetsAPI.createPermissionSet(
          apiName,
          selectedAuths,
          { en: setName },
          { en: setName }, // FIXME: Temporary workaround, as agreed with Giacomo
          enabledByDefault
        );
        message.success(text('onCreateOk'));
        goToPermissionSetPage();
      } catch (e) {
        message.error(text('onCreateErr'));
      }
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={goToPermissionSetPage}
      />

      <PageSection>{text('identification')}</PageSection>
      <PermissionSetForm
        selectedAuths={selectedAuths}
        setSelectedAuths={setSelectedAuths}
        onFinish={onFinish}
      >
        <Row gutter={20} justify='end' className='mt-8'>
          <Col>
            <DefaultButton label={text('cancel')} onClick={goToPermissionSetPage} />
          </Col>
          <Col>
            <SubmitButton label={text('createPermissionSet')} />
          </Col>
        </Row>
      </PermissionSetForm>

    </div>
  );
};
