export class OutfitCollectionsQuery {
  static get KEYS () {
    return {
      TEXT: 'text'
    };
  }
}

export class CollectionGroupsQuery {
  static get KEYS () {
    return {
      TEXT: 'text'
    };
  }
}
