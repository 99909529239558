import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useFilters } from 'src/hooks/useFilters';
import { DownloadsAPI } from '../api/DownloadsAPI';
import { usePagination } from 'src/hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { DownloadsMapper } from 'src/modules/downloads/domain/Downloads.mapper';
import { useParams, useLocation } from 'react-router-dom';

const DownloadsContext = createContext();

const useProvideDownloads = (text) => {
  const params = useParams();
  const location = useLocation();
  const [download, setDownload] = useState();
  const [downloads, setDownloads] = useState([]);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(DownloadsMapper);
  const [areDownloadsLoading, setAreDownloadsLoading] = useState(false);

  const getDownloads = useCallback(async () => {
    setAreDownloadsLoading(true);
    const downloadQuery = { ...query };
    try {
      const res = await DownloadsAPI.getDownloads(DownloadsMapper.fromQueryToPayload(downloadQuery));
      setDownloads(res?.data?.items || []);
      setPagination(res?.data || {});
    } catch (e) {
      message.error(text('failedToRetrieveDownloads'));
    } finally {
      setAreDownloadsLoading(false);
    }
  }, [query, setPagination, location]);

  const getDownload = useCallback(async () => {
    try {
      const res = await DownloadsAPI.getDetails(params.id);
      setDownload(res?.data);
    } catch (e) {
      message.error(text('failedToRetrieveDownload'));
    }
  }, [params.id]);

  useEffect(() => {
    getDownloads();
  }, [location.search]);

  useEffect(() => {
    if (params.id) {
      getDownload();
    }
  }, [params.id]);

  return {
    query,
    download,
    downloads,
    pagination,
    setDownloads,
    onChangeQuery,
    getDownloads,
    areDownloadsLoading
  };
};

export const DownloadsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.downloads', t);

  const downloads = useProvideDownloads(text);

  return (
    <DownloadsContext.Provider value={downloads}>
      {children}
    </DownloadsContext.Provider>
  );
};

export const useDownloads = () => useContext(DownloadsContext);
