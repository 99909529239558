import React from 'react';
import { Row, Col, Card } from 'antd';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';

export const Report = ({
  icon,
  title,
  onClick,
  isLoading,
  isDisabled,
  buttonLabel
}) => {
  return (
    <Card>
      <Row gutter={16}>

        <Col>
          <div className='download-icon'>{icon}</div>
        </Col>

        <Col>
          <div className='download-title pb-6'>{title}</div>
          <PrimaryButton
            onClick={onClick}
            label={buttonLabel}
            loading={isLoading}
            disabled={isDisabled}
          />
        </Col>

      </Row>
    </Card>
  );
};
