import React, { useEffect } from 'react';
import { Row, Col, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useWebhooks } from '../provider/webhook.provider';
import { Routes } from 'src/router/Routes.helper';
import { WebhookAPI } from '../api/webhookAPI';
import { PageTitle } from 'src/components/PageTitle';
import { CenteredText } from 'src/components/CenteredText';
import { WebhookTable } from '../component/WebhookTable';

export const PageWebhooks = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.webhooks', t);

  const { webhooks, pagination, refreshData, areWebhooksLoading } = useWebhooks();
  const history = useHistory();

  const handleEditing = (record) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.WEBHOOKS_EDIT, { id: record.clientId }));
  };

  useEffect(() => {
    refreshData();
  }, []);

  const handleDelete = async (record) => {
    try {
      await WebhookAPI.deleteWebhook(record.clientId);
      message.success(text('deleteOK'));
      refreshData();
    } catch (e) {
      message.error(text('deleteKO'));
    }
  };

  const onTableChange = ({ current, pageSize }) => {
    // TODO when (and if) pagination is fully supported
  };

  const navigateToCreateWebhook = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.WEBHOOKS_CREATE));
  };

  return (
    <>
      <div className='py-4 bg-gray-100'>
        <PageTitle>{text('webhooks')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={8} />
          <Col span={16}>
            <div className='flex justify-end'>
              <Button
                className='uppercase'
                type='primary'
                onClick={navigateToCreateWebhook}
              >
                {text('createNewWebhook')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {!areWebhooksLoading && !webhooks?.length
        ? (<CenteredText>{text('noWebhooksFound')}</CenteredText>)
        : (<WebhookTable
            loading={areWebhooksLoading}
            webhooks={webhooks}
            onEdit={handleEditing}
            onDelete={handleDelete}
            onTableChange={onTableChange}
            pagination={pagination}
           />)}
    </>
  );
};
