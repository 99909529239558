import React, { useState } from 'react';
import { PageSection } from 'src/components/PageSection';
import { RightOutlined, DownOutlined } from '@ant-design/icons';

const Header = ({
  title,
  onToggle,
  isOpen
}) => (
  <div className='as-wide-as-content'>
    <div className='collapsible-header' onClick={onToggle}>
      <PageSection>{title}</PageSection>
      <div className='collapsible-header-icon'>
        {isOpen
          ? <DownOutlined />
          : <RightOutlined />}
      </div>
    </div>
  </div>
);

const Panel = ({
  isOpen,
  content
}) => (
  <>
    {isOpen
      ? <div className='collapsible-panel'>{content}</div>
      : <></>}
  </>
);

export const Collapsible = ({
  title,
  className,
  initOpen = true,
  children
}) => {
  const [isOpen, setIsOpen] = useState(initOpen);

  const onToggle = () => {
    setIsOpen(x => !x);
  };

  return (
    <div className={className}>
      <Header
        title={title}
        isOpen={isOpen}
        onToggle={onToggle}
      />
      <hr className='collapsible-horizontal-line' />
      <Panel
        isOpen={isOpen}
        content={children}
      />
    </div>
  );
};
