import React, { useMemo } from 'react';
import { Row, Col, Table, Card, Typography } from 'antd';
import { PageTitle } from 'src/components/PageTitle';
import { useDashboard } from 'src/modules/dashboard/provider/dashboard.provider';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import Moment from 'react-moment';
import moment from 'moment';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { Link } from 'react-router-dom';
import { Routes } from 'src/router/Routes.helper';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

const { Title } = Typography;

export const PageDashboard = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.dashboard', t);

  const {
    publishedProductCount,
    outOfStockProductsCount,
    availableProductsCount,
    visualsList,
    productsList,
    outOfStockProductsList,
    draftProductsCount,
    pendingGetOutOfStock,
    pendingGetProducts,
    pendingGetVisuals
  } = useDashboard();
  const { defaultLanguage } = useLanguages();

  const { canSeeFullDashboard } = useAuth();

  const calculatePercentage = (value, total) => {
    if (!value) return;
    return `${((100 * value) / total).toFixed(2)}%`;
  };

  const variantColumns = useMemo(
    () => [
      {
        title: text('name'),
        dataIndex: ['names'],
        render: (_, record) => (<div>{record.names[defaultLanguage?.language]?.value || 'n/d'}</div>)
      },
      {
        title: text('EAN'),
        dataIndex: ['ean']
      },
      {
        title: text('price'),
        dataIndex: ['sellingPrice'],
        render: (_, record) => (
          <div>
            {record.sellingPrice}
          </div>
        ),
        editable: true,
        inputType: 'text',
        align: 'right'
      },
      {
        title: text('lastEdit'),
        dataIndex: ['updateDate'],
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text) => <Moment format='DD MMM YYYY' date={text} />
      }
    ],
    [defaultLanguage.language]);

  const productsColumns = useMemo(
    () => [
      {
        title: text('name'),
        dataIndex: ['names'],
        render: (_, record) => (<div>{record.names[defaultLanguage?.language]?.value || 'n/d'}</div>)
      },
      {
        title: text('macro'),
        dataIndex: ['macroCategoryMap'],
        render: (_, record) => record.macroCategoryMap[defaultLanguage?.language]?.name || 'n/d'
      },
      {
        title: text('micro'),
        dataIndex: ['microCategoryMap'],
        render: (_, record) => record.microCategoryMap[defaultLanguage?.language]?.name || 'n/d'
      },
      {
        title: text('lastEdit'),
        dataIndex: ['updateDate'],
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text) => <Moment format='DD MMM YYYY' date={text} />
      }
    ],
    [defaultLanguage.language]);

  const visualsColumns = useMemo(
    () => [
      {
        title: text('name'),
        dataIndex: ['name']
      },
      {
        title: text('area'),
        width: '30%',
        dataIndex: ['filters'],
        render: (filters) => filters.map((filter, i) => [
          i > 0 && ', ',
          filter.key

        ])
      },
      {
        title: text('articles'),
        width: '15%',
        dataIndex: ['numberOfItems'],
        align: 'right'
      },
      {
        title: text('lastEdit'),
        dataIndex: ['updateDate'],
        sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
        render: (text) => <Moment format='DD MMM YYYY' date={text} />
      }
    ],
    []);

  return (
    <>
      <Row className='pt-4'>
        <PageTitle>{text('title')}</PageTitle>
      </Row>
      {canSeeFullDashboard
        ? (
          <Row gutter={[16, 16]} className='py-4'>
            <Col span={6}>
              <div className='pb-4'>
                <Card className='py-4 mb-4'>
                  <SectionTitle title={text('overallVariants')} className='pb-4' />
                  <HelperText text={text('available')} />
                  <Title level={3}>{availableProductsCount || 'n/d'}</Title>
                  <p className='text-gray-600 bold'>{calculatePercentage(availableProductsCount, outOfStockProductsCount + availableProductsCount) || 'n/d'}</p>
                  <HelperText text={text('outOfStock')} />
                  <Title level={3}>{outOfStockProductsCount || 'n/d'}</Title>
                  <p className='text-gray-600 bold'>{calculatePercentage(outOfStockProductsCount, outOfStockProductsCount + availableProductsCount) || 'n/d'}</p>
                </Card>
              </div>
              <div className='pt-4'>
                <Card className='py-4 mb-4'>
                  <SectionTitle title={text('productsStatus')} className='pb-4' />
                  <HelperText text={text('published')} />
                  <Title level={3}>{publishedProductCount || 'n/d'}</Title>
                  <p className='text-gray-600 bold'>{calculatePercentage(publishedProductCount, publishedProductCount + draftProductsCount) || 'n/d'}</p>
                  <HelperText text={text('draft')} />
                  <Title level={3}>{draftProductsCount || 'n/d'}</Title>
                  <p className='text-gray-600 bold'>{calculatePercentage(draftProductsCount, publishedProductCount + draftProductsCount) || 'n/d'}</p>
                </Card>
              </div>
            </Col>
            <Col span={18}>
              <div className='pb-4'>
                <Card className='py-4 mb-4'>
                  <div className='flex justify-between'>
                    <SectionTitle title={text('lastOutOfStock')} className='pb-4' />
                    <Link to={Routes.parseRouteParams(Routes.PATHS.PRODUCTS_FASHION)}>
                      <p className='uppercase'>{text('seeAll')}</p>
                    </Link>
                  </div>
                  <Table
                    size='middle'
                    loading={pendingGetOutOfStock}
                    dataSource={outOfStockProductsList}
                    rowKey='id'
                    columns={variantColumns}
                    pagination={false}
                  />
                </Card>
              </div>
              <div className='pb-4'>
                <Card className='py-4 '>
                  <div className='flex justify-between'>
                    <SectionTitle title={text('lastEditedProducts')} className='pb-4' />
                    <Link to={Routes.parseRouteParams(Routes.PATHS.PRODUCTS_FASHION)}>
                      <p className='uppercase'>{text('seeAll')}</p>
                    </Link>
                  </div>
                  <Table
                    className='rowLineCamp'
                    size='middle'
                    loading={pendingGetProducts}
                    dataSource={productsList}
                    rowKey='id'
                    columns={productsColumns}
                    pagination={false}
                  />
                </Card>
              </div>
              <div className='pb-4'>
                <Card className='py-4'>
                  <div className='flex justify-between'>
                    <SectionTitle title={text('visualMerchandising')} className='pb-4' />
                    <Link to={Routes.parseRouteParams(Routes.PATHS.VISUAL_MERCHANDISING)}>
                      <p className='uppercase'>{text('seeAll')}</p>
                    </Link>
                  </div>
                  <Table
                    size='middle'
                    loading={pendingGetVisuals}
                    dataSource={visualsList}
                    rowKey='id'
                    columns={visualsColumns}
                    pagination={false}
                  />
                </Card>
              </div>
            </Col>
          </Row>)
        : null}
    </>
  );
};
