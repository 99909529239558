import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Row, Col, Form, Select, Button, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  formatTimeTableValues,
  getTimeLines,
  mapNumberToDay
} from 'src/utils/utils';
import { ContactsAndSocials } from './ContactsAndSocials';
import { AccountsAPI } from '../api/AccountsApi';
import { MainInfo } from './MainInfo';
import { Position } from './Position';
import { useAccounts } from '../provider/accounts.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { MaxPickupsForSlot } from './MaxPickupsForSlot';
import { ClosingPeriods } from './ClosingPeriods';

const { Title, Text } = Typography;
const { Option } = Select;

const CLOSED = 'Closed';

function parseClosingPeriods (values) {
  return values.closingPeriods
    .filter(period => period.start)
    .map(period =>
      ({
        start: period.start.format('YYYY-MM-DD'),
        end: period.end?.format('YYYY-MM-DD')
      })
    );
}

export const AccountData = () => {
  const { t } = useTranslation();
  const text = textFrom('components.accountData', t);

  const { userInfo } = useAccounts();
  const [form] = useForm();
  const [timeLines, setTimeLines] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [greeting, setGreeting] = useState('');
  const [description, setDescription] = useState('');
  const [timeTableData, setTimeTableData] = useState([]);

  const defaultDaysOfTheWeek = useMemo(
    () => [
      {
        dayOfTheWeek: 1,
        dinner_closing: null,
        dinner_opening: null,
        lunch_closing: null,
        lunch_opening: null
      },
      {
        dayOfTheWeek: 2,
        dinner_closing: null,
        dinner_opening: null,
        lunch_closing: null,
        lunch_opening: null
      },
      {
        dayOfTheWeek: 3,
        dinner_closing: null,
        dinner_opening: null,
        lunch_closing: null,
        lunch_opening: null
      },
      {
        dayOfTheWeek: 4,
        dinner_closing: null,
        dinner_opening: null,
        lunch_closing: null,
        lunch_opening: null
      },
      {
        dayOfTheWeek: 5,
        dinner_closing: null,
        dinner_opening: null,
        lunch_closing: null,
        lunch_opening: null
      },
      {
        dayOfTheWeek: 6,
        dinner_closing: null,
        dinner_opening: null,
        lunch_closing: null,
        lunch_opening: null
      },
      {
        dayOfTheWeek: 7,
        dinner_closing: null,
        dinner_opening: null,
        lunch_closing: null,
        lunch_opening: null
      }
    ], []);

  useEffect(() => {
    const timeLines = getTimeLines();
    timeLines.push(CLOSED);
    setTimeLines(timeLines);
  }, []);

  useEffect(() => {
    onValuesChange();
  }, [timeTableData]);

  useEffect(() => {
    // populate timeTable with default values
    if (userInfo) {
      const optimizedWeekFinalData = defaultDaysOfTheWeek.map((defaultDayOfTheWeek) => {
        const slots = userInfo.localBusiness.schedule.filter((slot) => (slot.dayOfTheWeek === defaultDayOfTheWeek.dayOfTheWeek));
        const dayFinalData = {
          ...defaultDayOfTheWeek,
          dayOfTheWeek: mapNumberToDay(defaultDayOfTheWeek.dayOfTheWeek)
        };
        if (slots.length === 1) {
          // only LUNCH or DINNER
          if (slots[0].slotOfTheDay.includes('LUNCH')) {
            const lunch = slots[0];
            const lunchDayFinalData = {
              dayOfTheWeek: mapNumberToDay(defaultDayOfTheWeek.dayOfTheWeek),
              lunch_opening: lunch.opening.substring(0, 5),
              lunch_closing: lunch.closing.substring(0, 5),
              dinner_opening: null,
              dinner_closing: null
            };
            return lunchDayFinalData;
          }
          if (slots[0].slotOfTheDay.includes('DINNER')) {
            const dinner = slots[0];
            const dinnerDayFinalData = {
              dayOfTheWeek: mapNumberToDay(defaultDayOfTheWeek.dayOfTheWeek),
              lunch_opening: null,
              lunch_closing: null,
              dinner_opening: dinner.opening.substring(0, 5),
              dinner_closing: dinner.closing.substring(0, 5)
            };
            return dinnerDayFinalData;
          }
        }
        if (slots.length === 2) {
          // both LUNCH and DINNER
          const lunch = slots.find(slot => slot.slotOfTheDay.includes('LUNCH'));
          const dinner = slots.find(slot => slot.slotOfTheDay.includes('DINNER'));
          const lunchDinnerDayFinalData = {
            dayOfTheWeek: mapNumberToDay(defaultDayOfTheWeek.dayOfTheWeek),
            lunch_opening: lunch.opening.substring(0, 5),
            lunch_closing: lunch.closing.substring(0, 5),
            dinner_opening: dinner.opening.substring(0, 5),
            dinner_closing: dinner.closing.substring(0, 5)
          };
          return lunchDinnerDayFinalData;
        }
        return dayFinalData;
      });
      setTimeTableData(optimizedWeekFinalData);
    }
  }, [userInfo]);

  const onFinish = async (values) => {
    const timeTablePayload = formatTimeTableValues(values);
    const { email, facebook, instagram, phoneNumber, website, xPosition, yPosition } = values;
    // Looks like "Closed" was never supported by the API
    const data = {
      schedule: timeTablePayload.filter((timeSlot) => (timeSlot.opening !== CLOSED && timeSlot.closing !== CLOSED)),
      social: Object.assign({},
        facebook ? { facebook: { link: facebook } } : null,
        instagram ? { instagram: { link: instagram } } : null
      ),
      email,
      telephone: phoneNumber,
      description,
      greeting,
      greetings: {
        en: greeting,
        it: greeting
      },
      websiteUrl: website,
      xposition: xPosition,
      yposition: yPosition,
      closingPeriods: parseClosingPeriods(values)
    };
    try {
      setIsLoading(true);
      await AccountsAPI.updateAccount(userInfo.localBusiness.id, data);
      message.success(text('onUpdateOk'));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(text('onUpdateErr', { optionalDetails: error.response?.data?.detail }));
    }
  };

  const onValuesChange = () => {
    const values = form.getFieldsValue();
    setFormValues(values);
  };

  const viewTimeTable = (
    <>

      <Row>
        <Title level={4} className='pb-6 pt-8'>
          {text('timetableTitle')}
        </Title>
      </Row>

      <Row>
        <Col xs={12}>
          <Row className='border-b border-gray-300 pb-2'>
            <Col xs={4} />
            <Col xs={9}>
              <Text className='font-semibold'>{text('lunchTime')}</Text>
            </Col>
            <Col xs={1} />
            <Col xs={8}>
              <Text className='font-semibold'>{text('dinnerTime')}</Text>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          {timeTableData.length > 0 &&
            timeTableData.map((day) => (
              <TimeTableRow
                key={day.dayOfTheWeek}
                name={day.dayOfTheWeek}
                timeLines={timeLines}
                formValues={formValues}
                form={form}
                day={day}
              />
            ))}
        </Col>
      </Row>
    </>
  );

  return (
    <>
      <MainInfo
        description={description}
        setDescription={setDescription}
        greeting={greeting}
        setGreeting={setGreeting}
        userInfo={userInfo}
      />

      <Form
        name='basic'
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        autoComplete='off'
        layout='vertical'
        form={form}
      >
        {userInfo && userInfo.localBusiness?.departments?.includes('food') && (
          <>
            <MaxPickupsForSlot
              form={form}
              onFinish={onFinish}
              userInfo={userInfo}
            />
            {viewTimeTable}
          </>
        )}

        <Row>
          <Col xs={12}>
            <ClosingPeriods
              form={form}
              initialClosingPeriods={userInfo?.localBusiness?.closingPeriods}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            {userInfo && (
              <ContactsAndSocials
                form={form}
                onFinish={onFinish}
                userInfo={userInfo}
              />
            )}
            {userInfo && (
              <Position
                form={form}
                onFinish={onFinish}
                userInfo={userInfo}
              />
            )}
          </Col>
          <Col xs={6}>
            <div className='flex flex-col h-full items-end border-b border-gray-300 py-6'>
              <Row gutter={16} className='mt-auto'>
                <Col xs={24}>
                  <div className='flex items-end justify-start mt-4 ml-20'>
                    <Button
                      type='secondary'
                      htmlType='button'
                      className='uppercase mr-2'
                    >
                      {text('cancel')}
                    </Button>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='uppercase ml-2'
                      loading={isLoading}
                    >
                      {text('saveChanges')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

      </Form>
    </>
  );
};

const TimeTableRow = ({ name, timeLines, formValues, form, day }) => {
  const { t } = useTranslation();
  const text = textFrom('components.accountData', t);

  useEffect(() => {
    timeLines.forEach(() => {
      form.setFieldsValue({
        [`${name}_lunch_from`]: day.lunch_opening || CLOSED
      });
      form.setFieldsValue({
        [`${name}_lunch_until`]: day.lunch_closing || CLOSED
      });
      form.setFieldsValue({
        [`${name}_dinner_from`]: day.dinner_opening || CLOSED
      });
      form.setFieldsValue({
        [`${name}_dinner_until`]: day.dinner_closing || CLOSED
      });
    });
  }, []);

  const castTimeAsDate = (time) => {
    return new Date().setHours(time.slice(0, 2), time.slice(3, 5), 0);
  };

  const filterLunchStartTime = (time, toTime) => {
    if (toTime && toTime !== CLOSED) {
      const timeAsDate = castTimeAsDate(time);
      const toTimeAsDate = castTimeAsDate(toTime);
      return (timeAsDate < toTimeAsDate);
    }
    return time;
  };

  const filterLunchEndTime = (time, fromTime, toTime) => {
    if (fromTime && toTime && fromTime !== CLOSED && toTime !== CLOSED) {
      const timeAsDate = castTimeAsDate(time);
      const fromTimeAsDate = castTimeAsDate(fromTime);
      const toTimeAsDate = castTimeAsDate(toTime);
      return (timeAsDate > fromTimeAsDate && timeAsDate < toTimeAsDate);
    }
    return time;
  };

  const filterDinnerStartTime = (time, fromTime, toTime) => {
    if (fromTime && toTime && fromTime !== CLOSED && toTime !== CLOSED) {
      const timeAsDate = castTimeAsDate(time);
      const fromTimeAsDate = castTimeAsDate(fromTime);
      const toTimeAsDate = castTimeAsDate(toTime);
      return (timeAsDate > fromTimeAsDate && timeAsDate < toTimeAsDate);
    }
    return time;
  };

  const filterDinnerEndTime = (time, fromTime) => {
    if (fromTime && fromTime !== CLOSED) {
      const timeAsDate = castTimeAsDate(time);
      const fromTimeAsDate = castTimeAsDate(fromTime);
      return (timeAsDate > fromTimeAsDate);
    }
    return time;
  };

  return (
    <Row className='border-b border-gray-300 py-6 flex items-center'>
      <Col xs={4}>
        <Title level={5}>{name}</Title>
      </Col>
      <Col xs={9}>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              name={`${name}_lunch_from`}
              label='From'
              required={false} // to hide the asterik icon
              rules={[{ required: true, message: text('required') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                placeholder={text('selectTime')}
              >
                {timeLines
                  .filter((time) => {
                    if (time === CLOSED) return true;
                    const toTime = formValues[`${name}_lunch_until`] || day.lunch_closing;
                    return filterLunchStartTime(time, toTime);
                  }).map((time) => (
                    <Option value={time} key={time}>
                      {time}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name={`${name}_lunch_until`}
              label={text('until')}
              required={false} // to hide the asterik icon
              rules={[{ required: (formValues[`${name}_lunch_from`] !== CLOSED), message: text('required') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                placeholder='Select time'
                disabled={formValues[`${name}_lunch_from`] === CLOSED}
              >
                {timeLines
                  .filter((time) => {
                    if (time === CLOSED) return true;
                    const fromTime = formValues[`${name}_lunch_from`] || day.lunch_opening;
                    const toTime = formValues[`${name}_dinner_from`] || day.dinner_opening;
                    return filterLunchEndTime(time, fromTime, toTime);
                  })
                  .map((time) => (
                    <Option value={time} key={time}>
                      {time}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col xs={1} />
      <Col xs={9}>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              name={`${name}_dinner_from`}
              label='From'
              required={false} // to hide the asterik icon
              rules={[{ required: true, message: text('required') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                placeholder={text('selectTime')}
              >
                {timeLines
                  .filter((time) => {
                    if (time === CLOSED) return true;
                    const lunchEndTime = formValues[`${name}_lunch_until`] || day.lunch_closing;
                    const dinnerEndTime = formValues[`${name}_dinner_until`] || day.dinner_closing;
                    return filterDinnerStartTime(time, lunchEndTime, dinnerEndTime);
                  })
                  .map((time) => (
                    <Option value={time} key={time}>
                      {time}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name={`${name}_dinner_until`}
              label={text('until')}
              required={false} // to hide the asterik icon
              rules={[{ required: (formValues[`${name}_dinner_from`] !== CLOSED), message: text('required') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                placeholder={text('selectTime')}
                disabled={formValues[`${name}_dinner_from`] === CLOSED}
              >
                {timeLines
                  .filter((time) => {
                    if (time === CLOSED) return true;
                    const fromTime = formValues[`${name}_dinner_from`] || day.dinner_opening;
                    return filterDinnerEndTime(time, fromTime);
                  })
                  .map((time) => (
                    <Option value={time} key={time}>
                      {time}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
