import React from 'react';
import { Row, Col, Table, Button } from 'antd';
import { ProductStatusTag } from 'src/modules/products/components/ProductStatusTag';
import { Product } from 'src/services/Product.service';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useOutfits } from '../provider/outfits.provider';

export const SelectedVariantTable = ({ selectedLanguage, setSelectedOutfitKeys, selectedOutfitKeys }) => {
  const { t } = useTranslation();
  const text = textFrom('components.outfitProductVariants', t);

  const { outfitVariants, setOutfitVariants } = useOutfits();

  const removeVariantsFromOutfit = () => {
    const outfitsObjects = outfitVariants.filter((obj) => !selectedOutfitKeys.includes(obj.id));
    setOutfitVariants(outfitsObjects);
    onSelectChange([]);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedOutfitKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedOutfitKeys,
    onChange: onSelectChange
  };

  const CoverImage = ({ src }) => (
    <img
      src={src}
      className='w-8 h-8 object-cover object-center'
    />
  );

  const columns =
    [
      {
        title: text('image'),
        dataIndex: ['photoGallery', 'images'],
        render: (images) => {
          const url = images?.find(image => image?.info?.type === 'cover')?.url;
          return (url ? <CoverImage src={url} /> : <></>);
        }
      },
      {
        title: text('name'),
        dataIndex: ['names', selectedLanguage, 'value'],
        render: (text) => <div>{text}</div>
      },
      {
        title: text('EAN'),
        dataIndex: ['manufacturerCode'],
        render: (text) => <div>{text}</div>
      },
      {
        title: text('color'),
        dataIndex: ['attributeVariants', 'color', 'localeName'],
        render: (color) => <div>{color}</div>
      },
      {
        title: text('scale'),
        dataIndex: ['attributeVariants', 'sizeScale', 'businessKey'],
        render: sizeScale => sizeScale
      },
      {
        title: text('size'),
        dataIndex: ['attributeVariants', 'size', 'businessKey'],
        render: size => size
      },
      // TODO Generalize ProductStatusTag and product service because it is being used on product and variant table - to save time now use like this
      {
        title: text('status'),
        dataIndex: ['published'],
        render: (status) => <ProductStatusTag status={status} />,
        inputType: 'published-select',
        editable: true,
        inputOptions: Product.statusOptions
      }
    ];

  return (
    <div className='mb-24 mt-4'>
      <Row gutter={[16, 16]} className='mb-2'>
        <Col span={6} />
        <Col span={6} />
        <Col span={12}>
          <Button
            type='primary'
            htmlType='button'
            className='uppercase float-right'
            onClick={removeVariantsFromOutfit}
          >
            {text('removeVariants')}
          </Button>
        </Col>
      </Row>
      <Table
        rowSelection={rowSelection}
        dataSource={outfitVariants}
        columns={columns}
        pagination={false}
        rowKey='id'
      />
    </div>
  );
};
