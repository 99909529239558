import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Table, List, Button, Card, Skeleton } from 'antd';
import { message } from 'src/services/Messages.service';
import { useUploadTypeStatus } from 'src/modules/upload/provider/uploadTypeStatus.provider';
import { useHistory, useParams } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { PageTitle } from 'src/components/PageTitle';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { UPLOAD_STATUS } from 'src/constants/uploadStatus';
import { TIPS_AND_SUGGESTIONS } from 'src/constants/tipsAndSuggestions';
import Moment from 'react-moment';
import fileDownload from 'js-file-download';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { Routes } from 'src/router/Routes.helper';
import { DownloadButton } from 'src/components/buttons/DownloadButton';
import { getTemplateFile } from '../constants/sampleTemplateFiles';

export const PageUploadTypeStatus = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.uploadTypeStatus', t);
  const FILE_TYPE_TITLE = textFrom('constants.fileType', t);

  const params = useParams();
  const history = useHistory();
  const { UUIDStatus, UUIDErrorFile, pendingGetStatus } = useUploadTypeStatus();
  const [errors, setErrors] = useState(null);
  const templateFile = getTemplateFile(params?.type);

  const getErrors = (errors) => {
    let parsedErrors = [];
    if (errors?.importErrors?.length > 0) {
      parsedErrors = errors.importErrors[0].errors.map(error => {
        return {
          details: error.details,
          field: error.field
        };
      });
    } else if (errors?.parsingErrors?.length > 0) {
      parsedErrors = errors.parsingErrors.map(error => {
        return {
          details: error.error,
          field: null
        };
      });
    } else if (errors?.errorMessage) {
      const parsedError = {
        details: errors.errorMessage,
        field: null
      };
      parsedErrors.push(parsedError);
    }
    return parsedErrors;
  };

  useEffect(() => {
    if (UUIDStatus) {
      const errors = getErrors(UUIDStatus.errors);
      setErrors(errors);
    }
  }, [UUIDStatus]);

  const fileTypeRender = (type) => {
    return (type) ? FILE_TYPE_TITLE(type.toLowerCase()) : text('nd');
  };

  const recordErrorsParser = (record) => {
    if (record?.errors) {
      const errors = getErrors(record.errors);
      return `${UPLOAD_STATUS[record.status]} ${errors.length} ${text('errors')}`;
    }
  };

  /**
   * @description Folder history table's columns
   * @type {[{dataIndex: string, title: string, key: string}, {dataIndex: string, title: string, key: string}, {dataIndex: string[], title: string, render: (function(*))}, {dataIndex: string[], title: string, render: (function(*, *)), key: string}]}
   */
  const columns = useMemo(() => [
    {
      title: text('col1.title'),
      dataIndex: 'errorFilePath',
      render: (_, record) => record.inputFileName
    },
    {
      title: text('col2.title'),
      dataIndex: 'type',
      render: (type) => fileTypeRender(type)
    },
    {
      title: text('col3.title'),
      dataIndex: ['creationDate'],
      render: (text) => <Moment format='HH:mm DD MMM YYYY' date={text} />
    },
    {
      title: text('col4.title'),
      dataIndex: ['status'],
      render: (_, record) => (
        recordErrorsParser(record)
      )
    }
  ], [recordErrorsParser, fileTypeRender]);

  const goToUploadList = () => {
    history.push(Routes.PATHS.UPLOADS_LIST);
  };

  return (
    <>
      <PageTitle>{UUIDStatus.errorFilePath}</PageTitle>
      {/* Back button */}
      <Row className='py-4'>
        <Col span={12}>
          <BackButton
            title={text('back')}
            className='pt-4 pb-4 text-sm'
            onClick={goToUploadList}
          />
        </Col>
      </Row>
      {/* Uploaded file table data */}
      <Row className='py-8'>
        <Col xs={24} xl={18}>
          <Table
            size='middle'
            dataSource={[UUIDStatus]}
            rowKey='uuid'
            columns={columns}
            pagination={false}
          />
        </Col>
      </Row>
      <Row>
        {/* TODO Update info text later - temporary */}
        <PageTitle>{text('somethingWentWrong')}</PageTitle>
      </Row>
      <Row gutter={[16, 16]} className='pt-8'>
        {/* What it is not matching section */}
        <Col span={12}>
          <Card>
            <SectionTitle title={text('notMatching')} className='pb-4' />
            <div className='h-64 overflow-y-auto'>
              {(errors && errors.length)
                ? <List
                    className='errorList'
                    rowKey='id'
                    dataSource={errors}
                    renderItem={(item, index) => (
                      <List.Item>
                        {index + 1}. {item.details}
                      </List.Item>
                    )}
                  />
                : <p className='italic'>{text('noErrors')}</p>}
              {pendingGetStatus && <Skeleton active className='h-full' />}
            </div>
          </Card>
        </Col>
        {/* Suggestion section */}
        <Col span={12}>
          <Card>
            <SectionTitle title={text('tips')} className='pb-4' />
            <div className='h-64 overflow-y-auto'>
              <List
                rowKey='key'
                dataSource={TIPS_AND_SUGGESTIONS}
                renderItem={(item) => (
                  <List.Item>
                    {item.value}
                  </List.Item>
                )}
              />
            </div>
          </Card>

        </Col>
      </Row>
      <Row gutter={[16, 16]} className='pt-8'>
        <Col span={12} className='pb-8'>
          <Button
            type='primary'
            className='uppercase'
            onClick={() => (!UUIDErrorFile || (!isEmpty(UUIDErrorFile) && UUIDErrorFile.size)) ? message.info(text('fileNotAvailable')) : fileDownload(UUIDErrorFile, UUIDStatus.inputFileName)}
          >
            {text('downloadWithErrors')}
          </Button>
        </Col>
        <Col span={12} className='pb-8'>
          <DownloadButton
            label={text('downloadCSVTemplate')}
            fileName={templateFile?.name}
            filePath={templateFile?.path}
          />
        </Col>
      </Row>
    </>
  );
};
