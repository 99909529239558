import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Row, InputNumber } from 'antd';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { ImageGallery } from 'src/modules/products/components/ImageGallery';
import { InputSelectInfinite } from 'src/components/inputs/InputSelectInfinite';
import { useAttributes } from 'src/hooks/useProvideAttributes';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { DeletionModal } from 'src/components/DeletionModal';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { SCOPE } from 'src/utils/scope-utils';

export const VariantDetails = ({
  language,
  variant,
  productId,
  onEditVariant,
  closeDrawer,
  selectedVariantAvailabilities,
  onGalleryOrderUpdate,
  onGalleryUpdate,
  variantGalleryFiles,
  isUpdatePending
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.variantDetails', t);
  const { selectedUserScope, userDepartments } = useAuth();
  const { LOCALBUSINESS } = SCOPE;
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);

  const [form] = Form.useForm();
  const { getAttrsForInfiniteSelect } = useAttributes();
  const [isNoSizeSelected, setIsNoSizeSelected] = useState(false);

  const SELECT_KEYS = {
    COLOR: 'color',
    SIZE: 'size',
    SIZE_SCALE: 'sizeScale'
  };
  const NO_SIZE = 'no-size';

  useEffect(() => {
    if (variant) {
      const fieldsValue = {
        color: {
          key: variant.attributeVariants.color.key,
          label: variant.attributeVariants.color.localeName,
          id: variant.attributeVariants.color.id
        },
        sizeScale: {
          key: variant.attributeVariants.sizeScale.businessKey,
          label: variant.attributeVariants.sizeScale.businessKey
        },
        size: {
          key: variant.attributeVariants.size.businessKey,
          label: variant.attributeVariants.size.businessKey
        },
        manufacturerCode: variant.manufacturerCode,
        price: variant.prices ? variant.prices.sellingPrice : null,
        availabilities: selectedVariantAvailabilities.reduce((acc, variant) => {
          acc[variant.id] = variant.availability;
          return acc;
        }, {})
      };
      if (fieldsValue.sizeScale.key === NO_SIZE) {
        setIsNoSizeSelected(true);
      }
      form.setFieldsValue(fieldsValue);
    }
  }, [form, variant, selectedVariantAvailabilities]);

  const onChangeValue = useCallback((code, value, selectKey) => {
    if (selectKey === SELECT_KEYS.SIZE_SCALE) {
      if (code.key === NO_SIZE && !isNoSizeSelected) {
        setIsNoSizeSelected(true);
      } else if (isNoSizeSelected) {
        setIsNoSizeSelected(false);
      }
    }
  }, [SELECT_KEYS, NO_SIZE, isNoSizeSelected, setIsNoSizeSelected]);

  const deleteVariant = async () => {
    await ProductsAPI.deleteVariant(productId, variant.id);
    window.location.reload(false);
  };

  const showDeletionModal = () => {
    setIsDeletionModalVisible(true);
  };

  const hideDeletionModal = () => {
    setIsDeletionModalVisible(false);
  };

  const loadInfiniteSelect = useCallback((page, key) => {
    const department = selectedUserScope === LOCALBUSINESS ? userDepartments[0] : undefined;
    const sortingKey = 'localeName';
    const sortingOrder = 'asc';
    return getAttrsForInfiniteSelect(key, page, language, department, sortingKey, sortingOrder);
  }, [getAttrsForInfiniteSelect]);

  const loadInfiniteSelectColor = useCallback((page) => {
    return loadInfiniteSelect(page, 'color');
  }, [loadInfiniteSelect]);

  const loadInfiniteSelectSize = useCallback((page) => {
    return loadInfiniteSelect(page, 'size');
  }, [loadInfiniteSelect]);

  const loadInfiniteSelectSizeScale = useCallback((page) => {
    return loadInfiniteSelect(page, 'sizeScale');
  }, [loadInfiniteSelect]);

  return (
    <>
      <div>
        <Form
          onFinish={onEditVariant}
          form={form}
          layout='vertical'
          autoComplete='off'
          initialValues={{
            color: { key: null, label: null, id: null },
            sizeScale: { key: null, label: null },
            size: { key: null, label: null },
            manufacturerCode: null,
            price: null,
            availability: []
          }}
          scrollToFirstError
        >
          <div className='relative pb-16'>
            {/* Manufacture Code */}
            <Row className='px-4 py-4'>
              <Form.Item
                className='w-full'
                name='manufacturerCode'
                label={text('EAN')}
                rules={[
                  {
                    required: true,
                    message: text('EANRequired')
                  }
                ]}
              >
                <Input placeholder={text('EAN')} />
              </Form.Item>
            </Row>

            {/* Color */}
            <Row className='px-4 pb-4'>
              <Form.Item
                className='w-full'
                name='color'
                label={text('color')}
                rules={[{ required: true, message: text('colorRequired') }]}
              >
                <InputSelectInfinite
                  selectKey={SELECT_KEYS.COLOR}
                  onLoadMore={loadInfiniteSelectColor}
                  onChange={onChangeValue}
                />
              </Form.Item>
            </Row>

            {/* Scale */}
            <Row className='px-4 pb-4'>
              <Form.Item
                className='w-full'
                name='sizeScale'
                label={text('scale')}
                rules={[
                  { required: true, message: text('scaleRequired') }
                ]}
              >
                <InputSelectInfinite
                  selectKey={SELECT_KEYS.SIZE_SCALE}
                  onLoadMore={loadInfiniteSelectSizeScale}
                  onChange={onChangeValue}
                />
              </Form.Item>
            </Row>

            {/* Size */}
            <Row className='px-4 pb-4'>
              <Form.Item
                name='size'
                label={text('size')}
                className='w-full'
                rules={[{ required: !isNoSizeSelected, message: text('sizeRequired') }]}
                disabled={isNoSizeSelected}
              >
                <InputSelectInfinite
                  selectKey={SELECT_KEYS.SIZE}
                  disabled={isNoSizeSelected}
                  onLoadMore={loadInfiniteSelectSize}
                  onChange={onChangeValue}
                />
              </Form.Item>
            </Row>

            <hr className='mt-2' />

            <Row className='px-4 pt-4'>
              <SectionTitle title={text('prices')} />
            </Row>

            {/* Prices */}
            <Row className='px-4 pb-4'>
              <Form.Item
                name='price'
                label={text('price')}
                className='w-full px-4 pb-4'
                rules={[
                  ({ getFieldValue }) => ({
                    validator (_, value) {
                      if (!value) {
                        return Promise.reject(new Error(text('priceRequired')));
                      } else if (value && getFieldValue('price') < 0) {
                        return Promise.reject(new Error(text('priceInvalid')));
                      } else return Promise.resolve();
                    }
                  })]}
              >
                <Input
                  min='0'
                  step='0.1'
                  addonBefore={variant.prices?.currency?.symbol}
                  placeholder={text('pricePlaceholder')}
                  className='w-full'
                />
              </Form.Item>
            </Row>
            <hr className='mt-2' />
            {/* Gallery */}
            <Row className='px-4 pt-4'>
              <SectionTitle title={text('gallery')} />
            </Row>
            <Row className='pl-4 pb-4'>
              <ImageGallery
                fileList={variantGalleryFiles.filter(img => (img?.info?.type === 'cover'))}
                detailFileList={variantGalleryFiles.filter(img => (!img.info || img?.info?.type !== 'cover'))}
                onGalleryUpdate={(file, fileList, type) => onGalleryUpdate(file, fileList, type)}
                onGalleryOrderUpdate={(fileList) => onGalleryOrderUpdate(fileList)}
              />
            </Row>
            {!!selectedVariantAvailabilities.length && <hr className='mt-2' />}
            <Row className='pl-4 pb-4'>
              <SectionTitle title={text('availability')} />
              {selectedVariantAvailabilities.length
                ? selectedVariantAvailabilities.map((availability, index) => (
                  <Form.Item
                    key={'random-prefix' + availability.id}
                    name={['availabilities', availability.id]}
                    label={availability.warehouse?.name}
                    className='w-full px-4 pb-4'
                    rules={[
                      { required: true, message: text('availabilityRequired') }
                    ]}
                  >
                    <InputNumber
                      className='w-full'
                      min='0'
                      placeholder={text('availabilityPlaceholder')}
                      parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                ))
                : text('noAvailabilityFound')}
            </Row>
            <Row className='p-4'>
              {/* Delete Product */}
              <Button
                type='danger'
                htmlType='button'
                className='uppercase'
                onClick={showDeletionModal}
              >
                {text('deleteVariant')}
              </Button>
            </Row>
          </div>
          <div className='absolute bottom-0 z-40 bg-white flex w-full justify-end pr-6 py-4'>
            <Button
              className='mr-2 uppercase'
              type='text'
              onClick={closeDrawer}
            >
              {text('cancel')}
            </Button>
            <Button
              className='uppercase'
              type='primary'
              htmlType='submit'
              loading={isUpdatePending}
              disabled={isUpdatePending}
            >
              {text('saveChanges')}
            </Button>
          </div>
        </Form>
      </div>

      <DeletionModal
        onOk={deleteVariant}
        visible={isDeletionModalVisible}
        onCancel={hideDeletionModal}
        msgOnSuccess={text('onDeleteOk')}
        msgOnError={text('onDeleteErr')}
      >
        <div>{text('askConfirmation')}</div>
      </DeletionModal>
    </>

  );
};
