import { message as antdMessage } from "antd";

antdMessage.config({ rtl: true });

export const message = {
  error(e) {
    if (typeof e === "string") {
      antdMessage.error(e);
    } else {
      antdMessage.error(
        e.response?.data?.detail || e.response?.statusText || e.message
      );
    }
  },
  success(msg) {
    antdMessage.success(msg);
  },
  info(msg) {
    antdMessage.info(msg);
  },
};
