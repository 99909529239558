import React from 'react';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

const makeColumns = (text, departmentText, onEdit) => [
  {
    title: text('name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: text('email'),
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: text('profile'),
    dataIndex: 'profile',
    key: 'id',
    render: (_, record) => (<span>{`${departmentText(record?.departments?.[0]?.key)}`}</span>)
  },
  {
    title: text('address'),
    dataIndex: 'address',
    key: 'address',
    render: (_, record) => (<span>{`${record?.address?.streetAddress ?? ''} ${record?.address?.streetNumber ?? ''}`}</span>)
  },
  {
    title: text('city'),
    dataIndex: ['address', 'city'],
    key: 'city'
  },
  {
    title: '',
    dataIndex: 'operation',
    align: 'center',
    width: 200,
    render: (_, row) => (
      <PopoverActionMenu>
        <MenuAction onClick={onEdit.bind(_, row)} label={text('edit')} testId='edit-action' />
      </PopoverActionMenu>
    )
  }
];

export const LocalBusinessTable = ({
  loading,
  onEdit,
  localBusinesses,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.localbusinesses', t);
  const departmentText = textFrom('entities.departments', t);

  const COLUMNS = makeColumns(text, departmentText, onEdit);

  return (
    <Table
      loading={loading}
      columns={COLUMNS}
      dataSource={localBusinesses}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
      rowClassName='cursor-pointer'
    />
  );
};
