import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useFilters } from 'src/hooks/useFilters';
import { usePagination } from 'src/hooks/usePagination';
import { WarehousesAPI } from '../api/WarehousesAPI';
import { useTranslation } from 'react-i18next';
import { WarehousesMapper } from 'src/modules/warehouses/domain/Warehouses.mapper';
import { useParams, useLocation } from 'react-router-dom';

const WarehousesContext = createContext();

const useProvideWarehouses = (text) => {
  const params = useParams();
  const location = useLocation();
  const [warehouse, setWarehouse] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(WarehousesMapper);
  const [areWarehousesLoading, setAreWarehousesLoading] = useState(false);

  const getWarehouses = useCallback(async () => {
    setAreWarehousesLoading(true);
    const warehouseQuery = { ...query };
    try {
      const res = await WarehousesAPI.getWarehouses(WarehousesMapper.fromQueryToPayload(warehouseQuery));
      setWarehouses(res?.data?.data?.items || []);
      setPagination(res?.data?.data || {});
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveWarehouses'));
      return Promise.reject(e);
    } finally {
      setAreWarehousesLoading(false);
    }
  }, [query, setPagination, location]);

  const getWarehouse = useCallback(async () => {
    try {
      const res = await WarehousesAPI.getWarehouse(params.id);
      setWarehouse(res?.data?.data);
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveWarehouse'));
      return Promise.reject(e);
    }
  }, [params.id]);

  useEffect(() => {
    getWarehouses();
  }, [location.search, getWarehouses]);

  useEffect(() => {
    if (params.id) {
      getWarehouse();
    }
  }, [params.id]);

  return {
    query,
    warehouse,
    warehouses,
    pagination,
    setWarehouses,
    onChangeQuery,
    getWarehouses,
    areWarehousesLoading
  };
};

export const WarehousesProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.warehouses', t);

  const warehouses = useProvideWarehouses(text);

  return (
    <WarehousesContext.Provider value={warehouses}>
      {children}
    </WarehousesContext.Provider>
  );
};

export const useWarehouses = () => useContext(WarehousesContext);
