import { AxiosClient } from 'src/api/AxiosClient';
import { CouponsMapper } from '../domain/Coupons.mapper';
import { message } from 'antd';

export class CouponsAPI {
  // ---------------------------------- COUPON CRUD --------------------------------------------

  /**
   * @description Search on Coupons
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async searchCoupons (data) {
    return AxiosClient.get('coupons', {
      params: {
        pageNumber: data?.pageNumber,
        limit: data?.limit,
        applicationCode: data?.applicationCode,
        localBusinessId: data?.localBusinessId,
        sort: data?.sort
      }
    });
  }

  /**
   * @description Update Coupon activation status
   * @param couponId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateCouponActivationStatus (couponId, data) {
    return AxiosClient.patch(`/coupons/${couponId}/activationStatus`, data, {
      headers: {
        Accept: 'application/stentle.api-v0.1+json'
      }
    });
  }

  /**
   * @description Delete Coupon
   * @param couponId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteCoupon (couponId) {
    return AxiosClient.delete(`/coupons/${couponId}`);
  }

  /**
   * @description Get details of a single coupon
   * @param couponId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getCoupon (couponId) {
    return AxiosClient.get(`/coupon/${couponId}`);
  }

  /**
   * @description Create a new coupon
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async createCoupon (data) {
    return AxiosClient.post('/coupons/createComplete', data);
  }

  /**
  * @description Search on Products
  * @param data
  * @returns {Promise<AxiosResponse<any>>}
  */
  static async searchProducts (data) {
    return AxiosClient.post('/backoffice/products/filter', data);
  }

  static async getCoupons (query) {
    try {
      const res = await CouponsAPI.searchCoupons(CouponsMapper.fromQueryToPayload(query));
      return res?.data?.data;
    } catch (e) {
      message.error('Failed to fetch coupons!');
    }
  }
}
