import { AxiosClient } from 'src/api/AxiosClient';
import { ProductUpdatePayload } from 'src/modules/products/api/payloads/ProductUpdatePayload';
import { ImageModel } from 'src/modules/products/domain/models/ImageModel';
import { Product } from 'src/services/Product.service';

export class OrdersAPI {
  // PRODUCT CRUD
  /**
   * @description Search on Products
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getOrders (data) {
    return AxiosClient.post('/orders/filter', data);
  }

  /**
   * @description Inline editing of a product
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProduct (id, data) {
    return AxiosClient.put(`/products/${id}`, new ProductUpdatePayload(data));
  }

  /**
   * @description Delete product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteProduct (productId) {
    return AxiosClient.delete(`/products/${productId}`);
  }

  /**
   * @description Get details of a single product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getProduct (productId) {
    return AxiosClient.get(`/products/${productId}`);
  }

  static async updateProductStatus (productId, status) {
    const productStatus = Product.getStatusBooleanValue(status);
    return AxiosClient.put(`/products/${productId}/publish/${productStatus}`, {
      productId: productId
    });
  }

  // VARIANT CRUD

  /**
   * @description Get product variants
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getProductVariants (productId, variantsFilters) {
    return AxiosClient.get(`/products/${productId}/variants`, {
      params: variantsFilters
    });
  }

  /**
   * @description Delete a single variant
   * @param productId
   * @param variantId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteVariant (productId, variantId) {
    return AxiosClient.delete(`/products/${productId}/variants/${variantId}`);
  }

  /**
   * @description Add ProductGalleryImage
   * @param productId
   * @param file
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async addProductGalleryImage (productId, file) {
    const fmData = new window.FormData();
    fmData.append('fileUpload1', file);
    try {
      const { data } = await AxiosClient.post(
        `/products/${productId}/product-photo/details`,
        fmData,
        {
          headers: { 'content-type': 'multipart/form-data' }
        }
      );
      return data.data.photoGallery.images.map((img) => new ImageModel(img));
    } catch (e) {
      return Promise.reject(e);
    }
  }
}
