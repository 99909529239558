import React, {
    createContext,
    useState,
    useCallback,
    useContext,
    useEffect,
    } from "react";
import { useParams } from "react-router-dom";
import { UploadsAPI } from "src/modules/upload/api/UploadsApi"
import { message } from "src/services/Messages.service";

export const uploadTypeStatusContext = createContext();

const useProvideUploadTypeStatus = () => {
    const params = useParams();
    const [UUIDStatus, setUUIDStatus] = useState({})
    const [UUIDErrorFile, setUUIDErrorFile] = useState({})
    const [pendingGetStatus, setPendingGetStatus] = useState(false)

    /**
     * @description Get Status by UUID
     * @type {(function(): Promise<void>)|*}
     */
    const getStatusByUUID = useCallback(async () => {
        setPendingGetStatus(true)

        try {
            const res = await UploadsAPI.getStatusByUUID(params.uuid);
            setUUIDStatus(res)
        } catch (e) {
            message.error(e);
        } finally {
            setPendingGetStatus(false)
        }
    }, [params.uuid]);

    /**
     * @desc Get uploaded file with errors
     * @type {(function(): Promise<void>)|*}
     */
    const getErrorFileByUUID = useCallback(async () => {
        try {
            const res = await UploadsAPI.getErrorFileByUUID(params.uuid);
            setUUIDErrorFile(res)
        } catch (e) {
            message.error(e);
        }
    }, [params.uuid]);

    useEffect(() => {
        getStatusByUUID();
        getErrorFileByUUID();
    }, [params, getErrorFileByUUID, getStatusByUUID]);


    return {
        UUIDStatus,
        UUIDErrorFile,
        pendingGetStatus
    };
};

export const UploadTypeStatusProvider = ({ children }) => {
    const uploadTypeStatus = useProvideUploadTypeStatus()
    return (
        <uploadTypeStatusContext.Provider value={uploadTypeStatus}>
            {children}
        </uploadTypeStatusContext.Provider>
    );
};

export const useUploadTypeStatus = () => useContext(uploadTypeStatusContext);
