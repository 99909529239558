export class CouponsQuery {
  applicationCode;
  current;
  pageSize;
  localBusinessId;
  localBusinessName;

  static get KEYS() {
    return {
      LOCAL_BUSINESS_ID: "localBusinessId",
      APPLICATION_CODE: "applicationCode",
      LOCAL_BUSINESS_NAME: "localBusinessName"
    };
  }
}
