import { AxiosClient } from 'src/api/AxiosClient';
// import { ProductUpdatePayload } from 'src/modules/products/api/payloads/ProductUpdatePayload';
import { FoodUpdatePayload } from 'src/modules/products/api/payloads/FoodUpdatePayload';
import { ImageModel } from 'src/modules/products/domain/models/ImageModel';
import { Product } from 'src/services/Product.service';
import { VariantUpdatePayload } from 'src/modules/products/api/payloads/VariantUpdatePayload';
import { AvailabilityPayload } from 'src/modules/products/api/payloads/AvailabilityPayload';
import { ProductDetailsUpdatePayload } from 'src/modules/products/api/payloads/ProductDetailsUpdatePayload';

export class ProductsAPI {
  // ----------------------------------PRODUCT CRUD --------------------------------------------
  /**
   * @description Search on Products
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async searchProducts (data) {
    return AxiosClient.post('/backoffice/products/filter', data);
  }

  /**
   * @description Inline editing of a product
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProduct (id, data) {
    return AxiosClient.put(`/products/${id}`, new FoodUpdatePayload(data))
      .then((res) => res.data.data)
      .then((apiData) => ({
        ...apiData,
        macroProductCategory: {
          ...apiData.macroCategory,
          key: apiData.macroCategory.key,
          label: apiData.macroCategory.localeName
        },
        microProductCategory: {
          ...apiData.microCategory,
          key: apiData.microCategory.key,
          label: apiData.microCategory.localeName
        }
      }));
  }

  /**
   * @description Set the availability of a product
   * @param ids -> expected array
   * @param boolean
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async toggleAvailability (ids, boolean) {
    return AxiosClient.put(`/products/bulk/availability/${boolean}`, { productIdList: ids });
  }

  /* @description Updates product availability
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProductAvailability (productId, isAvailable) {
    return AxiosClient.put(`/products/bulk/availability/${isAvailable}`, {
      productIdList: [productId]
    });
  }

  /**
   * @description Update product - product details page
   * @param productId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProductDetails (productId, data) {
    return AxiosClient.put(
      `/products/${productId}`,
      new ProductDetailsUpdatePayload(data)
    ).then((res) => res.data.data);
  }

  /**
   * @description Delete product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteProduct (productId) {
    return AxiosClient.delete(`/products/${productId}`,
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      }
    );
  }

  /**
   * @description Get details of a single product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getProduct (productId, language = 'en') {
    return AxiosClient.get(`/products/${productId}`, {
      headers: {
        'accept-language': language
      }
    });
  }

  /**
   * @description Update product status
   * @param productId
   * @param status
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProductStatus (productId, status) {
    const productStatus = Product.getStatusBooleanValue(status);
    return AxiosClient.put(`/products/${productId}/publish/${productStatus}`, {
      productId: productId
    });
  }

  static async updateProductVariantStatus (productId, variantId, status) {
    const productStatus = Product.getStatusBooleanValue(status);
    return AxiosClient.put(`/products/${productId}/variant/${variantId}/publish/${productStatus}`, {
      productId,
      variantId,
      status
    });
  }

  /**
   * @description Add ProductGalleryImage
   * @param productId
   * @param file
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async addProductGalleryImage (productId, file) {
    const fmData = new window.FormData();
    fmData.append('fileUpload1', file);
    try {
      const { data } = await AxiosClient.post(
        `/products/${productId}/product-photo/details`,
        fmData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
      return data.data.photoGallery.images.map((img) => new ImageModel(img));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  static async updateProductGallery (productId, galleryFilesList, addedFiles, deletedFiles) {
    const addedFilesPromises = addedFiles.filter(file => {
      const newDetailFile = galleryFilesList.find(galleryFile => file.uid === galleryFile.uid);
      if (newDetailFile) {
        const fmData = new window.FormData();
        fmData.append('fileUpload1', file);
        return AxiosClient.post(
          `/products/${productId}/product-photo/details`,
          fmData,
          {
            headers: {
              'content-type': 'multipart/form-data',
              accept: 'application/stentle.api-v0.2+json'
            }
          }
        ).then(({ data }) =>
          data.data.photoGallery.images.map((img) => new ImageModel(img))
        );
      } else {
        return false;
      }
    });
    const deletedFilesPromises = deletedFiles.map((file) => {
      return AxiosClient.delete(
        `/products/${productId}/product-photo/${file.uid}`,
        {
          headers: {
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
    });
    return Promise.all([...addedFilesPromises, ...deletedFilesPromises]);
  }

  static async updateProductCoverImage (productId, coverImage, addedFiles, deletedFiles) {
    const newCoverFile = addedFiles.find(file => file.uid === coverImage.uid);
    const addedFilesPromises = [];
    if (newCoverFile) {
      const fmData = new window.FormData();
      fmData.append('fileUpload1', newCoverFile);
      const addedFilesPromise = AxiosClient.post(
        `/products/${productId}/product-photo/cover`,
        fmData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      ).then(({ data }) =>
        data.data.photoGallery.images.map((img) => new ImageModel(img))
      );
      addedFilesPromises.push(addedFilesPromise);
    }
    const deletedFilesPromises = deletedFiles.map((file) => {
      return AxiosClient.delete(
        `/products/${productId}/product-photo/${file.uid}`,
        {
          headers: {
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
    });
    return Promise.all([...addedFilesPromises, ...deletedFilesPromises]);
  }

  // ---------------------------VARIANT CRUD -------------------------------

  /**
   * @description Get product variants
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getProductVariants (productId) {
    return AxiosClient.get(`/products/${productId}/variants`);
  }

  /**
   * @description Delete a single variant
   * @param productId
   * @param variantId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteVariant (productId, variantId) {
    return AxiosClient.delete(`/products/${productId}/variants/${variantId}`,
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      }
    );
  }

  static async updateVariantCoverImage (productId, variantId, coverImage, addedFiles, deletedFiles) {
    const newCoverFile = addedFiles.find(file => file.uid === coverImage.uid);
    const addedFilesPromises = [];
    if (newCoverFile) {
      const fmData = new window.FormData();
      fmData.append('fileUpload1', newCoverFile);
      const addedFilesPromise = AxiosClient.post(
        `/products/${productId}/variants/${variantId}/variant-photo/cover`,
        fmData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      ).then(({ data }) =>
        data.data.photoGallery.images.map((img) => new ImageModel(img))
      );
      addedFilesPromises.push(addedFilesPromise);
    }
    const deletedFilesPromises = deletedFiles.map((file) => {
      return AxiosClient.delete(
        `/products/${productId}/variants/${variantId}/variant-photo/${file.uid}`,
        {
          headers: {
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
    });
    return Promise.all([...addedFilesPromises, ...deletedFilesPromises]);
  }

  /**
   * @description Update variant gallery
   * @param productId
   * @param variantId
   * @param fileList
   * @param addedFiles
   * @param deletedFiles
   * @returns {Promise<unknown[]>}
   */
  static async updateVariantGallery (productId, variantId, fileList, addedFiles, deletedFiles) {
    const addedFilesPromises = addedFiles.filter((file) => {
      const newDetailFile = fileList.find(galleryFile => file.uid === galleryFile.uid);
      if (newDetailFile) {
        const fmData = new window.FormData();
        fmData.append('fileUpload1', newDetailFile.originFileObj);
        return AxiosClient.post(
          `/products/${productId}/variants/${variantId}/variant-photo/details`,
          fmData,
          {
            headers: {
              'content-type': 'multipart/form-data',
              accept: 'application/stentle.api-v0.2+json'
            }
          }
        ).then(({ data }) =>
          data?.data?.photoGallery?.images?.map((img) => new ImageModel(img))
        );
      } else {
        return false;
      }
    });

    const deletedFilesPromises = deletedFiles.map((file) => {
      return AxiosClient.delete(
        `/products/${productId}/variants/${variantId}/variant-photo/${file.uid}`,
        {
          headers: {
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
    });

    return Promise.all([...addedFilesPromises, ...deletedFilesPromises]);
  }

  /**
   * @description Get variant availabilities
   * @param productId
   * @param variantId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getVariantAvailabilities (productId, variantId) {
    return AxiosClient.get(
      `/products/${productId}/variants/${variantId}/availabilities`
    );
  }

  /**
   * @description Update variant request
   * @param productId
   * @param variantId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariant (productId, selectedVariant, variantFormData, VariantModel) {
    return AxiosClient.put(
      `/products/${productId}/variants/${selectedVariant.id}`,
      new VariantUpdatePayload(selectedVariant, variantFormData)
    )
      .then((res) => res.data.data)
      .then((apiData) => new VariantModel(apiData));
  }

  /**
   * @description Update variant request
   * @param productId
   * @param variantId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateSingleVariantPrice (product, selectedVariant, VariantModel) {
    const payload = {
      attributeVariants: { ...selectedVariant.attributeVariants },
      published: product.published,
      prices: {
        sellingPrice: product.prices.sellingPrice
      }
    };
    // payload.prices.sellingPrice = price
    return AxiosClient.put(
      `/products/${product.id}/variants/${selectedVariant.id}`,
      payload
    )
      .then((res) => {
        return res.data.data;
      })
      .then((apiData) => new VariantModel(apiData));
  }

  /**
   * @description Update Variant Availability
   * @param productId
   * @param variantId
   * @param warehouseAvailabilityId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariantAvailability (productId, variantId, availabilities, originalAvailabilities) {
    const mergedAvailabilities = originalAvailabilities.map((a) => {
      const [id, value] =
        Object.entries(availabilities).find(([id, value]) => id === a.id) || [];
      if (!id) return a;
      return { ...a, availability: value };
    });

    const promises = mergedAvailabilities.map((data) => {
      return AxiosClient.put(
        `/products/${productId}/variants/${variantId}/availabilities/${data.id}`,
        new AvailabilityPayload(data, variantId)
      ).then((res) => res.data.data);
    });

    return Promise.all(promises);
  }

  /**
   * @description Update Variant Price
   * @param productId
   * @param variantId
   * @param warehouseAvailabilityId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariantPrice (product, variant, availability, warehouseAvailabilityId, warehouseManaged) {
    const payload = {
      id: warehouseAvailabilityId,
      variantId: variant.id,
      warehouse: {
        id: warehouseManaged
      },
      availability: availability,
      prices: {
        sellingPrice: variant.prices.sellingPrice,
        currency: 'EUR'
      }
    };

    return AxiosClient.put(
      `/products/${product.id}/variants/${variant.id}/availabilities/${warehouseAvailabilityId}`,
      payload
    ).then((res) => res.data.data);
  }

  /**
   * @description Update variant status
   * @param productId
   * @param variantId
   * @param status
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariantStatus (productId, variantId, status) {
    const productStatus = Product.getStatusBooleanValue(status);
    return AxiosClient.put(
      `/products/${productId}/variant/${variantId}/publish/${productStatus}`,
      {
        productId: productId
      }
    );
  }

  static async getSizeAttributes () {
    return AxiosClient.get(
      '/attributes?attributeCode=size&department=food&pageNumber=1&limit=300'
    );
  }

  static async createVariant (productId, data) {
    return AxiosClient.post(`/products/${productId}/variants`, data);
  }

  static async createProduct (data) {
    return AxiosClient.post('/products', data);
  }

  static async authorizeProduct (productId) {
    return AxiosClient.put(`/products/${productId}/authorize/true`, {
      productId: productId
    });
  }

  static async updateVariantGalleryFromProduct (productId, variantId) {
    // this copies the whole product's gallery to the variant
    return AxiosClient.put(`/products/${productId}/variants/${variantId}/photo-gallery/from-product`);
  }

  // static async createProduct (data) {
  //   return AxiosClient.post('/products', data);
  // }

  // static async authorizeProduct (productId) {
  //   return AxiosClient.put(`/products/${productId}/authorize/true`, {
  //     productId: productId
  //   });
  // }
}
