import { AxiosClient } from 'src/api/AxiosClient';
import { Routes } from '../../../router/Routes.helper';

export class CategoriesAPI {
  static async getCategories (types = [], page, limit = 1000, department, name) {
    const params = Routes.arrayToParams(types);

    const res = await AxiosClient.get(`/categories${params}`, {
      params: {
        pageNumber: page,
        limit,
        parentKey: null,
        sort: 'key,ASC',
        department,
        text: name || undefined
      },
      headers: {
        Accept: 'application/stentle.api-v0.3+json'
      }
    });

    let data = {};

    if (res?.data?.data?.items) {
      data = { ...res.data.data };
      data.items = data.items.map(item => ({
        key: item.key,
        label: item.locale.name,
        type: item.type,
        id: item.id,
        ranking: item.ranking,
        imageList: item.imageList,
        photoAttributes: (item.imageList && item.imageList[0]?.imageURL) || null,
        parentKey: item?.parent?.key,
        department: item?.parent?.key,
        localizedValues: item?.localizedValues,
        editable: item.editable
      }));
    }

    return data;
  }

  static async getMacroCategories (page, parentKey, language = 'en', limit = 1000) {
    const res = await AxiosClient.get('/categories', {
      params: {
        pageNumber: page,
        limit,
        type: 'root',
        parentKey: parentKey || null
      },
      headers: {
        'accept-language': language
      }
    });

    res.data.data.items = res.data.data.items.map((item) => ({
      key: item.key,
      label: item.locale.name
    }));

    return res;
  }

  static async getMicroCategories (macroKey, page, language = 'en', limit = 1000) {
    const res = await AxiosClient.get('/products/micro-category-list', {
      params: {
        parentKey: macroKey,
        context: 'VARIANT',
        pageNumber: page,
        limit
      },
      headers: {
        'accept-language': language
      }
    });

    res.data.data.items = res.data.data.map((item) => ({
      key: item.key,
      label: item.localeName
    }));
    return res;
  }

  static async bulkAssignCategory (microKey, productIdList) {
    const res = await AxiosClient.put(`/products/bulk/category/micro/${microKey}`, {
      productIdList
    });
    return res;
  }

  static async createCategory ({ key, type, parentKey, ranking }) {
    const payload = {
      type,
      key,
      ranking,
      active: true,
      imageList: [],
      parentKey
    };

    return await AxiosClient.post('/categories', payload);
  }

  static async editCategory (payload) {
    return await AxiosClient.put(`/categories/${payload?.id}`, payload);
  }

  static async deleteCategory (id) {
    return AxiosClient.delete(`/categories/${id}`);
  }

  static getCategoriesByType (type) {
    const config = {
      params: {
        type: type
      }
    };
    return AxiosClient.get('/categories', config);
  }
}
