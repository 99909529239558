import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'src/components/PageTitle';
import { CollectionGroupForm } from 'src/modules/collections/components/collection-group/CollectionGroupForm.jsx';
import { CollectionGroupCollapsibles } from '../../components/collection-group/CollectionGroupCollapsibles';
import { textFrom } from 'src/utils/textFrom';
import { useOutfitCollections } from '../../provider/outfitCollections.provider';
import { CollectionsAPI } from '../../api/CollectionsAPI';
import { message } from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useHistory } from 'react-router-dom';
import { Routes } from 'src/router/Routes.helper';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';

export const PageCreateCollectionGroup = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.collections.collectionGroup/create', t);

  const [linkedCollections, setLinkedCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    outfitCollections
  } = useOutfitCollections();

  const history = useHistory();

  const navigateToCollectionGroup = () => {
    history.push(Routes.PATHS.COLLECTION_GROUP);
  };

  const onFinish = async (preparedPayload) => {
    const collectionGroupPayload = { ...preparedPayload };
    setIsLoading(true);
    try {
      const res = await CollectionsAPI.upsertCollection(collectionGroupPayload);
      const collectionId = res?.data?.id;
      if (collectionId) {
        const bulkSetPayload = linkedCollections.map(collection => collection.id);
        await CollectionsAPI.bulkSetLinkedCollectionsToCollectionGroup(collectionId, bulkSetPayload);
        message.success(text('collectionGroupCreatedOK'));
        navigateToCollectionGroup();
      } else {
        message.error(text('collectionGroupCreatedKO'));
      }
    } catch (e) {
      message.error(text('somethingWentWrong'));
    } finally {
      setIsLoading(false);
    }
  };

  const buttons = (
    <div className='flex w-full mt-20'>
      <DefaultButton
        label={text('cancel')}
        onClick={navigateToCollectionGroup}
        className='ml-auto mr-4'
      />
      <SubmitButton
        loading={isLoading}
        disabled={isLoading}
        label={text('create')}
      />
    </div>
  );

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>
      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToCollectionGroup}
        title={text('backButton')}
      />
      <CollectionGroupForm
        onFinish={onFinish}
        buttons={buttons}
      >
        <CollectionGroupCollapsibles
          collections={outfitCollections}
          linkedCollections={linkedCollections}
          setLinkedCollections={setLinkedCollections}
        />

      </CollectionGroupForm>
    </div>
  );
};
