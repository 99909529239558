import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useContext
} from 'react';
import { message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useFilters } from 'src/hooks/useFilters';
import { useLocation } from 'react-router-dom';
import { CategoriesAPI } from 'src/modules/categories/api/CategoriesAPI';
import { CategoriesMapper } from 'src/modules/categories/domain/categories.mapper';

function getPagination (data) {
  return {
    total: data?.totalItems,
    pageSize: data?.itemsPerPage
  };
}

export const CategoryContext = createContext();

const useProvideCategories = () => {
  const location = useLocation();
  const [macros, setMacros] = useState([]);
  const [macrosPagination, setMacrosPagination] = useState();
  const [areMacrosLoading, setAreMacrosLoading] = useState(false);
  const [micros, setMicros] = useState([]);
  const [microsPagination, setMicrosPagination] = useState();
  const [areMicrosLoading, setAreMicrosLoading] = useState(false);
  const { userDepartments } = useAuth();
  const { query, onChangeQuery } = useFilters(CategoriesMapper);

  const getMacros = useCallback(async () => {
    setAreMacrosLoading(true);
    const { pageNumber, pageSize, search } = CategoriesMapper.fromQueryToPayload(query);
    try {
      const data = await CategoriesAPI.getCategories(['ROOT'], pageNumber, pageSize, userDepartments[0], search);
      setMacros(data?.items || []);
      setMacrosPagination(getPagination(data));
    } catch (e) {
      message.error('Error while fetching macros');
    } finally {
      setAreMacrosLoading(false);
    }
  }, [query, location]);

  const getMicros = useCallback(async () => {
    setAreMicrosLoading(true);
    const { pageNumber, pageSize, search } = CategoriesMapper.fromQueryToPayload(query);
    try {
      const data = await CategoriesAPI.getCategories(['CHILD'], pageNumber, pageSize, userDepartments[0], search);
      setMicros(data?.items || []);
      setMicrosPagination(getPagination(data));
    } catch (e) {
      message.error('Error while fetching micros');
    } finally {
      setAreMicrosLoading(false);
    }
  }, [query, location]);

  useEffect(() => {
    getMacros();
    getMicros();
  }, [location.search, getMacros, getMicros]);

  return {
    query,
    macros,
    macrosPagination,
    areMacrosLoading,
    micros,
    microsPagination,
    areMicrosLoading,
    onChangeQuery
  };
};

export const CateogoryProvider = ({ children }) => {
  const categories = useProvideCategories();

  return (
    <CategoryContext.Provider value={categories}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => useContext(CategoryContext);
