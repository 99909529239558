import React, { useState, useCallback, Fragment } from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragableUploadListItem } from 'src/modules/products/components/DragableUploadListItem';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const ImageGalleryBrand = ({ fileList, onGalleryUpdate, onGalleryOrderUpdate, isDisabled }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { t } = useTranslation();
  const text = textFrom('components.imageGalleryBrand', t);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      onGalleryOrderUpdate(update(fileList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      }));
    },
    [fileList, onGalleryOrderUpdate]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Upload
        maxCount={1}
        accept='image/*'
        listType='picture-card'
        onChange={({ file, fileList }) => onGalleryUpdate(file, fileList)}
        fileList={fileList}
        beforeUpload={() => {
          return false;
        }}
        disabled={isDisabled}
        onPreview={handlePreview}
        itemRender={(originNode, file, currFileList) => {
          return (
            <Fragment key={file.uid}>
              <DragableUploadListItem
                originNode={originNode}
                file={file}
                fileList={currFileList}
                moveRow={moveRow}
              />
            </Fragment>
          );
        }}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{text('upload')}</div>
        </div>
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </DndProvider>
  );
};
