export const SCOPE = {
  PLATFORM: 'platform',
  TENANT: 'tenant',
  LOCALBUSINESS: 'localbusiness'
};

const { PLATFORM, TENANT, LOCALBUSINESS } = SCOPE;

export const PLATFORM_W_LB_SCOPES = [PLATFORM, TENANT, LOCALBUSINESS];
export const PLATFORM_WO_LB_SCOPES = [PLATFORM, TENANT];
export const TENANT_W_LB_SCOPES = [TENANT, LOCALBUSINESS];
export const TENANT_WO_LB_SCOPES = [TENANT];
export const LOCALBUSINESS_SCOPES = [LOCALBUSINESS];
