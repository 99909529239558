import { PRODUCT_STATUS } from 'src/constants/productStatus'

export class Product {
    static statusOptions = Object.values(PRODUCT_STATUS)

    static getStatusBooleanValue = (status) => {
        if (typeof status === 'boolean') {
            return status;
        } else if ([PRODUCT_STATUS.PUBLISHED, PRODUCT_STATUS.DRAFT].includes(status)) {
            return status === PRODUCT_STATUS.PUBLISHED;
        } else if (['true', 'false'].includes(status)) {
            return this.stringToBoolean(status);
        } else {
            throw new Error('Unacceptable status value in getStatusBoolenValue');
        }
    }

    static getStatus(status) {
        switch(status) {
            case true: return PRODUCT_STATUS.PUBLISHED;
            case false: return  PRODUCT_STATUS.DRAFT;
            default: return status;
        }
    }

    static stringToBoolean(string) {
        switch(string.toLowerCase().trim()){
            case "true": return true;
            case "false": return false;
            default: return Boolean(string);
        }
    }


}
