import React, { useState } from 'react';
import { useOutfits } from 'src/modules/outfits/provider/outfits.provider';
import { ProductTable } from 'src/modules/outfits/components/OutfitProductTable';
import { VariantTable } from 'src/modules/outfits/components/OutfitVariantTable';
import { useTranslation } from 'react-i18next';

export const VariantSelector = () => {
  const { i18n } = useTranslation();
  const [selectedVariantKeys, setSelectedVariantKeys] = useState([]);
  const { selectedProduct, setSelectedProduct, setOutfitVariants } = useOutfits();

  return (
    <>
      {selectedProduct
        ? <VariantTable
            selectedVariantKeys={selectedVariantKeys}
            setSelectedVariantKeys={setSelectedVariantKeys}
            selectedLanguage={i18n.language}
            setOutfitVariants={setOutfitVariants}
          />
        : <ProductTable
            onAdd={setSelectedProduct}
          />}
    </>
  );
};
