import React, { useState, useRef } from 'react';
import { Row, Col, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useCustomers } from 'src/modules/admin/provider/customers.provider';
import { CustomersAPI } from '../api/CustomersAPI';
import { CenteredText } from 'src/components/CenteredText';
import { CustomerTable } from '../component/CustomerTable';
import { useTranslation } from 'react-i18next';
import { AnonymizationModal } from '../component/AnonymizationModal';

export const PageCustomers = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.customers', t);

  const {
    query,
    customers,
    pagination,
    refreshData,
    onChangeQuery,
    areCustomersLoading
  } = useCustomers();
  const history = useHistory();
  const checkboxRef = useRef();
  const [customerToBeAnonymized, setCustomerToBeAnonymized] = useState();
  const [isDeletionPending, setIsDeletionPending] = useState(false);

  const handleCustomerSearch = (value) => {
    onChangeQuery({
      ...query,
      text: value,
      current: 1
    });
  };

  const goToCreatePage = () => {
    history.push('/app/customer/create');
  };

  const goToEditPage = async (customer) => {
    history.push(`/app/customer/edit/${customer.id}`);
  };

  const showAnonymizationModal = (customer) => {
    setCustomerToBeAnonymized(customer);
  };

  const resetCustomerToBeAnonymized = () => {
    setCustomerToBeAnonymized(undefined);
  };

  const anonymizeCustomer = async () => {
    setIsDeletionPending(true);
    const customerId = customerToBeAnonymized.id;
    const ordersShouldBeAnonymized = checkboxRef?.current?.checked;
    try {
      await CustomersAPI.anonymizeCustomer(customerId);
      if (ordersShouldBeAnonymized) {
        const ordersRes = await CustomersAPI.getOrders(customerId);
        const orders = ordersRes?.data?.data?.items?.map(item => item.id);
        if (orders?.length > 0) {
          await CustomersAPI.anonymizeOrders(orders);
        }
      }
      refreshData();
    } catch (e) {
      message.error(e);
    } finally {
      resetCustomerToBeAnonymized();
      setIsDeletionPending(false);
    }
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  return (
    <div className='py-4 page-filters'>
      <PageTitle>{text('customers')}</PageTitle>

      <Row gutter={[16, 16]} className='pt-2' justify='space-between'>
        <Col span={6}>
          <SearchInput
            autoFocus
            defaultValue={query?.text}
            onChangeText={handleCustomerSearch}
            placeholder={text('searchPlaceholder')}
          />
        </Col>
        <Col>
          <Button
            type='primary'
            className='uppercase'
            onClick={goToCreatePage}
          >
            {text('createNewCustomer')}
          </Button>
        </Col>
      </Row>

      {!areCustomersLoading && !customers?.length
        ? (<CenteredText>{text('noCustomersFound')}</CenteredText>)
        : (
          <div className='pt-8'>
            <CustomerTable
              loading={areCustomersLoading}
              onEdit={goToEditPage}
              onAnonymize={showAnonymizationModal}
              customers={customers}
              pagination={pagination}
              onTableChange={onTableChange}
            />
          </div>)}

      <AnonymizationModal
        ref={checkboxRef}
        customer={customerToBeAnonymized}
        onOk={anonymizeCustomer}
        onCancel={resetCustomerToBeAnonymized}
        isPending={isDeletionPending}
      />

    </div>
  );
};
