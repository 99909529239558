import isEmpty from 'lodash/isEmpty';
import { Filters } from 'src/services/Filters.service';
import { ProductsQuery } from 'src/modules/products/domain/models/ProductsQuery';
import { ProductsListPayload } from 'src/modules/products/api/payloads/ProductsListPayload';

export class ProductsMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = ProductsQuery;
    const query = new URLSearchParams(qs);
    const productsQueryModel = new ProductsQuery();
    productsQueryModel.startDate = query.get('startDate') || undefined;
    productsQueryModel.endDate = query.get('endDate') || undefined;
    productsQueryModel.text = query.get('text') || undefined;
    productsQueryModel.current = query.get('current') || undefined;
    productsQueryModel.pageSize = query.get('pageSize') || undefined;
    productsQueryModel.published = query.get('published') || undefined;
    productsQueryModel.brand =
      Filters.getFilterValues(query.get(KEYS.BRAND)) || undefined;
    productsQueryModel.macroCategory =
      Filters.getFilterValues(query.get(KEYS.MACRO_CATEGORY)) || undefined;
    productsQueryModel.microCategory =
      Filters.getFilterValues(query.get(KEYS.MICRO_CATEGORY)) || undefined;
    return productsQueryModel;
  }

  static fromQueryToPayload (query, department) {
    const { API_FILTERS_KEYS } = ProductsListPayload;
    const payload = new ProductsListPayload();
    payload.text = query.text;
    payload.pageNumber = +query.current === 0 ? 0 : +query.current - 1;
    payload.limit = query.pageSize;
    payload.filters = Filters.removeFalsyValues({
      brand: query.brand,
      published: query.published,
      [API_FILTERS_KEYS.MACRO_CATEGORY]: query.macroCategory,
      [API_FILTERS_KEYS.MICRO_CATEGORY]: query.microCategory,
      department: department
    });
    payload.filters = isEmpty(payload.filters) ? undefined : payload.filters;
    return payload;
  }
}
