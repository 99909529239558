import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useContext
} from 'react';
import { LocalBusinessAPI } from '../api/localBusinessAPI';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

export const DepartmentContext = createContext();

const useProvideDepartment = (text) => {
  const [departments, setDepartments] = useState([]);

  const getDepartments = useCallback(async () => {
    try {
      const res = await LocalBusinessAPI.getLocalBusinessDepartments();
      setDepartments(res.data?.data?.items || []);
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveDepartments'));
      return Promise.reject(e);
    }
  }, []);

  useEffect(() => {
    getDepartments();
  }, []);

  return {
    departments,
    refreshDepartments: getDepartments
  };
};

export const DepartmentProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.departments', t);

  const departments = useProvideDepartment(text);

  return (
    <DepartmentContext.Provider value={departments}>
      {children}
    </DepartmentContext.Provider>
  );
};

export const useDepartment = () => useContext(DepartmentContext);
