export class BrandsQuery {
    current;
    pageSize;
    brand;
    department;

    static get KEYS() {
        return {
            BRAND: "brand",
            DEPARTMENT: "department"
        };
    }

}