import { AxiosClient } from 'src/api/AxiosClient';

export class UsersAPI {
  static async getUsers (data) {
    return AxiosClient.get('/backoffice/users/filter', {
      params: {
        limit: data.limit,
        pageNumber: data.pageNumber,
        user: data.filters.user,
        group: data.filters.group
      }
    });
  }

  static async getRoles () {
    return AxiosClient.get('/backoffice/users/roles', {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    });
  }

  static async getUserDetails (userId) {
    const config = {
      headers: {
        Accept: 'application/stentle.api-v0.1+json'
      }
    };
    return AxiosClient.get(`/backoffice/users/${userId}`, config);
  }

  static async getUserEmailVerificationStatus (userId) {
    const config = {
      headers: {
        Accept: 'application/stentle.api-v0.1+json'
      }
    };
    return AxiosClient.get(`/backoffice/users/${userId}/email-verification`, config);
  }

  static async requestVerificationEmail (userId) {
    return AxiosClient.put(`/backoffice/users/${userId}/email-verification`);
  }

  static async updateUser (userId, payload) {
    return AxiosClient.put(`/backoffice/users/${userId}`, payload);
  }
}
