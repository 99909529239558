import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { SCOPE, PLATFORM_W_LB_SCOPES, PLATFORM_WO_LB_SCOPES, TENANT_W_LB_SCOPES, TENANT_WO_LB_SCOPES, LOCALBUSINESS_SCOPES } from 'src/utils/scope-utils.js';

const { Option } = Select;
const { PLATFORM, TENANT, LOCALBUSINESS } = SCOPE;

export const ScopeSelector = ({ userInfo }) => {
  const { userScope, selectedUserScope, updateSelectedUserScope } = useAuth();
  const [scopes, setScopes] = useState([]);

  useEffect(() => {
    if (userScope) {
      const parsedScopes = [];
      const hasLocalBusiness = !!userInfo?.localBusiness?.id;
      switch (userScope) {
        case PLATFORM:
          hasLocalBusiness ? parsedScopes.push(...PLATFORM_W_LB_SCOPES) : parsedScopes.push(...PLATFORM_WO_LB_SCOPES);
          break;
        case TENANT:
          hasLocalBusiness ? parsedScopes.push(...TENANT_W_LB_SCOPES) : parsedScopes.push(...TENANT_WO_LB_SCOPES);
          break;
        case LOCALBUSINESS:
          parsedScopes.push(...LOCALBUSINESS_SCOPES);
          break;
      }
      setScopes(parsedScopes);
    }
  }, [userScope, setScopes, userInfo]);

  return (
    scopes.length > 1 &&
      <Select
        bordered
        className='w-full'
        disabled={scopes.length === 1}
        value={selectedUserScope}
        onChange={updateSelectedUserScope}
      >
        {scopes.map(scope => <Option key={scope} value={scope}>{scope}</Option>)}
      </Select>
  );
};
