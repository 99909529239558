import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export const AppVersion = () => {
  return (
    <div>
      <Text type='secondary'>{`v. ${process.env.REACT_APP_VERSION}`}</Text>
    </div>
  );
};
