import React, {useRef} from "react";
import { Row, Col, Spin } from "antd";
import { ProductCard } from "src/modules/visualMerchandising/components/ProductCard";
import { arrayMoveImmutable } from "array-move";
import { sortableContainer, sortableElement } from "react-sortable-hoc";

export const SortableList = ({ data, onReorder, isLoading }) => {
  const rowRef = useRef();

  const SortableItem = sortableElement(({ cardItem, index }) => (
      <Col className="pb-3" xs={24} sm={16} md={8} lg={6} xl={4}>
        <ProductCard data={cardItem} index={index} isLoading={isLoading} />
      </Col>
  ));

  const SortableContainer = sortableContainer(({ items }) => (
    <>
      <Row gutter={{ xs: 0, sm: 16 }} className="pb-8 listView" ref={rowRef}>
        {items.map((cardItem, index) => (
            <SortableItem  key={index} index={index} cardItem={cardItem} />
        ))}
      </Row>
      {isLoading && <div className="flex justify-center"><Spin /></div>}
    </>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      onReorder(newData);
    }
  };

  return <SortableContainer items={data}
                            onSortEnd={onSortEnd}
                            axis={"xy"}
                            lockToContainerEdges
                            helperContainer={() => {
                              console.log('ref', rowRef)
                              return rowRef.current
                            }}
        />;
};
