export class CatalogModel {
    constructor(data) {
        this.filterAttributes = {
            basic: data.basicFilters.map(basicFilters => [basicFilters]),
            advanced: data.advancedFilters.map((advancedFilter) => {
                return [{key: advancedFilter.key, values: [advancedFilter.value]}]
            })
        };
        this.pageNumber = 1;
        this.limit = 9999;
    }
}
