import React from 'react';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

const makeColumns = (text, onEdit, onDelete) => [
  {
    title: text('name'),
    key: 'name',
    dataIndex: 'name',
    width: '40%',
    render: (name) => <div>{name}</div>
  },
  {
    title: text('type'),
    key: 'type',
    dataIndex: 'type',
    width: '40%',
    render: (type) => <div>{type}</div>
  },
  {
    align: 'center',
    render: (_, row) => (
      <PopoverActionMenu>
        <MenuAction onClick={onEdit.bind(_, row)} label={text('edit')} />
        <MenuAction onClick={onDelete.bind(_, row)} label={text('delete')} />
      </PopoverActionMenu>
    )
  }
];

export const WarehouseTable = ({
  loading,
  onEdit,
  onDelete,
  warehouses,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.warehouses', t);

  const COLUMNS = makeColumns(text, onEdit, onDelete);

  return (
    <Table
      loading={loading}
      columns={COLUMNS}
      dataSource={warehouses}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
    />
  );
};
