import React, { createContext, useContext, useState } from 'react';
import { AttributesAPI } from 'src/modules/attributes/api/AttributesAPI';
import { message } from 'src/services/Messages.service';

const attributesCache = {};

export const attributesContext = createContext();

const useProvideAttributes = () => {
  const [attributeCodes, setAttributeCodes] = useState([]);

  const fetchAttributeCodes = async (department) => {
    try {
      const res = await AttributesAPI.getDistinctCodes(department);
      setAttributeCodes(res.data.data);
    } catch (e) {
      setAttributeCodes([]);
      message.error(e);
    }
  };

  const getAttributes = async (code, page, language, department, sortingKey = null, sortingOrder = 'asc') => {
    try {
      const res = await AttributesAPI.getLocalizedAttributes(code, page, language, department, sortingKey, sortingOrder);
      if (res.data?.data?.items) {
        res.data.data.items = res.data.data.items.map((item) => ({
          key: item.attributeKey,
          label: item.localeName,
          id: item.id
        }));
      }
      return res;
    } catch (e) {
      message.error(e);
    }
  };

  const getAttrsForInfiniteSelect = (code, page, language, department, sortingKey = null, sortingOrder = 'asc') => {
    if (attributesCache[code]) {
      return attributesCache[code];
    }
    return getAttributes(code, page, language, department, sortingKey, sortingOrder);
  };

  const clearAttributesCache = () => {
    Object.keys(attributesCache).forEach((key) => delete attributesCache[key]);
  };

  return {
    attributeCodes,
    fetchAttributeCodes,
    getAttributes,
    getAttrsForInfiniteSelect,
    clearAttributesCache
  };
};

export const AttributesProvider = ({ children }) => {
  const attributes = useProvideAttributes();
  return (
    <attributesContext.Provider value={attributes}>
      {children}
    </attributesContext.Provider>
  );
};

export const useAttributes = () => useContext(attributesContext);
