import React, { useState, useEffect } from 'react';
import { SearchCollections } from 'src/modules/collections/components/collection-group/SearchCollections';

function outfitCollectionToSection (outfitCollection) {
  return {
    id: outfitCollection.id,
    code: outfitCollection.id,
    type: 'OUTFIT',
    names: outfitCollection.names,
    bannerMobile: { imageURL: outfitCollection.bannerMobile?.imageURL },
    bannerDesktop: { imageURL: outfitCollection.bannerDesktop?.imageURL }
  };
}

export const OutfitCollectionSelector = ({
  collections,
  linkedCollections,
  setLinkedCollections
}) => {
  const [collectionsToShow, setCollectionsToShow] = useState([]);

  useEffect(() => {
    if (linkedCollections.length === 0) {
      setCollectionsToShow(collections);
    } else {
      const getCollectionsToShow = () => collections.filter(collection => !(linkedCollections.some(linkedCollection => linkedCollection.id === collection.id)));
      setCollectionsToShow(getCollectionsToShow);
    }
  }, [linkedCollections, collections]);

  return (
    <SearchCollections
      mapper={outfitCollectionToSection}
      collections={collectionsToShow}
      setLinkedCollections={setLinkedCollections}
    />
  );
};
