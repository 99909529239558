import React from 'react';
import { Form, Select } from 'antd';
import { KEY_REGEX } from 'src/utils/constants';

export const SelectAttributeCode = React.forwardRef(({
  open,
  value,
  error,
  options,
  onReset,
  onSelect,
  onChange,
  disabled,
  label,
  placeholder,
  errorMessage,
  onDropdownVisibleChange
}, ref) => {
  return (
    <Form.Item
      name='attributeCode'
      label={label}
      help={error?.message}
      validateStatus={error?.status}
      rules={[
        {
          message: errorMessage,
          pattern: KEY_REGEX
        }
      ]}
    >
      <Select
        mode='tags'
        placeholder={placeholder}
        ref={ref}
        open={open}
        value={value}
        options={options}
        onClear={onReset}
        onFocus={onReset}
        onSelect={onSelect}
        onChange={onChange}
        disabled={disabled}
        onDeselect={onReset}
        onDropdownVisibleChange={onDropdownVisibleChange}
      />
    </Form.Item>
  );
});
