import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useOutfitCollections } from '../../provider/outfitCollections.provider';
import { OutfitCollectionTable } from '../outfit-collection/OutfitCollectionTable';

export const SelectedCollections = ({ linkedCollections, setLinkedCollections }) => {
  const { t } = useTranslation();
  const text = textFrom('components.selectedCollections', t);
  const { onChangeCollectionsQuery, areCollectionsLoading } = useOutfitCollections();

  const [selectedCollectionKeys, setSelectedCollectionKeys] = useState([]);

  const pagination = {
    position: 'topRight',
    pageSize: 10
  };

  const removeSelectedCollectionGroups = () => {
    const remainingCollections = linkedCollections.filter(collection => !selectedCollectionKeys.includes(collection.id));
    setLinkedCollections(remainingCollections);
    setSelectedCollectionKeys([]);
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeCollectionsQuery({ current, pageSize });
  };

  return (
    <>
      <Row justify='end'>
        <Col>
          <Button
            type='primary'
            className='uppercase mt-5'
            onClick={removeSelectedCollectionGroups}
          >
            {text('remove')}
          </Button>
        </Col>
      </Row>
      <OutfitCollectionTable
        isEditModeOn
        className='pt-8'
        tableData={linkedCollections}
        pagination={pagination}
        loading={areCollectionsLoading}
        onTableChange={onTableChange}
        selectedCollectionKeys={selectedCollectionKeys}
        setSelectedCollectionKeys={setSelectedCollectionKeys}
      />
    </>
  );
};
