import React, { useRef, useState } from 'react';
import { Row, Col, Button, Drawer, Tag } from 'antd';
import { useHistory } from 'react-router-dom';
import { useFashionProducts } from 'src/modules/products/provider/fashionProducts.provider';
import { useFilterTags } from 'src/hooks/useFilterTags';
import { ProductSideFilters } from 'src/modules/products/components/ProductSideFilters';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { FashionTable } from 'src/modules/products/components/FashionTable';
import { CustomMultiselect } from 'src/components/inputs/CustomMultiselect';
import { PageTitle } from 'src/components/PageTitle';
import { ProductsListPayload } from 'src/modules/products/api/payloads/ProductsListPayload';
import { ProductsQuery } from 'src/modules/products/domain/models/ProductsQuery';
import { Routes } from 'src/router/Routes.helper';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { Product } from 'src/services/Product.service';
import { message } from 'src/services/Messages.service';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { CenteredText } from 'src/components/CenteredText';
import { DeletionModal } from 'src/components/DeletionModal';
import { useAttributes } from 'src/hooks/useProvideAttributes';
import { tagLabel } from 'src/utils/utils';

const { API_FILTERS_KEYS } = ProductsListPayload;
const { KEYS } = ProductsQuery;

export const PageProducts = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.products', t);

  const history = useHistory();

  const header = useRef();

  const {
    query,
    products,
    availableFilters,
    pagination,
    onChangeQuery,
    areProductsLoading,
    refreshData
  } = useFashionProducts();
  const { clearAttributesCache } = useAttributes();

  const searchParameters = ['current', 'pageSize'];
  const textFilter = textFrom('constants.filter', t);

  const filterTags = useFilterTags(
    query,
    searchParameters,
    availableFilters?.filters || {}
  );
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [productToBeDeleted, setProductToBeDeleted] = useState(undefined);

  const resetProductToBeDeleted = () => {
    setProductToBeDeleted(undefined);
  };

  /**
   * @description This method handle the table data in case user changes the page or change the total items displayed on that page
   * @param {{
   *  current: string
   *  pageSize: number
   * }} config
   * @returns {string}
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  /**
   * @description This method handle the click on apply filter button inside the drawer. In the drawer are being added all the filters including even category and subcategory (apart ean)
   * @param filters
   * @param closeDrawer
   */
  const onApplyFilters = (filters, closeDrawer = false) => {
    const newFilters = { ...filters };

    if (filters.published !== undefined && filters.published !== null) {
      newFilters.published = Product.getStatusBooleanValue(filters.published);
    }
    newFilters.current = 1;

    onChangeQuery(newFilters);
    closeDrawer && setDrawerVisible(false);
  };

  /**
   * This method is being called when are being applied the multiselect filter (category and subcategory) - Check the buttons inside the multiselect dropdown
   * @param key
   * @param values
   */
  const onChangeFilter = (key, values) => {
    onChangeQuery({
      ...query,
      [key]: values,
      current: 1
    });
  };

  /**
   * @description This method handle the remove of applied filters on Filters applied section - On the click of 'X' button this method is being called
   * @param tag
   */
  const onTagRemove = (tag) => {
    if (Array.isArray(query[tag.key])) {
      const updatedValues = query[tag.key].filter(
        (value) => tag.value !== value
      );
      onChangeQuery({
        ...query,
        [tag.key]: updatedValues.length ? updatedValues : null
      });
    } else {
      onChangeQuery({
        ...query,
        [tag.key]: null
      });
    }
  };

  const handleEdit = (record) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.EDIT_PRODUCT_FASHION, {
      id: record.id
    }));
  };

  const handleUpdateStatus = async (record) => {
    try {
      const oldStatusBool = Product.getStatusBooleanValue(record.published);
      await ProductsAPI.updateProductStatus(record.id, Product.getStatus(!oldStatusBool));
      message.success(text('onUpdateOk'));
      refreshData();
    } catch (e) {
      message.error(e);
    }
  };

  /**
   * @description Full search - for the moment handles only ean and it is not an autocomplete
   * @param value
   */
  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      text: value,
      current: 1
    });
  };

  const showDeletionModal = (product) => {
    setProductToBeDeleted(product);
  };

  const confirmProductDeletion = async () => {
    try {
      await ProductsAPI.deleteProduct(productToBeDeleted.id);
      clearAttributesCache();
      message.success(text('onDeleteOk'));
      refreshData();
    } catch (e) {
      message.error(e);
    } finally {
      resetProductToBeDeleted();
    }
  };

  return (
    <>
      <div ref={header} className='py-4'>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          {/* EAN Search */}
          <Col span={6}>
            <SearchInput
              autoFocus
              placeholder={text('searchPlaceholder')}
              onChangeText={handleFullTextSearch}
              defaultValue={query.text}
            />
          </Col>

          {/* Macrocategory */}
          <Col span={6}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MACRO_CATEGORY]}
              values={query[KEYS.MACRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MACRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MACRO_CATEGORY, null)}
              placeholder={text('macro')}
              className='m-2'
              keyProperty='key'
              labelProperty='value'
            />
          </Col>

          {/* Microcategory */}
          <Col span={6}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MICRO_CATEGORY]}
              values={query[KEYS.MICRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MICRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MICRO_CATEGORY, null)}
              placeholder={text('micro')}
              className='m-2'
            />
          </Col>
          <Col span={6}>
            <div className='flex justify-between'>
              <Button onClick={() => setDrawerVisible(true)}>
                {text('allFiltersButton')}
              </Button>
              {/* TODO enable once API fixed
              <Button
                loading={downloadFile.isLoading}
                onClick={() =>
                  downloadFile.call("/backoffice/export", "catalog.xlsx")
                }
                type="primary"
                className="uppercase"
              >
                Download .xls
              </Button> */}
            </div>
          </Col>
        </Row>
        {filterTags?.length > 0 &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: filterTags.length })}:</div>
            {filterTags.map((tag, i) => (
              <Tag
                className='cursorTag'
                key={`${tag.key}-${tag.label}-${i}`}
                closable
                onClose={() => onTagRemove(tag)}
                onClick={() => onTagRemove(tag)}
              >
                {tagLabel(tag, Product)}
              </Tag>
            ))}
          </Row>}
      </div>

      {!areProductsLoading && !products?.length
        ? (<CenteredText>{text('noProductsFound')}</CenteredText>)
        : (<FashionTable
            data={products}
            loading={areProductsLoading}
            pagination={pagination}
            onTableChange={onTableChange}
            onEdit={handleEdit}
            onDelete={showDeletionModal}
            onUpdateStatus={handleUpdateStatus}
           />)}

      <DeletionModal
        visible={!!productToBeDeleted}
        onOk={confirmProductDeletion}
        onCancel={resetProductToBeDeleted}
      >
        <div>{text('confirmDeletion', { name: productToBeDeleted?.name })}</div>
      </DeletionModal>

      <Drawer
        visible={!!drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
        placement='right'
        className='jc-drawer'
        title={text('addFilters')}
      >
        <ProductSideFilters
          currentFilters={query}
          availableFilters={availableFilters}
          onApply={onApplyFilters}
        />
      </Drawer>
    </>
  );
};
