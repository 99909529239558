import { Product } from 'src/services/Product.service';
import { AttributePayload } from 'src/modules/products/api/payloads/AttributePayload';

export class VariantUpdatePayload {
  constructor (variant, formData) {
    this.manufacturerCode = formData.manufacturerCode;
    this.attributeVariants = {};
    if (formData.size) {
      this.attributeVariants.size = new AttributePayload('size', formData.size.key);
    }
    if (formData.sizeScale) {
      this.attributeVariants.sizeScale = new AttributePayload('sizeScale', formData.sizeScale.key);
    }
    if (formData.color) {
      this.attributeVariants.color = new AttributePayload('color', formData.color.key);
    }

    this.prices = {
      sellingPrice: parseFloat(formData.price)
    };
    this.published = Product.getStatusBooleanValue(variant.published);
  }
}
