import React from 'react';
import { Select, Form } from 'antd';

export const SelectDepartment = ({
  label,
  options,
  onSelect,
  disabled,
  placeholder
}) => {
  return (
    <Form.Item
      name='department'
      label={label}
    >
      <Select
        className='w-full'
        options={options}
        onSelect={onSelect}
        disabled={disabled}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export const toDepartmentOption = (departmentKey) => {
  return {
    value: departmentKey,
    label: departmentKey
  };
};
