import React, { useState, useCallback } from 'react';
import { Row, Col, Button, message, Select, Checkbox, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { Routes } from 'src/router/Routes.helper';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { ConfirmationModal } from 'src/components/modals/ConfirmationModal';
import { PageTitle } from 'src/components/PageTitle';
import { LanguagesAPI } from 'src/modules/global/api/LanguagesAPI';
import { useLanguages } from '../../global/provider/languages.provider';
import { supportedLanguages } from 'src/constants/supportedLanguages';

const optionIncludesInput = (input, option) => (
  option.children.join('').toLowerCase().includes(input.toLowerCase())
);

export const PageCreateLanguages = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.languages/create', t);
  const translateLanguage = textFrom('languages', t);

  const { languages, defaultLanguage, refreshData } = useLanguages();
  const history = useHistory();

  const { Option } = Select;
  const [form] = useForm();
  const [isDefault, setIsDefault] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const showModal = useCallback(() => {
    const newModalTitle = text('modal.title');
    setModalTitle(newModalTitle);
    const newModalBody = text('modal.body', { default: defaultLanguage.label });
    setModalBody(newModalBody);
    setIsModalVisible(true);
  }, [setModalTitle, setModalBody, setIsModalVisible, text]);

  const submitForm = async () => {
    const values = form.getFieldsValue(true);
    const { isoCode } = values;
    setIsLoading(true);
    try {
      const newLanguage = {
        language: isoCode,
        defaultLanguage: isDefault
      };
      await LanguagesAPI.addNewLanguage(newLanguage);
      message.success(text('createOK'));
      refreshData();
      history.push(Routes.PATHS.LANGUAGES);
    } catch (e) {
      message.error(text('createKO'));
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = () => {
    if (isDefault) {
      showModal();
    } else {
      submitForm();
    }
  };

  const handleModalOk = useCallback(async () => {
    submitForm();
  }, []);

  const handleModalCancel = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const navigateToLanguagesPage = () => {
    history.push(Routes.PATHS.LANGUAGES);
  };

  const invertIsDefaultStatus = () => {
    setIsDefault(!isDefault);
  };

  return (
    <div className='py-4'>
      <ConfirmationModal
        isVisible={isModalVisible}
        title={modalTitle}
        body={modalBody}
        onConfirm={handleModalOk}
        onDismiss={handleModalCancel}
      />
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        initialValues={{
          isoCode: null,
          isDefaultLanguage: null
        }}
        scrollToFirstError
        onFinish={onFinish}
      >
        <PageTitle>{text('createNewLanguage')}</PageTitle>
        <BackButton
          iconChevron
          className='mt-4 mb-8'
          onClick={navigateToLanguagesPage}
          title={text('backToLanguages')}
        />
        <Row gutter={20} className='pt-2 mb-4'>
          <Col xs={8}>
            {/* isoCode */}
            <Form.Item
              name='isoCode'
              label={text('language')}
              className='w-full px-4 pb-4'
              rules={[
                {
                  required: true,
                  message: text('languageRequired')
                },
                {
                  validator: (_, value) => languages.some((lang) => lang?.language === value) ? Promise.reject(new Error(text('languageRequired'))) : Promise.resolve(),
                  message: text('alreadyPresent')
                }
              ]}
            >
              <Select
                showSearch
                bordered
                allowClear
                className='w-full'
                label={text('languageCode')}
                placeholder={text('languagePlaceholder')}
                filterOption={optionIncludesInput}
              >
                {supportedLanguages.map((language) =>
                  <Option key={language.code} value={language.code}>
                    {translateLanguage(language.code)} - {language.code}
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={4}>
            {/* isDefaultLanguage */}
            <Form.Item
              label=' '
              name='isDefaultLanguage'
              required={false}
            >
              <div className='checkbox-one-line'>
                <div>{text('makeDefault')}</div>
                <Checkbox
                  checked={isDefault}
                  onClick={invertIsDefaultStatus}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        {/* Actions */}
        <div className='flex items-center w-full '>
          <Button
            type='default'
            htmlType='button'
            className='uppercase mr-2 ml-auto'
            onClick={navigateToLanguagesPage}
          >
            {text('cancel')}
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            className='uppercase ml-2'
            loading={isLoading}
            disabled={isLoading}
          >
            {text('saveNewLanguage')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
