import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

const optionalColumn = (condition, column) =>
  (condition
    ? [column]
    : []
  );

export const FashionOrderTable = ({
  onRow,
  orders,
  loading,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.orders-fashion', t);
  const statusText = textFrom('constants.fashionOrderStatuses', t);
  const { canSeeCustomerData } = useAuth();

  const columns = [
    {
      title: text('orderDate'),
      dataIndex: 'placeDate',
      key: 'placeDate',
      render: (date) => (
        <div className='flex items-center space-x-2'>
          {moment(date).format('HH:mm DD MMM YYYY')}
        </div>
      )
    },
    {
      title: text('orderNumber'),
      dataIndex: 'orderIdentifier',
      key: 'orderIdentifier'
    },
    ...optionalColumn(canSeeCustomerData,
      {
        title: text('customer'),
        dataIndex: ['customer', 'givenName'],
        key: ['customer', 'givenName'],
        render: (_, record) => (
          <span>{`${record.customer.givenName} ${record.customer.familyName}`}</span>
        )
      }
    ),
    {
      title: text('products'),
      dataIndex: ['id'],
      key: ['id'],
      render: (_, record) => (
        <span>{record.itemQuantity}</span>
      )
    },
    {
      title: text('totalAmount'),
      dataIndex: ['totals', 'finalGrandTotal'],
      key: ['totals', 'finalGrandTotal'],
      render: (_, record) => (
        <span>{`€ ${record.totals.finalGrandTotal}`}</span>
      )
    },
    {
      title: text('status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, record) =>
        <span>{record.statusTranslated || statusText(record.status)}</span>
    }
  ];

  return (
    <Table
      onRow={onRow}
      loading={loading}
      columns={columns}
      dataSource={orders}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
    />
  );
};
