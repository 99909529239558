import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { OutfitTable } from 'src/modules/outfits/components/OutfitTable';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useOutfits } from 'src/modules/outfits/provider/outfits.provider';
import debounce from 'lodash/debounce';
import { Chips } from 'src/components/Chips';

export const SearchOutfits = ({ outfits, setLinkedOutfits, mapper }) => {
  const { t } = useTranslation();
  const text = textFrom('components.searchOutfit', t);
  const textFilter = textFrom('constants.filter', t);

  const { pagination, onChangeQuery, query, areOutfitsLoading } = useOutfits();

  const [selectedOutfitKeys, setSelectedOutfitKeys] = useState([]);

  const addSelectedOutfits = () => {
    const selectedOutfits = selectedOutfitKeys.map(outfitKey => outfits.find(outfit => outfit.id === outfitKey));
    const newSelectedOutfits = mapper ? selectedOutfits.map(mapper) : selectedOutfits;
    setLinkedOutfits(prev => [...prev, ...newSelectedOutfits]);
    setSelectedOutfitKeys([]);
  };

  const handleSearchOutfitFilter = (text) => {
    onChangeQuery({ ...query, text: text });
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const onTagRemove = () => {
    handleSearchOutfitFilter('');
  };

  const OutfitSearch = () => (
    <SearchInput
      defaultValue={query?.text}
      placeholder={text('searchPlaceholder')}
      onChangeText={debounce((value) => handleSearchOutfitFilter(value), 500)}
    />
  );

  return (
    <>
      <Row justify='space-between'>
        <Col flex='1' span={4}>
          {text('searchLabel')}
          <OutfitSearch />
        </Col>
        <Col>
          <Button
            type='primary'
            className='uppercase mt-5'
            onClick={addSelectedOutfits}
          >
            {text('add')}
          </Button>
        </Col>
      </Row>
      {!!query?.text &&
        <Row className='py-4'>
          <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!query.text && +1) })}:
            <Chips
              query={query}
              chipKeys={['text']}
              onTagRemove={onTagRemove}
            />
          </div>
        </Row>}
      <OutfitTable
        isEditModeOn
        className='pt-8'
        outfits={outfits}
        pagination={pagination}
        loading={areOutfitsLoading}
        onTableChange={onTableChange}
        selectedOutfitKeys={selectedOutfitKeys}
        setSelectedOutfitKeys={setSelectedOutfitKeys}
      />
    </>
  );
};
