import React, { useRef, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Drawer,
  Tag,
  DatePicker
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useFilterTags } from 'src/hooks/useFilterTags';
// import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { ProductSideFilters } from 'src/modules/products/components/ProductSideFilters';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { EventTable } from 'src/modules/products/components/EventTable';
import { CustomMultiselect } from 'src/components/inputs/CustomMultiselect';
import { ProductsListPayload } from 'src/modules/products/api/payloads/ProductsListPayload';
import { ProductsQuery } from 'src/modules/products/domain/models/ProductsQuery';
import { Routes } from 'src/router/Routes.helper';
import { EventsAPI as ProductsAPI } from 'src/modules/products/api/EventsAPI';
import { Product } from 'src/services/Product.service';
import { message } from 'src/services/Messages.service';
import moment from 'moment';
import { SmallDashOutlined } from '@ant-design/icons';
import { useEvents } from '../provider/events.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { CenteredText } from 'src/components/CenteredText';
import { DeletionModal } from 'src/components/DeletionModal';
import { tagLabel, isAfter } from 'src/utils/utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

const { RangePicker } = DatePicker;

const { API_FILTERS_KEYS } = ProductsListPayload;
const { KEYS } = ProductsQuery;

export const PagePastEvents = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.events/past', t);
  const textFilter = textFrom('constants.filter', t);

  const header = useRef();
  const history = useHistory();

  const isAfterToday = isAfter(moment('00:00', 'h:mm').utc());
  const { canCreateEvents } = useAuth();

  // TODO enable once API fixed
  // const downloadFile = useDownloadFile({
  //   onError (err) {
  //     message.error(
  //       err.response?.data?.detail || err.response?.data || err.message
  //     );
  //   }
  // });
  const {
    query,
    products,
    availableFilters,
    pagination,
    onChangeQuery,
    setProducts,
    areEventsLoading,
    refreshData
  } = useEvents();

  const [eventToBeDeleted, setEventToBeDeleted] = useState(undefined);

  const searchParameters = ['current', 'pageSize'];
  const filterTags = useFilterTags(
    query,
    searchParameters,
    availableFilters?.filters || {}
  );

  const [drawerVisible, setDrawerVisible] = useState(false);

  const updateProductStatus = async (event) => {
    try {
      const oldStatusBool = Product.getStatusBooleanValue(event.published);
      await ProductsAPI.updateProductStatus(event.id, Product.getStatus(!oldStatusBool));
      refreshData();
      message.success(event.published === 'Published' ? text('onEventChangedToDraft') : text('onEventChangedToPublished'));
    } catch (e) {
      message.error(e);
    }
  };

  /**
   * @description This method handle the table data in case user changes the page or change the total items displayed on that page
   * @param {{
   *  current: string
   *  pageSize: number
   * }} config
   * @returns {string}
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  /**
   * @description This method handle the click on apply filter button inside the drawer. In the drawer are being added all the filters including even category and subcategory (apart ean)
   * @param filters
   * @param closeDrawer
   */
  const onApplyFilters = (filters, closeDrawer = false) => {
    const newFilters = { ...filters };

    if (filters.published !== undefined && filters.published !== null) {
      newFilters.published = Product.getStatusBooleanValue(filters.published);
    }
    newFilters.current = 1;

    onChangeQuery(newFilters);
    closeDrawer && setDrawerVisible(false);
  };

  /**
   * This method is being called when are being applied the multiselect filter (category and subcategory) - Check the buttons inside the multiselect dropdown
   * @param key
   * @param values
   */
  const onChangeFilter = (key, values) => {
    onChangeQuery({
      ...query,
      [key]: values,
      current: 1
    });
  };

  /**
   * @description This method handle the remove of applied filters on Filters applied section - On the click of 'X' button this method is being called
   * @param tag
   */
  const onTagRemove = (tag) => {
    if (Array.isArray(query[tag.key])) {
      const updatedValues = query[tag.key].filter(
        (value) => tag.value !== value
      );
      onChangeQuery({
        ...query,
        [tag.key]: updatedValues.length ? updatedValues : null
      });
    } else {
      onChangeQuery({
        ...query,
        [tag.key]: null
      });
    }
  };

  const handleEdit = (record) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.EDIT_PRODUCT_EVENT, {
      id: record.id
    }));
  };

  /**
   * @description Handle the inline update
   * @param form
   * @param recordID
   * @returns {Promise<void>}
   */
  const handleUpdate = async (form, record) => {
    // Add also brand when update inline
    const payload = {
      ...form,
      brand: {
        id: record.brandDetails.id,
        key: record.brandDetails.key
      }
    };
    try {
      setProducts((products) =>
        products.map((product) => {
          if (product.id !== record.id) return product;
          return { ...record, ...form, isUpdatePending: true };
        })
      );
      await ProductsAPI.updateProductStatus(record.id, form.published);
      const updatedRecord = await ProductsAPI.updateProduct(record.id, payload);

      setProducts((products) =>
        products.map((product) => {
          if (product.id !== updatedRecord.id) return product;
          return {
            ...product,
            ...updatedRecord,
            published: Product.getStatus(updatedRecord.published),
            isUpdatePending: false
          };
        })
      );
      message.success(text('onUpdateOk'));
    } catch (e) {
      message.error(e);
    }
  };

  /**
   * @description Full search - for the moment handles only ean and it is not an autocomplete
   * @param value
   */
  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      text: value,
      current: 1
    });
  };

  const onDelete = (event) => {
    setEventToBeDeleted(event);
  };

  const confirmEventDeletion = async () => {
    try {
      await ProductsAPI.deleteProduct(eventToBeDeleted.id);
      refreshData();
      message.success(text('onDeleteOk'));
    } catch (e) {
      message.success(text('onDeleteErr'));
    } finally {
      resetEventToBeDeleted();
    }
  };

  const resetEventToBeDeleted = () => {
    setEventToBeDeleted(undefined);
  };

  return (
    <div>
      <div ref={header} className='py-4 bg-gray-100'>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          {/* EAN Search */}
          <Col span={6}>
            <SearchInput
              autoFocus
              placeholder={text('searchPlaceholder')}
              onChangeText={handleFullTextSearch}
              defaultValue={query.text}
            />
          </Col>
          <Col span={4}>
            <RangePicker
              disabledDate={isAfterToday}
              onChange={val => {
                if (val) {
                  const startDate = moment(val[0]).set('hour', 0).set('minute', 0).set('second', 0).utc().format();
                  const endDate = moment(val[1]).set('hour', 23).set('minute', 59).set('second', 59).utc().format();
                  onChangeQuery({
                    ...query,
                    startDate,
                    endDate,
                    current: 1
                  });
                } else {
                  onChangeQuery({
                    ...query,
                    startDate: undefined,
                    endDate: undefined,
                    current: 1
                  });
                }
              }}
            />
          </Col>
          {/* Status */}
          <Col span={4}>
            <CustomMultiselect
              items={
                availableFilters.filters[API_FILTERS_KEYS.PUBLISHED] &&
                availableFilters.filters[API_FILTERS_KEYS.PUBLISHED].map(
                  (item) =>
                    item.key === 'true'
                      ? { key: item.key, value: 'Published' }
                      : { key: item.key, value: 'Draft' }
                )
              }
              values={query[KEYS.PUBLISHED]}
              onApply={(values) => onChangeFilter(KEYS.PUBLISHED, values.length > 1 ? null : values)}
              onReset={() => onChangeFilter(KEYS.PUBLISHED, null)}
              placeholder={text('statusPlaceholder')}
              className='m-2'
            />
          </Col>
          <Col span={4}>
            <Button onClick={() => setDrawerVisible(true)}>
              {text('moreFiltersButton')} <SmallDashOutlined />
            </Button>
          </Col>
          <Col span={6}>
            <div className='flex justify-end space-x-6'>
              {/* TODO enable once API fixed
              <Button
                loading={downloadFile.isLoading}
                onClick={() =>
                  downloadFile.call('/backoffice/export', 'catalog.xlsx')
                }
                type='link'
                className='uppercase text-indio-500'
              >
                Download .xls
              </Button> */}
              {canCreateEvents &&
                <Button
                  type='primary'
                  className='uppercase'
                  onClick={() => history.push(Routes.PATHS.CREATE_PRODUCT_EVENT)}
                >
                  {text('createNewButton')}
                </Button>}
            </div>
          </Col>
        </Row>
        {filterTags?.length > 0 &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: filterTags.length })}:</div>
            {filterTags.map((tag, i) => (
              <Tag
                closable
                key={`${tag.key}-${tag.label}-${i}`}
                onClose={() => onTagRemove(tag)}
              >
                {tagLabel(tag, Product)}
              </Tag>
            ))}
          </Row>}
      </div>

      {!areEventsLoading && !products?.length
        ? (<CenteredText>{text('noEventsFound')}</CenteredText>)
        : (<EventTable
            loading={areEventsLoading}
            tableData={products}
            pagination={{ ...pagination, showSizeChanger: false }}
            onTableChange={onTableChange}
            onUpdateRecord={handleUpdate}
            deleteMessage={text('table.deleteMessage')}
            updateProductStatus={updateProductStatus}
            onEdit={handleEdit}
            onDelete={onDelete}
           />)}

      <DeletionModal
        visible={!!eventToBeDeleted}
        onOk={confirmEventDeletion}
        onCancel={resetEventToBeDeleted}
      >
        <div>{text('confirmDeletion', { name: eventToBeDeleted?.name })}</div>
      </DeletionModal>

      <Drawer
        visible={!!drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
        placement='right'
        className='jc-drawer'
        title={text('addFilters')}
      >
        <ProductSideFilters
          currentFilters={query}
          availableFilters={availableFilters}
          onApply={onApplyFilters}
        />
      </Drawer>
    </div>
  );
};
