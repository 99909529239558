import { AxiosClient } from './AxiosClient';

export class UtilsAPI {
  static async uploadImage (file) {
    const fmData = new window.FormData();
    fmData.append('file', file);
    return AxiosClient.post('/files/images',
      fmData
      , {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((res) => res.data);
  }
}
