import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useFilters } from 'src/hooks/useFilters';
import { usePagination } from 'src/hooks/usePagination';
import { useLocation } from 'react-router-dom';
import { OrdersAPI as ProductsAPI } from 'src/modules/orders/api/OrdersAPI';
import { OrdersMapper as ProductsMapper } from 'src/modules/orders/domain/Orders.mapper';
import moment from 'moment';
import { message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Routes } from 'src/router/Routes.helper';

export const ordersContext = createContext();

const useProvideOrders = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(ProductsMapper);
  // TODO use department detection when available also for admins

  const upcomingOrders = location.pathname.includes(Routes.PATHS.UPCOMING_ORDERS_EVENT);

  const auth = useAuth();

  const availableFilters = { filters: [] };
  const orderType = 'event';

  /**
   * @description Get list of orders
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getOrders = useCallback(async () => {
    const filters = {
      department: [orderType]
    };
    const businessId = auth?.userInfo?.localBusiness?.id;
    if (businessId) {
      filters.local_business_id = businessId;
    }

    try {
      const res = await ProductsAPI.getOrders({
        filters,
        ranges: [
          {
            key: orderType === 'event' ? 'event_date' : 'pickup_date',
            start: upcomingOrders
              ? moment(new Date()).utc().startOf('day').format('YYYY-MM-DD:HH:mm:ss')
              : '1900-01-01',
            end: upcomingOrders
              ? '2099-01-02'
              : moment(new Date()).utc().subtract(1, 'days').endOf('day').format('YYYY-MM-DD:HH:mm:ss'),
            gap: '+9999 YEAR'
          }
        ],
        limit: query?.pageSize || 10,
        pageNumber: +query.current === 0 ? 0 : +query.current - 1,
        orderingList: [
          {
            key: 'update_date',
            value: 'descending'
          }
        ],
        text: query.text || ''
      });

      setProducts(res.data.facetPage.content);
      setPagination({
        itemsPerPage: res.data.facetPage.size,
        totalItems: res.data.facetPage.totalElements,
        startIndex: res.data.facetPage.number * res.data.facetPage.size
      });
    } catch (e) {
      message.error('Something went wrong!');
    }
  }, [query, setPagination, location]);

  useEffect(() => {
    getOrders();
  }, [location.search, getOrders]);

  return {
    query,
    products,
    setProducts,
    availableFilters,
    pagination,
    onChangeQuery,
    refreshData: getOrders,
    orderType
  };
};

export const OrdersProvider = ({ children }) => {
  const orders = useProvideOrders();
  return (
    <ordersContext.Provider value={orders}>{children}</ordersContext.Provider>
  );
};

export const useOrders = () => useContext(ordersContext);
