import React, { useEffect } from 'react';
import { BackButton } from 'src/components/BackButton';
import { Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useForm } from 'antd/lib/form/Form';
import { WebhookAPI } from '../api/webhookAPI';
import { useWebhooks } from '../provider/webhook.provider';
import { Routes } from 'src/router/Routes.helper';
import { PageTitle } from 'src/components/PageTitle';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { WebhookForm } from '../component/WebhookForm';

const HTTPS = 'https://';
const payloadURLParsingRule = /.*(:\/\/)/;

export const PageEditWebhook = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.webhooks/edit', t);

  const [form] = useForm();
  const history = useHistory();
  const { webhook } = useWebhooks();

  const navigateToPageWebhooks = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.WEBHOOKS));
  };

  const onCancel = () => {
    navigateToPageWebhooks();
  };

  useEffect(() => {
    if (webhook) {
      const {
        clientId,
        url,
        contexts
      } = webhook;
      form.setFieldsValue({
        payloadURL: url.replace(payloadURLParsingRule, ''),
        clientId: clientId,
        contexts
      });
    }
  }, [webhook]);

  const onFinish = async ({ payloadURL, contexts = [] }) => {
    if (contexts.length === 0) {
      message.error(text('onMissingEvent'));
      return;
    }
    const webhookPayload = {
      ...webhook,
      contexts,
      url: `${HTTPS}${payloadURL}`
    };
    try {
      await WebhookAPI.updateWebhook(webhookPayload);
      message.success(text('webhookUpdateOK'));
      navigateToPageWebhooks();
    } catch (e) {
      message.error(text('webhookUpdateKO'));
    }
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton label={text('cancel')} onClick={onCancel} />
      </Col>
      <Col>
        <SubmitButton label={text('editWebhook')} />
      </Col>
    </Row>
  );

  return (
    <div className='py-4'>
      <PageTitle>{text('editWebhookConfigurator')}</PageTitle>
      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToPageWebhooks}
        title={text('backButton')}
      />
      <WebhookForm
        form={form}
        mode='edit'
        buttons={buttons}
        onFinish={onFinish}
        initialContexts={webhook?.contexts}
      />
    </div>
  );
};
