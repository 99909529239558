export class LocalBusinessesQuery {
    limit;
    pageNumber;
    text;

    static get KEYS() {
        return {
          LOCAL_BUSINESS_ID: "localBusinessId",
          APPLICATION_CODE: "applicationCode"
        };
      }
};