import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Select, Button, DatePicker, message } from 'antd';
import { useAttributes } from 'src/hooks/useProvideAttributes';
import { InputSelectInfinite } from 'src/components/inputs/InputSelectInfinite';
import { InputWithAutocomplete } from 'src/components/inputs/InputWithAutocomplete';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const { Option } = Select;

export const ProductAttribute = ({ departments, currentDepartment, value, onChange, onDelete, className, disabledDate, alreadyChosenValues, language }) => {
  const { t } = useTranslation();
  const text = textFrom('components.productAttribute', t);

  const { getAttributes, attributeCodes, fetchAttributeCodes } = useAttributes();
  const [attributeCodesToShowInSelect, setAttributeCodesToShowInSelect] = useState([]);

  useEffect(() => {
    fetchAttributeCodes(currentDepartment);
  }, [currentDepartment]);

  useEffect(async () => {
    try {
      if (attributeCodes?.length > 0) {
        const allDepartmentsDeclareVariants = [];
        departments.forEach(department => {
          allDepartmentsDeclareVariants.push(...department?.declareVariants);
        });

        const codesAlreadyIn = [];
        alreadyChosenValues.forEach(code => {
          code?.code && codesAlreadyIn.push(code.code.toLowerCase());
        });

        const newAttributeCodesToShowInSelect = attributeCodes?.filter((key) => {
          return !codesAlreadyIn.includes(key.toLowerCase()) && !allDepartmentsDeclareVariants.includes(key);
        }
        );
        setAttributeCodesToShowInSelect(newAttributeCodesToShowInSelect);
      }
    } catch (e) {
      message.error(text('attributesKO'));
    }
  }, [attributeCodes, departments, alreadyChosenValues]);

  const onLoadMore = useCallback(
    (page, language) => {
      return getAttributes(value.code, page, language?.language, currentDepartment);
    },
    [value.code, getAttributes, language, departments]
  );

  const onChangeCode = useCallback(
    (code) => {
      onChange({
        ...value,
        code,
        value: null
      });
    },
    [onChange, value]
  );

  const onChangeValue = useCallback(
    (v) => {
      onChange({
        ...value,
        value: v
      });
    },
    [onChange, value]
  );

  const getCorrectField = (value) => {
    switch (value.code) {
      case 'series_start_date':
      case 'series_end_date':
        return (
          <DatePicker
            getPopupContainer={trigger => trigger.parentElement}
            disabledDate={disabledDate}
            className='w-full'
            format='DD-MM-YYYY'
            onChange={onChangeValue}
            value={value.value || (value.key && moment(value.key)) || null}
            defaultValue={null}
            placeholder='DD-MM-YYYY'
          />
        );
      case 'series_id':
        return (
          <InputWithAutocomplete
            onChange={onChangeValue}
            placeholder='Series ID'
            value={value}
            onLoadMore={onLoadMore}
          />
        );

      default:
        return (
          <InputSelectInfinite
            value={value}
            language={language}
            onLoadMore={onLoadMore}
            onChange={onChangeValue}
            placeholder={text('selectPlaceholder')}
          />
        );
    }
  };

  return (
    <Row gutter={[16, 16]} className={className}>
      <Col span={8}>
        <Select
          getPopupContainer={trigger => trigger.parentNode}
          value={value.code}
          onChange={onChangeCode}
          className='w-full'
          placeholder={text('selectPlaceholder')}
        >
          {attributeCodesToShowInSelect?.map((code) => (
            <Option key={code} value={code}>
              {code}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={8}>
        {getCorrectField(value)}
      </Col>
      <Col span={8} className='relative'>
        <div className='absolute bottom-0'>
          <Button
            className='uppercase delete-attribute-button'
            type='link'
            onClick={onDelete}
          >
            {text('removeAttribute')}
          </Button>
        </div>
      </Col>
    </Row>
  );
};
