import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useUsers } from 'src/modules/admin/provider/users.provider';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { UsersAPI } from '../api/UsersAPI';
import { PageTitle } from 'src/components/PageTitle';
import { Routes } from 'src/router/Routes.helper';
import { UserForm } from '../component/UserForm';
import { LocalBusinessAPI } from '../api/localBusinessAPI';
import { isUserTypeFullyEditable } from '../utils/utils';
import { getPermissionSetsFromDepartment } from 'src/utils/utils';

const ROLES = {
  LB_MANAGER: 'ROLE_LOCAL_BUSINESS_MANAGER',
  SALES_ASSISTANT: 'ROLE_CLIENTELING_SALES_ASSISTANT'
};

async function getPermissionSets (oldRole, newRole, lbId) {
  switch (true) {
    case oldRole === ROLES.LB_MANAGER && newRole === ROLES.SALES_ASSISTANT:
      return [];
    case oldRole === ROLES.SALES_ASSISTANT && newRole === ROLES.LB_MANAGER: {
      const res = await LocalBusinessAPI.getLocalBusinessDetails(lbId);
      const deptKey = res?.data?.departments?.[0]?.key;
      return getPermissionSetsFromDepartment(deptKey);
    }
    default:
      return undefined;
  }
}

export const PageEditUser = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.users/edit', t);

  const history = useHistory();
  const { canFullyEditUsers } = useAuth();
  const { user } = useUsers();
  const [form] = useForm();
  const isFullEditEnabled = canFullyEditUsers || isUserTypeFullyEditable(user?.type);

  useEffect(() => {
    if (user) {
      const {
        email,
        familyName,
        givenName,
        active,
        localBusiness,
        username,
        type: role
      } = user;
      form.setFieldsValue({
        userFirstName: givenName,
        userLastName: familyName,
        username: username,
        userEmail: email,
        userLocalBusiness: { value: localBusiness?.id, label: localBusiness?.name },
        userRole: role,
        userIsActive: active
      });
    }
  }, [user]);

  const navigateToUsersPage = () => {
    history.push(Routes.PATHS.USERS);
  };

  const buttons = (
    <div className='flex items-center w-full mt-4'>
      <Button
        type='default'
        htmlType='button'
        className='uppercase mr-2 ml-auto'
        onClick={navigateToUsersPage}
      >
        {text('cancel')}
      </Button>
      <Button
        type='primary'
        htmlType='submit'
        className='uppercase ml-2'
      >
        {text('saveChanges')}
      </Button>
    </div>
  );

  const onFinish = async (values) => {
    const {
      userFirstName,
      userLastName,
      username,
      userEmail,
      userRole,
      userLocalBusiness,
      userIsActive
    } = values;
    const {
      email: originalEmail,
      username: originalUsername,
      type: originalRole,
      localBusiness: originalLocalBusiness,
      active: originalUserIsActive
    } = user;

    const permissionSets = await getPermissionSets(originalRole, userRole, userLocalBusiness?.value);

    try {
      const updatedUserData = {
        givenName: userFirstName,
        familyName: userLastName,
        email: isFullEditEnabled ? userEmail : originalEmail,
        username: isFullEditEnabled ? username : originalUsername,
        type: isFullEditEnabled ? userRole : originalRole,
        active: isFullEditEnabled ? userIsActive : originalUserIsActive,
        permissionSets,
        localBusiness: {
          id: isFullEditEnabled ? userLocalBusiness.value : originalLocalBusiness?.id
        }
      };
      await UsersAPI.updateUser(user.id, updatedUserData);
      message.success(text('updateOK'));
      navigateToUsersPage();
    } catch (e) {
      if (e?.response?.status === 409) {
        message.error(text('updateErrDuplicated'));
      } else {
        message.error(text('updateErr'));
      }
    }
  };

  return (
    <div className='py-4'>

      <PageTitle>{text('editUser')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToUsersPage}
        title={text('backToUsers')}
      />

      {user &&
        <UserForm
          form={form}
          buttons={buttons}
          onFinish={onFinish}
          initialUserIsActive={user.active}
          mode='edit'
        />}

    </div>
  );
};
