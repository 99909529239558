import React, { useEffect } from 'react';
import { Typography, Col, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const { Title } = Typography;

const REGEX_LATITUDE = /^-?(90(\.0+)?|([0-8]?[0-9])(\.\d+)?)$/;
const REGEX_LONGITUDE = /^-?(180(\.0+)?|\d?\d(\.\d+)?|(1[0-7]\d)(\.\d+)?)$/;

export const Position = ({ form, userInfo }) => {
  const { t } = useTranslation();
  const text = textFrom('components.position', t);

  useEffect(() => {
    form.setFieldsValue({
      xPosition: userInfo.localBusiness.xposition,
      yPosition: userInfo.localBusiness.yposition
    });
  }, [userInfo.localBusiness.xposition, userInfo.localBusiness.yposition]);

  return (
    <div form={form}>
      <div className='border-b border-gray-300 pb-8'>
        <Col xs={12}>
          <Title level={5} className='pb-6 py-8'>
            {text('position')}
          </Title>

          <Form.Item
            label={text('xPosition') + ':'}
            name='xPosition'
            required={false}
            rules={[
              {
                message: text('xPositionInvalid'),
                pattern: new RegExp(REGEX_LATITUDE)
              }
            ]}
          >
            <Input placeholder={text('xPosition')} />
          </Form.Item>

          <Form.Item
            label={text('yPosition') + ':'}
            name='yPosition'
            required={false}
            rules={[
              {
                message: text('yPositionInvalid'),
                pattern: new RegExp(REGEX_LONGITUDE)
              }
            ]}
          >
            <Input placeholder={text('yPosition')} />
          </Form.Item>
        </Col>
      </div>
    </div>
  );
};
