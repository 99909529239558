import React, { useMemo, useCallback } from 'react';
import { Table, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTableHeight } from 'src/hooks/useTableHeight';
import { Routes } from 'src/router/Routes.helper';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { MenuAction, PopoverActionMenu } from 'src/components/PopoverActionMenu';

export const LocationTable = ({
  tableData,
  pagination,
  onTableChange,
  loading
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.locations/list', t);

  const [form] = Form.useForm();
  const tableHeight = useTableHeight();
  const history = useHistory();

  const onStartEditing = useCallback((record) => {
    history.push({
      pathname: `${Routes.PATHS.LOCATIONS}/${record.id}`
    });
  }, [form]);

  const handleDuplicate = useCallback(
    (record) => {
      history.push(`${Routes.PATHS.LOCATIONS}/${record.id}`, { location: record, mode: 'duplicate' });
    },
    [history]
  );

  const columns = useMemo(
    () => [
      {
        title: text('table.cols.locationName'),
        key: 'updateDate',
        width: '20%',
        render: (_, record) => `${record?.name ?? ''}`
      },
      {
        width: '25%',
        title: text('table.cols.locationKey'),
        render: (_, record) => `${record?.key ?? ''}`
      },
      {
        width: '15%',
        title: text('table.cols.locationCity'),
        render: (_, record) => `${record?.address?.city ?? ''}`
      },
      {
        title: text('table.cols.locationCountry'),
        width: '20%',
        render: (_, record) => `${record?.address?.country ?? ''}`
      },
      {
        title: text('table.cols.locationAddress'),
        width: '30%',
        render: (_, record) => `${record?.address?.streetAddress ?? ''}`
      },
      {
        title: '',
        dataIndex: 'operation',
        align: 'center',
        width: 200,
        render: (_, record) => {
          return (
            <PopoverActionMenu>
              <MenuAction onClick={() => onStartEditing(record)} label={text('edit')} />
              <MenuAction onClick={() => handleDuplicate(record)} label={text('duplicate')} />
            </PopoverActionMenu>
          );
        }
      }
    ], []);

  return (
    <Form form={form} component={false}>
      <Table
        sticky
        scroll={{ y: tableHeight }}
        rowKey='id'
        loading={loading}
        dataSource={tableData}
        columns={columns}
        pagination={pagination}
        onChange={onTableChange}
        rowClassName={(record) => (record.isUpdatePending ? 'opacity-50' : '')}
      />
    </Form>
  );
};
