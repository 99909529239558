import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import moment from 'moment';
import { CustomersAPI } from '../api/CustomersAPI';
import { PageTitle } from 'src/components/PageTitle';
import { Routes } from 'src/router/Routes.helper';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { CustomerForm } from '../component/CustomerForm';

export const PageCreateCustomer = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.customer/create', t);

  const [form] = useForm();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [billingDistrictsList, setBillingDistrictsList] = useState([]);
  const [shippingDistrictsList, setShippingDistrictsList] = useState([]);

  const goToCustomersPage = () => {
    history.push(Routes.PATHS.CUSTOMERS);
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton
          onClick={goToCustomersPage}
          label={text('cancel')}
        />
      </Col>
      <Col>
        <SubmitButton
          loading={isLoading}
          disabled={isLoading}
          label={text('submit')}
        />
      </Col>
    </Row>
  );

  const onFinish = async ({
    customerFirstName,
    customerLastName,
    customerBirthDate,
    customerGender,
    customerTelNumber,
    customerEmail,
    customerBillingCountry,
    customerBillingStateOrProvince,
    customerBillingCity,
    customerBillingAddress,
    customerBillingStreetNumber,
    customerBillingZipCode,
    customerShippingCountry,
    customerShippingStateOrProvince,
    customerShippingCity,
    customerShippingAddress,
    customerShippingStreetNumber,
    customerShippingZipCode
  }) => {
    const customerData = {
      primaryEmail: customerEmail,
      username: customerEmail,
      givenName: customerFirstName,
      familyName: customerLastName,
      password: Math.random().toString(36).slice(-8),
      birthDate: Number(moment(customerBirthDate).format('x')),
      gender: customerGender,
      telephone: customerTelNumber
    };
    const billingData = {
      city: customerBillingCity,
      country: customerBillingCountry,
      state: customerBillingStateOrProvince,
      familyName: customerLastName,
      givenName: customerFirstName,
      phone: customerTelNumber,
      postalCode: customerBillingZipCode,
      streetAddress: customerBillingAddress,
      streetNumber: customerBillingStreetNumber
    };
    const shippingData = {
      city: customerShippingCity,
      country: customerShippingCountry,
      state: customerShippingStateOrProvince,
      familyName: customerLastName,
      givenName: customerFirstName,
      phone: customerTelNumber,
      postalCode: customerShippingZipCode,
      streetAddress: customerShippingAddress,
      streetNumber: customerShippingStreetNumber
    };
    setIsLoading(true);
    try {
      const createCustomerRes = await CustomersAPI.createCustomer(customerData);
      await CustomersAPI.addBillingDataToCustomer(createCustomerRes.data.data.id, billingData);
      await CustomersAPI.addShippingDataToCustomer(createCustomerRes.data.data.id, shippingData);
      message.success(text('customerCreationSuccess'));
      goToCustomersPage();
    } catch (e) {
      message.error(text('customerCreationError'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={goToCustomersPage}
      />

      <CustomerForm
        form={form}
        mode='create'
        buttons={buttons}
        onFinish={onFinish}
        billingDistrictsList={billingDistrictsList}
        setBillingDistrictsList={setBillingDistrictsList}
        shippingDistrictsList={shippingDistrictsList}
        setShippingDistrictsList={setShippingDistrictsList}
      />

    </div>
  );
};
