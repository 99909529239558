import React, { useCallback } from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const { Link } = Typography;

export const BackButton = ({
  title,
  onClick,
  className,
  iconChevron
}) => {
  const history = useHistory();

  const navigateToPage = useCallback(() => {
    if (onClick) {
      onClick();
    } else {
      history.goBack();
    }
  }, [onClick, history]);

  return (
    <div className={`${className} as-wide-as-content`}>
      <Link
        onClick={navigateToPage}
        className='uppercase flex items-center'
      >
        {iconChevron
          ? (<LeftOutlined className='pr-1' />)
          : (<ArrowLeftOutlined className='pr-1' />)}
        {title}
      </Link>
    </div>
  );
};
