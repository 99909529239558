import React, { useEffect, useState } from 'react';
import { Row, Col, message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { WarehousesAPI } from '../api/WarehousesAPI';
import { WarehouseForm } from '../components/WarehouseForm';
import { useTranslation } from 'react-i18next';

export const PageCreateWarehouse = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.warehouses/create', t);

  const [form] = useForm();
  const history = useHistory();
  const { userInfo } = useAuth();
  const [isPending, setIsPending] = useState(false);

  const navigateToWarehousesPage = () => {
    history.push(Routes.PATHS.WAREHOUSES);
  };

  useEffect(() => {
    form.setFieldsValue({ ecommerce: true });
  }, [form]);

  const onFinish = async (values) => {
    setIsPending(true);
    const data = {
      name: values.name,
      type: values.type,
      company: values.companyName,
      taxId: values.taxId,
      address: {
        streetAddress: values.companyAddress
      },
      email: values.companyEmail,
      ecommerce: values.ecommerce,
      fulfillmentPriority: values.priority,
      backofficeUsers: [userInfo.email],
      externalWarehouseIdentifier: values.externalWarehouseIdentifier
    };
    try {
      await WarehousesAPI.createWarehouse(data);
      message.success(text('onCreateOK'));
      navigateToWarehousesPage();
    } catch (e) {
      message.error(text('onCreateKO'));
    } finally {
      setIsPending(false);
    }
  };

  // FIXME: remove this when type=MANUAL must be no longer hardcoded
  useEffect(() => {
    if (form) {
      form.setFieldsValue({ type: 'MANUAL' });
    }
  }, [form]);

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton label={text('cancel')} onClick={navigateToWarehousesPage} />
      </Col>
      <Col>
        <SubmitButton label={text('createNewWarehouse')} loading={isPending} disabled={isPending} />
      </Col>
    </Row>
  );

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={navigateToWarehousesPage}
      />

      <WarehouseForm
        form={form}
        buttons={buttons}
        onFinish={onFinish}
        initialEcommerceIsChecked
      />
    </div>
  );
};
