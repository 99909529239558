import { AxiosClient } from 'src/api/AxiosClient';

export class OutfitsAPI {
  static async getOutfits (data) {
    return AxiosClient.get('/backoffice/outfits',
      {
        params: {
          limit: data?.limit,
          pageNumber: data?.pageNumber,
          text: data?.filters?.text
        }
      }
    );
  }

  static async getOutfitByID (id) {
    return AxiosClient.get(`/backoffice/outfits/${id}`);
  }

  static async createOutfit (data) {
    return AxiosClient.post('/backoffice/outfits', data);
  }

  static async editOutfit (id, data) {
    return AxiosClient.put(`/backoffice/outfits/${id}`, data);
  }

  static async changeOutfitStatus (id, status) {
    return AxiosClient.put(`/backoffice/outfits/${id}/publish/${status}`);
  }

  static async deleteOutfit (outfitId) {
    return AxiosClient.delete(`/backoffice/outfits/${outfitId}`);
  }
}
