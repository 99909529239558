import React, { useState } from 'react';
import { Row, Col, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useLanguages } from '../../global/provider/languages.provider';
import { PageTitle } from 'src/components/PageTitle';
import { DeletionModal } from 'src/components/DeletionModal';
import { useHistory } from 'react-router-dom';
import { Routes } from 'src/router/Routes.helper';
import { LanguageTable } from '../component/LanguageTable';
import { CenteredText } from 'src/components/CenteredText';

export const PageLanguages = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.languages', t);
  const history = useHistory();
  const { languages, makeLanguageDefault, deleteLanguage, areLanguagesLoading } = useLanguages();
  const [languageToBeDeleted, setLanguageToBeDeleted] = useState(undefined);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);

  const setThisLangAsDefault = async (record) => {
    try {
      await makeLanguageDefault(record);
      message.success(text('defaultOK'));
    } catch (e) {
      message.error(text('defaultKO'));
    }
  };

  const showDeletionModal = (lang) => {
    setLanguageToBeDeleted(lang);
    setIsDeletionModalVisible(true);
  };

  const handleDelete = async () => {
    await deleteLanguage(languageToBeDeleted);
  };

  const navigateToCreateLanguage = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.LANGUAGES_CREATE));
  };

  return (
    <>
      <div className='py-4 bg-gray-100'>
        <PageTitle>{text('languages')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={8} />
          <Col span={16}>
            <div className='flex justify-end'>
              <Button
                className='uppercase'
                type='primary'
                onClick={navigateToCreateLanguage}
              >
                {text('addLanguage')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      {!areLanguagesLoading && !languages?.length
        ? (<CenteredText>{text('nolanguagesFound')}</CenteredText>)
        : (<LanguageTable
            loading={areLanguagesLoading}
            languages={languages}
            onSetAsDefault={setThisLangAsDefault}
            onDelete={showDeletionModal}
           />)}

      <DeletionModal
        onOk={handleDelete}
        visible={isDeletionModalVisible}
        onCancel={() => setIsDeletionModalVisible(false)}
        msgOnSuccess={text('deleteOK')}
        msgOnError={text('deleteKO')}
      >
        <div>{text('askConfirmation')}</div>
      </DeletionModal>

    </>
  );
};
