import { Filters } from 'src/services/Filters.service';
import { FolderHistoryQuery } from 'src/modules/upload/domain/models/FolderHistoryQuery';
import { PayloadListAbstract } from 'src/domain/PayloadListAbstract';

export class CategoriesMapper {
  static fromQueryToQueryString (query) {
    // alert('fromQueryToQueryString');
    const newQuery = { ...query };
    !query?.search && delete newQuery.search;
    const search = Filters.removeFalsyValues(newQuery);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    // alert('fromQueryStringToQuery');
    const query = new URLSearchParams(qs);
    const folderHistoryQueryModel = new FolderHistoryQuery();
    folderHistoryQueryModel.current = query.get('current') || 1;
    folderHistoryQueryModel.pageSize = query.get('pageSize') || 10;
    folderHistoryQueryModel.search = query.get('search') || '';
    return folderHistoryQueryModel;
  }

  static fromQueryToPayload (query) {
    // alert('fromQueryToPayload');
    const payload = new PayloadListAbstract();
    payload.pageNumber = query.current > 0 ? query.current : 1;
    payload.pageSize = query.pageSize || 10;
    payload.search = query.search || '';
    return payload.getCleanPayload();
  }
}
