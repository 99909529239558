import React, { useCallback } from "react";
import { Row, Col, Select, Button, Input, Form } from "antd";
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const { Option } = Select;

export const AdditionalProperty = ({
    value,
    availableTypes,
    onChange,
    onDelete,
    className,
    selectedLanguage,
 }) => {
    const { t } = useTranslation();
    const text = textFrom('components.additionalProperty', t);

    const onChangeProperty = useCallback(
        (type) => {
            onChange({
                ...value,
                type,
                value: null,
            });
        },
        [onChange, value]
    );

    const onChangeName = (newName) => {
        const newValue = {...value};
        newValue.name[selectedLanguage] = newName;
        onChange({ ...newValue });
    };

    const onChangeDescription = (newDescription) => {
        const newValue = {...value};
        newValue.description[selectedLanguage] = newDescription;
        onChange({ ...newValue });
    };

    const onChangeValue = (property, v) => {
        const newValue = {...value};
        newValue[property] = v;
        onChange({ ...newValue });
    };

    const capitalizedLanguage = selectedLanguage.toUpperCase();

    return (
        <Row gutter={[24, 16]} className={className}>
            <Col span={4}>
                <Form.Item 
                    label="Type"
                    required
                    rules={[{ required: true, message: 'Please select a type' }]}>
                <Select
                    getPopupContainer={trigger => trigger.parentNode}
                    value={value.type}
                    onChange={onChangeProperty}
                    className="w-full"
                    placeholder={text('selectPlaceholder')}
                >
                    {availableTypes.map((type) => (
                        <Option key={type} value={type}>
                            {type}
                        </Option>
                    ))}
                </Select>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label={`${text('name')} (${capitalizedLanguage})`}>
                    <Input
                        value={value?.name?.[selectedLanguage]}
                        onChange={(e) => onChangeName(e.target.value)}
                    />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label={`${text('description')} (${capitalizedLanguage})`}>
                    <Input
                        value={value?.description?.[selectedLanguage]}
                        onChange={(e) => onChangeDescription(e.target.value)}
                    />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label="HREF">
                    <Input
                        value={value.href}
                        onChange={(e) => onChangeValue('href', e.target.value)}
                    />
                </Form.Item>
            </Col>
            <Col span={4}>
                <Form.Item label={text('imageUrl')}>
                    <Input
                        value={value.imageUrl}
                        onChange={(e) => onChangeValue('imageUrl', e.target.value)}
                    />
                </Form.Item>
            </Col>
            <Col span={4} className="relative">
                <div className="absolute bottom-0">
                    <Button
                        className="uppercase delete-attribute-button"
                        type="link"
                        onClick={onDelete}
                    >
                        {text('removeProperty')}
                    </Button>
                </div>
            </Col>
        </Row>
    );
};
