import { AxiosClient, AxiosLoginClient } from 'src/api/AxiosClient';

export class AuthAPI {
  static login (data) {
    return AxiosClient.post(
      '/login',
      {},
      {
        params: {
          'remember-me': true,
          username: data.email,
          password: data.password
        }
      }
    );
  }

  static async getConfig (payload) {
    const encodedUsername = encodeURIComponent(payload.email);
    const encodedPassword = encodeURIComponent(payload.password);
    return AxiosLoginClient.get(`user?username=${encodedUsername}&password=${encodedPassword}`);
  }

  static async loginAs (targetEmail) {
    return AxiosClient.post(
      '/login_as',
      {},
      {
        params: {
          primary_email: targetEmail
        }
      }
    );
  }
}
