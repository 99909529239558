import React, { useState } from 'react';
import { Row, Col, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'src/components/PageTitle';
import { CollectionGroupTable } from '../../components/collection-group/CollectionGroupTable';
import { textFrom } from 'src/utils/textFrom';
import { CenteredText } from 'src/components/CenteredText';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { Routes } from 'src/router/Routes.helper';
import { useHistory } from 'react-router-dom';
import { Chips } from 'src/components/Chips';
import { DeletionModal } from 'src/components/DeletionModal';
import { useCollectionGroups } from '../../provider/collectionGroups.provider';
import debounce from 'lodash/debounce';
import { CollectionsAPI } from '../../api/CollectionsAPI';

export const PageCollectionGroup = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.collections.collectionGroup', t);
  const textFilter = textFrom('constants.filter', t);

  const {
    collectionGroups,
    groupsPagination,
    groupsQuery,
    onChangeGroupsQuery,
    areGroupsLoading,
    refreshCollectionGroups
  } = useCollectionGroups();

  const history = useHistory();

  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [collectionGroupToBeDeleted, setCollectionGroupToBeDeleted] = useState();

  const handleSearchCollectionGroup = (searchedText) => {
    onChangeGroupsQuery({
      ...groupsQuery,
      text: searchedText
    });
  };

  const onTagRemove = (key) => {
    if (key === 'text') {
      handleSearchCollectionGroup('');
    }
  };

  const navigatetoCreateCollectionGroup = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.COLLECTION_GROUP_CREATE));
  };

  const navigatetoEditCollectionGroup = (record) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.COLLECTION_GROUP_EDIT, {
      id: record.id
    }));
  };

  const onChangeAvailabilityStatus = async (collectionGroup) => {
    const newStatus = !collectionGroup?.published;
    if (collectionGroup?.id) {
      try {
        await CollectionsAPI.changeCollectionGroupAvailabilityStatus(collectionGroup.id, newStatus);
        message.success(text('onStatusUpdateOk'));
      } catch (err) {
        const errDetail = ` ${err.response?.data?.detail || ''}`;
        message.error(`${text('onStatusUpdateErr')}${errDetail}`);
      } finally {
        refreshCollectionGroups(groupsQuery);
      }
    }
  };

  const showDeletionModal = (collectionGroup) => {
    setCollectionGroupToBeDeleted(collectionGroup);
    setIsDeletionModalVisible(true);
  };

  const deleteOutfitCollection = async () => {
    if (collectionGroupToBeDeleted?.id) {
      try {
        await CollectionsAPI.deleteCollectionGroup(collectionGroupToBeDeleted.id);
        message.success(text('onDeleteOk'));
      } catch (err) {
        const errDetail = ` ${err.response?.data?.detail || ''}`;
        message.error(`${text('onDeleteErr')}${errDetail}`);
      } finally {
        refreshCollectionGroups(groupsQuery);
      }
    }
  };

  const hideModal = () => {
    setIsDeletionModalVisible(false);
  };

  const CollectionGroupSearch = () => (
    <SearchInput
      autoFocus
      defaultValue={groupsQuery?.text}
      placeholder={text('searchPlaceholder')}
      onChangeText={debounce((value) => handleSearchCollectionGroup(value), 500)}
    />
  );

  return (
    <>
      <div className='py-4'>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={6}>
            <CollectionGroupSearch />
          </Col>
          <Col span={18}>
            <div className='flex justify-end'>
              <Button
                type='primary'
                className='uppercase'
                onClick={navigatetoCreateCollectionGroup}
              >
                {text('createNewCollectionGroup')}
              </Button>
            </div>
          </Col>
        </Row>
        {/* Filter Chips */}
        {!!groupsQuery?.text &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!groupsQuery.text && +1) })}:
              <Chips
                query={groupsQuery}
                chipKeys={['text']}
                onTagRemove={onTagRemove}
              />
            </div>
          </Row>}

        {!areGroupsLoading && !collectionGroups?.length > 0
          ? (<CenteredText>{text('noCollectionGroupFound')}</CenteredText>)
          : (
            <div className='pt-8'>
              <CollectionGroupTable
                loading={false}
                collections={collectionGroups}
                pagination={groupsPagination}
                onEdit={navigatetoEditCollectionGroup}
                onDelete={showDeletionModal}
                onChangeAvailabilityStatus={onChangeAvailabilityStatus}
              />
            </div>)}

        <DeletionModal
          onOk={deleteOutfitCollection}
          visible={isDeletionModalVisible}
          onCancel={hideModal}
        >
          <div>{text('askConfirmation')}</div>
        </DeletionModal>
      </div>
    </>
  );
};
