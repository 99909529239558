import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
  } from 'react';
import { message } from 'antd';
import { PresentationConfigAPI } from 'src/modules/admin/api/PresentationConfigAPI';

const PresentationConfigContext = createContext();

const useProvidePresentationConfig = () => {
  const [config, setConfig] = useState();

  const getConfig = useCallback(async () => {
    try {
      const res = await PresentationConfigAPI.getConfig();
      setConfig(res?.data || {});
      return Promise.resolve();
    } catch (e) {
      message.error('Failed to retrieve configuration');
    }
  }, []);

  useEffect(() => {
    getConfig();
  }, []);

  return {
    config
  };
};

export const PresentationConfigProvider = ({ children }) => {
  const config = useProvidePresentationConfig();

  return (
    <PresentationConfigContext.Provider value={config}>
      {children}
    </PresentationConfigContext.Provider>
  );
};

export const usePresentationConfig = () => useContext(PresentationConfigContext);
