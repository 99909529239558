import React from 'react';
import { Form, Input } from 'antd';

export const InputAttributeTranslation = ({
  isDisabled,
  attributeKey,
  language
}) => (
  <Form.Item
    name={[`attributeTranslations-${attributeKey}-${language}`]}
  >
    <Input disabled={isDisabled} />
  </Form.Item>
);
