import React, { useMemo } from 'react';
import { Form, Select } from 'antd';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

export const LanguageSelect = ({ onSelect, label, placeholder, name = 'language' }) => {
  const { languages, defaultLanguage } = useLanguages();

  const { t } = useTranslation();
  const translateLanguage = textFrom('languages', t);

  const languageOptions = useMemo(() => {
    const defaultOpt = {
      value: defaultLanguage.language,
      label: `${translateLanguage(defaultLanguage.language)} (default)`
    };
    return [defaultOpt]
      .concat(
        languages
          .filter(lang => lang.language !== defaultLanguage.language)
          .map(lang => ({ value: lang.language, label: translateLanguage(lang.language) }))
      );
  }, [languages, defaultLanguage]);

  return (
    <Form.Item
      name={name}
      label={label}
    >
      <Select
        options={languageOptions}
        onSelect={onSelect}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};
