import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { Row, Col, Form, Select, DatePicker, message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { Report } from '../components/Report';
import { useForm } from 'antd/lib/form/Form';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { DownloadsAPI } from '../api/DownloadsAPI';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { PercentageOutlined } from '@ant-design/icons';
import { always, isAfter, isBefore } from 'src/utils/utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { CouponsAPI } from 'src/modules/coupons/api/CouponsAPI';
import { SelectWithLoadMore } from 'src/components/SelectWithLoadMore';
import { SelectLocalBusinessWithLoadMore } from 'src/components/SelectLocalBusinessWithLoadMore';

export const PageCreateDownload = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.downloads/create', t);

  const history = useHistory();
  const [form] = useForm();
  const endDate = Form.useWatch('endDate', form);
  const startDate = Form.useWatch('startDate', form);

  const goToDownloadsPage = useCallback(() => {
    history.push(Routes.PATHS.DOWNLOADS);
  }, []);

  const isAfterEndDate = endDate ? isAfter(moment(endDate)) : always(false);
  const isBeforeStartDate = startDate ? isBefore(moment(startDate)) : always(false);

  const clearFilters = () => {
    form.resetFields();
  };

  const handleCreateReport = async () => {
    const values = form.getFieldsValue();
    const { startDate, endDate, ...filters } = values;
    if (startDate) {
      filters.startTimestampInSeconds = moment(startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).unix();
    }
    if (endDate) {
      filters.endTimestampInSeconds = moment(endDate).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).unix();
    }
    if (filters.codes) {
      filters.codes = [filters.codes];
    }
    if (filters.localBusinessIds) {
      filters.localBusinessIds = [filters.localBusinessIds];
    }
    if (filters.type === 'any') {
      delete filters.type;
    }
    try {
      await DownloadsAPI.createCouponsUsageReport(filters);
      message.success(text('onCreateOk'));
      goToDownloadsPage();
    } catch (e) {
      message.error(text('onCreateErr'));
    }
  };

  return (
    <div className='py-4 h-full'>
      <PageTitle>{text('title')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={goToDownloadsPage}
      />

      <Form
        form={form}
        layout='vertical'
        className='h-full'
      >
        <Row gutter={16} align='bottom'>
          <Col>
            {text('filterBy')}
          </Col>
          <Col xs={5}>
            <SelectLocalBusinessWithLoadMore
              formItemName='localBusinessIds'
              label={text('localBusinessLabel')}
              placeholder={text('localBusinessPlaceholder')}
            />
          </Col>
          <Col xs={3}>
            <CouponTypeFormItem />
          </Col>
          <Col>
            <StartDateFormItem disabledDate={isAfterEndDate} />
          </Col>
          <Col>
            <EndDateFormItem disabledDate={isBeforeStartDate} />
          </Col>
          <Col xs={3}>
            <CouponCodeFormItem />
          </Col>
          <Col>
            <DefaultButton
              onClick={clearFilters}
              label={text('clearFilters')}
            />
          </Col>
        </Row>

        <div className='h-full grid place-items-center'>
          <div className='transform -translate-x-1/4 -translate-y-1/2'>
            <Report
              icon={<PercentageOutlined />}
              title={text('couponUsageReport')}
              buttonLabel={text('generateReport')}
              onClick={handleCreateReport}
            />
          </div>
        </div>

      </Form>

    </div>
  );
};

const CouponTypeFormItem = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.downloads/create', t);

  const COUPON_TYPE_OPTIONS = [
    { value: 'any', label: text('any') },
    { value: 'online', label: text('online') },
    { value: 'in-store', label: text('inStore') }
  ];

  return (
    <Form.Item
      name='type'
      label={text('couponTypeLabel')}
    >
      <Select
        defaultValue={text('any')}
        placeholder={text('couponTypePlaceholder')}
        options={COUPON_TYPE_OPTIONS}
      />
    </Form.Item>
  );
};

const StartDateFormItem = ({ disabledDate }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.downloads/create', t);

  return (
    <Form.Item
      name='startDate'
      label={text('startDateLabel')}
    >
      <DatePicker
        allowClear
        disabledDate={disabledDate}
      />
    </Form.Item>
  );
};

const EndDateFormItem = ({ disabledDate }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.downloads/create', t);

  return (
    <Form.Item
      name='endDate'
      label={text('endDateLabel')}
    >
      <DatePicker
        allowClear
        disabledDate={disabledDate}
      />
    </Form.Item>
  );
};

const couponToOption = coupon => ({ label: coupon.applicationCode, value: coupon.applicationCode });

const CouponCodeFormItem = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.downloads/create', t);

  const [coupons, setCoupons] = useState([]);
  const [pagination, setPagination] = useState({});
  const { userRole, userInfo } = useAuth();
  const isSuperAdmin = ['superAdmin'].includes(userRole);

  const query = {
    pageSize: 10,
    sort: 'application_code,asc'
  };

  const requestPaginatedCoupons = async (pageNumber, searchedText) => {
    const localBusinessId = (!isSuperAdmin && userInfo) ? userInfo.localBusiness.id : undefined;
    query.current = pageNumber;
    query.applicationCode = searchedText;
    query.localBusinessId = localBusinessId;
    const data = await CouponsAPI.getCoupons(query);
    const coupons = data?.items || [];
    setCoupons(old => pageNumber === 1 ? coupons : old.concat(coupons));
    setPagination({ ...data });
  };

  return (
    <SelectWithLoadMore
      showSearch
      items={coupons}
      getItemsAtPage={requestPaginatedCoupons}
      pagination={pagination}
      toOption={couponToOption}
      formItemName='codes'
      label={text('couponCodeLabel')}
      placeholder={text('couponCodePlaceholder')}
    />
  );
};
