import React, { useEffect } from 'react';
import { Typography, Input, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const { Title, Text } = Typography;
const { TextArea } = Input;
const MAX_DESCRIPTION_LENGTH = 500;
const MAX_GREETING_LENGTH = 2000;

export const MainInfo = ({ userInfo, description, setDescription, greeting, setGreeting }) => {
  const { t } = useTranslation();
  const text = textFrom('components.mainInfo', t);

  useEffect(() => {
    if (userInfo) {
      setDescription(userInfo.localBusiness.description);
      setGreeting(userInfo.localBusiness.greeting);
    }
  }, [userInfo]);
  return (
    <Row>
      <Col xs={12} className='border-b border-gray-300 pb-8'>
        <Title level={3} className='pb-4'>
          {userInfo && userInfo.localBusiness.name}
        </Title>
        <Text>{text('description')}</Text>
        <div className='mt-1 flex flex-col'>
          <TextArea
            rows={2}
            maxLength={MAX_DESCRIPTION_LENGTH}
            value={description}
            onChange={(e) => setDescription(e.target.value.trimStart())}
          />
          <Text className='ml-auto'>
            {description ? description.length : 0}/{MAX_DESCRIPTION_LENGTH}
          </Text>
        </div>
        <Text>{text('greeting')}</Text>
        <div className='mt-1 flex flex-col'>
          <TextArea
            rows={2}
            maxLength={MAX_GREETING_LENGTH}
            value={greeting}
            onChange={(e) => setGreeting(e.target.value.trimStart())}
          />
          <Text className='ml-auto'>
            {greeting ? greeting.length : 0}/{MAX_GREETING_LENGTH}
          </Text>
        </div>
      </Col>
    </Row>
  );
};
