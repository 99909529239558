import React, { useState } from 'react';
import { Row, Col, Tag, InputNumber } from 'antd';
import { message } from 'src/services/Messages.service';
import { useStocks } from 'src/modules/warehouses/provider/stocks.provider';
import { useFilterTags } from 'src/hooks/useFilterTags';
import { PageTitle } from 'src/components/PageTitle';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { StocksQuery } from 'src/modules/warehouses/domain/models/StocksQuery';
// import { StocksMapper } from 'src/modules/warehouses/domain/Stocks.mapper';
import { useTableHeight } from 'src/hooks/useTableHeight';
// import { useDownloadFile } from 'src/hooks/useDownloadFile';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { CenteredText } from 'src/components/CenteredText';
import { StockTable } from '../components/StockTable';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { WarehousesAPI } from '../api/WarehousesAPI';
import { SelectWithLoadMore } from 'src/components/SelectWithLoadMore';

const { KEYS } = StocksQuery;

const warehouseToOption = warehouse => ({ key: warehouse.id, label: warehouse.name, value: warehouse.name });

export const PageStocks = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.stocks', t);
  const textFilter = textFrom('constants.filter', t);

  const {
    query,
    stocks,
    setStocks,
    pagination,
    onChangeQuery,
    areStocksLoading,
    updateStockAvailability
  } = useStocks();
  const searchParameters = ['current', 'pageSize'];
  const tableHeight = useTableHeight();
  const filterTags = useFilterTags(query, searchParameters);
  const [warehouses, setWarehouses] = useState([]);
  const [warehousePagination, setWarehousePagination] = useState({});
  const [selectedWarehouse, setSelectedWarehouse] = useState(undefined);
  const { userDepartments } = useAuth();

  // TODO Enable once API is fixed
  // const downloadFile = useDownloadFile({
  //   onError (err) {
  //     message.error(
  //       err.response?.data?.detail || err.response?.data || err.message
  //     );
  //   }
  // });

  const onStockAvailabilityChange = async (record, availability) => {
    try {
      record.isUpdatePending = true;
      const res = await updateStockAvailability(record, availability);
      setStocks(stocks.map(el => el.id === record.id
        ? {
            ...el,
            availabilityTotal: res.data.data.availability,
            isUpdatePending: false
          }
        : el));
      message.success(text('onUpdateOk', { manufacturerCode: record.manufacturerCode, warehouseName: record.warehouse.name }));
    } catch (e) {
      message.error(e);
    }
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const onTagRemove = (tag) => {
    onChangeQuery({
      ...query,
      [tag.key]: null
    });
    if (tag.key === KEYS.WAREHOUSE) {
      setSelectedWarehouse(undefined);
    }
  };

  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      ean: value,
      current: 1
    });
  };

  const onChangeFilter = (key, value) => {
    if (key === KEYS.WAREHOUSE && value) {
      setSelectedWarehouse(value);
    } else if (key === KEYS.WAREHOUSE && !value) {
      setSelectedWarehouse(undefined);
    }
    onChangeQuery({
      ...query,
      [key]: value,
      current: 1
    });
  };

  // TODO: enable once API is fixed (I think?)
  // const downloadXLS = () => {
  //   const params = WarehousesMapper.fromQueryToQueryString(query)
  //   downloadFile.call(`/v2/products/stocks/export/xlsx?${params}`, 'warehouse.xlsx')
  // };

  const getWarehousesAtPage = async (pageNumber) => {
    const query = { limit: 10, pageNumber, filters: { department: userDepartments?.[0] } };
    const res = await WarehousesAPI.getWarehouses(query);
    const warehouses = res?.data?.data?.items || [];
    setWarehouses(old => old.concat(warehouses));
    setWarehousePagination({ ...res?.data?.data });
  };

  return (
    <>
      <div className='py-4'>
        <PageTitle>{text('pageTitle')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={6}>
            <SearchInput
              autoFocus
              id={KEYS.EAN}
              placeholder={text('EAN')}
              onChangeText={handleFullTextSearch}
              defaultValue={query[KEYS.EAN]}
            />
          </Col>
          <Col span={6}>
            <SelectWithLoadMore
              items={warehouses}
              getItemsAtPage={getWarehousesAtPage}
              value={selectedWarehouse}
              pagination={warehousePagination}
              placeholder={text('warehouse')}
              toOption={warehouseToOption}
              onClear={() => onChangeFilter(KEYS.WAREHOUSE, null)}
              onSelect={val => onChangeFilter(KEYS.WAREHOUSE, val)}
            />
          </Col>
          <Col span={6}>
            <InputNumber
              placeholder={text('stockQuantity')}
              min={0}
              onChange={debounce((value) => onChangeFilter(KEYS.AVAILABILITY, value), 500)}
              value={query[KEYS.AVAILABILITY]}
            />
          </Col>
          <Col span={6}>
            <div className='flex justify-end'>
              {/* TODO enable once API fixed
              <Button
                  loading={downloadFile.isLoading}
                  onClick={() => downloadXLS()}
                  type='primary'
                  className='uppercase'
              >
                Download .xls
              </Button> */}
            </div>
          </Col>
        </Row>
        {filterTags?.length > 0 &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: filterTags.length })}:</div>
            {filterTags.map((tag) => (
              <Tag className='cursorTag' key={tag.value} closable onClose={() => onTagRemove(tag)} onClick={() => onTagRemove(tag)}>
                {tag.value}
              </Tag>
            ))}
          </Row>}
      </div>
      {!areStocksLoading && !stocks?.length
        ? (<CenteredText>{text('noStocksFound')}</CenteredText>)
        : (<StockTable
            loading={areStocksLoading}
            stocks={stocks}
            onStockAvailabilityChange={onStockAvailabilityChange}
            pagination={pagination}
            onTableChange={onTableChange}
            scroll={{ y: tableHeight }}
           />)}
    </>
  );
};
