import React from 'react';
import { Form, Input } from 'antd';

export const InputAttributeKey = ({ attributeKey, isDisabled }) => (
  <Form.Item
    name={[`attributeKey-${attributeKey}`]}
  >
    <Input disabled={isDisabled} />
  </Form.Item>
);
