import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useContext
} from 'react';
import { LocalBusinessAPI } from '../api/localBusinessAPI';
import { useParams, useLocation } from 'react-router-dom';
import { usePagination } from 'src/hooks/usePagination';
import { LocalBusinessesMapper } from '../domain/LocalBusinesses.mapper';
import { useFilters } from 'src/hooks/useFilters';
import { message } from 'antd';

export const localBusinessContext = createContext();

const useProvideLocalBusiness = () => {
  const params = useParams();
  const location = useLocation();
  const [localBusiness, setLocalBusiness] = useState();
  const [localBusinesses, setLocalBusinesses] = useState([]);
  const [pagination, setPagination] = usePagination();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [areLocalBusinessesLoading, setAreLocalBusinessesLoading] = useState(false);
  const { query, onChangeQuery } = useFilters(LocalBusinessesMapper);

  const getLocalBusinesses = useCallback(async (query) => {
    setAreLocalBusinessesLoading(true);
    try {
      const res = await LocalBusinessAPI.getLocalBusinesses(LocalBusinessesMapper.fromQueryToPayload(query));
      setLocalBusinesses(res.data?.items);
      setPagination(res.data);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setAreLocalBusinessesLoading(false);
    }
  }, [query, location]);

  const createLocalBusiness = useCallback(async (data) => {
    try {
      return await LocalBusinessAPI.createLocalBusiness(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const updateLocalBusiness = useCallback(async (localBusinessId, data) => {
    try {
      return await LocalBusinessAPI.updateLocalBusiness(localBusinessId, data);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const getLocalBusinessCategories = useCallback(async (data) => {
    try {
      const res = await LocalBusinessAPI.getLocalBusinessCategories();
      const categories = [...res.data];
      setCategories(categories);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const getLocalBusinessTags = useCallback(async (data) => {
    try {
      const res = await LocalBusinessAPI.getLocalBusinessTags();
      const tags = [...res.data];
      setTags(tags);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const getLocalBusiness = async (id) => {
    try {
      const res = await LocalBusinessAPI.getLocalBusinessDetails(id);
      setLocalBusiness(res.data);
    } catch (e) {
      message.error('Failed to retrieve Local Business');
    }
  };

  useEffect(() => {
    getLocalBusinessTags();
    getLocalBusinessCategories();
  }, []);

  useEffect(() => {
    getLocalBusinesses(query);
  }, [location.search, query]);

  useEffect(() => {
    if (params.id) {
      getLocalBusiness(params.id);
    }
  }, [params.id]);

  return {
    localBusiness,
    localBusinesses,
    pagination,
    createLocalBusiness,
    updateLocalBusiness,
    getLocalBusinessCategories,
    getLocalBusinessTags,
    categories,
    tags,
    query,
    onChangeQuery,
    areLocalBusinessesLoading
  };
};

export const LocalBusinessProvider = ({ children }) => {
  const localBusinesses = useProvideLocalBusiness();

  return (
    <localBusinessContext.Provider value={localBusinesses}>
      {children}
    </localBusinessContext.Provider>
  );
};

export const useLocalBusiness = () => useContext(localBusinessContext);
