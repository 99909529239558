import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { message } from 'src/services/Messages.service';
import { useLatestRef } from 'src/hooks/useLatestRef';

const noop = () => { };
const { Option } = Select;

export const InputSelectInfinite = ({
  language,
  selectKey,
  onLoadMore,
  onChange,
  onMount = noop,
  placeholder,
  value,
  search = '',
  id,
  isDisabled,
  ...props
}) => {
  const valueRef = useLatestRef(value);
  const onMountRef = useLatestRef(onMount);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const [pagination, setPagination] = useState({
    startIndex: undefined,
    itemsPerPage: undefined,
    currentItemCount: undefined,
    totalItems: undefined
  });

  const currentPage = useMemo(() => {
    return Math.floor(pagination.startIndex / pagination.itemsPerPage) + 1;
  }, [pagination.startIndex, pagination.itemsPerPage]);

  const hasMoreItems = useMemo(() => {
    return (
      pagination.startIndex + pagination.currentItemCount <
      pagination.totalItems
    );
  }, [pagination]);

  const loadPage = useCallback(
    async (page) => {
      setLoading(true);
      try {
        const res = await onLoadMore(page, language);
        const items = Array.isArray(res?.data?.data?.items)
          ? res?.data?.data?.items
          : Array.isArray(res?.data?.data)
            ? res?.data?.data
            : [];
        onPaginationChange({ data: res?.data?.data });
        if (page === 1) {
          setOptions(items);
        } else {
          setOptions(options => ([...options, ...items]));
        }
      } catch (e) {
        message.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onLoadMore]
  );

  useEffect(() => {
    if (onLoadMore) {
      setOptions([]);
      loadPage(1);
    }
  }, [onLoadMore, loadPage, language]);

  useEffect(() => {
    onMountRef.current(valueRef.current);
  }, [onMountRef, valueRef]);

  const onScroll = async ({ target }) => {
    const isScrolledEnought =
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 50;

    if (!loading && isScrolledEnought && hasMoreItems) {
      loadPage(currentPage + 1);
    }
  };

  const onPaginationChange = (res) => {
    const { data } = res;
    setPagination({
      totalItems: data?.totalItems,
      startIndex: data?.startIndex,
      itemsPerPage: data?.itemsPerPage,
      currentItemCount: data?.currentItemCount
    });
  };

  return (
    <Select
      disabled={isDisabled}
      getPopupContainer={trigger => trigger.parentNode}
      value={getParsedValue(value)}
      style={{ width: '100%' }}
      placeholder={placeholder}
      onChange={(value, opt) => {
        return onChange(JSON.parse(value), {
          key: opt?.key,
          value: JSON.parse(opt?.value),
          id: opt.id,
          children: opt?.children
        }, selectKey);
      }}
      onPopupScroll={onScroll}
      {...props}
    >
      {options
        .filter(({ label }) => {
          return label?.toLowerCase().includes(search.toLowerCase());
        })
        .map((opt, i) => (
          <Option
            key={JSON.stringify({ ...opt, i })}
            value={JSON.stringify(opt)}
          >
            {opt.label}
          </Option>
        ))}
      {loading && <Option key='loading'>Loading...</Option>}
    </Select>
  );
};

function getParsedValue (value) {
  if (value?.label !== undefined) {
    return value?.label;
  }
  let parsedValue = '';
  try {
    parsedValue = JSON.parse(value);
    parsedValue = parsedValue?.label ?? parsedValue?.localName ?? parsedValue;
  } catch (e) {
    if (
      typeof value === 'string' ||
      typeof value === 'number' ||
      value === null ||
      value === undefined
    ) {
      parsedValue = value;
    } else {
      parsedValue = JSON.stringify(value);
    }
  }
  return parsedValue;
}
