import React, { useMemo } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { MenuAction, PopoverActionMenu } from 'src/components/PopoverActionMenu';

export const VisualMerchandisingTable = ({
  loading,
  scroll,
  visuals
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.pageVisualMerchandisingList', t);
  const history = useHistory();

  const navigateToPageVisualMerchProducts = (name, advancedFilters, basicFilters, filters, id) => {
    console.log('navigateToPageVisualMerchProducts');
    history.push(Routes.parseRouteParams(Routes.PATHS.VISUAL_MERCHANDISING_PRODUCTS, {
      visualMerchandising: name,
      VisualMerchandisingId: id
    }) + Routes.toUrlSearch({ advancedFilters, basicFilters, filters }));
  };

  const COLUMNS = useMemo(() => [
    {
      title: text('name'),
      dataIndex: ['name'],
      render: (_, record) => `${record?.name ?? ''}`
    },
    {
      title: text('area'),
      dataIndex: ['filters'],
      width: '25%',
      render: (filters) => filters.map((filter, i) => [
        i > 0 && ', ',
        filter.key
      ])
    },
    {
      title: text('articles'),
      width: '15%',
      dataIndex: ['numberOfItems'],
      align: 'right'
    },
    {
      title: text('createdOn'),
      dataIndex: ['creationDate'],
      sorter: (a, b) => moment(a.creationDate).unix() - moment(b.creationDate).unix(),
      render: (text) => <Moment format='DD MMM YYYY' date={text} />
    },
    {
      title: text('lastEdit'),
      dataIndex: ['updateDate'],
      key: 'updateDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.updateDate).unix() - moment(b.updateDate).unix(),
      render: (text) => <Moment format='DD MMM YYYY' date={text} />
    },
    {
      align: 'center',
      render: (_, { name, advancedFilters, basicFilters, filters, id }) => (
        <PopoverActionMenu>
          <MenuAction
            label={text('edit')}
            onClick={() => navigateToPageVisualMerchProducts(name, advancedFilters, basicFilters, filters, id)}
          />
        </PopoverActionMenu>
      )
    }
  ], []);

  return (
    <Table
      size='middle'
      rowKey='id'
      loading={loading}
      columns={COLUMNS}
      dataSource={visuals}
      pagination={false}
      scroll={scroll}
    />
  );
};
