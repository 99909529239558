export class Routes {
  static APP_PREFIX () {
    return '/app';
  }

  // @todo update with class private methods when
  static _getAuthenticatedPath (path) {
    return `${Routes.APP_PREFIX()}/${path}`;
  }

  // Replace URL parameters with values past from params object
  static parseRouteParams (url, params = {}) {
    return Object.keys(params).reduce((acc, key) => {
      acc = acc.replace(`:${key}`, params[key]);
      return acc;
    }, url);
  }

  static arrayToParams (arr) {
    let parameters = '';
    arr.forEach((element, index) => {
      parameters += !index ? '?' : '&';
      parameters += 'type=' + element.toLowerCase();
    });
    return parameters;
  }

  static encodeObject (obj) {
    return encodeURIComponent(JSON.stringify(obj));
  }

  static encodeString (str) {
    return encodeURIComponent(str);
  }

  static toUrlSearch (params) {
    return Object.entries(params)
      .reduce((acc, [key, value]) => {
        switch (typeof value) {
          case 'object':
            acc += `${key}=${Routes.encodeObject(value)}&`;
            break;
          case 'string':
            acc += `${key}=${Routes.encodeString(value)}&`;
            break;
          default:
            acc += JSON.stringify(value);
        }
        return acc;
      }, '?')
      .slice(0, -1);
  }

  static get PATHS () {
    return {
      LOGIN: '/login',
      USER: '/user',
      DASHBOARD: `${Routes.APP_PREFIX()}/dashboard`,
      SETTINGS: Routes._getAuthenticatedPath('settings'),
      PRODUCTS_FOOD: Routes._getAuthenticatedPath('products-food'),
      PRODUCTS_EVENT_UPCOMING: Routes._getAuthenticatedPath('products-event/upcoming'),
      PRODUCTS_EVENT_PAST: Routes._getAuthenticatedPath('products-event/past'),
      PRODUCTS_FASHION: Routes._getAuthenticatedPath('products-fashion'),
      CREATE_PRODUCT_FOOD: Routes._getAuthenticatedPath('create-product-food'),
      CREATE_PRODUCT_EVENT: Routes._getAuthenticatedPath('create-product-event'),
      CREATE_PRODUCT_FASHION: Routes._getAuthenticatedPath('create-product-fashion'),
      EDIT_PRODUCT_FOOD: Routes._getAuthenticatedPath('products-food/:id'),
      EDIT_PRODUCT_EVENT: Routes._getAuthenticatedPath('products-event/:id'),
      EDIT_PRODUCT_FASHION: Routes._getAuthenticatedPath('products-fashion/:id'),
      OUTFITS: Routes._getAuthenticatedPath('outfits'),
      OUTFIT_EDIT: Routes._getAuthenticatedPath('outfits/:id'),
      OUTFIT_CREATE: Routes._getAuthenticatedPath('outfits/create'),
      EDIT_OUTFIT: Routes._getAuthenticatedPath('outfits/:id'),
      LOCATIONS: Routes._getAuthenticatedPath('locations'),
      CREATE_LOCATION: Routes._getAuthenticatedPath('locations/create'),
      LOCATIONS_DETAILS: Routes._getAuthenticatedPath('locations/:id'),
      WAREHOUSES: Routes._getAuthenticatedPath('warehouses'),
      WAREHOUSES_CREATE: Routes._getAuthenticatedPath('warehouses/create'),
      WAREHOUSES_EDIT: Routes._getAuthenticatedPath('warehouses/edit/:id'),
      STOCKS: Routes._getAuthenticatedPath('stocks'),
      VISUAL_MERCHANDISING: Routes._getAuthenticatedPath('visual-merchandising'),
      VISUAL_MERCHANDISING_PRODUCTS: Routes._getAuthenticatedPath('visual-merchandising/:visualMerchandising/:VisualMerchandisingId'),
      UPLOADS_LIST: Routes._getAuthenticatedPath('uploads'),
      UPLOAD_TYPE_DETAILS: Routes._getAuthenticatedPath('uploads/:type'),
      DOWNLOADS: Routes._getAuthenticatedPath('downloads'),
      DOWNLOAD_CREATE: Routes._getAuthenticatedPath('downloads/create'),
      DOWNLOAD_DETAILS: Routes._getAuthenticatedPath('downloads/details/:id'),
      PAGE_UPLOAD_TYPE_STATUS: Routes._getAuthenticatedPath('upload-status/:type/:uuid'),
      ACCOUNTS: Routes._getAuthenticatedPath('account'),
      ORDERS_FASHION: Routes._getAuthenticatedPath('orders-fashion'),
      UPCOMING_ORDERS_EVENT: Routes._getAuthenticatedPath('orders-event/upcoming'),
      PAST_ORDERS_EVENT: Routes._getAuthenticatedPath('orders-event/past'),
      UPCOMING_ORDERS_FOOD: Routes._getAuthenticatedPath('orders-food/upcoming'),
      PAST_ORDERS_FOOD: Routes._getAuthenticatedPath('orders-food/past'),
      COUPONS: Routes._getAuthenticatedPath('coupons'),
      COUPON_CREATE: Routes._getAuthenticatedPath('coupon/create'),
      USERS: Routes._getAuthenticatedPath('users'),
      USERS_CREATE: Routes._getAuthenticatedPath('users/create'),
      USERS_EDIT: Routes._getAuthenticatedPath('users/edit/:userId'),
      PERMISSION_SETS: Routes._getAuthenticatedPath('permission-sets'),
      PERMISSION_SETS_CREATE: Routes._getAuthenticatedPath('permission-sets/create'),
      PERMISSION_SETS_EDIT: Routes._getAuthenticatedPath('permission-sets/edit/:id'),
      GENERAL_SETTINGS: Routes._getAuthenticatedPath('general-settings'),
      CUSTOMERS: Routes._getAuthenticatedPath('customers'),
      CUSTOMER_CREATE: Routes._getAuthenticatedPath('customer/create'),
      CUSTOMER_EDIT: Routes._getAuthenticatedPath('customer/edit/:id'),
      LOCAL_BUSINESSES: Routes._getAuthenticatedPath('localbusinesses'),
      LOCAL_BUSINESSES_CREATE: Routes._getAuthenticatedPath('localbusinesses/create'),
      LOCAL_BUSINESSES_EDIT: Routes._getAuthenticatedPath('localbusinesses/edit/:id'),
      LANGUAGES: Routes._getAuthenticatedPath('languages'),
      LANGUAGES_CREATE: Routes._getAuthenticatedPath('languages/create'),
      WEBHOOKS: Routes._getAuthenticatedPath('webhooks'),
      FLAG: Routes._getAuthenticatedPath('flag-features-config'),
      WEBHOOKS_CREATE: Routes._getAuthenticatedPath('webhooks/create'),
      WEBHOOKS_EDIT: Routes._getAuthenticatedPath('webhooks/edit/:id'),
      LOOKBOOK_CONFIG: Routes._getAuthenticatedPath('lookbook-config'),
      LOOKBOOK_HOMEPAGE_SETUP: Routes._getAuthenticatedPath('lookbook-homepage-setup'),
      PRESENTATION_CONFIG: Routes._getAuthenticatedPath('presentation-config'),
      BRANDS: Routes._getAuthenticatedPath('brands'),
      BRAND_CREATE: Routes._getAuthenticatedPath('brands/create'),
      BRAND_EDIT: Routes._getAuthenticatedPath('brands/edit/:id'),
      VARIANT_ATTRIBUTES: Routes._getAuthenticatedPath('variant-attributes'),
      PRODUCT_ATTRIBUTES: Routes._getAuthenticatedPath('product-attributes'),
      EMAIL_VERIFICATION: '/verify-email',
      RESET_PASSWORD: '/reset-password',
      FORGOT_PASSWORD: '/forgot-password',
      UNAUTHORIZED: '/unauthorized',
      MACROCATEGORY_CREATE: Routes._getAuthenticatedPath('macrocategories/create'),
      MICROCATEGORY_CREATE: Routes._getAuthenticatedPath('microcategories/create'),
      MICROCATEGORY_ASSIGN: Routes._getAuthenticatedPath('microcategories/assign'),
      COLLECTION_GROUP: Routes._getAuthenticatedPath('collections/collection-group'),
      COLLECTION_GROUP_CREATE: Routes._getAuthenticatedPath('collections/collection-group/create'),
      COLLECTION_GROUP_EDIT: Routes._getAuthenticatedPath('collections/collection-group/edit/:id'),
      OUTFIT_COLLECTION: Routes._getAuthenticatedPath('collections/outfit-collection'),
      OUTFIT_COLLECTION_CREATE: Routes._getAuthenticatedPath('collections/outfit-collection/create'),
      OUTFIT_COLLECTION_EDIT: Routes._getAuthenticatedPath('collections/outfit-collection/edit/:id')
    };
  }
}
