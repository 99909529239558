import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AutoComplete } from "antd";
import { message } from "src/services/Messages.service";
import { useLatestRef } from "src/hooks/useLatestRef";

const noop = () => {};

export const InputWithAutocomplete = ({
  onLoadMore,
  onChange,
  onMount = noop,
  placeholder,
  value,
  search = "",
  id,
  ...props
}) => {
  const valueRef = useLatestRef(value);
  const onMountRef = useLatestRef(onMount);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [refinedOptions, setRefinedOptions] = useState([]);

  const [pagination, setPagination] = useState({
    startIndex: undefined,
    itemsPerPage: undefined,
    currentItemCount: undefined,
    totalItems: undefined,
  });

  const currentPage = useMemo(() => {
    return Math.floor(pagination.startIndex / pagination.itemsPerPage) + 1;
  }, [pagination.startIndex, pagination.itemsPerPage]);

  const hasMoreItems = useMemo(() => {
    return (
      pagination.startIndex + pagination.currentItemCount <
      pagination.totalItems
    );
  }, [pagination]);

  const loadPage = useCallback(
    async (page) => {
      setLoading(true);
      try {
        const res = await onLoadMore(page);
        const items = Array.isArray(res?.data?.data?.items)
          ? res?.data?.data?.items
          : Array.isArray(res?.data?.data)
          ? res?.data?.data
          : [];
        onPaginationChange({ data: items });
        const optionsWithValue = items.map((itm) => {
          return {...itm, value: itm.key}
        })
        setOptions(optionsWithValue);
        setRefinedOptions(optionsWithValue);
      } catch (e) {
        message.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onLoadMore]
  );

  useEffect(() => {
    if (!onLoadMore) return;
    loadPage(1);
  }, [onLoadMore, loadPage]);

  useEffect(() => {
    onMountRef.current(valueRef.current);
  }, [onMountRef, valueRef]);

  const onScroll = async ({ target }) => {
    const isScrolledEnought =
    target.scrollTop + target.offsetHeight >= target.scrollHeight - 50;
    
    if (!loading && isScrolledEnought && hasMoreItems) {
      loadPage(currentPage + 1);
    }
  };

  const onSearch = (searchText) => {
    const optionsRefined = options.filter((itm) => itm.label.toLowerCase().includes(searchText.toLowerCase()));
    setRefinedOptions(optionsRefined);
  };

  const onPaginationChange = (res) => {
    setPagination({
      totalItems: res.totalItems,
      startIndex: res.startIndex,
      itemsPerPage: res.itemsPerPage,
      currentItemCount: res.currentItemCount,
    });
  };

  return (
    <AutoComplete
      style={{ width: "100%" }}
      placeholder={placeholder}
      onPopupScroll={onScroll}
      onSearch={onSearch}
      onChange={onChange}
      value={value}
      options={value ? refinedOptions : options}
      {...props}
    />
  );
};
