import { Filters } from 'src/services/Filters.service';
import { UsersQuery } from 'src/modules/admin/domain/models/UsersQuery';

export class UsersMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = UsersQuery;
    const query = new URLSearchParams(qs);
    const userQueryModel = new UsersQuery();
    userQueryModel.current = query.get('current') || undefined;
    userQueryModel.pageSize = query.get('pageSize') || undefined;
    userQueryModel.user = (query.get(KEYS.USER) || undefined);
    userQueryModel.group = (query.get(KEYS.GROUP) || undefined);
    return userQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: query?.current > 0 ? +query.current : 1,
      limit: query?.pageSize,
      filters: {
        user: query?.user,
        group: query?.group
      }
    };
    return payload;
  }
}
