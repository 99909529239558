import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, message, Tag } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { Report } from '../components/Report';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { BackButton } from 'src/components/BackButton';
import { useHistory } from 'react-router-dom';
import { HelperText } from 'src/components/forms/HelperText';
import { getIdSuffix } from '../utils/utils';
import { DownloadsAPI } from '../api/DownloadsAPI';
import { useDownloads } from '../provider/downloads.provider';
import { useTranslation } from 'react-i18next';
import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { filtersArrayMapper } from '../utils/filters';
import { PercentageOutlined } from '@ant-design/icons';
import { API_STENTLE_BASE_SUFFIX } from 'src/config';

export const PageDownloadDetails = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.downloads/id', t);

  const history = useHistory();
  const { download } = useDownloads();
  const [filters, setFilters] = useState([]);

  const navigateToDownloadsPage = useCallback(() => {
    history.push(Routes.PATHS.DOWNLOADS);
  }, []);

  const downloadFile = useDownloadFile({
    onError () {
      message.error(text('onDownloadErr'));
      download.status = 'ERROR';
    }
  });

  useEffect(async () => {
    if (download?.inputFilters) {
      const filtersArray = await filtersArrayMapper(download.inputFilters);
      setFilters(filtersArray);
    }
  }, [download?.id]);

  const handleDownloadReport = () => {
    const fileUrl = download?.relativePath?.replace(`/${API_STENTLE_BASE_SUFFIX}`, '');
    const fileName = `${getIdSuffix(download?.id)}.csv`;
    downloadFile.call(fileUrl, fileName);
  };

  const handleCreateReport = async () => {
    if (download?.inputFilters) {
      const filters = { ...download.inputFilters };
      delete filters.languageCode;
      try {
        await DownloadsAPI.createCouponsUsageReport(filters);
        navigateToDownloadsPage();
      } catch (e) {
        message.error(text('onGenerateErr'));
      }
    } else {
      message.error(text('onGenerateErr'));
    }
  };

  const actionFrom = (status) => {
    switch (status) {
      case 'ERROR': return handleCreateReport;
      case 'PROCESSED': return handleDownloadReport;
    }
  };

  const buttonLabelFrom = (status) => {
    switch (status) {
      case 'ERROR': return text('generateNewReport');
      case 'PROCESSED': return text('downloadReport');
      case 'PROCESSING': return text('generatingReport');
    }
  };

  const titleFrom = (status) => {
    switch (status) {
      case 'ERROR':
        return (
          <div>
            <div className='text-red-400'>{text('reportNotAvailable')}</div>
            <HelperText
              text={text('generateNewReportHint')}
            />
          </div>
        );
      default:
        return text('couponUsageReport');
    }
  };

  return (
    <div className='py-4 h-full'>
      <PageTitle>{text('title')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        title={text('backButton')}
        onClick={navigateToDownloadsPage}
      />

      {filters?.length > 0 &&
        <Row gutter={16}>
          <Col>
            {text('appliedFilters')}:
          </Col>
          <Col>
            {filters.map(filter => <Tag key={filter}>{filter}</Tag>)}
          </Col>
        </Row>}

      {download &&
        <div className='h-full grid place-items-center'>
          <div className='transform -translate-x-1/4 -translate-y-1/2'>
            <Report
              icon={<PercentageOutlined />}
              title={titleFrom(download.status)}
              isLoading={downloadFile.isLoading}
              isDisabled={download.status === 'PROCESSING'}
              buttonLabel={buttonLabelFrom(download.status)}
              onClick={actionFrom(download.status)}
            />
          </div>
        </div>}

    </div>
  );
};
