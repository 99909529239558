import React from 'react';
import { Button } from 'antd';

export const DownloadButton = (props) => (
  <Button
    {...props}
    type='primary'
    className={`uppercase ${props.className}`}
  >
    <a href={props.filePath} download={props.fileName}>
      {props.label}
    </a>
  </Button>
);
