import React, {
  useState
} from 'react';
import {
  Row,
  Col,
  Input,
  Alert,
  Button,
  Tooltip,
  Divider
} from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { PageSection } from 'src/components/PageSection';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useGeneralSettings } from 'src/modules/generalSettings/provider/generalSettings.provider';

export const PageGeneralSettings = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.general-settings', t);

  const {
    imagesBaseURL
  } = useGeneralSettings();

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const ImagesBaseURL = () => (
    <Input
      readOnly
      disabled
      className='images-base-url'
      defaultValue={imagesBaseURL}
    />
  );

  const ImagesBaseURLCopyButton = () => (
    <Tooltip title={text('copy')}>
      <Button
        icon={<CopyOutlined />}
        onClick={copyImagesBaseURLToClipboard}
      />
    </Tooltip>
  );

  const copyImagesBaseURLToClipboard = () => {
    navigator.clipboard.writeText(imagesBaseURL);
    openAlert();
  };

  const CopyAlert = () => (
    <Alert
      closable
      type='success'
      message={text('urlCopied')}
      onClose={closeAlert}
    />
  );

  const openAlert = () => {
    setIsAlertOpen(true);
  };

  const closeAlert = () => {
    setIsAlertOpen(false);
  };

  return (
    <div className='py-4 layout-main-content-and-footer'>
      <div className='layout-main-content'>
        <PageTitle>{text('generalSettings')}</PageTitle>
        <PageSection className='mt-2'>{text('imagesBaseUrl')}</PageSection>
        <HelperText text={text('hereIsTheUrl')} />
        <Row className='pt-4'>
          <Col span={15}>
            <Input.Group compact>
              <ImagesBaseURL />
              <ImagesBaseURLCopyButton />
            </Input.Group>
          </Col>
        </Row>
        <Divider />
      </div>
      {isAlertOpen &&
        <div className='layout-footer'>
          <div className='as-wide-as-content'>
            <CopyAlert />
          </div>
        </div>}
    </div>
  );
};
