import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { BrandsAPI } from '../api/BrandsAPI';
import { useBrands } from '../provider/brands.provider';
import { Routes } from 'src/router/Routes.helper';
import { DeletionModal } from 'src/components/DeletionModal';
import { PageTitle } from 'src/components/PageTitle';
import { ConfirmationModal } from 'src/components/modals/ConfirmationModal';
import { BrandForm } from '../components/BrandForm';

export const PageEditBrand = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.brands/edit', t);

  const history = useHistory();
  const { brand } = useBrands();

  const [form] = useForm();
  const [imageGallery, setImageGallery] = useState([]);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const MODAL_TITLE = text('modal.title');
  const MODAL_BODY = text('modal.body');

  useEffect(() => {
    if (brand) {
      const originalImageGallery = [];
      const originalBrandLogo = {
        id: brand?.logo?.id || 'defaultId',
        url: brand?.logo?.imageURL,
        isOriginal: true
      };
      originalImageGallery.push(originalBrandLogo);
      setImageGallery(originalImageGallery);
      form.setFieldsValue({
        brandName: brand?.name,
        key: brand?.key,
        department: brand?.departments?.length > 0 ? brand.departments[0] : null,
        description: brand?.longDescription,
        gallery: originalImageGallery,
        logoLink: null
      });
    }
  }, [brand]);

  const navigateToBrandsPage = () => {
    history.push(Routes.PATHS.BRANDS);
  };

  const onFinish = async (values) => {
    const {
      brandName,
      department,
      description,
      gallery,
      logoLink,
      key
    } = values;

    const brandData = {
      name: brandName,
      key,
      departments: [
        department
      ],
      longDescription: description,
      link: (!gallery || gallery?.length === 0) ? logoLink : null
    };
    try {
      setIsLoading(true);
      await BrandsAPI.updateBrand(brand.id, brandData);
      if (gallery?.length > 0 && !gallery[0].isOriginal) {
        await BrandsAPI.uploadBrandLogo(brand.id, gallery[0]);
      }
      setIsLoading(false);
      message.success(text('updateOK'));
      navigateToBrandsPage();
    } catch (e) {
      setIsLoading(false);
      message.error(text('updateKO'));
      if (e?.response?.data?.status === 409) {
        form.setFields([
          {
            name: 'key',
            errors: [text('updateKO409')]
          }
        ]);
      }
    }
  };

  const showDeletionModal = () => {
    setIsDeletionModalVisible(true);
  };

  const hideDeletionModal = () => {
    setIsDeletionModalVisible(false);
  };

  const deleteBrand = async () => {
    const status = await BrandsAPI.deleteBrand(brand.id);
    switch (status) {
      case 204:
        navigateToBrandsPage();
        message.success(text('onDeleteOk'));
        break;
      case 400:
        message.error(text('onDeleteErrBrandWithProducts'));
        break;
      default:
        message.error(text('onDeleteErrUnknown'));
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    const formValues = form.getFieldsValue();
    onFinish(formValues);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const buttons = (
    <div className='flex items-center w-full mt-20'>
      <Button
        type='danger'
        htmlType='button'
        className='uppercase'
        onClick={showDeletionModal}
      >
        {text('deleteBrand')}
      </Button>
      <Button
        type='secondary'
        htmlType='button'
        className='uppercase mr-2 ml-auto'
        onClick={navigateToBrandsPage}
      >
        {text('cancel')}
      </Button>
      <Button
        loading={isLoading}
        disabled={isLoading}
        type='primary'
        htmlType='submit'
        className='uppercase ml-2'
      >
        {text('updateBrand')}
      </Button>
    </div>
  );

  return (
    <div className='py-4'>

      <PageTitle>{text('editBrand')}</PageTitle>
      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToBrandsPage}
        title={text('backButton')}
      />

      <BrandForm
        form={form}
        buttons={buttons}
        onFinish={showModal}
        imageGallery={imageGallery}
        setImageGallery={setImageGallery}
        initialDescription={brand?.longDescription}
      />

      <ConfirmationModal
        isVisible={isModalVisible}
        title={MODAL_TITLE}
        body={MODAL_BODY}
        onConfirm={handleModalOk}
        onDismiss={handleModalCancel}
      />

      <DeletionModal
        onOk={deleteBrand}
        visible={isDeletionModalVisible}
        onCancel={hideDeletionModal}
      >
        <div>{text('askConfirmation')}</div>
      </DeletionModal>

    </div>
  );
};
