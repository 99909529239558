import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import i18next from 'src/i18n-config';
import { Table, Tag } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

export const OutfitTable = ({
  loading,
  className,
  outfits,
  onEdit,
  onChangeStatus,
  onDelete,
  pagination,
  onTableChange,
  isEditModeOn,
  selectedOutfitKeys,
  setSelectedOutfitKeys
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.outfitTable', t);

  const onSelectedOutfitKeysChange = (newSelectedOutfitsKeys) => {
    setSelectedOutfitKeys(newSelectedOutfitsKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedOutfitKeys,
    onChange: onSelectedOutfitKeysChange
  };

  const columns = [
    {
      title: text('coverImage'),
      dataIndex: 'coverImage',
      width: '5%',
      render: (url) => (
        <div className='flex items-center'>
          <img
            className='w-8 h-8 object-cover object-center'
            src={url}
          />
        </div>
      )
    },
    {
      title: text('name'),
      dataIndex: ['names', i18next.language],
      render: (name) => <div>{name}</div>
    },
    {
      title: text('id'),
      key: 'id',
      dataIndex: 'externalId',
      render: externalId => externalId
    },
    {
      title: text('description'),
      dataIndex: ['descriptions', i18next.language],
      render: (description) => <div>{description}</div>
    },
    {
      title: text('priority'),
      key: 'priority',
      dataIndex: 'priority',
      render: priority => priority
    },
    {
      title: text('startDate'),
      key: 'startDate',
      dataIndex: 'startDate',
      render: startDate => moment(startDate).utc().format('DD MMM YYYY')
    },
    {
      title: text('endDate'),
      key: 'endDate',
      dataIndex: 'endDate',
      width: '10%',
      render: endDate => moment(endDate).utc().format('DD MMM YYYY')
    },
    {
      title: text('lastEdit'),
      dataIndex: 'updateDate',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.updateDate).unix() - moment(b.updateDate).unix(),
      render: (date) => <Moment format='DD MMM YYYY' date={date} />
    },
    {
      title: text('status'),
      key: 'status',
      dataIndex: 'published',
      render: (published) => <div><Tag>{published ? text('published') : text('draft')}</Tag></div>
    }
  ];

  const columnsWithActionMenu = [
    ...columns,
    {
      align: 'center',
      render: (_, row) => {
        return row?.editable && (
          <PopoverActionMenu>
            <MenuAction onClick={onEdit?.bind(_, row)} label={text('edit')} />
            <MenuAction onClick={onChangeStatus?.bind(_, row)} label={row?.published ? text('unpublish') : text('publish')} />
            <MenuAction onClick={onDelete?.bind(_, row)} label={text('delete')} />
          </PopoverActionMenu>
        );
      }

    }
  ];

  return (
    <Table
      rowKey='id'
      loading={loading}
      columns={isEditModeOn ? columns : columnsWithActionMenu}
      className={className}
      dataSource={outfits}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
      rowSelection={isEditModeOn ? rowSelection : undefined}
    />
  );
};
