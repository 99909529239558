import React, { useMemo } from 'react';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

export const CategoriesTable = ({
  loading,
  categories,
  pagination,
  onTableChange,
  selectedCategories,
  setSelectedCategories
}) => {
  const { t, i18n } = useTranslation();
  const text = textFrom('components.categoriesTable', t);
  const language = i18n.language;

  const handleRowCheck = (_, rows) => {
    setSelectedCategories(rows);
  };

  const rowSelectionConfig = useMemo(() => ({
    onChange: handleRowCheck,
    selectedRowKeys: selectedCategories.map(category => category.key)
  }), [selectedCategories]);

  const COLUMNS = [
    {
      title: text('image'),
      dataIndex: 'photoAttributes',
      render: (url) =>
        <div className='staticImage'>
          <img
            className='w-8 h-8 object-cover object-center'
            src={url}
          />
        </div>
    },
    {
      title: text('name'),
      dataIndex: ['localizedValues', `${language}`, 'name'],
      render: name => name
    },
    {
      title: text('key'),
      dataIndex: 'businessKey',
      render: (businessKey, row) => businessKey || row.key
    }
  ];

  return (
    <Table
      loading={loading}
      columns={COLUMNS}
      dataSource={categories}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
      rowSelection={rowSelectionConfig}
    />
  );
};
