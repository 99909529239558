import { AxiosClient } from 'src/api/AxiosClient';
import { UpdateAvailabilityPayload } from 'src/modules/warehouses/domain/models/UpdateAvailabilityPayload';

export class StocksAPI {
  static async getStocks (data) {
    return AxiosClient.get('v2/products/stocks', { params: data });
  }

  static async updateStockAvailability (record, availability) {
    return AxiosClient.put(`/products/${record.productId}/variants/${record.variantId}/availabilities/${record.id}`, new UpdateAvailabilityPayload(record, availability));
  }
}
