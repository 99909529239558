export class AttributePayload {
  constructor (code, key) {
    this.attributeCode = code;

    let normalizedKey = key;
    let isString = typeof(normalizedKey) === 'string' || normalizedKey instanceof String;
    
    if (!isString && key.key != undefined) {
      normalizedKey = key.key;
    }

    this.key = normalizedKey;
  }


}