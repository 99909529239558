import { AxiosClient } from 'src/api/AxiosClient';

export class CollectionsAPI {
  static async getCollectionGroups (data) {
    return AxiosClient.get('/backoffice/collections/group',
      {
        params: {
          limit: data?.limit,
          pageNumber: data?.pageNumber,
          text: data?.filters?.text
        }
      }
    );
  }

  static async getOutfitCollections (data) {
    return AxiosClient.get('/backoffice/collections/outfit',
      {
        params: {
          limit: data?.limit,
          pageNumber: data?.pageNumber,
          text: data?.filters.text
        }
      }
    );
  }

  static async upsertCollection (payload) {
    const config = {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    };
    return AxiosClient.post('/backoffice/collections', payload, config);
  }

  static async getCollectionGroupDetail (collectionGroupId) {
    return AxiosClient.get(`/backoffice/collections/${collectionGroupId}/group/linked`);
  }

  static async getOutfitCollectionDetail (outfitCollectionId) {
    return AxiosClient.get(`/backoffice/collections/${outfitCollectionId}/outfit/linked`);
  }

  static async bulkSetLinkedOutfitsToOutfitCollection (outfitCollectionId, payload) {
    return AxiosClient.put(`/backoffice/collections/${outfitCollectionId}/outfit/linked`, payload);
  }

  static async bulkSetLinkedCollectionsToCollectionGroup (collectionGroupId, payload) {
    return AxiosClient.put(`/backoffice/collections/${collectionGroupId}/group/linked`, payload);
  }

  static async deleteOutfitcCollection (outfitCollectionId) {
    return AxiosClient.delete(`/backoffice/collections/${outfitCollectionId}`);
  }

  static async changeOutfitCollectionAvailabilityStatus (outfitCollectionId, status) {
    return AxiosClient.post(`/backoffice/collections/${outfitCollectionId}/publish/${status}`);
  }

  static async deleteCollectionGroup (collectionGroupId) {
    return AxiosClient.delete(`/backoffice/collections/${collectionGroupId}`);
  }

  static async changeCollectionGroupAvailabilityStatus (collectionGroupId, status) {
    return AxiosClient.post(`/backoffice/collections/${collectionGroupId}/publish/${status}`);
  }
}
