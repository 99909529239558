import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { AuthoritiesAPI } from '../api/AuthoritiesAPI';
import { DualListSelector } from 'src/components/DualListSelector';

export const AuthorizationSelector = ({
  selectedAuths,
  setSelectedAuths
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.authorizationSelector', t);

  const [auths, setAuths] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAuths = async () => {
    setIsLoading(true);
    try {
      const res = await AuthoritiesAPI.getAuthorities();
      const authNames = res?.data?.data?.map(a => a.name);
      setAuths(authNames || []);
    } catch (e) {
      message.error(text('failedToRetrieveAuthorizations'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAuths();
  }, []);

  return (
    <DualListSelector
      isLoading={isLoading}
      items={auths}
      selectedItems={selectedAuths}
      setSelectedItems={setSelectedAuths}
      searchPlaceholder={text('searchPlaceholder')}
      sourceTitle={text('authorizations')}
      targetTitle={text('selectedAuthorizations')}
    />
  );
};
