import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { LocalBusinessAPI } from '../api/localBusinessAPI';
import { message } from 'src/services/Messages.service';
import { useDepartment } from '../provider/department.provider';
import { PageTitle } from 'src/components/PageTitle';
import { Routes } from 'src/router/Routes.helper';
import { getPermissionSetsFromDepartment } from 'src/utils/utils';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { LocalBusinessForm } from '../component/LocalBusinessForm';
import { LOCALBUSINESS_FORM, getCheckedTags } from '../utils/utils';

export const PageCreateLocalBusiness = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.localbusinesses/create', t);
  const departmentText = textFrom('entities.departments', t);

  const [form] = useForm();
  const history = useHistory();
  const { departments } = useDepartment();

  const navigateToLocalBusinessesPage = () => {
    history.push(Routes.PATHS.LOCAL_BUSINESSES);
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton
          label={text('cancel')}
          onClick={navigateToLocalBusinessesPage}
        />
      </Col>
      <Col>
        <SubmitButton
          label={text('saveNewLocalBusiness')}
        />
      </Col>
    </Row>
  );

  const onFinish = async ({
    affiliatedCategory,
    backofficeUserEmail,
    city,
    department,
    externalBusinessId,
    localBusinessName,
    parentCategory,
    streetAddress,
    stripeId,
    taxID,
    zipCode,
    username
  }) => {
    const getCheckedTagsAt = getCheckedTags(form, LOCALBUSINESS_FORM.KEY, LOCALBUSINESS_FORM.CHECKED);

    const selectedGeneralTags = getCheckedTagsAt(LOCALBUSINESS_FORM.GENERAL_TAGS);
    const selectedParentCategoryTags = getCheckedTagsAt(LOCALBUSINESS_FORM.MACRO_TAGS);
    const selectedAffiliatedCategoryTags = getCheckedTagsAt(LOCALBUSINESS_FORM.MICRO_TAGS);

    const localBusinessData = {
      backofficeUserEmail: backofficeUserEmail,
      role: 'ROLE_LOCAL_BUSINESS_MANAGER',
      localBusinessName: localBusinessName,
      backofficeUser: {
        type: '',
        nickName: '',
        givenName: '',
        familyName: '',
        backofficeLanguage: '',
        permissionSets: getPermissionSetsFromDepartment(department),
        username: username
      },
      warehouse: {
        company: localBusinessName,
        type: department === 'food' ? 'UNLIMITED' : null,
        slug: localBusinessName
      },
      localBusiness: {
        address: {
          city: city,
          streetAddress: streetAddress,
          postalCode: zipCode
        },
        department: department,
        externalLocalBusinessId: externalBusinessId,
        macroCategoryKey: (parentCategory && parentCategory !== 'any') ? parentCategory : null,
        microCategoryKey: (affiliatedCategory && affiliatedCategory !== 'any') ? affiliatedCategory : null,
        tags: selectedGeneralTags.concat(selectedParentCategoryTags).concat(selectedAffiliatedCategoryTags),
        taxID: taxID
      },
      paymentServiceAccounts: [{
        paymentSystem: 'stripe-card',
        accountId: stripeId
      }]
    };
    try {
      await LocalBusinessAPI.createLocalBusiness(localBusinessData);
      message.success(text('createOK'));
      navigateToLocalBusinessesPage();
    } catch (e) {
      if (e?.response?.data?.status === 409) {
        message.error(text('emailAlreadyExists', { email: backofficeUserEmail }));
      } else {
        message.error(text('createKO'));
      }
    }
  };

  const toDepartmentOption = dept => ({
    'data-testid': 'department-option',
    key: dept.key,
    value: dept.key,
    label: departmentText(dept.key)
  });

  const departmentOptions = useMemo(() => (
    departments.map(toDepartmentOption) || []
  ), [departments]);

  return (
    <div className='py-4'>
      <PageTitle>{text('createNewLocalBusiness')}</PageTitle>

      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToLocalBusinessesPage}
        title={text('backToLocalBusinesses')}
      />

      <LocalBusinessForm
        form={form}
        mode='create'
        departmentOptions={departmentOptions}
        buttons={buttons}
        onFinish={onFinish}
      />
    </div>
  );
};
