import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { LookbookConfigAPI } from 'src/modules/admin/api/LookbookConfigAPI';

const LookbookConfigContext = createContext();

const useProvideLookbookConfig = (text) => {
  const { userInfo } = useAuth();
  const [config, setConfig] = useState();

  const getConfig = useCallback(async () => {
    try {
      const localBusinessId = userInfo?.localBusiness?.id;
      const res = await LookbookConfigAPI.getConfig(localBusinessId);
      setConfig(res?.data);
      return Promise.resolve();
    } catch (e) {
      if (e.response.status === 404) {
        setConfig(undefined);
      } else {
        message.error(text('failedToRetrieveLookbookConfig'));
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo?.localBusiness?.id) {
      getConfig();
    }
  }, []);

  return {
    config,
    refreshData: getConfig,
    setConfig
  };
};

export const LookbookConfigProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.lookbookConfig', t);

  const config = useProvideLookbookConfig(text);

  return (
    <LookbookConfigContext.Provider value={config}>
      {children}
    </LookbookConfigContext.Provider>
  );
};

export const useLookbookConfig = () => useContext(LookbookConfigContext);
