import React, { Fragment } from 'react';
import { Typography, Divider } from 'antd';
import { ORDER_STATUS as EVENT_ORDER_STATUS } from 'src/constants/eventStatus';
import { ORDER_STATUS as FOOD_ORDER_STATUS } from 'src/constants/foodStatus';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { deliveryTimeFrom } from 'src/utils/utils';
import { useLanguages } from 'src/modules/global/provider/languages.provider';

const { Text, Title } = Typography;

export const OrderDetails = ({ selectedOrder, orderType, canSeeCustomerData }) => {
  if (orderType === 'event') {
    return <EventOrderDetails selectedOrder={selectedOrder} canSeeCustomerData={canSeeCustomerData} />;
  } else if (orderType === 'food') {
    return <FoodOrderDetails selectedOrder={selectedOrder} canSeeCustomerData={canSeeCustomerData} />;
  } else if (orderType === 'fashion') {
    return <FashionOrderDetails selectedOrder={selectedOrder} canSeeCustomerData={canSeeCustomerData} />;
  }
};

const EventOrderDetails = ({ selectedOrder, canSeeCustomerData }) => {
  const { t } = useTranslation();
  const text = textFrom('components.orderDetails', t);
  const { defaultLanguage } = useLanguages();

  const {
    orderIdentifier,
    status,
    productOrderList,
    totals,
    customerEmail,
    placeDate,
    customer
  } = selectedOrder;

  const durationInMin = productOrderList[0].attributeList.filter(item => item.attributeCode === 'durationInMin');
  const startTime = productOrderList[0].attributeList.filter(item => item.attributeCode === 'start_time');
  const startDate = productOrderList[0].attributeList.filter(item => item.attributeCode === 'start_date');

  const sumTicketsPurchasedQuantity = productOrderList.reduce(
    (previousValue, currentValue) => previousValue + currentValue.purchasedQuantity,
    0
  );

  return (
    <div className='py-4 px-7'>
      <div className='w-4/4 space-y-6'>
        <div className='grid grid-cols-2 mb-6'>
          <div className='flex flex-col'>
            <SingleDetail label={text('orderNumber')} value={orderIdentifier} />
          </div>
          <div className='flex flex-col'>
            <Text>{text('orderDate')}</Text>
            <div className='flex items-center space-x-2'>
              <Text style={{ fontWeight: '600' }}>
                {moment(placeDate).format('HH:mm DD MMM YYYY')}
              </Text>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 mb-6'>
          <div className='flex flex-col'>
            {status && (
              <SingleDetail
                label={text('orderStatus')}
                value={EVENT_ORDER_STATUS[status]}
              />
            )}
          </div>
          <div className='flex flex-col'>
            <SingleDetail label={text('numberOfTickets')} value={sumTicketsPurchasedQuantity} />
          </div>
        </div>
        <div className='grid grid-cols-2 mb-6'>
          <div className='flex flex-col'>
            <SingleDetail
              label={text('totalAmount')}
              value={`€ ${totals.finalGrandTotal}`}
            />
          </div>
        </div>
        <div className='grid grid-cols-2 mb-6' />

        <Divider />
        {canSeeCustomerData &&
          <>
            <div className='mb-6'>
              <Title level={5}>{text('customer')}</Title>
            </div>
            <SingleDetail
              label={text('name')}
              value={`${customer.givenName} ${customer.familyName}`}
            />
            <SingleDetail
              label={text('email')}
              value={customerEmail}
            />
            <SingleDetail
              label={text('phoneNumber')}
              value={customer.telephone || '-'}
            />
            <Divider />
          </>}

        <div className='mb-6'>
          <Title level={5}>{text('event')}</Title>
        </div>
        {/* TODO add link to event when parentId is fetchable example: /app/event/edit/${productOrderList[0].parentId} */}
        <div className='flex flex-col'>
          <Text>{text('eventDate')}</Text>
          <div className='flex items-center space-x-2'>
            {startTime && durationInMin &&
              <span
                style={{
                  fontWeight: '600',
                  fontSize: 12,
                  lineHeight: '14px',
                  marginTop: '0.25rem'
                }}
              >
                {moment(startTime[0].key, 'HH:mm').format('HH:mm')} - {moment(startTime[0].key, 'HH:mm').add(durationInMin[0].key, 'minutes').format('HH:mm')}
              </span>}
            {startDate &&
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 12,
                  lineHeight: '14px',
                  marginTop: '0.25rem'
                }}
              >
                {moment(startDate[0].key).format('DD MMM YYYY')}
              </Text>}
          </div>
        </div>
        {productOrderList?.map((itm, index) =>
          <div className='grid grid-cols-4 mb-6' key={index}>
            <Product label={text('name')} value={itm?.names[defaultLanguage?.language]?.value} />
            <Product label='Location' value={itm?.localizedAttributeVariants?.location} />
            <Product label={text('quantity')} value={itm?.purchasedQuantity} />
            <Product label={text('price')} currency='€' value={itm?.prices.finalSellingPrice} />
          </div>
        )}
        {/* <SingleDetail label='Place' value='place' /> */}
      </div>
    </div>
  );
};

const FoodOrderDetails = ({ selectedOrder, canSeeCustomerData }) => {
  const { t } = useTranslation();
  const text = textFrom('components.orderDetails', t);
  const { defaultLanguage } = useLanguages();

  const {
    orderIdentifier,
    status,
    productOrderList,
    totals,
    customer,
    cart,
    customerEmail,
    customerBillingAddress,
    pickupSlot,
    placeDate
  } = selectedOrder;

  return (
    <div className='py-4 px-7'>
      <div className='w-4/4'>
        <div className='grid grid-cols-2 mb-6'>
          <SingleDetail label={text('orderNumber')} value={orderIdentifier} />
          <SingleDetail label={text('orderStatus')} value={FOOD_ORDER_STATUS[status]} />
        </div>
        <div className='grid grid-cols-2 mb-6'>
          <div className='flex flex-col'>
            <Text>{text('orderDate')}</Text>
            <div className='flex items-center space-x-2'>
              <Text style={{ fontWeight: '600' }}>
                {moment(placeDate).format('HH:mm DD MM YYYY')}
              </Text>
            </div>
          </div>
          <SingleDetail
            label={text('deliveryTime')}
            value={deliveryTimeFrom(pickupSlot.start)}
          />
        </div>
        <div className='grid grid-cols-2  mb-6'>
          <SingleDetail
            label={text('quantity')}
            value={cart.itemQuantity}
          />
          <SingleDetail
            label={text('totalAmount')}
            value={`€ ${totals.finalSellingPrice}`}
          />
        </div>
        <Divider />
        {canSeeCustomerData &&
          <>
            <div className='mb-6'>
              <Title level={5}>{text('customer')}</Title>
            </div>
            {customer &&
              <SingleDetail
                label={text('name')}
                value={`${customer.givenName || ''} ${customer.familyName || ''}`}
              />}
            <br />
            <SingleDetail
              label={text('email')}
              value={customerEmail}
            />
            <br />
            {customerBillingAddress &&
              <SingleDetail
                label={text('phoneNumber')}
                value={customerBillingAddress.telephone || '-'}
              />}
            <Divider />
          </>}
        <div className='mb-6'>
          <Title level={5}>{text('products')}</Title>
        </div>
        {productOrderList?.map((itm, index) =>
          <div className='grid grid-cols-4 mb-6' key={index}>
            <Product label={text('name')} value={itm?.names[defaultLanguage?.language]?.value} />
            <Product label='Size' value={itm?.localizedAttributeVariants?.size} />
            <Product label={text('quantity')} value={itm?.purchasedQuantity} />
            <Product label={text('price')} currency='€' value={itm?.prices.finalSellingPrice} />
          </div>
        )}
      </div>
    </div>
  );
};

const FashionOrderDetails = ({ selectedOrder, canSeeCustomerData }) => {
  const { t } = useTranslation();
  const text = textFrom('components.orderDetails', t);
  const statusText = textFrom('constants.fashionOrderStatuses', t);

  const {
    orderIdentifier,
    status,
    productOrderList,
    totals,
    customer,
    cart,
    customerEmail,
    customerBillingAddress,
    deliveryOption,
    placeDate
  } = selectedOrder;

  return (
    <div className='py-4 px-7'>
      <div className='w-4/4'>
        <div className='grid grid-cols-2 mb-6'>
          <SingleDetail label={text('orderNumber')} value={orderIdentifier} />
          <SingleDetail label={text('orderStatus')} value={statusText(status)} />
        </div>
        <div className='grid grid-cols-2 mb-6'>
          <SingleDetail
            label={text('orderDate')}
            value={moment(placeDate).format('HH:mm DD MM YYYY')}
          />
          <SingleDetail
            label={text('deliveryMethod')}
            value={deliveryOption?.name}
          />
        </div>
        <div className='grid grid-cols-2 mb-6'>
          <SingleDetail
            label={text('quantity')}
            value={cart.itemQuantity}
          />
          <SingleDetail
            label={text('totalAmount')}
            value={`€ ${totals.finalSellingPrice}`}
          />
        </div>
        <Divider />
        {canSeeCustomerData &&
          <>
            <div className='mb-6'>
              <Title level={5}>{text('customer')}</Title>
            </div>
            {customer &&
              <SingleDetail
                label={text('name')}
                value={`${customer.givenName || ''} ${customer.familyName || ''}`}
              />}
            <br />
            {customerEmail &&
              <SingleDetail
                label={text('email')}
                value={customerEmail}
              />}
            <br />
            {customerBillingAddress &&
              <SingleDetail
                label={text('phoneNumber')}
                value={customerBillingAddress.telephone || '-'}
              />}
            <Divider />
          </>}
        <div className='mb-6'>
          <Title level={5}>{text('products')}</Title>
        </div>
        {productOrderList?.map((itm, index) => <FashionAttributes product={itm} key={index} />)}
      </div>
    </div>
  );
};

const FashionAttributes = ({ product }) => {
  const { t } = useTranslation();
  const text = textFrom('components.orderDetails', t);
  const { defaultLanguage } = useLanguages();

  return (
    <div className='grid grid-cols-5 mb-6'>
      <Product label={text('name')} value={product.names?.[defaultLanguage?.language]?.value} />
      <Product label={text('size')} value={product.localizedAttributeVariants?.size} />
      <Product label={text('color')} value={product.localizedAttributeVariants?.color} />
      <Product label={text('quantity')} value={product.purchasedQuantity} />
      <Product label={text('price')} value={product.prices.finalSellingPrice} currency='€' />
    </div>
  );
};

const SingleDetail = ({ label, value }) => {
  return (
    <div className='flex flex-col'>
      <Text>{label}</Text>
      <Text strong>{value}</Text>
    </div>
  );
};

const Product = ({ label, value, linkTo, currency }) => {
  return (
    <div className='flex flex-col'>
      <Text>{label}</Text>
      <Text
        strong
        style={{
          fontSize: 12,
          lineHeight: '14px',
          marginTop: '0.25rem'
        }}
      >{currency} {value}
      </Text>
    </div>
  );
};
