import React, {createContext, useContext, useState, useEffect, useCallback} from "react";
import {message} from "src/services/Messages.service";
import { useLocation } from "react-router-dom";
import { DashboardAPI } from "src/modules/dashboard/api/DashboardAPI"

export const dashboardContext = createContext();

const useProvideDashboard = () => {
  const location = useLocation();
  const [publishedProductCount, setPublishedProductCount] = useState();
  const [outOfStockProductsCount, setOutOfStockProductsCount] = useState();
  const [availableProductsCount, setAvailableProductsCount] = useState();
  const [visualsList, setVisualsList] = useState()
  const [productsList, setProductsList] = useState()
  const [outOfStockProductsList, setOutOfStockProductsList] = useState()
  const [draftProductsCount, setDraftProductsCount] = useState()
  const [pendingGetOutOfStock, setPendingGetOutOfStock] = useState(false)
  const [pendingGetProducts, setPendingGetProducts] = useState(false)
  const [pendingGetVisuals, setPendingGetVisuals] = useState(false)

/**
 * @description Get published product Count
 */
  const getPublishedProductCount = useCallback(async () => {
    try {
      const res = await DashboardAPI.getPublishedProductCount()
      setPublishedProductCount(res.data);

    } catch (e) {
      message.error(e);
    }
  }, []);

/**
 * @description Get product Stock count
 */
  const getProductsStockCount = useCallback(async (avaliabile) => {
    try {
      const res = await DashboardAPI.getProductsStockCount(avaliabile)
      avaliabile ? setAvailableProductsCount(res.data) : setOutOfStockProductsCount(res.data);
    } catch (e) {
      message.error(e);
    }
  }, []);


  /**
   * @description Get visuals
   */
  const getAllVisuals = useCallback(async () => {
    setPendingGetVisuals(true)
    try {
      const res = await DashboardAPI.getAllVisuals()
      setVisualsList(res.data);
    } catch (e) {
      message.error(e);
    } finally {
      setPendingGetVisuals(false)
    }
  }, []);

/**
 * @description Get last updated product list
 */
  const getLastUpdatedProductList = useCallback(async () => {
    setPendingGetProducts(true)
    try {
      const res = await DashboardAPI.getLastUpdatedProductList()
      setProductsList(res.data);
    } catch (e) {
      message.error(e);
    } finally {
      setPendingGetProducts(false)
    }
  }, []);

  /**
   * @description Get out of stock product list
   */
  const getOutOfStockProductList = useCallback(async () => {
    setPendingGetOutOfStock(true)
    try {
      const res = await DashboardAPI.getOutOfStockProductList()
      setOutOfStockProductsList(res.data);
    } catch (e) {
      message.error(e);
    } finally {
      setPendingGetOutOfStock(false)
    }
  }, []);

  /**
   * @description Get draft
   */
  const getDraftProductsCount = useCallback(async () => {
    try {
      const res = await DashboardAPI.getDraftProductsCount()
      setDraftProductsCount(res.data);

    } catch (e) {
      message.error(e);
    }
  }, []);

  useEffect(() => {
    getPublishedProductCount();
    getProductsStockCount(true);
    getProductsStockCount(false);
    getAllVisuals();
    getLastUpdatedProductList();
    getOutOfStockProductList();
    getDraftProductsCount();
  }, [getAllVisuals, getDraftProductsCount, getLastUpdatedProductList, getOutOfStockProductList, getProductsStockCount, getPublishedProductCount, location]);

  return {
    publishedProductCount,
    outOfStockProductsCount,
    availableProductsCount,
    visualsList,
    productsList,
    outOfStockProductsList,
    draftProductsCount,
    pendingGetOutOfStock,
    pendingGetProducts,
    pendingGetVisuals
  };
};

export const DashboardProvider = ({ children }) => {
  const dashboard = useProvideDashboard();
  return (
    <dashboardContext.Provider value={dashboard}>
      {children}
    </dashboardContext.Provider>
  );
};

export const useDashboard = () => useContext(dashboardContext);
