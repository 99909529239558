import React from 'react';
import { Input, Form } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

export const RankingInput = ({ name, label, disabled }) => {
  const { t } = useTranslation();
  const text = textFrom('components.rankingInput', t);

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={0}
      rules={[{
        required: true,
        message: text('integersOnly'),
        pattern: /^(0|[1-9][0-9]*)$/
      }]}
    >
      <Input disabled={disabled} />
    </Form.Item>
  );
};
