import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const WysiwygEditor = ({ initialValue, onChange, name }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  useEffect(() => {
    const editorContent = initialValue
      ? EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(initialValue).contentBlocks
        )
      )
      : EditorState.createEmpty();
    setEditorState(editorContent);
  }, [initialValue]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());
    const text = blocks[0].text;
    const spanRegEx = /^(<span.*>)(.*)(<\/span>)$/gm;
    const formattedString = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll(spanRegEx, '$2');
    onChange(
      name,
      text === ''
        ? text
        : formattedString.substr(0, formattedString.length - 1)
    );
  };
  return (
    <Editor
      editorState={editorState}
      toolbarLocation='bottom'
      toolbarClassName='toolbarClassName'
      wrapperClassName='wrapperClassName'
      editorClassName='editorClassName'
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'textAlign', 'link', 'list', 'image'],
        inline: {
          inDropdown: false,
          options: ['bold', 'italic', 'underline']
        },
        textAlign: {
          inDropdown: false,
          options: ['left', 'center', 'right'],
          left: { className: 'textalignedLeft' }
        },
        list: {
          className: 'editorList',
          inDropdown: true,
          options: ['unordered', 'ordered']
        },
        link: {
          inDropdown: false,
          showOpenOptionOnHover: true,
          defaultTargetOption: '_self',
          options: ['link']
        }
      }}
    >
      <textarea
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      />
    </Editor>
  );
};
