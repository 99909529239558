import React, { useEffect, useState, useMemo } from 'react';
import { Form, Row, Col, Select, Input, DatePicker, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { flatten, nestify } from 'src/utils/objectManipulation';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { ImageUpload } from 'src/modules/admin/component/ImageUpload';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { SEPARATOR } from 'src/utils/constants';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Product } from 'src/services/Product.service';
import { UtilsAPI } from 'src/api/utils';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;
const { RangePicker } = DatePicker;

const NameInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('components.collectionGroupForm', t);

  return (
    <Form.Item
      required
      name={`names${SEPARATOR}${selectedLanguage}`}
      rules={[{ required: true, message: text('form.name.message') }]}
      label={text('form.name.label')}
    >
      <Input.TextArea
        rows='1'
        showCount
        maxLength={100}
        placeholder={text('form.name.placeholder')}
      />
    </Form.Item>
  );
};

const DescriptionInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('components.collectionGroupForm', t);

  return (
    <Form.Item
      required
      name={`descriptions${SEPARATOR}${selectedLanguage}`}
      rules={[{ required: true, message: text('form.description.message') }]}
      label={text('form.description.label')}
    >
      <Input.TextArea
        rows='6'
        showCount
        maxLength={1500}
        placeholder={text('form.description.placeholder')}
      />
    </Form.Item>
  );
};

export const CollectionGroupForm = ({
  collectionGroup,
  onFinish,
  buttons,
  children
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.collectionGroupForm', t);

  const { userInfo } = useAuth();
  const [form] = useForm();

  const { languages, defaultLanguage } = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState();

  const [imageUrl, setImageUrl] = useState(null);
  const localBusinessId = userInfo?.localBusiness?.id;

  const requiredLanguage = useMemo(() => {
    return languages.find(language => language.defaultLanguage);
  }, [languages]);

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguage(languageId);
  };

  useEffect(() => {
    if (defaultLanguage) {
      form.setFieldsValue({ language: defaultLanguage.label });
      setSelectedLanguage(defaultLanguage?.language);
    }
  }, [form, defaultLanguage]);

  const initializeMultiLangForm = (form, collectionGroup) => {
    if (collectionGroup) {
      form.setFieldsValue({
        ...flatten(collectionGroup, ['names', 'descriptions'])
      });
    }
  };

  const setDatesInForm = (startDate, endDate) => {
    const originalDates = [moment(startDate).utc(), moment(endDate).utc()];
    form.setFieldsValue({
      dates: originalDates
    });
  };

  const setImageInForm = (newImageUrl) => {
    setImageUrl(newImageUrl);
    form.setFieldsValue({
      imageUrl: newImageUrl
    });
  };

  const handleImageUpload = async (file) => {
    const result = await UtilsAPI.uploadImage(file);
    const newImageUrl = result?.data?.images[0]?.imageURL;
    setImageInForm(newImageUrl);
  };

  useEffect(() => {
    if (collectionGroup && form) {
      const {
        externalId,
        bannerDesktop: { imageURL: imageUrl },
        startDate,
        endDate,
        published
      } = collectionGroup;

      setDatesInForm(startDate, endDate);
      setImageInForm(imageUrl);
      initializeMultiLangForm(form, collectionGroup);

      form.setFieldsValue({
        id: externalId,
        status: Product.getStatus(published)
      });
    }
  }, [collectionGroup, form]);

  const prepareOnFinish = async (values) => {
    if (selectedLanguage) {
      const { id, imageUrl, dates, status } = values;
      // Get all the values, even the unmounted ones
      const formValues = form.getFieldsValue(true);
      const { names, descriptions } = nestify(formValues, ['names', 'descriptions']);

      if (!names?.[requiredLanguage.language]) {
        message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('form.name.label') }));
      } else if (!descriptions?.[requiredLanguage.language]) {
        message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('form.description.label') }));
      } else {
        const {
          localBusinessId: originalLocalBusinessId,
          externalId: originalExternalId,
          priority: originalPriority,
          type: originalType,
          tagsConditions: originalTagsConditions,
          tags: originalTags
        } = collectionGroup ?? {};

        const preparedPayload = {
          ...collectionGroup,
          localBusinessId: originalLocalBusinessId ?? (localBusinessId ?? null),
          externalId: originalExternalId ?? (id?.trim() || uuidv4()),
          names: names,
          descriptions: descriptions,
          startDate: dates[0].utc().format('x'),
          endDate: dates[1].utc().format('x'),
          bannerDesktop: {
            imageURL: imageUrl
          },
          bannerMobile: {
            imageURL: imageUrl
          },
          icon: {
            imageURL: imageUrl
          },
          discount: 0,
          tags: originalTags ?? [{ key: id }],
          tagsConditions: originalTagsConditions ?? 'ALL',
          type: originalType ?? 'ROOT',
          priority: originalPriority ?? 0,
          published: Product.getStatusBooleanValue(status)
        };
        onFinish(preparedPayload);
      }
    } else {
      message.error(text('onMissingLocale'));
    }
  };

  return (
    <Form
      name='basic'
      initialValues={{
        id: null,
        imageUrl: null,
        names: null,
        descriptions: null,
        status: null,
        dates: null
      }}
      onFinish={prepareOnFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
      className='max-w-8xl'
      scrollToFirstError
    >
      <div className='max-w-5xl'>
        <Row gutter={[16, 16]} className='pt-2 pb-8'>
          <Col xs={4}>
            <LanguageSelect
              label={text('chooseLanguage')}
              placeholder={text('chooseLanguage')}
              onSelect={handleLanguageSelection}
            />
          </Col>
        </Row>
        <Row gutter={20} className='mb-24'>
          <Col xs={4}>
            <Form.Item
              name='imageUrl'
              label={text('form.image.label')}
              rules={[{ required: true, message: text('form.image.message') }]}
            >
              <ImageUpload
                imageUrl={imageUrl}
                onUpload={handleImageUpload}
              />
            </Form.Item>
          </Col>
          <Col xs={8}>
            {!collectionGroup &&
              <Form.Item
                name='id'
                label={text('form.id.label')}
              >
                <Input placeholder={text('form.id.placeholder')} />
              </Form.Item>}
            <NameInput selectedLanguage={selectedLanguage} />
            <DescriptionInput selectedLanguage={selectedLanguage} />
          </Col>
          <Col xs={6}>
            <Form.Item
              name='status'
              label={text('form.status.label')}
              rules={[{ required: true, message: text('form.status.message') }]}
            >
              <Select
                placeholder={text('form.status.placeholder')}
                getPopupContainer={trigger => trigger.parentNode}
                style={{ width: '100%' }}
              >
                <Option value='Draft'>{text('form.status.optionDraft')}</Option>
                <Option value='Published'>{text('form.status.optionPublished')}</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='dates'
              label={text('form.dates.label')}
              rules={[{ required: true, message: text('form.dates.message') }]}
            >
              <RangePicker />
            </Form.Item>
          </Col>
        </Row>
      </div>

      {children}

      {buttons}

    </Form>
  );
};
