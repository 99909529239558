import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  Row,
  Col,
  Form,
  Table,
  message
} from 'antd';
import {
  makeOption,
  replaceAttribute,
  attributeIsLocal,
  attributeIsRemote,
  containsAttribute,
  attributeKeyFormWasEdited,
  attributeCodeFormWasEdited
} from '../utils/utils';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useForm } from 'antd/lib/form/Form';
import { UtilsAPI } from 'src/api/utils';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { PageSection } from 'src/components/PageSection';
import { ImageUpload } from 'src/modules/admin/component/ImageUpload';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { ErrorWrapper } from '../components/ErrorWrapper';
import { AttributesAPI } from '../api/AttributesAPI';
import { useDepartment } from 'src/modules/admin/provider/department.provider';
import { DangerButton } from 'src/components/buttons/DangerButton';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { SelectDepartment } from '../components/SelectDepartment';
import { InputAttributeKey } from '../components/InputAttributeKey';
import { SelectAttributeCode } from '../components/SelectAttributeCode';
import { InputNewAttributeKey } from '../components/InputNewAttributeKey';
import { InputAttributeTranslation } from '../components/InputAttributeTranslation';
import { InputAttributeCodeTranslation } from '../components/InputAttributeCodeTranslation';
import { KEY_REGEX } from 'src/utils/constants';
import { SCOPE } from 'src/utils/scope-utils';

const TEXT_SOURCE = 'pages.product-attributes';

export const PageProductAttributes = () => {
  const { t } = useTranslation();
  const text = textFrom(TEXT_SOURCE, t);

  const [form] = useForm();
  const attributeInputRef = useRef();
  const { selectedUserScope, userDepartments, canAddNewProductAttributeCodes } = useAuth();
  const { languages, defaultLanguage } = useLanguages();
  const { departments } = useDepartment();
  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [department, setDepartment] = useState();
  const [language, setLanguage] = useState();
  const [localCodes, setLocalCodes] = useState([]);
  const [remoteCodes, setRemoteCodes] = useState([]);
  const [attributeCode, setAttributeCode] = useState();
  const [attributeCodeError, setAttributeCodeError] = useState();
  const [attributeCodeInitialTranslations, setAttributeCodeInitialTranslations] = useState(undefined);
  const [isAttributeCodeSelectOpen, setIsAttributeCodeSelectOpen] = useState(false);
  const [attributeKeyError, setAttributeKeyError] = useState();
  const [isAttributeKeyEmpty, setIsAttributeKeyEmpty] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [attributesChecked, setAttributesChecked] = useState([]);
  const [attributesToBeRemoved, setAttributesToBeRemoved] = useState([]);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  // DEPARTMENT ==============================================================================================================
  useEffect(() => {
    const depts =
      selectedUserScope === SCOPE.LOCALBUSINESS
        ? departments.filter(dept => dept.key === userDepartments[0])
        : departments;
    setAvailableDepartments(depts);
  }, [selectedUserScope, userDepartments, departments]);

  const departmentOptions = useMemo(() => (
    availableDepartments?.map(dept => makeOption(dept.key))
  ), [availableDepartments]);

  useEffect(() => {
    if (selectedUserScope === SCOPE.LOCALBUSINESS && userDepartments[0]) {
      handleDepartmentSelection(userDepartments[0]);
      form.setFieldsValue({ department: userDepartments[0] });
    }
  }, [selectedUserScope, userDepartments, availableDepartments]);

  const handleDepartmentSelection = (departmentKey) => {
    const department = availableDepartments.find(dept => dept.key === departmentKey);
    setDepartment(department);
  };

  useEffect(() => {
    resetLocalCodes();
    resetAttributeCode();
    if (department) {
      initRemoteCodes(department);
    }
  }, [department]);

  // LANGUAGE ================================================================================================================
  const handleLanguageSelection = (languageId) => {
    setLanguage(languageId);
  };

  // ATTRIBUTE CODE ==========================================================================================================
  const initRemoteCodes = async (department) => {
    const res = await AttributesAPI.getDistinctCodes(department.key);
    const newDistinctCodes = res?.data?.data || [];
    const newRemoteCodes = newDistinctCodes.filter(code => !department?.declareVariants?.includes(code));
    setRemoteCodes(newRemoteCodes);
  };

  const attributeCodeAlreadyExists = remoteCodes.includes(attributeCode);
  const attributeCodeOptions = remoteCodes.concat(localCodes).map(code => makeOption(code));

  const handleAttributeCodeSelection = (value) => {
    if (KEY_REGEX.test(value)) {
      setAttributeCode(value?.[0]);
      setIsAttributeCodeSelectOpen(false); /* eslint-disable no-unused-expressions */
      attributeInputRef.current?.blur();
    } else {
      setAttributeCodeError({ message: text('keyInvalid'), status: 'error' });
    }
  };

  useEffect(() => {
    resetAttributeCodeError();
    resetAttributeKey();
  }, [attributeCode]);

  const resetAttributeCode = () => {
    setAttributeCodeError(undefined);
    setAttributeCode(undefined);
    form.setFieldsValue({ attributeCode: undefined });
    resetAttributeCodeTranslations();
    resetAttributes();
  };

  const toggleAttributeCodeDropdown = (visible) => {
    setIsAttributeCodeSelectOpen(visible);
  };

  const resetAttributeCodeTranslations = () => {
    setAttributeCodeInitialTranslations(undefined);
    languages.forEach(({ language }) => {
      const codeField = `attributeCodeTranslations-${language}`;
      form.setFieldsValue({ [codeField]: undefined });
    });
  };

  const resetLocalCodes = () => {
    setLocalCodes([]);
  };

  const updateAttributeCodeError = (msg) => {
    setAttributeCodeError({ message: msg, status: 'error' });
  };

  const resetAttributeCodeError = () => {
    setAttributeCodeError({ message: undefined, status: undefined });
  };

  const enterEditMode = () => {
    const variantAlreadyExists = department?.declareVariants?.includes(attributeCode);
    if (variantAlreadyExists) {
      updateAttributeCodeError(text('errConflictingVariantCode'));
    } else {
      setIsEditModeOn(true);
      if (!attributeCodeAlreadyExists) {
        setLocalCodes(codes => [attributeCode, ...codes]);
      }
    }
  };

  // ATTRIBUTE KEY ==========================================================================================================
  const handleAttributeKeyAddition = () => {
    const newAttributeKey = form.getFieldValue('attributeKey');
    validateAttributeKey(newAttributeKey)
      .then(addAttributeKey)
      .catch(updateAttributeKeyError);
  };

  const validateAttributeKey = (rawKey) => {
    const key = rawKey?.trim().toLowerCase();
    if (attributes.some(attribute => attribute.attributeKey?.toLowerCase() === key)) {
      return Promise.reject(new Error(text('errAttributeKeyAlreadyExists')));
    }
    if (!KEY_REGEX.test(key)) {
      return Promise.reject(new Error(text('keyInvalid')));
    }
    return Promise.resolve(key);
  };

  const addAttributeKey = (newAttributeKey) => {
    const keyField = `attributeKey-${newAttributeKey}`;
    const keyValue = newAttributeKey;
    form.setFieldsValue({ [keyField]: keyValue });
    resetAttributeKey();
    const newAttribute = {
      key: newAttributeKey,
      attributeKey: newAttributeKey,
      attributeCode: attributeCode,
      editable: true
    };
    setAttributes(oldAttributes => [newAttribute, ...oldAttributes]);
    setIsAttributeKeyEmpty(true);
  };

  const updateAttributeKeyError = (err) => {
    setAttributeKeyError({ message: err.message, status: 'error' });
  };

  const handleAttributeKeyChange = (e) => {
    resetAttributeKeyError();
    const input = e.target.value?.trim();
    if (!input) {
      setIsAttributeKeyEmpty(true);
    } else {
      setIsAttributeKeyEmpty(false);
    }
  };

  const resetAttributeKeyError = () => {
    setAttributeKeyError({ message: undefined, status: undefined });
  };

  const resetAttributeKey = () => {
    form.setFieldsValue({ attributeKey: undefined });
    resetAttributeKeyError();
  };

  const initializeAttributes = useCallback(async (attributeCode) => {
    form.setFieldsValue({ attributeCode: attributeCode });
    const res = await AttributesAPI.getAttributes(department.key, attributeCode);
    const newAttributes = res?.data?.data?.items || [];
    const firstAttribute = newAttributes[0];
    if (firstAttribute) {
      const codeTranslations = {};
      languages.forEach(({ language }) => {
        const codeField = `attributeCodeTranslations-${language}`;
        const codeValue = firstAttribute.names[language].locale;
        form.setFieldsValue({ [codeField]: codeValue });
        codeTranslations[language] = codeValue;
      });
      setAttributeCodeInitialTranslations(codeTranslations);
    }
    newAttributes.forEach(attribute => {
      attribute.key = attribute.id; // NB: Do not change/rename the 'key' field, as it is necessary to be able to select rows in the table!
      initAttributeForm(attribute);
    });
    setAttributes(newAttributes);
  }, [department]);

  const removeAttributesLocally = () => {
    setAttributes(locals => locals.filter(local => !containsAttribute(attributesChecked, local)));
    setAttributesToBeRemoved(attributesChecked);
    resetAttributesChecked();
  };

  // ATTRIBUTES TABLE ====================================================================================================
  const columns = [
    {
      title: text('image'),
      width: '15%',
      render: (_, row) =>
        <Form.Item name={[`attributeImage-${row.attributeKey}`]}>
          {(row?.href || isEditModeOn) &&
            <ImageUpload
              imageUrl={row.href}
              isDisabled={!isEditModeOn || !row?.editable}
              onUpload={handleImageUpload.bind(_, row)}
            />}
        </Form.Item>
    },
    {
      title: text('key'),
      width: '40%',
      render: (_, row) =>
        <InputAttributeKey
          attributeKey={row.attributeKey}
          isDisabled
        />
    },
    {
      title: text('translation'),
      render: (_, row) =>
        <InputAttributeTranslation
          attributeKey={row.attributeKey}
          isDisabled={!isEditModeOn || !row?.editable}
          language={language}
        />
    }
  ];

  const handleImageUpload = async (oldAttribute, file) => {
    const res = await UtilsAPI.uploadImage(file);
    const newHref = res?.data?.images?.[0]?.imageURL;
    form.setFieldsValue({ [`attributeImage-${oldAttribute.attributeKey}`]: newHref }); // This enables the use of the 'isFieldTouched' antd function later
    if (newHref) {
      const newAttribute = { ...oldAttribute, href: newHref };
      setAttributes(replaceAttribute(newAttribute, attributes));
      setAttributesChecked(replaceAttribute(newAttribute, attributesChecked));
    } else {
      message.error(text('onImageUploadError'));
    }
  };

  const initAttributeForm = (attribute) => {
    const keyField = `attributeKey-${attribute.attributeKey}`;
    const keyValue = attribute.attributeKey;
    form.setFieldsValue({ [keyField]: keyValue });
    languages.forEach(({ language }) => {
      const translationField = `attributeTranslations-${attribute.attributeKey}-${language}`;
      const translationValue = attribute.names[language].value;
      form.setFieldsValue({ [translationField]: translationValue });
    });
  };

  const handleAttributeChecking = (_, rows) => {
    setAttributesChecked(rows);
  };

  const resetAttributes = () => {
    setAttributes([]);
    resetAttributesChecked();
  };

  const resetAttributesChecked = () => {
    setAttributesChecked([]);
  };

  const resetAttributesToBeRemoved = () => {
    setAttributesToBeRemoved([]);
  };

  const rowSelectionConfig = {
    // NB: each row in the table must have a 'key' field for this to work!
    onChange: handleAttributeChecking,
    getCheckboxProps: (row) => ({ disabled: !row.editable })
  };

  // SAVING/DISMISSING ====================================================================================================
  const handleSaveChanges = async () => {
    if (!attributeTranslationsAreMissing()) {
      const attributeIdsToBeRemoved = getAttributeIdsToBeRemoved();
      const attributesToBeUpserted = getAttributesToBeUpserted();
      setIsSaveLoading(true);
      if (attributesToBeUpserted.length === 0 && attributeIdsToBeRemoved.length === 0) {
        message.warning(text('onSaveNothing'));
      } else {
        try {
          if (attributeIdsToBeRemoved.length > 0) {
            const res = await removeAttributesRemotely(attributeIdsToBeRemoved);
            if (res.data?.data?.skippedElements?.length > 0) {
              message.warning(text('onRemoveSkipped'), 5);
            } else {
              message.success(text('onRemoveSuccess'));
            }
          }
        } catch (err) {
          message.error(text('onRemoveError'));
        }
        try {
          if (attributesToBeUpserted.length > 0) {
            await upsertAttributesRemotely(attributesToBeUpserted);
            message.success(text('onUpsertSuccess'));
          }
        } catch (err) {
          message.error(text('onUpsertError'));
        }
      }
      initRemoteCodes(department);
      resetLocalCodes();
      leaveEditMode();
      setIsSaveLoading(false);
    } else {
      message.error(text('errAttributeTranslationsMissing', { language: defaultLanguage.label }));
    }
  };

  const attributeTranslationsAreMissing = () => {
    return attributes.some(attr => !form.getFieldValue([`attributeTranslations-${attr.attributeKey}-${defaultLanguage.language}`]));
  };

  const getAttributeIdsToBeRemoved = () => {
    return attributesToBeRemoved.filter(attributeIsRemote).map(attr => attr.id);
  };

  const getAttributesToBeUpserted = () => {
    return (
      attributeCodeWasEdited(attributeCodeInitialTranslations)
        ? attributes
        : attributes.filter(a => attributeIsLocal(a) || attributeKeyWasEdited(a))
    );
  };

  const handleDismissChanges = () => {
    leaveEditMode();
  };

  const leaveEditMode = () => {
    resetAttributesChecked();
    resetAttributesToBeRemoved();
    initializeAttributes(attributeCode);
    setIsEditModeOn(false);
  };

  const removeAttributesRemotely = async (attributeIds) => {
    return await AttributesAPI.deleteAttributeKeys(attributeIds);
  };

  const upsertAttributesRemotely = async (attributes) => {
    await AttributesAPI.bulkUpsertAttributes(attributes.map(toAttribute));
  };

  const toAttribute = (attribute) => {
    const attributeKey = form.getFieldValue(`attributeKey-${attribute.attributeKey}`);
    const translatedAttributeKey = getTranslations(`attributeTranslations-${attribute.attributeKey}`);
    const translatedAttributeCode = getTranslations('attributeCodeTranslations');
    return {
      departments: [department?.key],
      href: attribute.href,
      attributeCode: attribute.attributeCode,
      key: attributeKey,
      localizedNames: translatedAttributeKey,
      localizedCodes: translatedAttributeCode
    };
  };

  const getTranslations = (field) => {
    const translations = {};
    languages.forEach(({ language }) => {
      const translation = form.getFieldValue(`${field}-${language}`);
      if (translation) {
        translations[language] = translation;
      }
    });
    return translations;
  };

  const attributeKeyWasEdited = useCallback(attribute => (
    attributeKeyFormWasEdited(form, languages, attribute)
  ), [languages]);

  const attributeCodeWasEdited = useCallback(initialTranslations => (
    attributeCodeFormWasEdited(form, languages, initialTranslations)
  ), [languages]);

  return (
    <div className='py-4 bg-gray-100 page-filters'>
      <PageTitle>{text('pageTitle')}</PageTitle>
      <Form
        form={form}
        layout='vertical'
      >
        <Row gutter={20} className='mt-4 mb-2'>
          <Col xs={7}>
            <SelectDepartment
              disabled={isEditModeOn}
              textSource={TEXT_SOURCE}
              options={departmentOptions}
              onSelect={handleDepartmentSelection}
            />
          </Col>
          <Col xs={7}>
            <PageSection>{text('language')}</PageSection>
            <LanguageSelect
              label={text('languageLabel')}
              placeholder={text('languagePlaceholder')}
              onSelect={handleLanguageSelection}
            />
          </Col>
        </Row>

        <PageSection>{text('attribute')}</PageSection>
        <Row gutter={30} className='mb-2'>
          <Col xs={7}>
            <ErrorWrapper minHeight={90}>
              <SelectAttributeCode
                label={canAddNewProductAttributeCodes ? text('attributeLabelModifyOrAdd') : text('attributeLabelModify')}
                placeholder={canAddNewProductAttributeCodes ? text('attributePlaceholderModifyOrAdd') : text('attributePlaceholderModify')}
                errorMessage={text('keyInvalid')}
                ref={attributeInputRef}
                value={attributeCode}
                error={attributeCodeError}
                options={attributeCodeOptions}
                onSelect={initializeAttributes}
                open={isAttributeCodeSelectOpen}
                onReset={resetAttributeCode}
                onChange={handleAttributeCodeSelection}
                disabled={!department || !language || isEditModeOn}
                onDropdownVisibleChange={toggleAttributeCodeDropdown}
              />
            </ErrorWrapper>
          </Col>
          <Col xs={7}>
            <InputAttributeCodeTranslation
              textSource={TEXT_SOURCE}
              disabled={!attributeCode || !isEditModeOn}
              language={language}
            />
          </Col>
        </Row>

        <Row gutter={30} className='mt-6 mb-10'>
          <Col>
            <PrimaryButton
              onClick={enterEditMode}
              disabled={isEditModeOn || !attributeCode || (!attributeCodeAlreadyExists && !canAddNewProductAttributeCodes)}
              label={canAddNewProductAttributeCodes && attributeCode && !attributeCodeAlreadyExists ? text('addAttributeCode') : text('modifyAttributeCode')}
            />
          </Col>
          {
              isEditModeOn &&
                <>
                  <Col>
                    <DefaultButton
                      label={text('cancel')}
                      onClick={handleDismissChanges}
                    />
                  </Col>
                  <Col>
                    <PrimaryButton
                      label={text('save')}
                      loading={isSaveLoading}
                      onClick={handleSaveChanges}
                    />
                  </Col>
                </>
            }
        </Row>

        {isEditModeOn &&
          <div className='minHeight120'>
            <PageSection>{text('keys')}</PageSection>
            <div>{text('keyLabel')}</div>
            <Row gutter={20} className='mt-2 mb-8'>
              <Col xs={8}>
                <ErrorWrapper minHeight={60}>
                  <InputNewAttributeKey
                    textSource={TEXT_SOURCE}
                    error={attributeKeyError}
                    disabled={!attributeCode}
                    onChange={handleAttributeKeyChange}
                  />
                </ErrorWrapper>
              </Col>
              <Col flex='auto'>
                <PrimaryButton
                  label={text('addAttributeKey')}
                  onClick={handleAttributeKeyAddition}
                  disabled={!attributeCode || isAttributeKeyEmpty}
                />
              </Col>
              <Col>
                <DangerButton
                  label={text('removeSelected')}
                  onClick={removeAttributesLocally}
                  disabled={!attributesChecked.length}
                />
              </Col>
            </Row>
          </div>}

        <Table
          pagination={false}
          columns={columns}
          dataSource={isEditModeOn ? attributes.filter(a => a.editable) : attributes}
          rowSelection={isEditModeOn ? rowSelectionConfig : undefined}
        />

      </Form>
    </div>
  );
};
