import React, { useState, useCallback } from 'react';
import { Col, Row, message } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { OutfitsAPI } from '../api/OutfitsAPI';
import { useOutfits } from '../provider/outfits.provider';
import { OutfitTable } from '../components/OutfitTable';
import { CenteredText } from 'src/components/CenteredText';
import { DeletionModal } from 'src/components/DeletionModal';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { Chips } from 'src/components/Chips';

export const PageOutfits = () => {
  const { t, i18n } = useTranslation();
  const text = textFrom('pages.outfits', t);
  const textFilter = textFrom('constants.filter', t);

  const history = useHistory();
  const {
    outfits,
    pagination,
    onChangeQuery,
    areOutfitsLoading,
    query,
    refreshOutfits
  } = useOutfits();
  const [outfitToBeDeleted, setOutfitToBeDeleted] = useState(undefined);

  const FILTERS = {
    TEXT: 'text'
  };
  const { TEXT } = FILTERS;

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const goToCreateOutfitPage = useCallback(() => {
    history.push(Routes.PATHS.OUTFIT_CREATE);
  }, []);

  const goToEditOutfitPage = (outfit) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.EDIT_OUTFIT, { id: outfit.id }));
  };

  const openDeletionModal = (outfit) => {
    setOutfitToBeDeleted(outfit);
  };

  const OutfitSearch = () => (
    <SearchInput
      autoFocus
      defaultValue={query.text}
      onChangeText={handleSearchOutfit}
      placeholder={text('searchPlaceholder')}
    />
  );

  const handleSearchOutfit = (searchedText) => {
    onChangeQuery({
      ...query,
      text: searchedText
    });
  };

  const toggleOutfitStatus = async (outfit) => {
    const newStatus = !outfit?.published;
    try {
      await OutfitsAPI.changeOutfitStatus(outfit.id, newStatus);
      message.success(text('onStatusUpdateOk'));
    } catch (err) {
      const errDetail = ` ${err.response?.data?.detail || ''}`;
      message.error(`${text('onStatusUpdateErr')}${errDetail}`);
    } finally {
      refreshOutfits();
    }
  };

  const confirmOutfitDeletion = async () => {
    try {
      await OutfitsAPI.deleteOutfit(outfitToBeDeleted.id);
      message.success(text('onDeleteOk'));
    } catch (err) {
      const errDetail = ` ${err.response?.data?.detail || ''}`;
      message.error(`${text('onDeleteErr')}${errDetail}`);
    } finally {
      resetOutfitToBeDeleted();
      refreshOutfits();
    }
  };

  const resetOutfitToBeDeleted = () => {
    setOutfitToBeDeleted(undefined);
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <Row gutter={[16, 16]} className='pt-2'>
        <Col span={6}>
          <OutfitSearch />
        </Col>
        <Col className='last-column'>
          <PrimaryButton
            label={text('createOutfit')}
            onClick={goToCreateOutfitPage}
          />
        </Col>
      </Row>

      {/* Filter Chips */}
      {!!query?.text &&
        <Row className='py-4'>
          <div className='pr-2'>{textFilter('appliedFilter_one')}:
            <Chips
              query={query}
              chipKeys={[TEXT]}
              onTagRemove={() => handleSearchOutfit('')}
            />
          </div>
        </Row>}

      {!areOutfitsLoading && !outfits?.length
        ? (<CenteredText>{text('noOutfitsFound')}</CenteredText>)
        : (
          <div className='pt-8'>
            <OutfitTable
              isEditModeOn={false}
              loading={areOutfitsLoading}
              outfits={outfits}
              onEdit={goToEditOutfitPage}
              onChangeStatus={toggleOutfitStatus}
              onDelete={openDeletionModal}
              pagination={pagination}
              onTableChange={onTableChange}
            />
          </div>)}

      <DeletionModal
        visible={!!outfitToBeDeleted}
        onOk={confirmOutfitDeletion}
        onCancel={resetOutfitToBeDeleted}
      >
        <div>{text('confirmDeletion', { name: outfitToBeDeleted?.names?.[`${i18n.language}`] })}</div>
      </DeletionModal>

    </div>
  );
};
