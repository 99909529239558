export const ORDER_STATUS = {
  ORDER_CANCELED: 'Canceled',
  ORDER_DELIVERED: 'Delivered',
  ORDER_PARTIALLY_SHIPPED: 'Pending',
  ORDER_PARTIALLY_READY_FOR_PICK_UP: 'Pending',
  ORDER_PARTIALLY_DELIVERED: 'Ready for pick-up',
  ORDER_PLACED: 'Confirmed',
  ORDER_READY_FOR_PICK_UP: 'Ready for pick-up',
  ORDER_SENT_TO_LOGISTIC: 'Confirmed',
  ORDER_SHIPPED: 'Ready for pick-up'
};
