import omit from 'lodash/omit';
import _ from 'lodash';
import { LANGUAGE } from 'src/config';

export class Filters {
  static getFilterValues (string) {
    return string ? string.split(',') : null;
  }

  static removeFalsyValues (obj) {
    return _.omitBy(obj, _.isNil);
  }

  static removeEmptyObjects (obj) {
    if (_.isArray(obj)) {
      return _(obj)
        .filter(_.isObject)
        .map(Filters.removeEmptyObjects)
        .reject(_.isEmpty)
        .concat(_.reject(obj, _.isObject))
        .value();
    }
    return _(obj)
      .pickBy(_.isObject)
      .mapValues(Filters.removeEmptyObjects)
      .omitBy(_.isEmpty)
      .assign(_.omitBy(obj, _.isObject))
      .value();
  }

  static removePaginationFilters (mapper, qs) {
    return omit(mapper.fromQueryStringToQuery(qs), ['pageSize', 'current']);
  }

  static getLocalisedFilterPayload (value) {
    return {
      [LANGUAGE]: {
        locale: LANGUAGE,
        value
      }
    };
  }

  static getFilterPayloadKey (value) {
    return {
      key: value
    };
  }
}
