import React, { useState } from 'react';
import { Col, Row, Drawer } from 'antd';
import { Chips } from 'src/components/Chips';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { OrderDetails } from '../components/OrderDetails';
import { CenteredText } from 'src/components/CenteredText';
import { useTranslation } from 'react-i18next';
import { useFashionOrders } from '../provider/ordersFashion.provider';
import { FashionOrderTable } from '../components/FashionOrderTable';

function countFilters (...filters) {
  return (
    filters
      .filter(i => i?.length > 0)
      .length
  );
}

export const PageOrdersFashion = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.orders-fashion', t);
  const textFilter = textFrom('constants.filter', t);

  const FILTERS = {
    TEXT: 'text'
  };

  const {
    query,
    orders,
    pagination,
    onChangeQuery,
    areOrdersLoading
  } = useFashionOrders();
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const { canSeeCustomerData } = useAuth();

  const resetSelectedOrder = () => {
    setSelectedOrder(undefined);
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const handleSearchText = (searchedText) => {
    onChangeQuery({
      ...query,
      text: searchedText
    });
  };

  const onTagRemove = (key) => {
    if (key === FILTERS.TEXT) {
      handleSearchText(undefined);
    }
  };

  const handleRowEvent = (order) => {
    return {
      onClick: () => { setSelectedOrder(order); }
    };
  };

  const SearchOrders = () =>
    <SearchInput
      placeholder={text('searchPlaceholder')}
      onChangeText={handleSearchText}
      defaultValue={query.text}
    />;

  return (
    <div className='py-4 bg-gray-100'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <Row gutter={[16, 16]} className='pt-2'>
        <Col span={6}>
          <SearchOrders />
        </Col>
      </Row>

      {(!!query?.text) &&
        <Row className='py-4'>
          <div className='pr-2'>{textFilter('appliedFilter', { count: countFilters(query.text) })}:
            <Chips
              query={query}
              chipKeys={[FILTERS.TEXT]}
              onTagRemove={onTagRemove}
            />
          </div>
        </Row>}

      {!areOrdersLoading && orders?.length < 1
        ? (<CenteredText>{text('noOrdersFound')}</CenteredText>)
        : (
          <div className='pt-8'>
            <FashionOrderTable
              loading={areOrdersLoading}
              orders={orders}
              pagination={pagination}
              onTableChange={onTableChange}
              onRow={handleRowEvent}
            />
          </div>)}

      <Drawer
        visible={!!selectedOrder}
        onClose={resetSelectedOrder}
        closable
        width={500}
        placement='right'
        className='jc-drawer'
        title={text('orderDetails')}
      >
        {selectedOrder &&
          <OrderDetails
            orderType='fashion'
            selectedOrder={selectedOrder}
            canSeeCustomerData={canSeeCustomerData}
          />}
      </Drawer>

    </div>
  );
};
