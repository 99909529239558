import React, { useState } from 'react';
import { Row, Col, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { PageTitle } from 'src/components/PageTitle';
import { OutfitCollectionTable } from '../../components/outfit-collection/OutfitCollectionTable';
import { textFrom } from 'src/utils/textFrom';
import { useOutfitCollections } from '../../provider/outfitCollections.provider';
import { CenteredText } from 'src/components/CenteredText';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { Routes } from 'src/router/Routes.helper';
import { useHistory } from 'react-router-dom';
import { Chips } from 'src/components/Chips';
import { DeletionModal } from 'src/components/DeletionModal';
import debounce from 'lodash/debounce';
import { CollectionsAPI } from '../../api/CollectionsAPI';

export const PageOutfitCollection = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.collections.outfitCollection', t);
  const textFilter = textFrom('constants.filter', t);

  const {
    outfitCollections,
    areCollectionsLoading,
    onChangeCollectionsQuery,
    collectionsQuery,
    collectionsPagination,
    refreshOutfitCollections
  } = useOutfitCollections();

  const history = useHistory();

  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [outfitCollectionToBeDeleted, setOutfitCollectionToBeDeleted] = useState();

  const handleSearchOutfitCollection = (searchedText) => {
    onChangeCollectionsQuery({
      ...collectionsQuery,
      text: searchedText
    });
  };

  const onTagRemove = (key) => {
    if (key === 'text') {
      handleSearchOutfitCollection('');
    }
  };

  const navigatetoCreateOutfitCollection = () => {
    history.push(Routes.PATHS.OUTFIT_COLLECTION_CREATE);
  };

  const navigatetoEditOutfitCollection = (record) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.OUTFIT_COLLECTION_EDIT, {
      id: record.id
    }));
  };

  const onChangeAvailabilityStatus = async (outfitCollection) => {
    const newStatus = !outfitCollection?.published;
    if (outfitCollection?.id) {
      try {
        await CollectionsAPI.changeOutfitCollectionAvailabilityStatus(outfitCollection.id, newStatus);
        message.success(text('onStatusUpdateOk'));
      } catch (err) {
        const errDetail = ` ${err.response?.data?.detail || ''}`;
        message.error(`${text('onStatusUpdateErr')}${errDetail}`);
      } finally {
        refreshOutfitCollections(collectionsQuery);
      }
    }
  };

  const deleteOutfitCollection = async () => {
    if (outfitCollectionToBeDeleted?.id) {
      try {
        await CollectionsAPI.deleteOutfitcCollection(outfitCollectionToBeDeleted.id);
        message.success(text('onDeleteOk'));
      } catch (err) {
        const errDetail = ` ${err.response?.data?.detail || ''}`;
        message.error(`${text('onDeleteErr')}${errDetail}`);
      } finally {
        refreshOutfitCollections(collectionsQuery);
      }
    }
  };

  const hideModal = () => {
    setIsDeletionModalVisible(false);
  };

  const showDeletionModal = (outfitCollection) => {
    setOutfitCollectionToBeDeleted(outfitCollection);
    setIsDeletionModalVisible(true);
  };

  const OutfitCollectionSearch = () => (
    <SearchInput
      autoFocus
      defaultValue={collectionsQuery?.text}
      placeholder={text('searchPlaceholder')}
      onChangeText={debounce((value) => handleSearchOutfitCollection(value), 500)}
    />
  );

  return (
    <>
      <div className='py-4'>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={6}>
            <OutfitCollectionSearch />
          </Col>
          <Col span={18}>
            <div className='flex justify-end'>
              <Button
                type='primary'
                className='uppercase'
                onClick={navigatetoCreateOutfitCollection}
              >
                {text('createNewOutfitCollection')}
              </Button>
            </div>
          </Col>
        </Row>
        {/* Filter Chips */}
        {!!collectionsQuery?.text &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!collectionsQuery.text && +1) })}:
              <Chips
                query={collectionsQuery}
                chipKeys={['text']}
                onTagRemove={onTagRemove}
              />
            </div>
          </Row>}

        {!areCollectionsLoading && !outfitCollections?.length
          ? (<CenteredText>{text('noOutfitCollectionFound')}</CenteredText>)
          : (
            <div className='pt-8'>
              <OutfitCollectionTable
                loading={areCollectionsLoading}
                tableData={outfitCollections}
                pagination={collectionsPagination}
                onEdit={navigatetoEditOutfitCollection}
                onDelete={showDeletionModal}
                onChangeAvailabilityStatus={onChangeAvailabilityStatus}
              />
            </div>)}

        <DeletionModal
          onOk={deleteOutfitCollection}
          visible={isDeletionModalVisible}
          onCancel={hideModal}
        >
          <div>{text('askConfirmation')}</div>
        </DeletionModal>
      </div>
    </>
  );
};
