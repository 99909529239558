export const ORDER_STATUS = {
  ORDER_CANCELED: 'Canceled',
  ORDER_DELIVERED: 'Ticket used',
  ORDER_PARTIALLY_SHIPPED: 'Pending',
  ORDER_PARTIALLY_READY_FOR_PICK_UP: 'Pending',
  ORDER_PARTIALLY_DELIVERED: 'Ready to deliver',
  ORDER_PLACED: 'Ticket Sold',
  ORDER_READY_FOR_PICK_UP: 'Ready to deliver',
  ORDER_SENT_TO_LOGISTIC: 'Ticket Sold',
  ORDER_SHIPPED: 'Ready to deliver'
};
