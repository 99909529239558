import React from 'react';
import { AccountData } from '../components/AccountData';
import { Images } from '../components/Images';
import { Allergens } from '../components/Allergens';

export const PageAccount = () => {
  return (
    <div className='py-4'>
      <AccountData />
      <Images />
      <Allergens />
    </div>
  );
};
