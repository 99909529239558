import React, { useState } from 'react';
import { Row, Col, Button, Input, Form, Typography, InputNumber, Modal, Progress } from 'antd';
import { message } from 'src/services/Messages.service';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { EventsAPI } from '../api/EventsAPI';
import { MODE } from 'src/utils/utils';

const { Title, Text } = Typography;

export const ProductVariantsEvent = ({ productId, productVariants, setProductVariants, setNewVariantAdded, mode }) => {
  const { t } = useTranslation();
  const text = textFrom('components.productVariantsEvent', t);

  const [form] = Form.useForm();
  const { canManageEventAreas } = useAuth();
  const [variantsList, setVariantsList] = useState([]);
  const [isAddingSize, setIsAddingSize] = useState(false);
  const [variantBeingEdited, setVariantBeingEdited] = useState(null);
  const [variantLocationToBeDeleted, setVariantLocationToBeDeleted] = useState(undefined);
  const [isDeletionUnderway, setIsDeletionUnderway] = useState(false);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);

  const handleAddNewVariant = async () => {
    setIsAddingSize(true);
    setVariantsList([...variantsList, Variant(variantsList.length)]);
    // Reset pending edits
    setVariantBeingEdited(null);
    form.setFieldsValue({
      location: '',
      price: 0,
      initialAvailability: 0
    });
  };

  const handleSaveVariant = async (index, event, variantToEdit = null) => {
    event.preventDefault();
    const values = form.getFieldsValue();
    const { location, price, initialAvailability } = values;
    if (location && price !== undefined && initialAvailability !== undefined) {
      const areaExists = productVariants.find(item => item.attributes.location === location);
      if (areaExists && !variantToEdit) {
        message.error(text('areaAlreadyAdded'));
        return;
      }
      // New or edited area
      setIsAddingSize(false);
      const payload = {
        attributes: {
          location
        },
        price,
        initialAvailability,
        originalInitialAv: variantBeingEdited?.originalInitialAv || initialAvailability,
        availableSeatsQty: initialAvailability,
        soldTickets: 0
      };
      if (mode && mode === MODE.EDIT && !variantToEdit) {
        setNewVariantAdded(true);
      }
      // setVariantsList([...variantsList].splice(index, 1));
      setVariantsList([]);
      if (variantToEdit) {
        const updatedVariants = productVariants.map((pv, i) => {
          if (i !== index) {
            return pv;
          } else if (!pv.id) {
            // edited new variant
            return payload;
          } else {
            // edited existing variant
            const existingV = {
              ...pv,
              price: price,
              initialAvailability: initialAvailability,
              originalInitialAv: variantBeingEdited.originalInitialAv,
              soldTickets: variantBeingEdited.soldTickets
            };
            return existingV;
          }
        });
        setProductVariants(updatedVariants);
        setVariantBeingEdited(null);
      } else {
        setProductVariants(prevState => ([...prevState, payload]));
      }
      form.setFieldsValue({
        location: '',
        price: 0,
        initialAvailability: 0
      });
    }
  };

  const handleCancelSavingOrEditing = async (index, event) => {
    // TODO move it into the try and uncomment the API call
    // setVariantsList([...variantsList].splice(index, 1));
    setVariantsList([]);
    setIsAddingSize(false);
    // Reset pending edits
    setVariantBeingEdited(null);
  };

  const handleVariantUpdate = () => {
    // TODO Variant update
  };

  const handleEditVariant = (variant, index) => {
    const counter = (variant?.soldTickets >= 0) ? variant.soldTickets : (+variant.initialAvailability - +(variant.availableSeatsQty)) || 0;
    const editedVariant = {
      ...variant,
      originalInitialAv: variant.originalInitialAv || variant.initialAvailability,
      soldTickets: counter,
      index: index
    };
    // Reset pending adds
    setIsAddingSize(false);
    setVariantsList([]);
    form.setFieldsValue({
      location: variant.attributes.location,
      price: variant.price,
      initialAvailability: variant.initialAvailability
    });
    setVariantBeingEdited(editedVariant);
  };

  const showDeletionModal = (variantLocation) => {
    setVariantLocationToBeDeleted(variantLocation);
    setIsDeletionModalVisible(true);
  };

  const dismissDeletionModal = () => {
    setVariantLocationToBeDeleted(undefined);
    setIsDeletionModalVisible(false);
  };

  const confirmVariantDeletion = () => {
    const {
      id: variantId,
      attributes: { location: variantLocation }
    } = productVariants.find(variant => variant.attributes.location === variantLocationToBeDeleted);

    const variantIsLocalOnly = !variantId;

    if (variantIsLocalOnly) {
      deleteVariantLocally(variantLocation);
    } else {
      deleteVariantRemotely(variantLocation, variantId);
    }
  };

  const deleteVariantLocally = (locationToBeRemoved) => {
    setProductVariants(variants => variants.filter(variant => variant.attributes.location !== locationToBeRemoved));
    dismissDeletionModal();
  };

  const deleteVariantRemotely = async (variantLocation, variantId) => {
    setIsDeletionUnderway(true);
    try {
      await EventsAPI.deleteVariant(productId, variantId);
      deleteVariantLocally(variantLocation);
    } catch (e) {
      message.error(e?.response?.data?.message);
    } finally {
      setIsDeletionUnderway(false);
    }
  };

  const Variant = (index, variantToEdit) => {
    return (
      <Form
        onFinish={handleVariantUpdate}
        form={form}
        initialValues={{
          location: variantToEdit?.attributes?.location || '',
          price: variantToEdit ? variantToEdit.price : 0,
          initialAvailability: variantToEdit ? variantToEdit.initialAvailability : 0
        }}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError
      >
        <Row gutter={8} className='my-2'>
          <Col>
            <Form.Item name='location' label={text('area')} required rules={[{ required: true }]}>
              <Input
                disabled={variantToEdit?.id}
                placeholder={text('areaName')}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='price' label={text('price')} required rules={[{ required: true }]}>
              <InputNumber
                addonBefore='€'
                parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                minValue={0}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='initialAvailability' label={text('seatingCapacity')} required rules={[{ required: true }]}>
              <InputNumber
                placeholder={text('seatingCapacity')}
                min={(variantToEdit?.id) ? (variantToEdit.originalInitialAv - variantToEdit.availableSeatsQty) : 0}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} className='my-2'>
          <Col offset={8}>
            <Button
              className='uppercase w-100'
              type='secondary'
              onClick={event => handleCancelSavingOrEditing(index, event)}
            >
              {text('cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              type='default w-100'
              className='uppercase'
              onClick={event => handleSaveVariant(index, event, variantToEdit)}
            >
              {variantToEdit ? text('editArea') : text('addArea')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <div>
      <Modal
        visible={isDeletionModalVisible}
        onOk={confirmVariantDeletion}
        onCancel={dismissDeletionModal}
        confirmLoading={isDeletionUnderway}
      >
        <div>{text('confirmDeletion')}</div>
      </Modal>
      <AllVariantsList
        productVariants={productVariants}
        showDeletionModal={showDeletionModal}
        handleEditVariant={handleEditVariant}
        variantBeingEdited={variantBeingEdited}
        canManageEventAreas={canManageEventAreas}
        VariantForm={Variant}
        mode={mode}
      />
      {variantsList.map((variant, i) => <div key={i}> {variant}</div>)}
      <Row gutter={[18, 18]}>
        <Button
          type='primary'
          className='uppercase ml-2 mt-4'
          onClick={handleAddNewVariant}
          hidden={isAddingSize}
        >
          {text('addNewArea')}
        </Button>
      </Row>
    </div>
  );
};

const AllVariantsList = ({ productVariants, handleEditVariant, variantBeingEdited, showDeletionModal, canManageEventAreas, VariantForm, mode }) => {
  const { t } = useTranslation();
  const text = textFrom('components.productVariantsEvent', t);

  const remoteVariants = productVariants?.filter(variant => variant.id);

  return (
    <div className='my-4'>
      {productVariants && productVariants.length > 0 &&
        <Row gutter={8} className='mb-4'>
          <Col xs={4}>
            <Title level={5}>{text('area')}</Title>
          </Col>
          <Col xs={3}>
            <Title level={5}>{text('price')}</Title>
          </Col>
          <Col xs={3}>
            <Title level={5}>{text('seatingCapacity')}</Title>
          </Col>
        </Row>}
      {productVariants && productVariants.length > 0 &&
        productVariants.map((variant, index) => {
          const soldTicketPercentage = (variant) => {
            return (Math.floor((variant.soldTickets * 100) / variant.initialAvailability));
          };

          const soldTicketCounter = (variant) => {
            return `${variant.soldTickets} / ${variant.initialAvailability}`;
          };

          const VariantCopy = (variant) => {
            return (
              <Row gutter={8} key={variant.id} className='mb-2 hover-class items-center'>
                <Col xs={4}>
                  <Text>{variant.attributes.location}</Text>
                </Col>
                <Col xs={3}>
                  <Text>{variant.price}</Text>
                </Col>
                <Col xs={4}>
                  <Text>
                    {variant.initialAvailability}
                  </Text>
                </Col>
                {mode !== MODE.DUPLICATE &&
                  <Col xs={6}>
                    <div className='flex items-center space-x-4 max-w-sm py-2'>
                      <Progress
                        showInfo={false}
                        percent={soldTicketPercentage(variant)}
                      />
                      <p className='w-40 mb-0'>
                        {soldTicketCounter(variant)}
                      </p>
                    </div>
                  </Col>}

                {canManageEventAreas &&
                  <Col xs={3}>
                    <Button
                      type='text'
                      htmlType='button'
                      className='uppercase hover-toggle'
                      onClick={() => handleEditVariant(variant, index)}
                    >
                      {text('editArea')}
                    </Button>
                  </Col>}

                {canManageEventAreas && (!variant.id || remoteVariants.length > 2) &&
                  <Col xs={3}>
                    <Button
                      danger
                      type='text'
                      htmlType='button'
                      className='uppercase hover-toggle'
                      onClick={() => showDeletionModal(variant.attributes.location)}
                    >
                      {text('removeArea')}
                    </Button>
                  </Col>}
              </Row>
            );
          };
          if (variantBeingEdited?.id) {
            // variantBeingEdited is a saved variant
            if (variantBeingEdited.id === variant.id) {
              // variant is edited -> show edit form
              return VariantForm(index, variant);
            } else {
              // variant is not edited -> show copy
              return VariantCopy(variant);
            }
          } else {
            // variantBeingEdited is not yet saved
            if (variantBeingEdited?.attributes.location === variant?.attributes.location) {
              // variant is edited -> show edit form
              return VariantForm(index, variant);
            } else {
              // variant is not edited -> show copy
              return VariantCopy(variant);
            }
          }
        })}
    </div>
  );
};
