import React from 'react';
import { BackButton } from 'src/components/BackButton';
import { Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useForm } from 'antd/lib/form/Form';
import { WebhookAPI } from '../api/webhookAPI';
import { Routes } from 'src/router/Routes.helper';
import { PageTitle } from 'src/components/PageTitle';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { WebhookForm } from '../component/WebhookForm';

const HTTPS = 'https://';

export const PageCreateWebhook = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.webhooks/create', t);

  const [form] = useForm();
  const history = useHistory();

  const navigateToPageWebhooks = () => {
    history.push(Routes.parseRouteParams(Routes.PATHS.WEBHOOKS));
  };

  const onCancel = () => {
    navigateToPageWebhooks();
  };

  const onFinish = async ({ payloadURL, clientId, contexts = [] }) => {
    if (contexts.length === 0) {
      message.error(text('onMissingEvent'));
      return;
    }
    const webhookPayload = {
      url: `${HTTPS}${payloadURL}`,
      contexts,
      clientId
    };
    try {
      await WebhookAPI.createWebhook(webhookPayload);
      message.success(text('webhookCreateOK'));
      navigateToPageWebhooks();
    } catch (e) {
      message.error(text('webhookCreateKO'));
    }
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton label={text('cancel')} onClick={onCancel} />
      </Col>
      <Col>
        <SubmitButton label={text('saveWebhook')} />
      </Col>
    </Row>
  );

  return (
    <div className='py-4'>
      <PageTitle>{text('webhookConfigurator')}</PageTitle>
      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToPageWebhooks}
        title={text('backButton')}
      />
      <WebhookForm
        form={form}
        mode='create'
        buttons={buttons}
        onFinish={onFinish}
        initialContexts={[]}
      />
    </div>
  );
};
