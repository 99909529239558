import React from 'react';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { trim200Characters } from 'src/utils/utils';

export const TrimmedDescription = ({ description }) => {
  const { t } = useTranslation();
  const text = textFrom('components.trimmedDescription', t);

  return (
    <div className='my-2 break-word'>
      <b>{text('description')}: </b>
      <em>{trim200Characters(description)}</em>
    </div>
  );
};
