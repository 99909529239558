import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en/translation.json';
import es from './translations/es/translation.json';
import de from './translations/de/translation.json';
import fr from './translations/fr/translation.json';

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  },
  de: {
    translation: de
  },
  fr: {
    translation: fr
  }
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en'
  });

export default i18next;
