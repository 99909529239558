import { AxiosClient } from 'src/api/AxiosClient';

export class AttributesAPI {
  static async getLocalizedAttributes (code, page, language = 'en', department, sortingKey = null, sortingOrder = 'asc', limit = 10) {
    const params = {
      attributeCode: code,
      pageNumber: page,
      limit,
      sort: (sortingKey && sortingOrder) ? `${sortingKey},${sortingOrder}` : null
    };

    if (department) {
      params.department = department;
    }

    const res = await AxiosClient.get('/attributes', {
      headers: {
        'accept-language': language
      },
      params
    });
    return res;
  }

  static async getDistinctCodes (departmentKey) {
    return AxiosClient.get('/attributes/distinct-codes', {
      params: {
        department: departmentKey
      }
    });
  }

  static async getAttributes (departmentKey, attributeCode) {
    return AxiosClient.get('/attributes', {
      params: {
        department: departmentKey,
        attributeCode,
        limit: 500,
        pageNumber: 1
      }
    });
  }

  static async addNewAttributeCode (department, newAttributeCode) {
    return AxiosClient.put(`/categories/${department.id}`, {
      id: department?.id,
      key: department?.key,
      type: department?.type,
      declareVariants: [...department?.declareVariants, newAttributeCode],
      localizedValues: {
        it: {
          name: department?.locale?.name,
          url: department?.locale?.url,
          nav: department?.locale?.nav
        }
      }
    });
  }

  static async bulkUpsertAttributes (attributes) {
    return AxiosClient.put('/attributes/bulk/update',
      { attributes },
      {
        headers: {
          Accept: 'application/stentle.api-v0.1+json'
        }
      }
    );
  }

  static async deleteAttributeKeys (attributeKeyIds) {
    return AxiosClient.put('/attributes/bulk/delete', {
      idList: attributeKeyIds
    });
  }
}
