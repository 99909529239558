import React, { useState } from 'react';
import { Form, Input, Button, Layout, message, Typography, Row, Col } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useUrlSearch } from 'src/hooks/useUrlSearch';
import { AccountsAPI } from 'src/modules/accounts/api/AccountsApi';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { buildAccountRecoveryAxiosClient } from 'src/api/AxiosClient';
import mcubeLogoTransparent from 'src/images/logo-mcube_transparent.png';

const { Text } = Typography;

const SingleDetail = ({ label, value, styling = null }) => {
  return (
    <div className={styling || 'flex flex-col'}>
      <Text>{label}</Text>
      <Text className='text-center' strong>{value}</Text>
    </div>
  );
};

export const PageResetPassword = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.pageResetPassword', t);
  const [form] = useForm();
  const search = useUrlSearch();
  const history = useHistory();
  const [pwdUpdateOK, setPwdUpdateOK] = useState(false);

  const { token, endpoint, tenant } = search;

  if (endpoint && tenant) {
    const config = {
      apiEndpoint: endpoint,
      tenant: tenant
    };
    buildAccountRecoveryAxiosClient(config);
  }

  const returnToLogin = () => {
    history.push('/login');
  };

  const onFinish = async (values) => {
    const { pwd, confirmPwd } = values;
    if (pwd !== confirmPwd) {
      message.error(text('pwdsDontMatch'));
    } else {
      try {
        const payload = {
          password: pwd
        };
        await AccountsAPI.resetPwd(token, payload);
        message.success(text('updateOK'));
        setPwdUpdateOK(true);
      } catch (e) {
        message.error(text('updateKO'));
      }
    }
  };

  return (
    <Layout className='h-full'>
      <Layout.Content className='flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <img
            className='mb-8 h-24'
            src={mcubeLogoTransparent}
            alt='M-Cube'
          />
          <div className='pb-16 pt-10 px-12 w-96 bg-white rounded shadow'>
            {!pwdUpdateOK &&
              <>
                <SingleDetail
                  styling='flex flex-col items-center justify-center'
                  label={text('resetPwdTitle')}
                  value={text('resetPwdSubtitle')}
                />
                <Form
                  name='basic'
                  initialValues={{
                    password: '',
                    confirmPwd: ''
                  }}
                  onFinish={onFinish}
                  autoComplete='off'
                  layout='vertical'
                  form={form}
                >
                  <div className='flex flex-col items-center justify-center my-8'>
                    <Row className='w-full'>
                      <Col className='w-full'>
                        {/* Password */}
                        <Form.Item
                          name='pwd'
                          rules={[
                            { required: true, message: text('errorPwd') }
                          ]}
                        >
                          <Input.Password
                            placeholder={text('pwd')}
                          />
                        </Form.Item>
                        {/* Confirm Password */}
                        <Form.Item
                          name='confirmPwd'
                          rules={[
                            { required: true, message: text('errorConfirmPwd') }
                          ]}
                        >
                          <Input.Password
                            placeholder={text('confirmPwd')}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  {/* Actions */}
                  <div className='flex flex-col items-center justify-center'>
                    <Button
                      type='primary'
                      htmlType='submit'
                      className='uppercase w-full'
                    >
                      {text('confirm')}
                    </Button>
                  </div>
                </Form>
              </>}
            {pwdUpdateOK &&
              <>
                <SingleDetail
                  styling='flex flex-col items-center justify-center text-center my-8'
                  label={text('updateOKTitle')}
                  value={text('updateOKSubtitle')}
                />
                <div className='flex flex-col items-center justify-center'>
                  <Button
                    type='primary'
                    className='uppercase w-full'
                    onClick={returnToLogin}
                  >
                    {text('updateOkButton')}
                  </Button>
                </div>
              </>}
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
