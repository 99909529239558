import React from 'react';
import { Button } from 'antd';

export const DefaultButton = (props) => (
  <Button
    {...props}
    type='default'
    className={`uppercase ${props.className}`}
  >
    {props.label}
  </Button>
);
