export const TIPS_AND_SUGGESTIONS = [
  {
    key: 'no_spaces',
    value: 'Don\'t write with spaces'
  },
  {
    key: 'uppercase_lowercase',
    value: 'Pay attention to lowercase and uppercase letters'
  },
  {
    key: 'use-comma',
    value: 'Use a comma as a delimiter in your file'
  },
  {
    key: 'date-format',
    value: 'Dates must conform to the YYYY-MM-DD format'
  },
  {
    key: 'number-of-col-headers',
    value: 'The number of column headers must be equal to the number of columns in each row'
  },
  {
    key: 'escape-double-quotes',
    value: 'If a field value contains a double quote, escape the double quote by preceding it with another double quote'
  },
  {
    key: 'use-crlf',
    value: 'Use CRLF as a line break symbol'
  }
];
