import React from 'react';
import moment from 'moment';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { useTranslation } from 'react-i18next';
import { MenuAction, PopoverActionMenu } from 'src/components/PopoverActionMenu';

export const PermissionSetTable = ({
  onEdit,
  onDelete,
  loading,
  permissionSets,
  pagination,
  onTableChange
}) => {
  const { t, i18n } = useTranslation();
  const text = textFrom('components.permissionSetTable', t);
  const { defaultLanguage } = useLanguages();

  const COLUMNS = [
    {
      title: text('setName'),
      dataIndex: 'localizedNames',
      render: names => names?.[i18n.language] || names?.[defaultLanguage?.language]
    },
    {
      title: text('apiName'),
      dataIndex: 'code',
      render: apiName => apiName
    },
    {
      title: text('creationDate'),
      dataIndex: 'creationDate',
      render: (date) => moment.utc(date).format('DD/MM/YY')
    },
    {
      align: 'center',
      render: (_, row) => (
        <PopoverActionMenu>
          <MenuAction onClick={onEdit?.bind(_, row)} label={text('edit')} />
          <MenuAction onClick={onDelete?.bind(_, row)} label={text('delete')} />
        </PopoverActionMenu>
      )
    }
  ];

  return (
    <Table
      loading={loading}
      columns={COLUMNS}
      dataSource={permissionSets}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
    />
  );
};
