import React from 'react';
import { Form, Input } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';

export const InputNewAttributeKey = ({
  textSource,
  error,
  onChange,
  disabled
}) => {
  const { t } = useTranslation();
  const text = textFrom(textSource, t);

  return (
    <Form.Item
      name='attributeKey'
      help={error?.message}
      validateStatus={error?.status}
    >
      <Input
        onChange={onChange}
        disabled={disabled}
        placeholder={text('keyPlaceholder')}
      />
    </Form.Item>
  );
};
