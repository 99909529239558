import React, { useEffect, useState } from 'react';
import { message } from 'src/services/Messages.service';
import { Row, Col, Form, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocationDetails } from 'src/modules/locations/provider/locationDetails.provider.js';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { BackButton } from 'src/components/BackButton';
import { Routes } from 'src/router/Routes.helper';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { LocationAPI } from '../api/LocationAPI';
import { PageTitle } from 'src/components/PageTitle';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { SubmitButton } from 'src/components/buttons/SubmitButton';
import { LocationForm } from '../components/LocationForm';

export const PageEditLocations = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.locations/edit', t);

  const history = useHistory();
  const [form] = Form.useForm();
  const { location } = useLocationDetails();
  const { defaultLanguage } = useLanguages();
  const [mode, setMode] = useState('edit');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!defaultLanguage) return;
    form.setFieldsValue({ language: defaultLanguage.label });
  }, [form, defaultLanguage]);

  useEffect(() => {
    if (history?.location?.state?.mode === 'duplicate') {
      setMode('duplicate');
    }
  }, []);

  useEffect(() => {
    if (location) {
      form.setFieldsValue({
        name: location.name,
        key: location.key,
        country: location?.address?.country,
        city: location?.address?.city,
        zip: location?.address?.postalCode,
        address: location?.address?.streetAddress,
        number: location?.address?.streetNumber,
        positionX: location?.position?.x,
        positionY: location?.position?.y,
        description: location?.description
      });
    }
  }, [form, location]);

  const navigateToLocationsPage = () => {
    history.push({ pathname: Routes.PATHS.LOCATIONS });
  };

  const buttons = (
    <Row gutter={20} justify='end' className='mt-12'>
      <Col>
        <DefaultButton
          label={text('cancel')}
          onClick={navigateToLocationsPage}
        />
      </Col>
      <Col>
        <SubmitButton
          loading={isLoading}
          disabled={isLoading}
          label={mode === 'edit' ? text('editLocation') : text('duplicateLocation')}
        />
      </Col>
    </Row>
  );

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      let attributes = null;
      if (location.attributes && Object.keys(location.attributes).length > 0) {
        attributes = { ...location.attributes };
      }
      const payload = { ...values, attributes: { ...attributes } };
      if (mode === 'edit') {
        await LocationAPI.updateLocationDetails(location.id, payload);
        message.success(text('locationEditOK'));
      } else {
        await LocationAPI.createLocation(payload);
        message.success(text('locationDuplicationOK'));
      }
      navigateToLocationsPage();
    } catch (e) {
      message.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  let title = location && location.name;
  if (mode !== 'edit') {
    title = `${text('duplicateLocation')}: ${location && location.name}`;
  }

  return (
    <div className='py-4'>
      <div className='flex items-center justify-between w-full mb-4'>
        <PageTitle>{title}</PageTitle>

        {mode === 'edit' && (
          <Button
            type='link'
            htmlType='button'
            className='uppercase'
            onClick={() => {
              setMode('duplicate');
            }}
          >
            {text('duplicateLocation')}
          </Button>
        )}
      </div>

      <BackButton
        iconChevron
        className='mb-4 mb-8'
        onClick={navigateToLocationsPage}
        title={text('back')}
      />

      <LocationForm
        form={form}
        buttons={buttons}
        onFinish={onFinish}
      />

    </div>
  );
};
