import React from 'react';
import { Layout, Typography, message } from 'antd';
import { useUrlSearch } from 'src/hooks/useUrlSearch';
import { AccountsAPI } from 'src/modules/accounts/api/AccountsApi';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { buildAccountRecoveryAxiosClient } from 'src/api/AxiosClient';

const { Text } = Typography;

const SingleDetail = ({ label, value, styling = null }) => {
  return (
    <div className={styling || 'flex flex-col'}>
      <Text>{label}</Text>
      <Text strong>{value}</Text>
    </div>
  );
};

export const PageEmailVerification = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.pageEmailVerification', t);
  const search = useUrlSearch();

  const { token, endpoint, tenant } = search;

  const validateEmail = async () => {
    try {
      await AccountsAPI.validateEmail(token);
      message.success(text('validateOK'));
    } catch (e) {
      message.error(text('validateKO'));
    }
  };

  if (endpoint && tenant) {
    const config = {
      apiEndpoint: endpoint,
      tenant: tenant
    };
    buildAccountRecoveryAxiosClient(config);
  }

  if (token) {
    validateEmail();
  }

  return (
    <Layout className='h-full'>
      <Layout.Content className='flex items-center justify-center'>
        <div className='flex flex-col items-center justify-center'>
          <div className='pb-16 pt-10 px-12 bg-white rounded shadow'>
            <SingleDetail
              styling='flex flex-col items-center justify-center'
              label={text('verifyingEmail')}
              value={text('checkEmail')}
            />
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};
