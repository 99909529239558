/**
 * @description Produces a function that you can later call to retrieve translations for a specific page or component.
 * @example const { t } = useTranslation();
  const text = textFrom('pages.dashboard', t);
 * @NB 'useTranslation' must be imported from react-i18next
 * @returns {fn: (a: string) => string}
 */
export function textFrom(prefix, t) {
    return (key, opts) => {
      return t(prefix + '.' + key, opts);
    };
}