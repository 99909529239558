import React from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Divider,
  DatePicker,
  InputNumber
} from 'antd';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { CountriesUtils } from '../../../utils/utils';
import { Gender } from '../utils/utils';

const { Option } = Select;

export const CustomerForm = ({
  form,
  mode,
  buttons,
  onFinish,
  billingDistrictsList,
  setBillingDistrictsList,
  shippingDistrictsList,
  setShippingDistrictsList
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.customerForm', t);

  const { getCountriesList, getDistrictListByCountry } = CountriesUtils();
  const countriesList = getCountriesList();

  const handleBillingCountryChange = (value) => {
    if (value) {
      setBillingDistrictsList(getDistrictListByCountry(value));
    }
  };

  const handleShippingCountryChange = (value) => {
    if (value) {
      setShippingDistrictsList(getDistrictListByCountry(value));
    }
  };

  return (
    <div className='py-4'>
      <Form
        name='basic'
        form={form}
        initialValues={{ customerGender: Gender.NA }}
        onFinish={onFinish}
        layout='vertical'
        autoComplete='off'
        className='max-w-8xl'
      >
        <SectionTitle
          className='mt-2'
          title={text('personalInfoSection.title')}
        />
        <HelperText
          className='mb-2'
          text={text('personalInfoSection.helperText')}
        />

        <Row gutter={20} className='pt-2'>
          <Col xs={8}>
            <Form.Item
              name='customerFirstName'
              className='w-full px-4 pb-4'
              label={text('customerFirstName.label')}
              rules={[{ required: true, message: text('customerFirstName.message') }]}
            >
              <Input placeholder={text('customerFirstName.placeholder')} />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerLastName'
              className='w-full px-4 pb-4'
              label={text('customerLastName.label')}
              rules={[{ required: true, message: text('customerLastName.message') }]}
            >
              <Input placeholder={text('customerLastName.placeholder')} />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerBirthDate'
              className='w-full px-4 pb-4'
              label={text('customerBirthDate.label')}
              rules={[{ required: true, message: text('customerBirthDate.message') }]}
            >
              <DatePicker
                getPopupContainer={trigger => trigger.parentElement}
                placeholder={text('customerBirthDate.placeholder')}
                format='DD/MM/YYYY'
                className='w-full'
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='pt-2'>
          <Col xs={8}>
            <Form.Item
              name='customerGender'
              className='w-full px-4 pb-4'
              label={text('customerGender.label')}
              rules={[{ required: true, message: text('customerGender.message') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                style={{ width: '100%' }}
              >
                <Option value={Gender.MALE}>{Gender.MALE}</Option>
                <Option value={Gender.FEMALE}>{Gender.FEMALE}</Option>
                <Option value={Gender.NA}>{Gender.NA}</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerTelNumber'
              className='w-full px-4 pb-4'
              label={text('customerTelNumber.label')}
              rules={[{ required: true, message: text('customerTelNumber.message') }]}
            >
              <InputNumber
                parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                placeholder={text('customerTelNumber.placeholder')}
              />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerEmail'
              className='w-full px-4 pb-4'
              label={text('customerEmail.label')}
              rules={[
                { required: true, message: text('customerEmail.message') },
                { type: 'email', message: text('customerEmail.invalid') }
              ]}
            >
              <Input
                disabled={mode === 'edit'}
                placeholder={text('customerEmail.placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <SectionTitle
          className='mt-2'
          title={text('billingAddressSection.title')}
        />
        <HelperText
          className='mb-2'
          text={text('billingAddressSection.helperText')}
        />

        <Row gutter={20} className='pt-2'>
          <Col xs={8}>
            <Form.Item
              name='customerBillingCountry'
              className='w-full px-4 pb-4'
              label={text('customerBillingCountry.label')}
              rules={[{ required: true, message: text('customerBillingCountry.message') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                showSearch
                bordered
                allowClear
                className='w-full'
                placeholder={text('customerBillingCountry.placeholder')}
                onChange={handleBillingCountryChange}
                filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
              >
                {countriesList.map((country) => (
                  <Option key={country.key} value={country.key}>
                    {country.value}
                  </Option>)
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerBillingStateOrProvince'
              className='w-full px-4 pb-4'
              label={text('customerBillingStateOrProvince.label')}
              rules={[{ required: true, message: text('customerBillingStateOrProvince.message') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                showSearch
                bordered
                allowClear
                className='w-full'
                placeholder={text('customerBillingStateOrProvince.placeholder')}
                filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
              >
                {billingDistrictsList.map((district) => (
                  <Option key={district.key} value={district.key}>
                    {district.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerBillingCity'
              className='w-full px-4 pb-4'
              label={text('customerBillingCity.label')}
              rules={[{ required: true, message: text('customerBillingCity.message') }]}
            >
              <Input placeholder={text('customerBillingCity.placeholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='pt-2'>
          <Col xs={8}>
            <Form.Item
              name='customerBillingAddress'
              className='w-full px-4 pb-4'
              label={text('customerBillingAddress.label')}
              rules={[{ required: true, message: text('customerBillingAddress.message') }]}
            >
              <Input placeholder={text('customerBillingAddress.placeholder')} />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerBillingStreetNumber'
              className='w-full px-4 pb-4'
              label={text('customerBillingStreetNumber.label')}
              rules={[{ required: true, message: text('customerBillingStreetNumber.message') }]}
            >
              <Input placeholder={text('customerBillingStreetNumber.placeholder')} />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerBillingZipCode'
              className='w-full px-4 pb-4'
              label={text('customerBillingZipCode.label')}
              rules={[{ required: true, message: text('customerBillingZipCode.message') }]}
            >
              <Input placeholder={text('customerBillingZipCode.placeholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <SectionTitle
          className='mt-2'
          title={text('shippingAddressSection.title')}
        />
        <HelperText
          className='mb-2'
          text={text('shippingAddressSection.helperText')}
        />

        <Row gutter={20} className='pt-2'>
          <Col xs={8}>
            <Form.Item
              name='customerShippingCountry'
              className='w-full px-4 pb-4'
              label={text('customerShippingCountry.label')}
              rules={[{ required: true, message: text('customerShippingCountry.message') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                showSearch
                bordered
                allowClear
                className='w-full'
                placeholder={text('customerShippingCountry.placeholder')}
                onChange={handleShippingCountryChange}
                filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
              >
                {countriesList.map((country) => (
                  <Option key={country.key} value={country.key}>
                    {country.value}
                  </Option>)
                )}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerShippingStateOrProvince'
              className='w-full px-4 pb-4'
              label={text('customerShippingStateOrProvince.label')}
              rules={[{ required: true, message: text('customerShippingStateOrProvince.message') }]}
            >
              <Select
                getPopupContainer={trigger => trigger.parentNode}
                showSearch
                bordered
                allowClear
                className='w-full'
                placeholder={text('customerShippingStateOrProvince.placeholder')}
                filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
              >
                {shippingDistrictsList.map((district) => (
                  <Option key={district.key} value={district.key}>
                    {district.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerShippingCity'
              className='w-full px-4 pb-4'
              label={text('customerShippingCity.label')}
              rules={[{ required: true, message: text('customerShippingCity.message') }]}
            >
              <Input placeholder={text('customerShippingCity.placeholder')} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20} className='pt-2'>
          <Col xs={8}>
            <Form.Item
              name='customerShippingAddress'
              className='w-full px-4 pb-4'
              label={text('customerShippingAddress.label')}
              rules={[{ required: true, message: text('customerShippingAddress.message') }]}
            >
              <Input placeholder={text('customerShippingAddress.placeholder')} />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerShippingStreetNumber'
              className='w-full px-4 pb-4'
              label={text('customerShippingStreetNumber.label')}
              rules={[{ required: true, message: text('customerShippingStreetNumber.message') }]}
            >
              <Input placeholder={text('customerShippingStreetNumber.placeholder')} />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              name='customerShippingZipCode'
              className='w-full px-4 pb-4'
              label={text('customerShippingZipCode.label')}
              rules={[{ required: true, message: text('customerShippingZipCode.message') }]}
            >
              <Input placeholder={text('customerShippingZipCode.placeholder')} />
            </Form.Item>
          </Col>
        </Row>

        {buttons}

      </Form>
    </div>
  );
};
