import React, { useState, createContext, useCallback, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { usePagination } from 'src/hooks/usePagination';
import { useFilters } from 'src/hooks/useFilters';
import { message } from 'src/services/Messages.service';
import { StocksMapper } from 'src/modules/warehouses/domain/Stocks.mapper';
import { StocksAPI } from '../api/StocksAPI';

export const stocksContext = createContext();

const useProvideStocks = () => {
  const location = useLocation();
  const [stocks, setStocks] = useState([]);
  const [areStocksLoading, setAreStocksLoading] = useState(false);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(StocksMapper);

  const getStocks = useCallback(async () => {
    setAreStocksLoading(true);
    try {
      const res = await StocksAPI.getStocks(
        StocksMapper.fromQueryToPayload(query)
      );
      setStocks(res.data?.items.map(stock => ({ ...stock, isUpdatePending: false })));
      setPagination(res.data);
    } catch (e) {
      message.error(e);
    } finally {
      setAreStocksLoading(false);
    }
  }, [query, setPagination]);

  useEffect(() => {
    getStocks();
  }, [location, getStocks]);

  const updateStockAvailability = useCallback(async (record, availability) => {
    try {
      const res = await StocksAPI.updateStockAvailability(record, availability);
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  return {
    query,
    stocks,
    setStocks,
    pagination,
    onChangeQuery,
    areStocksLoading,
    updateStockAvailability
  };
};

export const StocksProvider = ({ children }) => {
  const stocks = useProvideStocks();

  return (
    <stocksContext.Provider value={stocks}>
      {children}
    </stocksContext.Provider>
  );
};

export const useStocks = () => useContext(stocksContext);
