import { useLatestRef } from "src/hooks/useLatestRef";
import { useCallback, useEffect, useRef } from "react";

export const useDebounce = (callback, wait = 0, leading = false) => {
  const storedCallbackRef = useLatestRef(callback);
  const timeout = useRef();
  // Cleans up pending timeouts when the deps change
  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = void 0;
    },
    [wait, leading, storedCallbackRef]
  );

  return useCallback(
    function () {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      const { current } = timeout;
      // Calls on leading edge
      if (current === void 0 && leading) {
        timeout.current = setTimeout(() => {
          timeout.current = void 0;
        }, wait);
        // eslint-disable-next-line prefer-spread
        return storedCallbackRef.current.apply(null, args);
      }
      // Clear the timeout every call and start waiting again
      current && clearTimeout(current);
      // Waits for `wait` before invoking the callback
      timeout.current = setTimeout(() => {
        timeout.current = void 0;
        storedCallbackRef.current.apply(null, args);
      }, wait);
    },
    [wait, leading, storedCallbackRef]
  );
};
