import React from 'react';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { trim80Characters } from 'src/utils/utils';
import { PopoverActionMenu, MenuAction } from 'src/components/PopoverActionMenu';

export const BrandTable = ({
  loading,
  className,
  tableData,
  onTableChange,
  onEdit,
  onDelete,
  pagination
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.brands', t);

  const columns = [
    {
      title: text('image'),
      key: 'image',
      dataIndex: 'image',
      width: '5%',
      render: (_, record) => (
        <div className='staticImage'>
          {record?.logo?.imageURL && <img src={record?.logo?.imageURL} />}
        </div>
      )
    },
    {
      title: text('name'),
      key: 'name',
      dataIndex: 'name',
      width: '15%',
      render: name => name
    },
    {
      title: text('key'),
      key: 'key',
      dataIndex: 'key',
      width: '10%',
      render: key => key
    },
    {
      title: text('department'),
      key: ['departments', '0'],
      dataIndex: ['departments', '0'],
      width: '15%',
      render: department => department
    },
    {
      title: text('description'),
      key: 'longDescription',
      dataIndex: 'longDescription',
      width: '55%',
      render: description => trim80Characters(description)
    },
    {
      align: 'center',
      render: (_, record) => {
        const actions = (
          <PopoverActionMenu>
            <MenuAction onClick={onEdit.bind(_, record)} label={text('edit')} />
            <MenuAction onClick={onDelete.bind(_, record)} label={text('delete')} />
          </PopoverActionMenu>
        );
        return record.editable ? actions : null;
      }
    }
  ];

  return (
    <Table
      loading={loading}
      columns={columns}
      className={className}
      dataSource={tableData}
      onChange={onTableChange}
      rowKey={(record) => record.id}
      pagination={{ ...pagination, showSizeChanger: false }}
    />
  );
};
