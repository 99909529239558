import React from 'react';
import { Card } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

export const DownloadType = ({ type, onClick }) => (
  <Card
    hoverable
    onClick={onClick}
    className='download-type-card'
  >
    <div className='download-icon'>{type.icon}</div>
    <div className='download-title'>{type.name}</div>
    <div className='download-arrow-icon'><ArrowRightOutlined /></div>
  </Card>
);
