import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Button } from 'antd';
import { PageTitle } from 'src/components/PageTitle';
import { ProductStatusTag } from 'src/modules/products/components/ProductStatusTag';
import { Product } from 'src/services/Product.service';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useOutfits } from '../provider/outfits.provider';
import { BackButton } from 'src/components/BackButton';

export const VariantTable = ({ selectedLanguage, selectedVariantKeys, setSelectedVariantKeys, setOutfitVariants }) => {
  const { t } = useTranslation();
  const text = textFrom('components.outfitProductVariants', t);

  const [loading, setLoading] = useState(true);

  const { productVariants, setProductVariants, setSelectedProduct, outfitVariants } = useOutfits();

  useEffect(() => {
    if (productVariants) {
      setLoading(false);
    }
  }, [productVariants]);

  const navigateToOutfitsPage = () => {
    setProductVariants([]);
    setSelectedProduct(null);
  };

  const onSelectChange = (newselectedVariantKeys) => {
    setSelectedVariantKeys(newselectedVariantKeys);
  };

  const isVariantAlreadyPresent = (array) => {
    const variant = outfitVariants.length > 0 ? outfitVariants : productVariants;
    return array.filter((obj) => !variant.includes(obj.id));
  };

  const addVariantsToOutfit = () => {
    const outfitsObjects = rowSelection?.selectedVariantKeys.map((itm) => {
      return productVariants.find((el) => el.id === itm);
    });

    const checkedVariantArray = isVariantAlreadyPresent(outfitsObjects);

    setOutfitVariants((prevState) => [...prevState, ...checkedVariantArray]);
    navigateToOutfitsPage();
  };

  const rowSelection = {
    preserveSelectedRowKeys: false,
    selectedVariantKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return {
        disabled: outfitVariants.find((item) => item.id === record.id),
        checked: outfitVariants.find((item) => item.id === record.id)
      };
    }
  };

  const CoverImage = ({ src }) => (
    <img
      src={src}
      className='w-8 h-8 object-cover object-center'
    />
  );

  const columns =
    [
      {
        title: text('image'),
        dataIndex: ['photoGallery', 'images'],
        render: (images) => {
          const url = images?.find(image => image?.info?.type === 'cover')?.url;
          return (url ? <CoverImage src={url} /> : <></>);
        }
      },
      {
        title: text('name'),
        dataIndex: ['names', selectedLanguage, 'value'],
        render: (text) => <div>{text}</div>
      },
      {
        title: text('EAN'),
        dataIndex: ['manufacturerCode'],
        render: (text) => <div>{text}</div>
      },
      {
        title: text('color'),
        dataIndex: ['attributeVariants', 'color', 'localeName'],
        render: (color) => <div>{color}</div>
      },
      {
        title: text('scale'),
        dataIndex: ['attributeVariants', 'sizeScale', 'businessKey'],
        render: sizeScale => sizeScale
      },
      {
        title: text('size'),
        dataIndex: ['attributeVariants', 'size', 'businessKey'],
        render: size => size
      },
      // TODO Generalize ProductStatusTag and product service because it is being used on product and variant table - to save time now use like this
      {
        title: text('status'),
        dataIndex: ['published'],
        render: (status) => <ProductStatusTag status={status} />,
        inputType: 'published-select',
        editable: true,
        inputOptions: Product.statusOptions
      }
    ];

  return (
    <div className='py-4'>
      <PageTitle>{text('searchVariantTitle')}</PageTitle>
      <Row gutter={[16, 16]} className='py-2'>
        <Col span={6}>
          <BackButton
            iconChevron
            className='mt-4'
            onClick={navigateToOutfitsPage}
            title={text('backToProductList')}
          />

        </Col>
        <Col span={6} />
        <Col span={12}>
          <Button
            type='primary'
            htmlType='button'
            className='uppercase float-right'
            onClick={addVariantsToOutfit}
          >
            {text('addVariants')}
          </Button>
        </Col>
      </Row>
      <Table
        loading={loading}
        rowSelection={rowSelection}
        dataSource={productVariants}
        columns={columns}
        pagination={false}
        rowKey='id'
        className='variantTableOutfits'
      />
    </div>
  );
};
