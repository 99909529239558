import React, { useState } from 'react';
import { LocalBusinessAPI } from 'src/modules/admin/api/localBusinessAPI';
import { SelectWithLoadMore } from './SelectWithLoadMore';

const localBusinessToOption = lb => ({
  id: lb.id,
  key: lb.id,
  value: lb.id,
  label: lb.name,
  'data-testid': 'local-business-option'
});

export const SelectLocalBusinessWithLoadMore = (props) => {
  const [pagination, setPagination] = useState({});
  const [localBusinesses, setLocalBusinesses] = useState([]);

  const getPaginatedLocalBusinesses = async (pageNumber, searchedText) => {
    const query = {
      limit: 10,
      pageNumber,
      text: searchedText,
      orderingList: [{ key: 'name', value: 'ascending' }]
    };
    const res = await LocalBusinessAPI.getLocalBusinesses(query);
    const localBusinesses = res?.data?.items || [];
    setPagination({ ...res?.data });
    setLocalBusinesses(old => pageNumber === 1 ? localBusinesses : old.concat(localBusinesses));
  };

  return (
    <SelectWithLoadMore
      showSearch
      items={localBusinesses}
      getItemsAtPage={getPaginatedLocalBusinesses}
      pagination={pagination}
      toOption={localBusinessToOption}
      {...props}
    />
  );
};
