import { AxiosClient } from 'src/api/AxiosClient';

export class LookbookHomepageAPI {
  static async getHomepageSections (localBusinessId) {
    return AxiosClient.get(`/lookbook/homepage/${localBusinessId}`);
  }

  static async saveSections (localBusinessId, sections) {
    const data = {
      localBusinessId,
      sections
    };
    return AxiosClient.post('/lookbook/homepage', data);
  }
}
