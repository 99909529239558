import React, { useState, useEffect } from 'react';
import { SearchCollectionGroups } from 'src/modules/collections/components/SearchCollectionGroups';

function collectionGroupToSection (collectionGroup) {
  return {
    id: collectionGroup.id,
    code: collectionGroup.id,
    type: 'COLLECTION_GROUP',
    names: collectionGroup.names,
    bannerMobile: { imageURL: collectionGroup.bannerMobile?.imageURL },
    bannerDesktop: { imageURL: collectionGroup.bannerDesktop?.imageURL }
  };
}

export const CollectionGroupSelector = ({
  collectionGroups,
  linkedCollectionGroups,
  setLinkedCollectionGroups
}) => {
  const [groupsToShow, setGroupsToShow] = useState([]);

  useEffect(() => {
    if (linkedCollectionGroups.length === 0) {
      setGroupsToShow(collectionGroups);
    } else {
      const getGroupsToShow = () => collectionGroups.filter(group => !(linkedCollectionGroups.some(linkedGroup => linkedGroup.id === group.id)));
      setGroupsToShow(getGroupsToShow);
    }
  }, [linkedCollectionGroups, collectionGroups]);

  return (
    <SearchCollectionGroups
      mapper={collectionGroupToSection}
      collectionGroups={groupsToShow}
      setLinkedCollectionGroups={setLinkedCollectionGroups}
    />
  );
};
