import { AxiosClient } from 'src/api/AxiosClient';

export class CustomersAPI {
  static async getCustomers (data) {
    if (data) {
      return AxiosClient.post('customers/filter',
        {
          pageNumber: data.pageNumber,
          limit: data.limit,
          filters: data.filters
        },
        {
          headers: {
            Accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
    }
  }

  static async getCustomerDetails (id) {
    return AxiosClient.get(`/customers/${id}`);
  }

  /**
  * @description creates a customer
  * @returns {Promise<*>}
  */
  static async createCustomer (payload) {
    return AxiosClient.post('/customers/', payload);
  }

  /**
  * @description Adds billing data to a customer
  * @returns {Promise<*>}
  */
  static async addBillingDataToCustomer (customerId, payload) {
    return AxiosClient.post(`/customers/${customerId}/billing-addresses`, payload);
  }

  /**
  * @description Adds shipping data to a customer
  * @returns {Promise<*>}
  */
  static async addShippingDataToCustomer (customerId, payload) {
    return AxiosClient.post(`/customers/${customerId}/shipping-addresses`, payload);
  }

  /**
  * @description Updates customer personal data
  * @returns {Promise<*>}
  */
  static async updateCustomer (id, payload) {
    return AxiosClient.put(`/customers/${id}`, payload);
  }

  /**
  * @description Updates customer billing data
  * @returns {Promise<*>}
  */
  static async updateCustomerBillingData (id, billingId, payload) {
    return AxiosClient.put(`/customers/${id}/billing-addresses/${billingId}`, payload);
  }

  /**
  * @description Updates customer shipping data
  * @returns {Promise<*>}
  */
  static async updateCustomerShippingData (id, shippingId, payload) {
    return AxiosClient.put(`/customers/${id}/shipping-addresses/${shippingId}`, payload);
  }

  static async anonymizeCustomer (customerId) {
    return AxiosClient.delete(`customers/${customerId}/anonymize`);
  }

  static async getOrders (customerId) {
    return AxiosClient.get(`/customers/${customerId}/purchase-orders?pageLimit=1000&startPage=1`);
  }

  /**
   * @param orders: an array of order IDs (ie, strings)
   */
  static async anonymizeOrders (orders) {
    return AxiosClient.post('/orders/anonymize', orders);
  }
}
