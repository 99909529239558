import React, { useState, useEffect, useMemo } from 'react';
import { Row, Col, Typography, Upload, Button, message } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { AccountsAPI } from '../api/AccountsApi';
import { useAccounts } from '../provider/accounts.provider';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const { Title, Text } = Typography;

export const Allergens = () => {
  const { t } = useTranslation();
  const text = textFrom('components.allergens', t);

  const { userInfo } = useAccounts();
  const { userDepartments } = useAuth();
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState('');
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);

  useEffect(() => {
    const hasAllergens =
      userInfo &&
      userInfo.localBusiness.attachments.ALLERGEN &&
      userInfo.localBusiness.attachments.ALLERGEN.length > 0;
    if (hasAllergens) {
      setFileName(userInfo.localBusiness.attachments.ALLERGEN[0].name);
      setFile(userInfo.localBusiness.attachments.ALLERGEN[0].url);
    }
  }, [userInfo]);

  const title = useMemo(() => {
    return (userDepartments[0] === 'food') ? text('allergens') : text('docs');
  }, [userDepartments]);

  const beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error(text('onFileTooLarge'));
    }
    return isLt10M;
  };

  const onChange = (info) => {
    if (info.file.status === 'done') {
      message.success(text('onUploadOk', { fileName: info.file.name }));
    } else if (info.file.status === 'error') {
      message.error(text('onUploadErr', { fileName: info.file.name }));
    }
  };

  const handleUpload = async (options) => {
    if (beforeUpload) {
      const { onSuccess, onError, file } = options;
      try {
        await AccountsAPI.updateAllergens(userInfo.localBusiness.id, file);
        setFile(file);
        onSuccess('Ok');
      } catch (error) {
        console.log(error);
        onError({ error });
      }
    }
  };

  const handleRemove = async () => {
    try {
      await AccountsAPI.deleteAllergens(userInfo.localBusiness.id);
      setFile([]);
      message.success(text('onDeleteOk'));
    } catch (error) {
      message.error(text('onDeleteErr'));
    }
  };

  return (
    <Row>
      <Col xs={18} className='border-b border-gray-300 py-8 account-images'>
        <Title level={5}>{title}</Title>
        <Text type='secondary' className='pb-4'>
          {/* Lorem ipsum dolor sit amet, conesctur adispiscing elit. */}
        </Text>
        <br />
        <br />
        <div className='max-w-xs'>
          {file.length > 0
            ? (
              <div
                className={
                  'flex items-center ' + (showDeleteBtn ? 'bg-gray-50' : '')
                }
                onMouseEnter={() => setShowDeleteBtn(true)}
                onMouseLeave={() => setShowDeleteBtn(false)}
              >
                <a
                  href={file}
                  target='_blank'
                  rel='noreferrer'
                  className='cursor-pointer'
                  style={{ color: '#0090ff' }}
                >
                  {fileName}
                </a>

                {showDeleteBtn && (
                  <DeleteOutlined
                    className='ml-auto mr-2 cursor-pointer'
                    onClick={handleRemove}
                  />
                )}
              </div>
              )
            : (
              <Upload
                onChange={onChange}
                customRequest={handleUpload}
                accept='application/pdf'
                beforeUpload={beforeUpload}
                onRemove={handleRemove}
                showUploadList={file.length !== 0 || false}
              >
                {file.length === 0
                  ? <Button icon={<UploadOutlined />}>{text('upload')}</Button>
                  : null}
              </Upload>
              )}
        </div>
      </Col>
    </Row>
  );
};
