import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import { OutfitCollectionTable } from '../outfit-collection/OutfitCollectionTable';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useOutfitCollections } from '../../provider/outfitCollections.provider';
import debounce from 'lodash/debounce';
import { Chips } from 'src/components/Chips';

export const SearchCollections = ({ collections, setLinkedCollections, mapper }) => {
  const { t } = useTranslation();
  const text = textFrom('components.searchCollections', t);
  const textFilter = textFrom('constants.filter', t);

  const { pagination, onChangeCollectionsQuery, collectionsQuery, areCollectionsLoading } = useOutfitCollections();

  const [selectedCollectionKeys, setSelectedCollectionKeys] = useState([]);

  const addSelectedCollections = () => {
    const selectedCollections = selectedCollectionKeys.map(collectionKey => collections.find(collection => collection.id === collectionKey));
    const newSelectedCollections = mapper ? selectedCollections.map(mapper) : selectedCollections;
    setLinkedCollections(prev => [...prev, ...newSelectedCollections]);
    setSelectedCollectionKeys([]);
  };

  const handleSearchCollectionFilter = (text) => {
    onChangeCollectionsQuery({ ...collectionsQuery, text: text });
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeCollectionsQuery({ current, pageSize });
  };

  const onTagRemove = () => {
    handleSearchCollectionFilter('');
  };

  const OutfitCollectionSearch = () => (
    <SearchInput
      defaultValue={collectionsQuery?.text}
      placeholder={text('searchPlaceholder')}
      onChangeText={debounce((value) => handleSearchCollectionFilter(value), 500)}
    />
  );

  return (
    <>
      <Row justify='space-between'>
        <Col flex='1' span={4}>
          {text('searchLabel')}
          <OutfitCollectionSearch />
        </Col>
        <Col>
          <Button
            type='primary'
            className='uppercase mt-5'
            onClick={addSelectedCollections}
          >
            {text('add')}
          </Button>
        </Col>
      </Row>
      {!!collectionsQuery?.text &&
        <Row className='py-4'>
          <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!collectionsQuery.text && +1) })}:
            <Chips
              query={collectionsQuery}
              chipKeys={['text']}
              onTagRemove={onTagRemove}
            />
          </div>
        </Row>}
      <OutfitCollectionTable
        isEditModeOn
        className='pt-8'
        tableData={collections}
        pagination={pagination}
        loading={areCollectionsLoading}
        onTableChange={onTableChange}
        selectedCollectionKeys={selectedCollectionKeys}
        setSelectedCollectionKeys={setSelectedCollectionKeys}
      />
    </>
  );
};
