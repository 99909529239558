import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Select,
  Form,
  DatePicker,
  Table,
  TimePicker,
  InputNumber,
  Button,
  Radio,
  Space,
  message
} from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { CouponsAPI } from '../api/CouponsAPI';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { isBefore } from 'src/utils/utils';
import { PageTitle } from 'src/components/PageTitle';
import { Routes } from 'src/router/Routes.helper';
import { ConfirmationModal } from 'src/components/modals/ConfirmationModal';
import { SelectLocalBusinessWithLoadMore } from 'src/components/SelectLocalBusinessWithLoadMore';

const { Option } = Select;
const { Search } = Input;

const AMOUNT_STEP_FIXED = 1;
const AMOUNT_STEP_PERC = 5;

export const PageCreateCoupon = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.coupon/create', t);

  const SELECT_FIXED = text('selectFixed');
  const SELECT_PERC = text('selectPercentage');
  const SELECT_SCOPE_ONLINE = text('selectScopeOnline');
  const SELECT_SCOPE_STORE = text('selectScopeStore');
  const RULE_ALWAYS = text('ruleAlways');
  const RULE_SOME = text('ruleSome');

  const ANY_LB_OPTION = {
    id: 'any',
    key: 'any',
    value: 'any',
    label: text('anyLocalBusiness')
  };

  const [, setFormValues] = useState({});
  const [selectedType, setSelectedType] = useState(SELECT_PERC);
  const [amountStep, setAmountStep] = useState(AMOUNT_STEP_PERC);
  const [couponRule, setCouponRule] = useState(RULE_ALWAYS);
  const [selectedProductsKeys, setSelectedProductsKeys] = useState([]);
  const [productSearchQuery, onChangeProductSearchQuery] = useState(null);
  const [products, setProducts] = useState([]);
  const [productsToDisplay, setProductsToDisplay] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isProductSearchDisabled, setIsProductSearchDisabled] = useState(true);
  const [selectedLocalBusinesses, setSelectedLocalBusinesses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');

  const history = useHistory();

  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      couponRule: couponRule
    });
  }, [couponRule]);

  useEffect(() => {
    form.setFieldsValue({
      localBusinessIds: selectedLocalBusinesses.map(lb => lb.id)
    });
    const isCouponRuleSome = couponRule === RULE_SOME;
    const isMultipleLocalBusiness = (selectedLocalBusinesses.length > 1 || selectedLocalBusinesses[0]?.id === ANY_LB_OPTION.id);
    if (isMultipleLocalBusiness && isCouponRuleSome) {
      setCouponRule(RULE_ALWAYS);
    }
  }, [selectedLocalBusinesses]);

  const onValuesChange = () => {
    const values = form.getFieldsValue();
    setFormValues(values);
  };

  const setTimeToDate = (date, time) => {
    return moment.utc(date).set({ hours: time.format('HH'), minutes: time.format('mm'), seconds: 0, millisecond: 0 });
  };

  const onFinish = async (values) => {
    const {
      amount,
      couponCode,
      couponScope,
      couponRule,
      couponType,
      description,
      endDate,
      endTime,
      localBusinessIds,
      maxUsage,
      startDate,
      startTime,
      totalCoupons
    } = values;

    const couponData = {
      activationStatus: true,
      applicationCode: couponCode,
      articleId: (couponRule === RULE_SOME) ? selectedProducts.map(sP => sP.articleId) : [],
      description: description,
      discountAbsolute: couponType === SELECT_FIXED ? amount : null,
      discountPercentage: couponType === SELECT_PERC ? amount : null,
      endDate: setTimeToDate(endDate, endTime).format('x'),
      localBusinessIds: (localBusinessIds[0] === ANY_LB_OPTION.id) ? null : localBusinessIds,
      maxUtilization: totalCoupons,
      maxUtilizationPerUser: maxUsage,
      scope: (couponScope === SELECT_SCOPE_ONLINE) ? 'ONLINE' : 'IN_STORE',
      startDate: setTimeToDate(startDate, startTime).format('x')
    };
    setIsLoading(true);
    try {
      await CouponsAPI.createCoupon(couponData);
      message.success(text('createOK'));
      navigateToCouponsPage();
    } catch (e) {
      message.error(text('createKO'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCouponTypeChange = (value) => {
    switch (value) {
      case SELECT_PERC:
        setSelectedType(SELECT_PERC);
        setAmountStep(AMOUNT_STEP_PERC);
        break;
      case SELECT_FIXED:
        setSelectedType(SELECT_FIXED);
        setAmountStep(AMOUNT_STEP_FIXED);
        break;
      default:
        setSelectedType(SELECT_PERC);
        setAmountStep(AMOUNT_STEP_PERC);
        break;
    }
  };

  const handleCouponRuleChange = (rule) => {
    setCouponRule(rule?.target?.value);
  };

  const onProductSearch = (searchText) => {
    if (!isProductSearchDisabled) {
      const searchProductQuery = {
        limit: 10,
        pageNumber: 0,
        filters: [
          {
            local_business_id: selectedLocalBusinesses.map(lb => lb.id),
            published: 'true'
          }
        ],
        text: searchText,
        ranges: [],
        orderingList: null
      };
      onChangeProductSearchQuery(searchProductQuery);
      onSelectedProductsChange([]);
    } else {
      message.error(text('cannotSearch'));
    }
  };

  /**
  * @description Handle all the columns being displayed on the found product table
  */
  const foundProductColumns = useMemo(
    () => [
      {
        title: text('foundProduct'),
        dataIndex: ['name'],
        editable: false,
        inputType: 'text',
        render: (_, record) => {
          return <div>{record?.name}</div>;
        }
      }
    ], []);

  /**
  * @description Handle all the columns being displayed on the selected product table
  */
  const selectedProductColumns = useMemo(
    () => [
      {
        title: text('selectedProduct'),
        dataIndex: ['name'],
        editable: false,
        inputType: 'text',
        render: (_, record) => {
          return <div>{record?.name}</div>;
        }
      },
      {
        title: '',
        dataIndex: ['remove'],
        editable: false,
        inputType: 'text',
        align: 'center',
        render: (_, record, index) => {
          return (
            <DeleteOutlined
              className='text-xl hover:text-red-600'
              onClick={(e) => {
                removeSelectedProduct(record, index);
              }}
            />
          );
        }
      }
    ], [selectedProducts]);

  const onSelectedProductsChange = (newSelectedProductsKeys) => {
    setSelectedProductsKeys(newSelectedProductsKeys);
  };

  const rowSelection = {
    selectedRowKeys: selectedProductsKeys,
    onChange: onSelectedProductsChange
  };

  const pagination = {
    position: 'bottomRight',
    pageSize: 10
  };

  const addSelectedProducts = () => {
    const newlyAddedProducts = selectedProductsKeys.map(selectedProductKey => products.find(product => product.articleId === selectedProductKey));
    setSelectedProducts(selectedProducts.concat(newlyAddedProducts));
  };

  const removeSelectedProduct = (item, index) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts.splice(index, 1);
    setSelectedProducts(newSelectedProducts);
    const newSelectedProductsKeys = newSelectedProducts.map(selectedProduct => selectedProduct.articleId);
    setSelectedProductsKeys(newSelectedProductsKeys);
  };

  const getProducts = useCallback(async () => {
    if (productSearchQuery) {
      try {
        const res = await CouponsAPI.searchProducts(productSearchQuery);
        const products = res.data.items;
        setProducts(products);
        return Promise.resolve();
      } catch (e) {
        message.error(text('somethingWentWrong'));
      }
    }
  }, [productSearchQuery]);

  const filterProducts = () => {
    const newProductsToDisplay = [...products];
    selectedProducts.forEach(sP => {
      const index = newProductsToDisplay.findIndex(product => product.articleId === sP.articleId);
      if (index > -1) {
        newProductsToDisplay.splice(index, 1);
      }
    });
    setProductsToDisplay(newProductsToDisplay);
  };

  const resetProductListAndProductSelection = () => {
    setSelectedProductsKeys([]);
    setProducts([]);
    setProductsToDisplay([]);
    setSelectedProducts([]);
  };

  const handleSelectedLocalBusinessChange = (_, selectedOptions) => {
    const lastSelected = selectedOptions.slice(-1)?.[0];
    if (!lastSelected) {
      setSelectedLocalBusinesses([]);
    } else if (lastSelected.id === ANY_LB_OPTION.id) {
      setSelectedLocalBusinesses([ANY_LB_OPTION]);
    } else {
      setSelectedLocalBusinesses(selectedOptions.filter(opt => opt.id !== ANY_LB_OPTION.id));
    }
    resetProductListAndProductSelection();
  };

  useEffect(() => {
    getProducts();
  }, [productSearchQuery]);

  useEffect(() => {
    filterProducts();
  }, [products, selectedProducts]);

  useEffect(() => {
    const isProductSearchDisabled = (couponRule !== RULE_SOME || selectedLocalBusinesses.find(lb => lb.id === ANY_LB_OPTION.id));
    if (isProductSearchDisabled) {
      resetProductListAndProductSelection();
    }
    setIsProductSearchDisabled(isProductSearchDisabled);
  }, [couponRule, selectedLocalBusinesses]);

  const isBeforeToday = isBefore(moment('00:00', 'h:mm').utc());

  const navigateToCouponsPage = () => {
    history.push(Routes.PATHS.COUPONS);
  };

  const handleModalOk = async () => {
    const formValues = form.getFieldsValue();
    onFinish(formValues);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = () => {
    const newModalTitle = text('modal.title');
    setModalTitle(newModalTitle);
    if (selectedLocalBusinesses.length === 1 && selectedLocalBusinesses[0]?.id === ANY_LB_OPTION.id) {
      const newModalBody = text('modal.bodyAnyLB');
      setModalBody(newModalBody);
    } else {
      const localBusinessSummary = selectedLocalBusinesses.map(lb => lb.label).join(', ');
      const newModalBody = text('modal.body', { localBusinesses: localBusinessSummary });
      setModalBody(newModalBody);
    }
    setIsModalVisible(true);
  };

  return (
    <div className='py-4'>
      <ConfirmationModal
        isVisible={isModalVisible}
        title={modalTitle}
        body={modalBody}
        onConfirm={handleModalOk}
        onDismiss={handleModalCancel}
      />
      <Form
        name='basic'
        initialValues={{
          amount: amountStep,
          couponType: SELECT_PERC,
          couponScope: SELECT_SCOPE_ONLINE,
          couponRule: RULE_ALWAYS
        }}
        onFinish={showModal}
        onValuesChange={onValuesChange}
        autoComplete='off'
        layout='vertical'
        form={form}
        className='max-w-8xl'
      >
        <PageTitle>{text('createNewCoupon')}</PageTitle>
        <BackButton
          iconChevron
          className='mt-4 mb-8'
          onClick={navigateToCouponsPage}
          title={text('backButton')}
        />

        <div>
          <Row gutter={20}>
            <Col xs={8}>
              {/* Local Business */}
              <SelectLocalBusinessWithLoadMore
                mode='multiple'
                formItemName='localBusinessIds'
                rules={[{ required: true, message: text('form.localBusiness.message') }]}
                label={text('form.localBusiness.label')}
                placeholder={text('form.localBusiness.placeholder')}
                onChange={handleSelectedLocalBusinessChange}
                defaultOptions={[ANY_LB_OPTION]}
              />

              {/* Start Date */}
              <Form.Item
                name='startDate'
                label={text('form.startDate.label')}
                rules={[{ required: true, message: text('form.startDate.message') }]}
              >
                <DatePicker
                  className='w-full'
                  format='DD/MM/YYYY'
                  getPopupContainer={trigger => trigger.parentElement} placeholder={text('form.startDate.placeholder')}
                  disabledDate={isBeforeToday}
                />
              </Form.Item>
              {/* End Date */}
              <Form.Item
                name='endDate'
                label={text('form.endDate.label')}
                rules={[{ required: true, message: text('form.endDate.message') }]}
              >
                <DatePicker
                  className='w-full'
                  format='DD/MM/YYYY'
                  getPopupContainer={trigger => trigger.parentElement}
                  placeholder={text('form.endDate.placeholder')}
                  disabledDate={isBeforeToday}
                />
              </Form.Item>
              {/* Total Coupons */}
              <Form.Item
                label={text('form.totalCoupons.label')}
                name='totalCoupons'
                rules={[{ required: true, message: text('form.totalCoupons.message') }]}
              >
                <InputNumber
                  parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                  placeholder='300'
                />
              </Form.Item>
              {/* Coupon Type */}
              <Form.Item
                name='couponType'
                label={text('form.couponType.label')}
                rules={[{ required: true, message: text('form.couponType.message') }]}
              >
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  style={{ width: '100%' }}
                  onChange={handleCouponTypeChange}
                >
                  <Option value={SELECT_PERC}>{SELECT_PERC}</Option>
                  <Option value={SELECT_FIXED}>{SELECT_FIXED}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              {/* Coupon Code */}
              <Form.Item
                label={text('form.couponCode.label')}
                name='couponCode'
                rules={[{ required: true, message: text('form.couponCode.message') }]}
              >
                <Input
                  placeholder={text('form.couponCode.placeholder')}
                />
              </Form.Item>
              {/* Start Time */}
              <Form.Item
                name='startTime'
                label={text('form.startTime.label')}
                rules={[{ required: true, message: text('form.startTime.message') }]}
              >
                <TimePicker
                  getPopupContainer={trigger => trigger.parentElement}
                  showNow={false}
                  placeholder={text('form.startTime.placeholder')}
                  className='w-full'
                  format='HH:mm'
                />
              </Form.Item>
              {/* End Time */}
              <Form.Item
                name='endTime'
                label={text('form.endTime.label')}
                rules={[{ required: true, message: text('form.startTime.message') }]}
              >
                <TimePicker
                  getPopupContainer={trigger => trigger.parentElement}
                  showNow={false}
                  placeholder={text('form.startTime.placeholder')}
                  className='w-full'
                  format='HH:mm'
                />
              </Form.Item>
              {/* Max Usage for Each Customer */}
              <Form.Item
                label={text('form.maxUsage.label')}
                name='maxUsage'
                rules={[{ required: true, message: text('form.maxUsage.message') }]}
              >
                <InputNumber
                  parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                  placeholder='1'
                />
              </Form.Item>
              {/* Amount */}
              <Form.Item
                label={text('form.amount.label')}
                name='amount'
                rules={[{ required: true, message: text('form.amount.message') }]}
              >
                <InputNumber
                  addonBefore={selectedType === SELECT_PERC ? '%' : '€'}
                  parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                  step={amountStep}
                />
              </Form.Item>
            </Col>

            <Col xs={8}>
              {/* Description */}
              <Form.Item
                label={text('form.description.label')}
                name='description'
                rules={[{ required: true, message: text('form.description.message') }]}
              >
                <Input.TextArea
                  rows='6'
                  showCount
                  maxLength={1500}
                  placeholder={text('form.description.placeholder')}
                />
              </Form.Item>
              {/* Scope */}
              <Form.Item
                name='couponScope'
                label={text('form.scope.label')}
                rules={[{ required: true, message: text('form.scope.message') }]}
              >
                <Select
                  getPopupContainer={trigger => trigger.parentNode}
                  style={{ width: '100%' }}
                >
                  <Option value={SELECT_SCOPE_ONLINE}>{SELECT_SCOPE_ONLINE}</Option>
                  <Option value={SELECT_SCOPE_STORE}>{SELECT_SCOPE_STORE}</Option>
                </Select>
              </Form.Item>
              {/* Rules */}
              <Form.Item
                name='couponRule'
                label={text('form.rules.label')}
                rules={[{ required: true, message: text('form.rules.message') }]}
              >
                <Radio.Group
                  onChange={handleCouponRuleChange}
                  value={couponRule}
                >
                  <Space direction='vertical'>
                    <Radio value={RULE_ALWAYS}>{RULE_ALWAYS}</Radio>
                    <Radio
                      disabled={selectedLocalBusinesses.length > 1 || selectedLocalBusinesses[0]?.id === ANY_LB_OPTION.id}
                      value={RULE_SOME}
                    >
                      {RULE_SOME}
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {/* Product Search && Selected Products */}
          <div className='mt-4'>
            <Form.Item
              name='productSearch'
              label={text('form.productSearch.label')}
              required={false}
            >
              <Row gutter={20}>
                <Col xs={8}>
                  <Search
                    placeholder={text('form.productSearch.placeholder')}
                    disabled={isProductSearchDisabled}
                    onSearch={onProductSearch}
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={8}>
                  <Table
                    className='mt-2 coupon-products-table'
                    rowSelection={rowSelection}
                    dataSource={productsToDisplay}
                    columns={foundProductColumns}
                    pagination={{ ...pagination, showSizeChanger: false }}
                    rowKey='articleId'
                  />
                </Col>
                <Col xs={8}>
                  <Table
                    className='mt-2 coupon-products-table'
                    dataSource={selectedProducts}
                    columns={selectedProductColumns}
                    pagination={{ ...pagination, showSizeChanger: false }}
                    rowKey='articleId'
                  />
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={8}>
                  <div className='mt-2 w-full'>
                    <Button
                      type='primary'
                      className='uppercase'
                      disabled={isProductSearchDisabled}
                      onClick={addSelectedProducts}
                    >
                      {text('form.addSelected')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </div>
        </div>
        {/* Actions */}
        <div className='flex items-center w-full '>
          <Button
            type='secondary'
            htmlType='button'
            className='uppercase mr-2 ml-auto  mt-40'
          >
            {text('cancel')}
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            type='primary'
            htmlType='submit'
            className='uppercase ml-2 mt-40'
          >
            {text('createCoupon')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
