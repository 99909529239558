import { AxiosClient } from 'src/api/AxiosClient';

const DEFAULT_LOGO_CONFIG = {
  headers: {
    'content-type': 'multipart/form-data',
    accept: 'application/stentle.api-v0.1+json'
  }
};

export class BrandsAPI {
  static async getDepartments () {
    return AxiosClient.get('/departments?pageNumber=1&limit=100');
  }

  static async getBrands (data) {
    const res = await AxiosClient.get('/brands/filter?sort=departments,asc&sort=key,asc',
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        },
        params: {
          limit: data.limit,
          pageNumber: data.pageNumber,
          text: data.filters.brand,
          departmentKey: data.filters.department,
          localBusinessId: data.filters.localBusinessId
        }
      }
    );
    return res?.data?.data;
  }

  static buildFmData (brandLogo) {
    const fmData = new window.FormData();
    fmData.append('fileUpload1', brandLogo.originFileObj);
    return fmData;
  }

  static async getBrand (brandId) {
    return AxiosClient.get(`/brands/${brandId}`);
  }

  static async createBrand (data) {
    return AxiosClient.post('/brands', data);
  }

  static async createBrandLogo (brandId, brandLogo) {
    const fmData = this.buildFmData(brandLogo);
    const config = DEFAULT_LOGO_CONFIG;
    return AxiosClient.post(`/brands/${brandId}/logo`, fmData, config);
  }

  static async uploadBrandLogo (brandId, brandLogo) {
    const fmData = this.buildFmData(brandLogo);
    const config = DEFAULT_LOGO_CONFIG;
    return AxiosClient.post(`/brands/${brandId}/logo/update`, fmData, config);
  }

  static async updateBrand (brandId, data) {
    return AxiosClient.put(`/brands/${brandId}`, data);
  }

  static async deleteBrand (brandId) {
    try {
      const res = await AxiosClient.delete(`/brands/${brandId}`, {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      });
      return res.status;
    } catch (err) {
      return err.response?.status;
    }
  }
}
