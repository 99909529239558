import { AxiosClient } from "src/api/AxiosClient";

export class DashboardAPI {
  // ---------------------------------- Dashboard--------------------------------------------

  /**
   *
   * @returns @description Get Published product Count
   */
  static async getPublishedProductCount() {
    return AxiosClient.get("/backoffice/dashboard/product/published/count");
  }

  /**
   * @description Get Product Stock Count
   * @param {*} availability
   * @returns
   */
  static async getProductsStockCount(available) {
    return AxiosClient.get(`backoffice/dashboard/product/available/${available}/count`);
  }

  /**
   * @description Get all visuals data
   * @returns
   */

  static async getAllVisuals() {
    return AxiosClient.get(`/visuals?pageNumber=1&limit=4&orderBy=updateDate`);
  }

  /**
   * @description Get last updated product list
   * @returns
   */
  static async getLastUpdatedProductList() {
    return AxiosClient.get(`backoffice/dashboard/product/last-edited?limit=4`)
  }

  /**
   * @description Get out of stock product list
   * @returns
   */
  static async getOutOfStockProductList() {
    return AxiosClient.get(`backoffice/dashboard/product/available/out-of-stock?limit=4`)
  }

  /**
   * @description Get draft product count
   * @returns
   */
  static async getDraftProductsCount() {
    return AxiosClient.get(`backoffice/dashboard/product/draft/count`)
  }
}
