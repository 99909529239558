import { Filters } from 'src/services/Filters.service';
import { CustomersQuery, KEYS } from 'src/modules/admin/domain/models/CustomersQuery';

export class CustomersMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const query = new URLSearchParams(qs);
    const customerQueryModel = new CustomersQuery();
    customerQueryModel.current = query.get('current') || undefined;
    customerQueryModel.pageSize = query.get('pageSize') || undefined;
    customerQueryModel.text = (query.get(KEYS.TEXT) || undefined);
    return customerQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: !query?.current ? 1 : +query.current,
      limit: query?.pageSize || 10,
      filters: {
        text: query?.text
      }
    };
    return payload;
  }
}
