import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { BrandsAPI } from 'src/modules/brands/api/BrandsAPI';
import { useFilters } from 'src/hooks/useFilters';
import { BrandsMapper } from 'src/modules/brands/domain/Brands.mapper';
import { usePagination } from 'src/hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

const BrandsContext = createContext();

const useProvideBrands = (text) => {
  const location = useLocation();
  const params = useParams();
  const { query, onChangeQuery } = useFilters(BrandsMapper);
  const [brands, setBrands] = useState([]);
  const [areBrandsLoading, setAreBrandsLoading] = useState(false);
  const [pagination, setPagination] = usePagination();
  const [brand, setBrand] = useState(null);

  const getBrands = useCallback(async () => {
    setAreBrandsLoading(true);
    const brandQuery = { ...query };
    try {
      const data = await BrandsAPI.getBrands(BrandsMapper.fromQueryToPayload(brandQuery));
      const receivedBrands = data?.items || [];
      setBrands(receivedBrands);
      setPagination(data);
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveBrands'));
    } finally {
      setAreBrandsLoading(false);
    }
  }, [query, setPagination, location]);

  const getBrandDetails = useCallback(async () => {
    try {
      const res = await BrandsAPI.getBrand(params.id);
      setBrand(res?.data?.data);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      getBrandDetails();
    }
  }, [params.id]);

  useEffect(() => {
    if (query.department !== undefined) {
      getBrands(query);
    }
  }, [location.search, getBrands]);

  return {
    query,
    brands,
    brand,
    pagination,
    setBrands,
    onChangeQuery,
    refreshData: getBrands,
    areBrandsLoading
  };
};

export const BrandsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.brands', t);

  const brands = useProvideBrands(text);

  return (
    <BrandsContext.Provider value={brands}>
      {children}
    </BrandsContext.Provider>
  );
};

export const useBrands = () => useContext(BrandsContext);
