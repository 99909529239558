import { Select, Button } from "antd";
import React, { useState, useEffect, createRef, useCallback } from "react";
import { InputMultiSelectSearch } from "src/components/inputs/InputMultiSelectSearch";
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

export const CustomMultiselect = ({
  items,
  values,
  placeholder,
  className,
  onReset,
  onApply,
  searchProperty,
  keyProperty = "key",
  labelProperty = "value",
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.customMultiselect', t);

  const [currentSelectedValues, setCurrentSelectedValues] = useState(values);
  const [visible, setVisible] = useState(false);
  const dropdownRef = createRef();

  const onResetFilter = () => {
    onReset();
    setCurrentSelectedValues([]);
    setVisible(false);
  };

  const onApplyFilter = (values) => {
    onApply(values);
    setVisible(false);
  };

  const clickOutside = useCallback(
    (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCurrentSelectedValues(values);
        setVisible(false);
      }
    },
    [values, dropdownRef]
  );

  useEffect(() => {
    if (dropdownRef.current) {
      document.addEventListener("mousedown", clickOutside);
    }
    return () => document.removeEventListener("mousedown", clickOutside);
  }, [dropdownRef, clickOutside]);

  return (
      <>
        <Select
            getPopupContainer={trigger => trigger.parentNode}
            open={visible}
            onFocus={() => setVisible(true)}
            onDropdownVisibleChange={() => setVisible(!visible)}
            className="w-full multi-select-dropdown"
            placeholder={placeholder}
            dropdownRender={() => (
                <div className={`multi-select ${className || ""}`} ref={dropdownRef}>
                  <InputMultiSelectSearch
                      items={items}
                      values={currentSelectedValues}
                      onChange={setCurrentSelectedValues}
                      placeholder={placeholder}
                      searchProperty={searchProperty}
                      keyProperty={keyProperty}
                      labelProperty={labelProperty}
                  />
                  {/* Actions */}
                  <div className="flex justify-end  pt-2">
                    <Button
                        type="text"
                        className="mr-2 uppercase"
                        onClick={onResetFilter}
                    >
                      {text('clear')}
                    </Button>
                    <Button
                        className="uppercase"
                        type="primary"
                        onClick={() => onApplyFilter(currentSelectedValues)}
                    >
                      {text('apply')}
                    </Button>
                  </div>
                </div>
            )}
        />
      </>
  );
};
