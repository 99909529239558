import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { LookbookHomepageAPI } from '../api/LookbookHomepageAPI';

const LookbookHomepageContext = createContext();

const useProvideLookbookHomepage = (text) => {
  const { userInfo } = useAuth();
  const [homepageSections, setHomepageSections] = useState([]);
  const [areHomepageSectionsLoading, setAreHomepageSectionsLoading] = useState(false);

  const getHomepageSections = useCallback(async () => {
    setAreHomepageSectionsLoading(true);
    try {
      const localBusinessId = userInfo?.localBusiness?.id;
      const res = await LookbookHomepageAPI.getHomepageSections(localBusinessId);
      setHomepageSections(res?.data?.sections || []);
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveLookbookHomepage'));
    } finally {
      setAreHomepageSectionsLoading(false);
    }
  }, [userInfo?.localBusiness?.id]);

  useEffect(() => {
    getHomepageSections();
  }, []);

  return {
    homepageSections,
    areHomepageSectionsLoading
  };
};

export const LookbookHomepageProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.lookbookHomepage', t);

  const homepage = useProvideLookbookHomepage(text);

  return (
    <LookbookHomepageContext.Provider value={homepage}>
      {children}
    </LookbookHomepageContext.Provider>
  );
};

export const useLookbookHomepage = () => useContext(LookbookHomepageContext);
