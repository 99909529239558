import { Filters } from 'src/services/Filters.service';
import { LocalBusinessesQuery } from 'src/modules/admin/domain/models/LocalBusinessesQuery';

export class LocalBusinessesMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const query = new URLSearchParams(qs);
    const localBusinessesQueryModel = new LocalBusinessesQuery();
    localBusinessesQueryModel.pageNumber = query.get('current') || undefined;
    localBusinessesQueryModel.pageSize = query.get('pageSize') || undefined;
    localBusinessesQueryModel.text = query.get('text') || undefined;
    return localBusinessesQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      limit: query?.pageSize || 10,
      pageNumber: query?.pageNumber > 0 ? +query.pageNumber : 1,
      text: query?.text || ''
    };
    return payload;
  }
}
