import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Row, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { ImageUpload } from '../component/ImageUpload';
import { PageSection } from 'src/components/PageSection';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { useLookbookConfig } from '../provider/lookbookConfig.provider';
import { LookbookConfigAPI } from '../api/LookbookConfigAPI';

const NEW_CONFIG = {
  title: '',
  iconUrl: null,
  availabilityServiceSource: 'JUST_COMMERCE'
};

export const PageLookbookConfig = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.lookbookConfig', t);

  const [form] = useForm();
  const { userInfo } = useAuth();
  const { config, refreshData, setConfig } = useLookbookConfig();
  const [logoUrl, setLogoUrl] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (config) {
      form.setFieldsValue({
        lookbookTitle: config?.title,
        logoUrl: config?.iconUrl
      });
      setLogoUrl(config?.iconUrl);
    }
  }, [form, config]);

  const handleTitleSaveOrUpdate = async () => {
    const localBusinessId = userInfo?.localBusiness?.id;
    const { lookbookTitle } = form.getFieldsValue();
    if (config) {
      const lookbookTitle = form.getFieldValue('lookbookTitle');
      setIsLoading(true);
      try {
        await LookbookConfigAPI.updateTitle(localBusinessId, lookbookTitle);
        message.success(text('onTitleSaveOk'));
      } catch (e) {
        message.error(text('onTitleSaveErr'));
      } finally {
        setIsLoading(false);
      }
    } else if (lookbookTitle) {
      const configData = {
        ...NEW_CONFIG,
        title: lookbookTitle
      };
      setIsLoading(true);
      try {
        const localBusinessId = userInfo?.localBusiness?.id;
        await LookbookConfigAPI.createConfig(localBusinessId, configData);
        message.success(text('onTitleSaveOk'));
      } catch (e) {
        message.error(text('onTitleSaveErr'));
      } finally {
        setIsLoading(false);
        refreshData();
      }
    }
  };

  const handleLogoUpload = async (file) => {
    const localBusinessId = userInfo?.localBusiness?.id;
    if (!config) {
      const configData = { ...NEW_CONFIG };
      const res = await LookbookConfigAPI.createConfig(localBusinessId, configData);
      setConfig(res?.data);
    }
    try {
      const res = await LookbookConfigAPI.uploadLogo(localBusinessId, file);
      setLogoUrl(res?.data?.iconUrl);
      form.setFieldsValue({
        logoUrl: res?.data?.iconUrl
      });
      message.success(text('onLogoSaveOk'));
    } catch (e) {
      message.error(text('onLogoSaveErr'));
    } finally {
      refreshData();
    }
  };

  return (
    <div className='py-4 layout-main-content-and-footer'>
      <div className='layout-main-content'>
        <PageTitle>{text('pageTitle')}</PageTitle>
        <Form
          form={form}
          initialValues={{
            lookbookTitle: null,
            logoUrl: null
          }}
        >
          <PageSection className='mt-2'>{text('lookbookTitle')}</PageSection>
          <Row gutter={20}>
            <Col xs={6}>
              <Form.Item
                name='lookbookTitle'
                rules={[{ required: true, message: text('lookbookTitleRequired') }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <PageSection className='mt-2'>{text('logo')}</PageSection>
              <Form.Item
                name='logoUrl'
                rules={[{ required: true, message: text('logoUrlRequired') }]}
              >
                <ImageUpload
                  imageUrl={logoUrl}
                  onUpload={handleLogoUpload}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <div className='flex justify-end w-full '>
              <PrimaryButton
                loading={isLoading}
                disabled={isLoading}
                label={text('save')}
                onClick={handleTitleSaveOrUpdate}
              />
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
};
