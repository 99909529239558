export class Dom {
  static getWindowSize(includeScrollbars = true) {
    const width = includeScrollbars
      ? window.innerWidth
      : document.documentElement.clientWidth;
    const height = includeScrollbars
      ? window.innerHeight
      : document.documentElement.clientHeight;

    return { width, height };
  }

  static getComputedSize(element) {
    const { width, height } = element.getBoundingClientRect();
    return { width, height };
  }

  static setCssVariable(variableName, value) {
    return (root) => {
      root = root || document.documentElement;
      const _value = typeof value === "number" ? `${value}` : value;
      if (!root) return;
      root.style.setProperty(variableName, _value);
    };
  }

  /**
   * Template string based utility to compute classNames: \
   * cn`
   *     items-center
   *     ${isActive && 'bg-green-500'}
   *     ${isVisible ? 'flex': 'hidden}
   *`
   */
  static cn(template, ...args) {
    let res = "";

    for (let i = 0; i < template.length; i++) {
      res += `${template[i]}${args[i] || ""} `;
    }
    return res.replace(/(\r\n|\n|\r|\s+)/gm, " ").trim();
  }

  /**
   *
   * @param {*} target HTMLElement
   * @param {*} stringifiedCSS string
   */
  static injectCSS(target, stringifiedCSS) {
    if (!target) return;
    const styleTag = document.createElement("style");
    styleTag.innerHTML = stringifiedCSS;
    target.prepend(styleTag);
  }
}
