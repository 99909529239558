import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useFilters } from 'src/hooks/useFilters';
import { usePagination } from 'src/hooks/usePagination';
import { useLocation } from 'react-router-dom';
import { EventsAPI as ProductsAPI } from 'src/modules/products/api/EventsAPI';
import { ProductsMapper } from 'src/modules/products/domain/Products.mapper';
import { Product } from 'src/services/Product.service';
import { message } from 'antd';
import moment from 'moment';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Routes } from 'src/router/Routes.helper';

export const eventsContext = createContext();

const useProvideEvents = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [availableFilters, setAvailableFilters] = useState({ filters: [] });
  const [areEventsLoading, setAreEventsLoading] = useState(false);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(ProductsMapper);

  const upcomingEvents = location.pathname.includes(Routes.PATHS.PRODUCTS_EVENT_UPCOMING);

  const auth = useAuth();

  let rangeFilter = {
    key: 'start_dt',
    start: upcomingEvents
      ? moment(new Date()).utc().startOf('day').format('YYYY-MM-DD:HH:mm:ss')
      : '1970-01-01:08:30:00',
    end: upcomingEvents
      ? '2099-01-01:23:59:59'
      : moment(new Date()).utc().subtract(1, 'days').endOf('day').format('YYYY-MM-DD:HH:mm:ss')
  };

  if (query.startDate && query.endDate) {
    rangeFilter = {
      key: 'start_dt',
      start: query.startDate,
      end: query.endDate
    };
  }

  const searchQuery = {};

  const initialCustomFilters = [
    {
      'macro_category.key': query.macroCategory || [],
      'micro_category.key': query.microCategory || [],
      ...searchQuery,
      department: 'event'
    }
  ];

  if (query.published) {
    initialCustomFilters[0].published = query.published;
  }

  /**
   * @description Get list of products
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getProducts = useCallback(async () => {
    setAreEventsLoading(true);
    const filters = [...initialCustomFilters];
    const businessId = auth?.userInfo?.localBusiness?.id;
    if (businessId) {
      filters[0].local_business_id = businessId;
    }
    try {
      const res = await ProductsAPI.searchProducts({
        limit: query?.pageSize || 20,
        pageNumber: +query.current === 0 ? 0 : +query.current - 1,
        ranges: [rangeFilter],
        filters,
        orderingList: null,
        text: query.text || ''
      });

      const items = res.data.items.map((item) => ({
        ...item,
        published: Product.getStatus(item.published),
        macroProductCategory: {
          ...item.macroProductCategory,
          key: item.macroProductCategory.key,
          label: item.macroProductCategory.localeName
        },
        microProductCategory: {
          ...item.microProductCategory,
          key: item.microProductCategory.key,
          label: item.microProductCategory.localeName
        }
      }));

      setProducts(items);
      setAvailableFilters(res.data.availableFilters);
      setPagination(res.data);
      return Promise.resolve();
    } catch (e) {
      message.error('Something went wrong!');
    } finally {
      setAreEventsLoading(false);
    }
  }, [query, setPagination, location.pathname]);

  useEffect(() => {
    getProducts();
  }, [location.search, getProducts]);

  const statusOptions = [
    {
      value: 'draft',
      name: 'Draft'
    },
    {
      value: 'published',
      name: 'Published'
    }
  ];

  return {
    query,
    products,
    setProducts,
    availableFilters,
    pagination,
    onChangeQuery,
    refreshData: getProducts,
    statusOptions,
    areEventsLoading
  };
};

export const EventsProvider = ({ children }) => {
  const products = useProvideEvents();
  return (
    <eventsContext.Provider value={products}>{children}</eventsContext.Provider>
  );
};

export const useEvents = () => useContext(eventsContext);
