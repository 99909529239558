import { Filters } from 'src/services/Filters.service';
import { BrandsQuery } from 'src/modules/brands/domain/models/BrandsQuery';
import { ANY_OPTION } from 'src/utils/utils';

export class BrandsMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = BrandsQuery;
    const query = new URLSearchParams(qs);
    const brandQueryModel = new BrandsQuery();
    brandQueryModel.current = query.get('current') || undefined;
    brandQueryModel.pageSize = query.get('pageSize') || undefined;
    brandQueryModel.brand = (query.get(KEYS.BRAND) || undefined);
    brandQueryModel.department = (query.get(KEYS.DEPARTMENT) || undefined);
    return brandQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: (!query || !query.current || query.current === 0) ? 1 : +query.current,
      limit: query.pageSize,
      filters: {
        brand: query?.brand,
        department: query?.department === ANY_OPTION.value ? undefined : query?.department
      }
    };
    return payload;
  }
}
