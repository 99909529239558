import React, { useState, useEffect } from 'react';
import { SearchOutfits } from 'src/modules/collections/components/outfit-collection/SearchOutfits';

function outfitToSection (outfit) {
  return {
    id: outfit.id,
    code: outfit.id,
    type: 'OUTFIT_DETAILS',
    names: outfit.names,
    bannerMobile: { imageURL: outfit.coverImage }
  };
}

export const OutfitSelector = ({
  outfits,
  linkedOutfits,
  setLinkedOutfits
}) => {
  const [outfitsToShow, setOutfitsToShow] = useState([]);

  useEffect(() => {
    if (linkedOutfits.length === 0) {
      setOutfitsToShow(outfits);
    } else {
      const getOutfitsToShow = () => outfits.filter(collection => !(linkedOutfits.some(linkedCollection => linkedCollection.id === collection.id)));
      setOutfitsToShow(getOutfitsToShow);
    }
  }, [linkedOutfits, outfits]);

  return (
    <SearchOutfits
      mapper={outfitToSection}
      outfits={outfitsToShow}
      setLinkedOutfits={setLinkedOutfits}
    />
  );
};
