import { Filters } from 'src/services/Filters.service';
import { WarehousesQuery } from 'src/modules/warehouses/domain/models/WarehousesQuery';

export class WarehousesMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = WarehousesQuery;
    const query = new URLSearchParams(qs);
    const warehouseQueryModel = new WarehousesQuery();
    warehouseQueryModel.current = query?.get('current');
    warehouseQueryModel.pageSize = query?.get('pageSize');
    warehouseQueryModel.name = query?.get(KEYS.NAME);
    warehouseQueryModel.type = query?.get(KEYS.TYPE);
    warehouseQueryModel.department = query?.get(KEYS.DEPARTMENT);
    return warehouseQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: query?.current > 0 ? +query.current : 1,
      limit: query?.pageSize ?? 10,
      filters: {
        name: query?.name,
        type: query?.type,
        department: query?.department
      }
    };
    return payload;
  }
}
