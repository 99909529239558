import { AxiosClient } from 'src/api/AxiosClient';

export class DownloadsAPI {
  static async getDownloads (data) {
    return AxiosClient.get('/report/coupons-usage/history',
      {
        params: {
          limit: data?.limit,
          pageNumber: data?.pageNumber
        }
      }
    );
  }

  static async getDetails (id) {
    return AxiosClient.get(`/report/execution/${id}`);
  }

  static async createCouponsUsageReport (filters = {}) {
    return AxiosClient.post('/report/coupons-usage', filters);
  }

  static async retryToCreateReport (id) {
    return AxiosClient.post(`/report/retry?id=${id}`);
  }
}
