import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useUrlSearch = () => {
    const searchString = useLocation()?.search

    return useMemo(() => {
        const search = {}
        const params = new URLSearchParams(searchString)
        for (let [key, value] of params.entries()) {
				try{
            	search[key] = JSON.parse(value)
				}
				catch(e){
					search[key] = value
				}
        }
		  return search
    }, [searchString])
}

