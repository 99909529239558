import { AxiosClient } from 'src/api/AxiosClient';

export class PermissionSetsAPI {
  static async getPermissionSets (data) {
    return AxiosClient.get('/permission-sets',
      {
        params: {
          limit: data.limit,
          pageNumber: data.pageNumber
        }
      }
    );
  }

  static async getPermissionSet (id) {
    return AxiosClient.get(`/permission-sets/${id}`);
  }

  static async createPermissionSet (code, permissions, localizedNames, localizedDescriptions, enabledByDefault) {
    return AxiosClient.post('/permission-sets',
      {
        code,
        permissions,
        localizedNames,
        localizedDescriptions,
        enabledByDefault
      }
    );
  }

  static async updatePermissionSet (code, permissions, localizedNames, localizedDescriptions, enabledByDefault) {
    return AxiosClient.put('/permission-sets',
      {
        code,
        permissions,
        localizedNames,
        localizedDescriptions,
        enabledByDefault
      }
    );
  }

  static async deletePermissionSet (code) {
    return AxiosClient.delete(`/permission-sets/${code}`);
  }
}
