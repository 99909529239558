import React from 'react';
import { Modal } from 'antd';

export const ConfirmationModal = ({ isVisible, title, body, onConfirm, onDismiss }) => {
  return (
    <Modal
      title={title}
      visible={isVisible}
      onOk={onConfirm}
      onCancel={onDismiss}
    >
      {body}
    </Modal>
  );
};
