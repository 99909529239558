import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { CollectionsAPI } from '../api/CollectionsAPI';
import { usePagination } from 'src/hooks/usePagination';
import { useFilters } from 'src/hooks/useFilters';
import { OutfitCollectionsMapper } from '../domain/OutfitCollections.mapper';
import { useParams, useLocation } from 'react-router-dom';
import { Routes } from 'src/router/Routes.helper';
import { hasMoreThanXCharacters } from 'src/utils/utils';

const OutfitCollectionsContext = createContext();

const useProvideOutfitCollections = (text) => {
  const location = useLocation();
  const params = useParams();
  const [outfitCollections, setOutfitCollections] = useState([]);
  const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);
  const [collectionsPagination, setCollectionsPagination] = usePagination();
  const [previousSearch, setPreviousSearch] = useState(undefined);
  const { query: collectionsQuery, onChangeQuery: onChangeCollectionsQuery } = useFilters(OutfitCollectionsMapper);

  const [outfitCollectionDetail, setOutfitCollectionDetail] = useState(null);

  const getOutfitCollections = useCallback(async (collectionsQuery) => {
    const query = { ...collectionsQuery };
    setAreCollectionsLoading(true);
    try {
      const res = await CollectionsAPI.getOutfitCollections(OutfitCollectionsMapper.fromQueryToPayload(query));
      setOutfitCollections(res?.data?.items || []);
      setCollectionsPagination(res?.data || {});
      return Promise.resolve();
    } catch (e) {
      message.error(text('failedToRetrieveCollections'));
      return Promise.reject(e);
    } finally {
      setAreCollectionsLoading(false);
    }
  }, [collectionsQuery, location]);

  const getOutfitCollectionDetail = useCallback(async (id) => {
    try {
      const res = await CollectionsAPI.getOutfitCollectionDetail(id);
      setOutfitCollectionDetail(res?.data ?? {});
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, [params.id]);

  useEffect(() => {
    if ([undefined, null].includes(previousSearch) || hasMoreThanXCharacters(collectionsQuery.text, 2) || hasMoreThanXCharacters(previousSearch, 2)) {
      getOutfitCollections(collectionsQuery);
    }
    setPreviousSearch(collectionsQuery.text);
  }, [location.search, collectionsQuery]);

  useEffect(() => {
    if (params.id) {
      const acceptedLocationPath = Routes.parseRouteParams(Routes.PATHS.OUTFIT_COLLECTION_EDIT, {
        id: params.id
      });
      const isEditOutfitCollectionFlow = location.pathname.includes(acceptedLocationPath);
      if (isEditOutfitCollectionFlow) {
        getOutfitCollectionDetail(params.id);
      }
    }
  }, [params.id, location.pathname]);

  return {
    outfitCollections,
    areCollectionsLoading,
    collectionsPagination,
    collectionsQuery,
    onChangeCollectionsQuery,
    getOutfitCollectionDetail,
    outfitCollectionDetail,
    refreshOutfitCollections: getOutfitCollections
  };
};

export const OutfitCollectionsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.collections', t);

  const outfitCollections = useProvideOutfitCollections(text);

  return (
    <OutfitCollectionsContext.Provider value={outfitCollections}>
      {children}
    </OutfitCollectionsContext.Provider>
  );
};

export const useOutfitCollections = () => useContext(OutfitCollectionsContext);
