import React, { useCallback, useEffect, useState } from 'react';
import { ProductAttribute } from 'src/modules/products/components/ProductAttribute';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { CategoriesAPI } from 'src/modules/categories/api/CategoriesAPI';

export const ProductAttributeList = ({ value = [], onChange, disabledDate, language, currentDepartment }) => {
  const { t } = useTranslation();
  const text = textFrom('components.productAttributeList', t);
  const [departments, setDepartments] = useState([]);

  useEffect(async () => {
    const res = await CategoriesAPI.getCategoriesByType('department');
    setDepartments(res?.data?.data?.items || []);
  }, []);

  const onChangeAttribute = (changedAttribute) => {
    const updatedAttributes = value.map((prevAttribute) => {
      if (prevAttribute.id !== changedAttribute.id) return prevAttribute;
      // changed first Select value
      if (prevAttribute.code !== changedAttribute.code) {
        // reset second Select value
        return { ...changedAttribute, label: null, value: null };
      }
      // changed second Select value
      // update second Select label if label exists
      if (changedAttribute.value?.label) {
        return { ...changedAttribute, label: changedAttribute.value?.label, key: changedAttribute.value?.key };
      }
      return changedAttribute;
    });
    onChange(updatedAttributes);
  };

  const onDelete = useCallback(
    (attr) => {
      onChange(value.filter(({ id }) => id !== attr.id));
    },
    [onChange, value]
  );

  const onAddAttribute = useCallback(() => {
    onChange([
      ...value,
      {
        id: (value?.at(-1)?.id || 0) + 1,
        key: null,
        code: null,
        label: null
      }
    ]);
  }, [onChange, value]);

  return (
    <div>
      {value.map((attribute) => (
        <ProductAttribute
          departments={departments}
          currentDepartment={currentDepartment}
          key={attribute.id}
          className='pt-4 pb-4 border-b border-dashed'
          value={attribute}
          disabledDate={disabledDate}
          onDelete={() => onDelete(attribute)}
          onChange={onChangeAttribute}
          alreadyChosenValues={value}
          language={language}
        />
      ))}
      <div className='pt-4'>
        <Button type='primary' className='uppercase' onClick={onAddAttribute}>
          {text('addNewAttribute')}
        </Button>
      </div>
    </div>
  );
};
