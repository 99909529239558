import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { VariantModel } from 'src/modules/products/domain/models/VariantModel';
import { ProductModel } from 'src/modules/products/domain/models/ProductModel';
import moment from 'moment';
import { get, some } from 'lodash';
import { message } from 'antd';

export const productDetailsContext = createContext();

const initialProduct = {
  prices: {},
  attributeList: [],
  macroCategory: {
    key: null
  },
  microCategory: {
    key: null
  },
  brand: { id: null, key: null }
};

const useProvideProductDetails = () => {
  const params = useParams();
  const [product, setProduct] = useState(initialProduct);
  const [productVariants, setProductVariants] = useState([]);
  const [filteredProductVariants, setFilteredProductVariants] = useState([]);
  const [sizeAttributes, setSizeAttributes] = useState([]);
  const [initialVariantsCount, setInitialVariantsCount] = useState(0);

  /**
   * @description Get product details
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getProductDetails = useCallback(async (language) => {
    try {
      const res = await ProductsAPI.getProduct(params.id, language);
      setProduct(new ProductModel(res.data.data));
      return Promise.resolve();
    } catch (e) {
      // @todo handle notification
      return Promise.reject(e);
    }
  }, [params.id]);

  /**
   * @description Get variants
   * @type {(function(): Promise<void|undefined>)|*}
   */
  const getProductVariants = useCallback(async () => {
    try {
      const res = await ProductsAPI.getProductVariants(params.id);
      if (res.data.data) {
        const variants = res.data.data.map(
          (apiVariant) => new VariantModel(apiVariant)
        );
        // get availability for each variant
        for (let index = 0; index < variants.length; index++) {
          const element = variants[index];
          try {
            const variantAvailability = await getVariantAvailabilities(params.id, element.id);
            element.availabilityTotal = variantAvailability.data.data?.items[0].availability;
          } catch (error) {
            message.error('Something went wrong while getting variants!');
            break;
          }
        }
        setProductVariants(variants);
        setInitialVariantsCount(variants.length);
        setFilteredProductVariants(variants);
        return Promise.resolve();
      }
    } catch (e) {
      // @todo handle notification
      message.error('Something went wrong!');
      return Promise.reject(e);
    }
  }, [params.id]);

  /**
   * @description On filter change - variant search
   * @param dataIndex
   * @param searchValue
   */
  const onFiltersChange = (lookupList, searchValue) => {
    const filteredProductVariants = productVariants.filter((productVariant) => {
      return some(lookupList, function (key) {
        const cellValue =
          key === 'updateDate'
            ? moment(get(productVariant, key)).format('DD MMM YYYY')
            : get(productVariant, key);
        return (
          cellValue &&
          cellValue.toString().toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    });
    setFilteredProductVariants(filteredProductVariants);
  };

  /**
   * @description Add Product image - gallery section
   * @type {(function(*=, *=): Promise<AxiosResponse<*>|undefined>)|*}
   */
  const addProductGalleryImage = useCallback(async (productId, file) => {
    try {
      return await ProductsAPI.addProductGalleryImage(productId, file);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  /**
   * @description Get variant availabilities
   * @type {(function(*=, *=): Promise<AxiosResponse<*>|undefined>)|*}
   */
  const getVariantAvailabilities = useCallback(async (productId, variantId) => {
    try {
      const res = await ProductsAPI.getVariantAvailabilities(
        productId,
        variantId
      );
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  /**
   * @description Get size attributes
   * @type {(function(*=, *=): Promise<AxiosResponse<*>|undefined>)|*}
   */
  const getSizeAttributes = useCallback(async () => {
    try {
      const res = await ProductsAPI.getSizeAttributes();
      setSizeAttributes(res.data.data.items);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      getProductDetails();
    }
    getSizeAttributes();
  }, [params.id, getProductDetails, getSizeAttributes]);

  /**
   * @description GetVariants on
   */
  useEffect(() => {
    if (params.id) {
      getProductVariants();
    }
  }, [params.id, getProductVariants]);

  return {
    product,
    refreshProduct: getProductDetails,
    filteredProductVariants,
    onFiltersChange,
    addProductGalleryImage,
    getVariantAvailabilities,
    setFilteredProductVariants,
    setProduct,
    sizeAttributes,
    productVariants,
    setProductVariants,
    initialVariantsCount,
    refreshProductData: getProductDetails,
    refreshProductVariants: getProductVariants
  };
};

export const ProductDetailsProvider = ({ children }) => {
  const product = useProvideProductDetails();
  return (
    <productDetailsContext.Provider value={product}>
      {children}
    </productDetailsContext.Provider>
  );
};

export const useProductDetails = () => useContext(productDetailsContext);
