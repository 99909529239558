export class AvailabilityPayload {
    constructor(data, variantId) {
        this.id = data.id
        this.variantId = variantId
        this.warehouse = {
            id: data.warehouse.id,
        }
        this.availability = parseFloat(data.availability)
    }
}
