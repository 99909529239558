import React, { useCallback, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { CategoriesAPI } from 'src/modules/categories/api/CategoriesAPI';
import { InputSelectInfinite } from 'src/components/inputs/InputSelectInfinite';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

export const CategoriesFood = ({ form, editMode }) => {
  const { t } = useTranslation();
  const text = textFrom('components.categoriesFood', t);

  const [selectedMacro, setSelectedMacro] = useState('');
  const FOOD_PARENT_KEY = 'food';

  const getMacroCategories = useCallback((page) => {
    return CategoriesAPI.getMacroCategories(page, FOOD_PARENT_KEY);
  }, []);

  const getMicroCategories = useCallback(
    (macroKey, page) => {
      return CategoriesAPI.getMicroCategories(macroKey, page);
    },
    [form, selectedMacro]
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Form.Item
          name={editMode ? ['categories', 'macro'] : ['categories', 'macro', 'key']}
          label={text('macro')}
          rules={[
            {
              required: true,
              message: text('macroRequired')
            }
          ]}
        >
          <InputSelectInfinite
            onLoadMore={getMacroCategories}
            placeholder={text('select')}
            onChange={(val) => {
              setSelectedMacro(val.key);
              form.setFieldsValue({
                categories: {
                  micro: {
                    key: null,
                    label: null
                  }
                }
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          name={editMode ? ['categories', 'micro'] : ['categories', 'micro', 'key']}
          label={text('micro')}
          shouldUpdate={(prevValues, currValues) => currValues.categories.macro.key}
          rules={[
            {
              required: true,
              message: text('microRequired')
            }
          ]}
        >
          <InputSelectInfinite
            onLoadMore={() => selectedMacro ? getMicroCategories(selectedMacro, 1) : null}
            placeholder={text('select')}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
