import { AxiosClient } from 'src/api/AxiosClient';

export class LookbookConfigAPI {
  static async getConfig (localBusinessId) {
    return AxiosClient.get(`/backoffice/lookbook/settings/${localBusinessId}`);
  }

  static async updateTitle (localBusinessId, lookbookTitle) {
    const url = `/backoffice/lookbook/settings/${localBusinessId}`;
    return AxiosClient.patch(url, { title: lookbookTitle });
  }

  static async deleteConfig (localBusinessId) {
    const url = `/backoffice/lookbook/settings/${localBusinessId}`;
    return AxiosClient.delete(url);
  }

  static async uploadLogo (localBusinessId, image) {
    const url = `/backoffice/lookbook/settings/${localBusinessId}/upload/icon`;
    const formData = new window.FormData();
    formData.append('file', image);
    return AxiosClient.post(url, formData);
  }

  static async createConfig (localBusinessId, payload) {
    const url = `/backoffice/lookbook/settings/${localBusinessId}`;
    return AxiosClient.post(url, payload);
  }
}
