import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  List,
  Typography
} from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const { Text } = Typography;

const SCOPE_ONLINE = 'ONLINE';

export const DrawerCouponSideDetails = ({ selectedCoupon, visible, onApply, onClose, typeExtractor }) => {
  const { t } = useTranslation();
  const text = textFrom('components.drawerCouponSideDetails', t);

  const onCancel = () => {
    setIsActivationStatus(selectedCoupon.activationStatus);
    onClose();
  };

  const applyChanges = (values) => {
    onApply(isActivationStatus, true);
  };

  const setActivationStatus = () => {
    setIsActivationStatus(!isActivationStatus);
  };

  const concatArrayOfObjIntoString = (selectedCoupon) => {
    return selectedCoupon.localBusiness.reduce(
      (accumulator, currentValue, index) => {
        const commaChar = index === selectedCoupon.localBusiness.length - 1 ? '' : ', ';
        return accumulator + currentValue.name + commaChar;
      },
      '');
  };

  const initializeForm = (form, selectedCoupon) => {
    if (selectedCoupon) {
      const startDate = getUtcDate(selectedCoupon.startDate);
      const endDate = getUtcDate(selectedCoupon.endDate);

      const stringOfLocalBusinessNames = Array.isArray(selectedCoupon?.localBusiness)
        ? concatArrayOfObjIntoString(selectedCoupon)
        : text('anyLocalBusiness');

      form.setFieldsValue({
        localBusiness: stringOfLocalBusinessNames,
        couponCode: selectedCoupon?.applicationCode,
        description: selectedCoupon?.description,
        startDate: getDay(startDate),
        startTime: getTime(startDate),
        endDate: getDay(endDate),
        endTime: getTime(endDate),
        totalCoupons: selectedCoupon?.maxUtilizationNumber,
        maxUsage: selectedCoupon?.maxUtilizationNumberPerUser,
        couponScope: (selectedCoupon?.scope === null) ? text(SCOPE_ONLINE) : text(selectedCoupon?.scope),
        couponType: type.label,
        amount: type.value
      });
    }
  };

  const [isActivationStatus, setIsActivationStatus] = useState(selectedCoupon?.activationStatus);
  const type = typeExtractor(selectedCoupon);
  const { canManageCoupons, canActivateCoupons } = useAuth();
  const [form] = useForm();
  initializeForm(form, selectedCoupon);

  useEffect(() => {
    setIsActivationStatus(selectedCoupon?.activationStatus);
  }, [visible]);

  useEffect(() => {
    initializeForm(form, selectedCoupon);
  }, [selectedCoupon]);

  const onFinish = async (values) => {
    applyChanges(values);
  };

  const listInnerRef = useRef(null);

  return (
    <>
      <Drawer
        visible={!!visible}
        onClose={onClose}
        closable
        placement='right'
        className='jc-drawer'
        title={text('title')}
      >
        <Form
          className='max-w-8xl'
          layout='vertical'
          autoComplete='off'
          name='couponDetails'
          form={form}
          onFinish={onFinish}
        >
          <div className='jc-drawer__content'>
            {/* Local Business */}
            {canManageCoupons &&
              <Form.Item
                label={text('localBusiness.label')}
                name='localBusiness'
              >
                <Input.TextArea
                  rows='2'
                  placeholder={text('localBusiness.placeholder')}
                  disabled
                />
              </Form.Item>}
            {/* Coupon Code */}
            <Form.Item
              label={text('couponCode.label')}
              name='couponCode'
            >
              <Input
                placeholder={text('couponCode.placeholder')}
                disabled
              />
            </Form.Item>
            {/* Description */}
            <Form.Item
              label={text('description.label')}
              name='description'
            >
              <Input.TextArea
                rows='6'
                maxLength={1500}
                placeholder={text('description.placeholder')}
                disabled
              />
            </Form.Item>
            {/* Start Date / Start Time */}
            <div className='grid grid-cols-2'>
              <Form.Item
                name='startDate'
                label={text('startDate.label')}
              >
                <Input
                  placeholder={text('startDate.placeholder')}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name='startTime'
                label={text('startTime.label')}
              >
                <Input
                  placeholder={text('startTime.placeholder')}
                  disabled
                />
              </Form.Item>
            </div>
            {/* End Date / End Time */}
            <div className='grid grid-cols-2'>
              <Form.Item
                name='endDate'
                label={text('endDate.label')}
              >
                <Input
                  placeholder={text('endDate.placeholder')}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name='endTime'
                label={text('endTime.label')}
              >
                <Input
                  placeholder={text('endTime.placeholder')}
                  disabled
                />
              </Form.Item>
            </div>
            {/* Total Coupons */}
            <Form.Item
              label={text('totalCoupons.label')}
              name='totalCoupons'
            >
              <InputNumber
                parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                disabled
              />
            </Form.Item>
            {/* Max usage for each customer */}
            <Form.Item
              label={text('maxUsage.label')}
              name='maxUsage'
            >
              <InputNumber
                parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                disabled
              />
            </Form.Item>
            {/* Coupon Type / Amount */}
            <div className='grid grid-cols-2'>
              <Form.Item
                name='couponType'
                label={text('couponType.label')}
              >
                <Input
                  disabled
                />
              </Form.Item>
              <Form.Item
                label={text('amount.label')}
                name='amount'
              >
                <Input
                  addonBefore={type.symbol}
                  disabled
                />
              </Form.Item>
            </div>
            {/* Coupon Scope */}
            <Form.Item
              label={text('scope.label')}
              name='couponScope'
            >
              <Input
                disabled
              />
            </Form.Item>
            {/* Products */}
            <div className='flex flex-col mb-6'>
              {selectedCoupon?.products.length > 0 && <Text>{text('applyWhenInCart')}</Text>}
              <List>
                <div
                  ref={listInnerRef}
                  className='bg-white overflow-y-auto max-h-48'
                >
                  {selectedCoupon?.products.map((product) => (
                    <List.Item key={product.articleId}>
                      <List.Item.Meta
                        className='pl-5'
                        title={product.name}
                      />
                    </List.Item>
                  ))}
                </div>
              </List>
            </div>
            {/* Active */}
            <Form.Item
              label={text('active.label')}
              name='activationStatus'
              required={false}
            >
              <Checkbox
                disabled={!canActivateCoupons}
                checked={isActivationStatus}
                onClick={setActivationStatus}
              />
            </Form.Item>
          </div>
          {/* Actions */}
          {canManageCoupons &&
            <div className='jc-drawer__footer flex justify-end'>
              <Button
                className='mr-2 uppercase'
                type='text'
                htmlType='button'
                onClick={onCancel}
              >
                {text('cancel')}
              </Button>
              <Button
                className='uppercase'
                type='primary'
                htmlType='submit'
                disabled={isActivationStatus === selectedCoupon?.activationStatus}
              >
                {text('saveChanges')}
              </Button>
            </div>}
        </Form>
      </Drawer>
    </>
  );
};

const getUtcDate = (str) => {
  return moment.utc(str, 'YYYY-MM-DD HH:mm:ss');
};

const getDay = (date) => {
  return date.format('YYYY/MM/DD');
};

const getTime = (date) => {
  return date.format('HH:mm');
};
