import { AxiosClient } from 'src/api/AxiosClient';

export class GeneralSettingsAPI {
  static async getImagesBaseURL () {
    return AxiosClient.get('/s3/base-url',
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      }
    );
  }
}
