import React, { useState, useMemo, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Modal,
  Button,
  Select,
  message,
  Form
} from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { ProductsAPI } from '../../products/api/ProductsAPI';
import { CategoriesAPI } from '../api/CategoriesAPI';
import { SearchOutlined } from '@ant-design/icons';
import { ProductsEditableTable } from '../component/ProductsEditableTable';
import { useForm } from 'antd/lib/form/Form';
import { useDebounce } from '../../../hooks/useDebounce';
import { useFilters } from 'src/hooks/useFilters';
import { CategoriesMapper } from '../domain/categories.mapper';
import { usePagination } from 'src/hooks/usePagination';
import { PageTitle } from 'src/components/PageTitle';
import { Chips } from 'src/components/Chips';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

export const PageAssignmentCategories = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.microcategories/assign', t);
  const textFilter = textFrom('constants.filter', t);

  const [form] = useForm();
  const [products, setProducts] = useState([]);
  const [microCategories, setMicroCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userDepartments } = useAuth();

  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(CategoriesMapper);

  const FILTERS = {
    SEARCH: 'search'
  };
  const { SEARCH } = FILTERS;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onTableChange = ({ current, pageSize, query }) => {
    onChangeQuery({ current, pageSize, query });
  };

  const handleSearchProducts = (searchedText) => {
    onChangeQuery({
      ...query,
      current: 1,
      search: searchedText
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  useEffect(async () => {
    // SET DEP
    const data = await CategoriesAPI.getCategories(['CHILD'], 1, 500, userDepartments[0]);
    if (data?.items?.length > 0) {
      setMicroCategories(data.items);
    } else {
      setMicroCategories([]);
    }
  }, []);

  useEffect(async () => {
    // SET PRODUCTS
    const payload = {
      limit: +query?.pageSize,
      pageNumber: +query?.current - 1,
      text: query?.search,
      orderingList: [
        {
          key: 'id',
          value: 'ascending'
        }
      ]
    };
    callDebounced(payload);
  }, [query]);

  const searchCatalog = async (payload) => {
    setLoading(true);
    const pr = await ProductsAPI.searchProducts(payload);
    setLoading(false);
    if (pr?.data) {
      setProducts(pr.data.items);
      console.log('partito', pr.data);
      setPagination(pr.data);
    }
  };

  const onTagRemove = () => {
    handleSearchProducts('');
  };

  const callDebounced = useDebounce((payload) => {
    const LSLocalBusiness = JSON.parse(window.localStorage.getItem('userInfo'))?.localBusiness;
    if (LSLocalBusiness?.id) {
      payload.filters = [
        {
          local_business_id: [
            LSLocalBusiness?.id
          ]
        }
      ];
    }
    return (
      searchCatalog(payload)
    );
  }, 300);

  const onFinish = async (values) => {
    if (selectedRowKeys.length > 0) {
      const microKey = form.getFieldValue('micro');
      try {
        await CategoriesAPI.bulkAssignCategory(microKey, selectedRowKeys);
        const editedProducts = products.map((product) => {
          const newProduct = { ...product };
          if (selectedRowKeys.some((id) => id === product.id)) {
            newProduct.microProductCategory.key = microKey;
          }
          return newProduct;
        });
        setProducts(editedProducts);
        message.success(`(${selectedRowKeys.length})` + ' Products correctly assigned ', 5);
        setSelectedRowKeys([]);
      } catch (error) {
        const stringIds = selectedRowKeys.concat();
        const errorMessage = text('errors.wrongDepartment') + stringIds;
        message.error(errorMessage, 5);
      }
    } else {
      message.error(text('errors.unselectedProducts'), 5);
    }
  };

  const columnsProps = useMemo(() => {
    return [
      { title: text('column.image'), key: 'image', dataIndex: 'image', width: '4%' },
      { title: text('column.name'), key: 'name', dataIndex: 'name', width: '26%' },
      { title: text('column.sku'), key: 'sku/ean/id', dataIndex: 'sku', width: '20%' },
      { title: text('column.microcategory'), key: 'category', dataIndex: ['microProductCategory', 'businessKey'], width: '50%' }
    ];
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
    setSelectedRowKeys([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className='py-4'>
        <PageTitle>{text('pageTitle')}</PageTitle>
        <Form
          name='basic'
          onFinish={onFinish}
          autoComplete='off'
          layout='vertical'
          form={form}
          className='max-w-8xl'
        >
          <Row gutter={20} className='pt-2' style={{ alignItems: 'flex-start' }}>
            <Col xs={6}>
              <Input value={query?.search || ''} onChange={(e) => handleSearchProducts(e.target.value)} addonAfter={<SearchOutlined />} placeholder='Search product' />
              {/* Filter Chips */}
              {!!query?.search && (
                <Row className='py-4'>
                  <div className='pr-2'>{textFilter('appliedFilter', { count: 1 })}:</div>
                  <Chips
                    query={query}
                    chipKeys={[SEARCH]}
                    onTagRemove={onTagRemove}
                  />
                </Row>
              )}
            </Col>
            <Col xs={6}>
              <Form.Item
                name='micro'
                rules={[{ required: true, message: text('form.microCategory.message') }]}
              >
                <Select
                  style={{ width: '100%' }}
                  getPopupContainer={trigger => trigger.parentNode}
                  placeholder={text('form.microCategory.placeholder')}
                  showSearch
                >
                  {microCategories
                    .map((opt, i) => {
                      return (
                        <Select.Option
                          key={opt.key}
                          value={opt.key}
                        >
                          {opt?.locale?.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>

            </Col>
            <Col xs={6}>
              <Button
                type='primary'
                htmlType='submit'
                className='uppercase'
              >
                {text('assignCategory')}
              </Button>
            </Col>
          </Row>
        </Form>
        <Row style={{ alignItems: 'flex-end', marginTop: '3rem', width: '100%' }}>
          <ProductsEditableTable
            className='pt-8'
            columnsProps={columnsProps}
            products={products}
            setProducts={setProducts}
            pagination={{ ...pagination, showSizeChanger: false }}
            loading={loading}
            rowSelection={rowSelection}
            onTableChange={onTableChange}
          />
        </Row>
        <Modal title='New page results' visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>{text('modal.text')}</p>
        </Modal>
      </div>
    </>
  );
};
