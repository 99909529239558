import React, { useState, useEffect } from 'react';
import { Collapsible } from 'src/components/Collapsible';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { SelectedCollections } from './SelectedCollections';
import { SearchCollections } from './SearchCollections';

export const CollectionGroupCollapsibles = ({
  collections,
  linkedCollections,
  setLinkedCollections
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.collectionGroupCollapsible', t);

  const [collectionsToShow, setCollectionsToShow] = useState([]);

  useEffect(() => {
    if (collections.length > 0 && linkedCollections) {
      const newCollectionsToShow = [];
      collections.forEach(collection => {
        if (linkedCollections.length === 0 || !(linkedCollections.find(linkedCollection => linkedCollection.id === collection.id))) {
          newCollectionsToShow.push(collection);
        }
      });
      setCollectionsToShow(newCollectionsToShow);
    }
  }, [linkedCollections, collections]);

  return (
    <>
      <Collapsible
        title={text('selectedCollectionOutfit')}
        className='mb-8'
      >
        <SelectedCollections
          linkedCollections={linkedCollections}
          setLinkedCollections={setLinkedCollections}
        />
      </Collapsible>
      <Collapsible
        title={text('searchCollectionOutfit')}
      >
        <SearchCollections
          collections={collectionsToShow}
          setLinkedCollections={setLinkedCollections}
        />
      </Collapsible>
    </>
  );
};
