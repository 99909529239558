import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Routes } from 'src/router/Routes.helper';
import { StorageService } from 'src/services/Storage.service';

export const UnauthenticatedRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !StorageService.getUserIsLogged()
          ? (
              children
            )
          : (
            <Redirect
              to={{
                pathname: Routes.PATHS.DASHBOARD,
                state: { from: location }
              }}
            />
            )}
    />
  );
};
