import {
  AxiosClient
} from 'src/api/AxiosClient';

export class LanguagesAPI {
  static async getLanguages () {
    return AxiosClient.get('/languages');
  }

  static async addNewLanguage (payload) {
    return AxiosClient.post('/languages', payload);
  }

  static async deleteLanguage (payload) {
    return AxiosClient.delete(`/languages/${payload}`);
  }

  static async makeLanguageDefault (payload) {
    return AxiosClient.patch(`/languages/${payload}/default/true`);
  }
}
