import React, { useState, useCallback } from 'react';
import { Row, Col, Select } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { toOption } from 'src/utils/utils';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { CenteredText } from 'src/components/CenteredText';
import { WarehousesAPI } from '../api/WarehousesAPI';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { useWarehouses } from 'src/modules/warehouses/provider/warehouses.provider';
import { DeletionModal } from 'src/components/DeletionModal';
import { useTranslation } from 'react-i18next';
import { WarehouseTable } from '../components/WarehouseTable';
import { WAREHOUSE_TYPES } from '../domain/models/warehouseTypes';
import { Chips } from 'src/components/Chips';

const WAREHOUSE_TYPE_OPTIONS = WAREHOUSE_TYPES.map(toOption);

export const PageWarehouses = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.warehouses', t);
  const textFilter = textFrom('constants.filter', t);

  const history = useHistory();
  const {
    query,
    warehouses,
    pagination,
    getWarehouses,
    onChangeQuery,
    areWarehousesLoading
  } = useWarehouses();
  const [warehouseToBeDeleted, setWarehouseToBeDeleted] = useState(undefined);

  const FILTERS = {
    NAME: 'name',
    TYPE: 'type'
  };
  const { NAME, TYPE } = FILTERS;

  const goToCreateWarehousePage = useCallback(() => {
    history.push(Routes.PATHS.WAREHOUSES_CREATE);
  }, []);

  const handleSearchWarehouse = (searchedText) => {
    onChangeQuery({
      ...query,
      name: searchedText
    });
  };

  const handleSelectType = (selectedType) => {
    onChangeQuery({
      ...query,
      type: selectedType
    });
  };

  const clearSearch = () => {
    onChangeQuery({
      ...query,
      name: undefined
    });
  };

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const onEdit = (warehouse) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.WAREHOUSES_EDIT, { id: warehouse.id }));
  };

  const onDelete = (warehouse) => {
    setWarehouseToBeDeleted(warehouse);
  };

  const confirmWarehouseDeletion = async () => {
    await WarehousesAPI.deleteWarehouse(warehouseToBeDeleted.id);
    resetWarehouseToBeDeleted();
    getWarehouses();
  };

  const resetWarehouseToBeDeleted = () => {
    setWarehouseToBeDeleted(undefined);
  };

  const onTagRemove = (key) => {
    if (key === NAME) {
      clearSearch();
    } else if (key === TYPE) {
      handleSelectType(null);
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <Row gutter={[16, 16]} className='pt-2'>
        <Col span={6}>
          <SearchInput
            autoFocus
            defaultValue={query.name}
            onChangeText={handleSearchWarehouse}
            placeholder={text('searchPlaceholder')}
          />
        </Col>
        <Col span={3}>
          <Select
            allowClear
            className='w-full'
            value={query.type}
            onChange={handleSelectType}
            placeholder={text('selectType')}
            options={WAREHOUSE_TYPE_OPTIONS}
          />
        </Col>
        <Col className='last-column'>
          <PrimaryButton
            label={text('createNewWarehouse')}
            onClick={goToCreateWarehousePage}
          />
        </Col>
      </Row>

      {/* Filter Chips */}
      {(!!query?.name || !!query?.type) &&
        <Row className='py-4'>
          <div className='pr-2'>{textFilter('appliedFilter', { count: +(!!query.name && +1) + (!!query.type && +1) })}:
            <Chips
              query={query}
              chipKeys={[NAME, TYPE]}
              onTagRemove={onTagRemove}
            />
          </div>
        </Row>}

      {!areWarehousesLoading && !warehouses?.length
        ? (<CenteredText>{text('noWarehousesFound')}</CenteredText>)
        : (
          <div className='pt-8'>
            <WarehouseTable
              loading={areWarehousesLoading}
              warehouses={warehouses}
              onEdit={onEdit}
              onDelete={onDelete}
              pagination={pagination}
              onTableChange={onTableChange}
            />
          </div>)}

      <DeletionModal
        visible={!!warehouseToBeDeleted}
        onOk={confirmWarehouseDeletion}
        onCancel={resetWarehouseToBeDeleted}
        msgOnSuccess={text('onDeleteOk')}
        msgOnError={text('onDeleteErr')}
      >
        <div>{text('confirmDeletion', { name: warehouseToBeDeleted?.name })}</div>
      </DeletionModal>
    </div>
  );
};
