import React from 'react';
import { useTranslation } from 'react-i18next';
import { BackButton } from 'src/components/BackButton';
import { textFrom } from 'src/utils/textFrom';

export const PageUnauthorized = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.unauthorized', t);

  // TODO Give this page some love
  return (
    <div class='flex items-center justify-center h-screen'>
      <div class='px-40 py-20 bg-white rounded-md shadow-xl'>
        <div class='flex flex-col items-center'>
          <h1 class='font-bold text-blue-600 text-5xl'>{text('title')}</h1>
          <h3 class='mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl'>
            <span class='text-red-500'>{text('subtitle')}</span>
          </h3>
          <p class='mb-8 text-center text-gray-500 md:text-lg'>
            {text('cta')}
          </p>
          <BackButton title={text('goBack')} className='px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100' />
        </div>
      </div>
    </div>
  );
};
