import React, { useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, List, Table, Tooltip, Button } from 'antd';
import { useUploadsList } from 'src/modules/upload/provider/uploadsList.provider';
import { PageTitle } from 'src/components/PageTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { Routes } from 'src/router/Routes.helper';
import { UploadTypes } from 'src/modules/upload/domain/models/UploadTypes';
import { UPLOAD_TYPES } from 'src/constants/uploadTypes';
import { UPLOAD_STATUS } from 'src/constants/uploadStatus';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useTableHeight } from 'src/hooks/useTableHeight';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

const { TYPE_KEYS } = UploadTypes;

export const PageUploadsList = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.uploads', t);
  const FILE_TYPE_TITLE = textFrom('constants.fileType', t);
  const history = useHistory();
  const tableHeight = useTableHeight();
  const { folderHistory, pendingFolderHistory, onChangeQuery, pagination, getFolderHistory } = useUploadsList();
  const { userAuthorities } = useAuth();
  // TODO refactor the way neededAuthorities gets checked for uploads (centralize / protect the route)
  const filteredByUserAuthoritiesUploadTypes = UPLOAD_TYPES.filter((type) => type.neededAuthorities.every((authority) => userAuthorities.includes(authority)));
  /**
   * @description Redirect to upload page - keep track of type (product, attribute, category or stock)
   * @param uploadTypeKey: string
   */
  const handleRedirection = (uploadTypeKey) => {
    history.push(
      Routes.parseRouteParams(Routes.PATHS.UPLOAD_TYPE_DETAILS, {
        type: uploadTypeKey
      })
    );
  };

  const getErrors = (errors) => {
    const parsedErrors = [];
    if (errors?.importErrors?.length) {
      parsedErrors.push(...errors.importErrors[0].errors);
    } else if (errors?.parsingErrors?.length) {
      parsedErrors.push(...errors.parsingErrors);
    } else if (errors?.errorMessage) {
      parsedErrors.push(errors?.errorMessage);
    }
    return parsedErrors;
  };

  const fileTypeRender = (type) => {
    return (type) ? FILE_TYPE_TITLE(type.toLowerCase()) : text('nd');
  };

  const recordErrorsParser = useCallback((record) => {
    const errors = [];
    if (record?.errors) {
      errors.push(...getErrors(record.errors));
    }
    return `${UPLOAD_STATUS[record.status]} ${errors.length} ${text('errors')}`;
  }, [folderHistory]);

  /**
   * @description Folder history table's columns
   * @type {[{dataIndex: string, title: string, key: string}, {dataIndex: string, title: string, key: string}, {dataIndex: string[], title: string, render: (function(*))}, {dataIndex: string[], title: string, render: (function(*, *)), key: string}]}
   */
  const columns = useMemo(() => [
    {
      title: text('col2.table.cols.fileName'),
      dataIndex: 'inputFileName',
      key: 'inputFileName',
      render: (text) => (
        <Tooltip title={text || text('untitled')}>
          <p className='line-clamp-1 m-0'>
            {text || text('untitled')}
          </p>
        </Tooltip>)
    },
    {
      title: text('col2.table.cols.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => fileTypeRender(type)
    },
    {
      title: text('col2.table.cols.uploadedOn'),
      dataIndex: ['creationDate'],
      render: (text) => <Moment format='HH:mm DD MMM YYYY' date={text} />
    },
    {
      title: text('col2.table.cols.status'),
      dataIndex: ['status'],
      key: 'Status',
      render: (_, record) => {
        const ErrorLink = (record) => {
          return (
            <Link
              className='hover:text-red-600 text-red-500'
              to={Routes.parseRouteParams(Routes.PATHS.PAGE_UPLOAD_TYPE_STATUS,
                {
                  uuid: record.uuid,
                  type: TYPE_KEYS[record.type]
                })}
            >
              {recordErrorsParser(record)}
            </Link>
          );
        };
        return record?.status === 'ERROR' ? ErrorLink(record) : UPLOAD_STATUS[record.status];
      }
    }
  ], [recordErrorsParser, fileTypeRender]);
  /**
   * @description Method called when user navigates through pages
   * @param current
   * @param pageSize
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const getUploadHistoryData = async () => {
    try {
      await getFolderHistory();
    } catch (e) {
      console.error(e);
    }
  };

  const FolderHistoryTable = useCallback((folderHistory) => {
    return (
      <Table
        size='middle'
        loading={pendingFolderHistory}
        dataSource={folderHistory}
        rowKey='id'
        columns={columns}
        scroll={{ y: tableHeight }}
        pagination={{ ...pagination, showSizeChanger: false }}
        onChange={onTableChange}
      />
    );
  }, [pendingFolderHistory, columns, tableHeight, pagination, onTableChange]);

  const EmptyListRenderer = useMemo(() => {
    return (
      <div
        className='p-24 text-center bg-gray-200'
      >
        {text('col2.noUploads')}
      </div>
    );
  }, []);

  const rightArrow = useCallback((item) => {
    return ([<ArrowRightOutlined key={item.id} />]);
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} xl={8} className='py-4'>
        <div>
          <PageTitle>{text('col1.title')}</PageTitle>
          <HelperText
            className='pt-2 pb-2'
            text={text('col1.helperText')}
          />
          <List
            itemLayout='horizontal'
            dataSource={filteredByUserAuthoritiesUploadTypes}
            renderItem={(item, index) => {
              return (
                <List.Item
                  onClick={() => handleRedirection(item.type_key)}
                  className='uploadCardType pb-4'
                  actions={rightArrow(item)}
                >
                  <div className='ListItemMeta'>
                    <div className='icon'>{item.icon}</div>
                    {FILE_TYPE_TITLE(item.type_key) && <h4>{FILE_TYPE_TITLE(item.type_key)}</h4>}
                    {item.description && <p>{item.description}</p>}
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
      </Col>
      <Col xs={24} xl={16}>
        <div className='flex py-4 justify-between title-section page-filters'>
          <div>
            <PageTitle>{text('col2.title')}</PageTitle>
            <HelperText
              className='pt-2 pb-2'
              text={text('col2.helperText')}
            />
          </div>
          <Button
            type='primary'
            className='mr-2 mt-6 uppercase'
            onClick={getUploadHistoryData}
            disabled={pendingFolderHistory}
            loading={pendingFolderHistory}
          >
            {text('col2.refreshButton')}
          </Button>
        </div>

        {folderHistory?.length > 0 ? FolderHistoryTable(folderHistory) : EmptyListRenderer}
      </Col>
    </Row>
  );
};
