import React from 'react';
import moment from 'moment';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Table } from 'antd';

export const CouponTable = ({
  loading,
  typeExtractor,
  setCouponToDelete,
  showModal,
  coupons,
  pagination,
  onRow,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.coupons', t);
  const { canManageCoupons } = useAuth();

  const COLUMNS = [
    {
      title: text('table.cols.localBusiness'),
      dataIndex: ['localBusiness'],
      editable: false,
      inputType: 'text',
      hidden: !canManageCoupons,
      render: (_, record) => {
        switch (record?.localBusiness?.length) {
          case 0:
          case undefined:
            return <div>{text('anyLocalBusiness')}</div>;
          case 1:
            return <div>{record?.localBusiness[0]?.name}</div>;

          default:
            return <div>{text('manyLocalBusiness')}</div>;
        }
      }
    },
    {
      title: text('table.cols.couponCode'),
      dataIndex: ['couponCode'],
      editable: false,
      hidden: false,
      render: (text, record, index) => {
        return <div>{record?.applicationCode}</div>;
      }
    },
    {
      title: text('table.cols.startDate'),
      dataIndex: ['startDate'],
      key: 'startDate',
      editable: false,
      hidden: false,
      render: (date) => <div>{moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm')}</div>
    },
    {
      title: text('table.cols.endDate'),
      dataIndex: ['endDate'],
      key: 'endDate',
      editable: false,
      hidden: false,
      render: (date) => <div>{moment.utc(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm')}</div>
    },
    {
      title: text('table.cols.type'),
      dataIndex: ['type'],
      editable: false,
      inputType: 'text',
      hidden: false,
      render: (_, record) => {
        return <div>{typeExtractor(record).label}</div>;
      }
    },
    {
      title: text('table.cols.active'),
      dataIndex: ['active'],
      editable: false,
      inputType: 'text',
      align: 'center',
      hidden: false,
      render: (_, record) => {
        return <Checkbox checked={record?.activationStatus} disabled />;
      }
    },
    {
      title: text('table.cols.remove'),
      dataIndex: ['remove'],
      editable: false,
      inputType: 'text',
      align: 'center',
      hidden: !canManageCoupons,
      render: (_, record) => {
        return (
          <DeleteOutlined
            className='text-xl hover:text-red-600'
            onClick={(e) => {
              e.stopPropagation();
              setCouponToDelete(record);
              showModal();
            }}
          />
        );
      }
    }
  ].filter(col => !col.hidden);

  return (
    <Table
      loading={loading}
      columns={COLUMNS}
      dataSource={coupons}
      onChange={onTableChange}
      pagination={{ ...pagination, showSizeChanger: false }}
      onRow={onRow}
      rowKey='id'
    />
  );
};
