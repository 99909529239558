import { AxiosClient, AxiosLoginClient, AxiosNoCredentialClient } from 'src/api/AxiosClient';
import { imageType } from 'src/utils/utils';
import { USE_CENTRALIZED_LOGIN } from 'src/config';

export class AccountsAPI {
  static async getUserInfo () {
    return AxiosClient.get('/backoffice/users/me');
  }

  static async addNewUser (data) {
    return AxiosClient.post('/backoffice/users', data, {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    });
  }

  static async updateUser (userId, data) {
    return AxiosClient.put(`/backoffice/users/${userId}`,
      data, {
        headers: {
          Accept: 'application/stentle.api-v0.1+json'
        }
      });
  }

  static async deactivateUser (email) {
    return AxiosClient.post(
      '/backoffice/users/active/false',
      { email },
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      }
    );
  }

  static async updateAccount (localBusinessId, data) {
    return AxiosClient.put(
      `/localBusinesses/${localBusinessId}/account`,
      data,
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      }
    );
  }

  static async updateAllergens (localBusinessId, allergensFile) {
    const formData = new window.FormData();
    formData.append('file', allergensFile);
    return AxiosClient.post(
      `/localBusinesses/${localBusinessId}/account/attachment/ALLERGEN`,
      formData,
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json',
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  static async deleteAllergens (localBusinessId) {
    return AxiosClient.delete(
      `/localBusinesses/${localBusinessId}/account/attachment/ALLERGEN`
    );
  }

  static async uploadImage (localBusinessId, image, type) {
    const formData = new window.FormData();
    formData.append('file', image);
    return AxiosClient.post(
      `/localBusinesses/${localBusinessId}/account/photo/${type}`,
      formData,
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json',
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  static async uploadLogo (localBusinessId, image) {
    const formData = new window.FormData();
    formData.append('file', image);
    console.log('formData', formData);
    return AxiosClient.post(
      `/localBusinesses/${localBusinessId}/logo`,
      formData,
      {
        headers: {
          Accept: 'application/stentle.api-v0.1+json',
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  static async deleteSpecificImage (localBusinessId, type) {
    return AxiosClient.delete(
      `/localBusinesses/${localBusinessId}/account/photo?type=${type}`
    );
  }

  static async deleteLogo (localBusinessId) {
    return AxiosClient.delete(
      `/localBusinesses/${localBusinessId}/logo`
    );
  }

  static async deleteImageByType (localBusinessId, type) {
    return AxiosClient.delete(
      `/localBusinesses/${localBusinessId}/account/photo?type=${type}`
    );
  }

  static async deleteImage (localBusinessId, type) {
    if (type === imageType.logo) {
      return this.deleteLogo(localBusinessId);
    } else {
      return this.deleteImageByType(localBusinessId, type);
    }
  }

  static async validateEmail (token) {
    return AxiosNoCredentialClient.patch(
      `/backoffice/users/verify-email/${token}`,
      null,
      {
        headers: {
          Accept: 'application/json;charset=utf-8'
        }
      }
    );
  }

  static async resetPwd (token, payload) {
    return AxiosNoCredentialClient.patch(
      `/backoffice/users/auth?token=${token}`,
      payload,
      {
        headers: {
          Accept: 'application/json;charset=utf-8'
        }
      }
    );
  }

  static async sendMeAnEmail (email) {
    let restClient = AxiosNoCredentialClient;
    let url = '/backoffice/users/reset-password';
    let payload = {
      primaryEmail: email
    };
    if (USE_CENTRALIZED_LOGIN === 'true') {
      restClient = AxiosLoginClient;
      url = '/user/backoffice/reset-password';
      payload = {
        username: email
      };
    }
    return restClient.post(url, payload, {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    });
  }
}
