import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message
} from 'antd';
import cssTemplateFile from 'src/downloadable/css-template.txt';
import { useForm } from 'antd/lib/form/Form';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { FileUpload } from 'src/modules/upload/components/FileUpload';
import { ImageUpload } from '../component/ImageUpload';
import { PageSection } from 'src/components/PageSection';
import { useTranslation } from 'react-i18next';
import { DownloadButton } from 'src/components/buttons/DownloadButton';
import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { PresentationConfigAPI } from '../api/PresentationConfigAPI';
import { usePresentationConfig } from '../provider/presentationConfig.provider';

const FILE_NAME = {
  TEMPLATE: 'present-config-template.css',
  CURRENT: 'present-config-current.css'
};

export const PagePresentationConfig = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.presentationConfig', t);

  const [form] = useForm();
  const { config } = usePresentationConfig();
  const [logoUrl, setLogoUrl] = useState();
  const [cssUrl, setCssUrl] = useState();

  useEffect(() => {
    form.setFieldsValue({ brandName: config?.brand });
    setLogoUrl(config?.iconUrl);
    setCssUrl(config?.cssUrl);
  }, [config]);

  const saveBrandName = async () => {
    const brandName = form.getFieldValue('brandName');
    try {
      await PresentationConfigAPI.saveBrandName(brandName);
      message.success(text('onNameUpdateOk'));
    } catch (e) {
      message.error(text('onNameUpdateErr'));
    }
  };

  const SectionBrandName = () => (
    <>
      <PageSection className='mt-2'>{text('brandName')}</PageSection>
      <Row gutter={20}>
        <Col xs={6}>
          <Form.Item
            name='brandName'
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Button
            type='primary'
            className='uppercase'
            onClick={saveBrandName}
          >
            {text('save')}
          </Button>
        </Col>
      </Row>
    </>
  );

  const handleLogoUpload = async (file) => {
    try {
      const res = await PresentationConfigAPI.uploadBrandLogo(file);
      setLogoUrl(res?.data?.iconUrl);
      message.success(text('onLogoUpdateOk'));
    } catch (e) {
      message.error(text('onLogoUpdateErr'));
    }
  };

  const SectionBrandLogo = () => (
    <>
      <PageSection className='mt-8'>{text('brandLogo')}</PageSection>
      <ImageUpload
        imageUrl={logoUrl}
        onUpload={handleLogoUpload}
      />
    </>
  );

  const handleCssFileUpload = async (file) => {
    try {
      const res = await PresentationConfigAPI.uploadCssFile(file);
      setCssUrl(res?.data?.cssUrl);
      message.success(text('onCssFileUpdateOk'));
    } catch (e) {
      message.error(text('onCssFileUpdateErr'));
    }
  };

  const downloadFile = useDownloadFile({
    onError () {
      message.error(text('onDownloadErr'));
    }
  });

  const handleCurrentCssDownload = () => {
    downloadFile.call(cssUrl, FILE_NAME.CURRENT);
  };

  const SectionCssFile = () => (
    <>
      <PageSection className='mt-8'>{text('cssFile')}</PageSection>
      <Row gutter={20}>
        <Col xs={6}>
          <FileUpload
            fileSize={3}
            acceptedFileTypes={['css']}
            onUploadedFileChange={handleCssFileUpload}
          />
        </Col>
      </Row>
      <Row gutter={20} className='mt-4'>
        <Col>
          <DownloadButton
            label={text('downloadCssTemplate')}
            fileName={FILE_NAME.TEMPLATE}
            filePath={cssTemplateFile}
          />
        </Col>
        {cssUrl &&
          <Col>
            <Button
              type='primary'
              className='uppercase'
              loading={downloadFile.isLoading}
              onClick={handleCurrentCssDownload}
            >
              {text('downloadCssCurrent')}
            </Button>
          </Col>}
      </Row>
    </>
  );

  return (
    <div className='py-4 layout-main-content-and-footer'>
      <div className='layout-main-content'>
        <PageTitle>{text('pageTitle')}</PageTitle>
        <Form form={form}>
          <SectionBrandName />
          <SectionBrandLogo />
          <SectionCssFile />
        </Form>
      </div>
    </div>
  );
};
