import { immerable } from "immer";
import { ImageModel } from "src/modules/products/domain/models/ImageModel";

export class ProductModel {
  [immerable] = true;
  constructor(apiProduct) {
    this.id = apiProduct.id;
    this.creationDate = apiProduct.creationDate;
    this.updateDate = apiProduct.updateDate;
    this.tenantId = apiProduct.tenantId;
    this.href = apiProduct.href;
    this.version = apiProduct.version;
    this.name = apiProduct.name;
    this.descriptions = apiProduct.descriptions;
    this.erpCode = apiProduct.erpCode;
    this.brand = apiProduct.brand;
    this.externalCode = apiProduct.externalCode;
    this.externalParentCode = apiProduct.externalParentCode;
    this.sku = apiProduct.sku;
    this.articleId = apiProduct.articleId;
    this.customSkus = apiProduct.customSkus;
    this.attributes = apiProduct.attributes;
    this.published = apiProduct.published;
    this.publicationDate = apiProduct.publicationDate;
    this.productDescriptionCompleteness =
      apiProduct.productDescriptionCompleteness;
    this.threshold = apiProduct.threshold;
    this.photoGallery = apiProduct.photoGallery && {
      ...apiProduct.photoGallery,
      images: apiProduct.photoGallery.images.map(
        (image) => new ImageModel(image)
      ),
    };
    this.videoGallery = apiProduct.videoGallery;
    this.documentArchive = apiProduct.documentArchive;
    this.stories = apiProduct.stories;
    this.loverList = apiProduct.loverList;
    this.numberOfLovers = apiProduct.numberOfLovers;
    this.numberOfProductSaving = apiProduct.numberOfProductSaving;
    this.numberOfProductSharing = apiProduct.numberOfProductSharing;
    this.loved = apiProduct.loved;
    this.saved = apiProduct.saved;
    this.addedToCart = apiProduct.addedToCart;
    this.purchasable = apiProduct.purchasable;
    this.hasCoverPhoto = apiProduct.hasCoverPhoto;
    this.sales = apiProduct.sales;
    this.catalogList = apiProduct.catalogList;
    this.productCatalogMultipliers = apiProduct.productCatalogMultipliers;
    this.productCatalogPrices = apiProduct.productCatalogPrices;
    this.productCatalogScheduledPrices =
      apiProduct.productCatalogScheduledPrices;
    this.status = apiProduct.status;
    this.slug = apiProduct.slug;
    this.customSlugs = apiProduct.customSlugs;
    this.statusHistory = apiProduct.statusHistory;
    this.names = apiProduct.names;
    this.seller = apiProduct.seller;
    this.numberOfComments = apiProduct.numberOfComments;
    this.declareVariants = apiProduct.declareVariants;
    this.photoAttributes = apiProduct.photoAttributes;
    this.manufacturerCode = apiProduct.manufacturerCode;
    this.warehouseArrayList = apiProduct.warehouseArrayList;
    this.width = apiProduct.width;
    this.height = apiProduct.height;
    this.weight = apiProduct.weight;
    this.depth = apiProduct.depth;
    this.parentId = apiProduct.parentId;
    this.note = apiProduct.note;
    this.story = apiProduct.story;
    this.description = apiProduct.description;
    this.attributeVariants = apiProduct.attributeVariants;
    this.bannerImage = apiProduct.bannerImage;
    this.mpn = apiProduct.mpn;
    this.unitPrice = apiProduct.unitPrice;
    this.unitQuantity = apiProduct.unitQuantity;
    this.madeIn = apiProduct.madeIn;
    this.taxes = apiProduct.taxes;
    this.groupedProductInfo = apiProduct.groupedProductInfo;
    this.tagList = apiProduct.tagList;
    this.notes = apiProduct.notes;
    this.prices = apiProduct.prices;
    this.ranking = apiProduct.ranking;
    this.eventPlaceObjectId = apiProduct.eventPlaceObjectId;
    this.eventPlaceLocalBusiness = apiProduct.eventPlaceLocalBusiness;
    this.initialAvailability = apiProduct.initialAvailability;
    this.tags = apiProduct.tags;
    this.availabilityTotal = apiProduct.availabilityTotal;
    this.hasVariants = apiProduct.hasVariants;
    this.errorList = apiProduct.errorList;
    this.attributeList = apiProduct.attributeList;
    this.attributeGroupsView = apiProduct.attributeGroupsView;
    this.variantsGroup = apiProduct.variantsGroup;
    this.infoOtherCollections = apiProduct.infoOtherCollections;
    this.productVariant = apiProduct.productVariant;
    this.attributeDescription = apiProduct.attributeDescription;
    this.microCategory = apiProduct.microCategory;
    this.macroCategory = apiProduct.macroCategory;
    this.department = apiProduct.department;
    this.pricesComparison = apiProduct.pricesComparison;
  }
}
