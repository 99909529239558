import { Filters } from 'src/services/Filters.service';
import { CouponsQuery } from 'src/modules/coupons/domain/models/CouponsQuery';

export class CouponsMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const { KEYS } = CouponsQuery;
    const query = new URLSearchParams(qs);
    const couponQueryModel = new CouponsQuery();
    couponQueryModel.current = query.get('current') || undefined;
    couponQueryModel.pageSize = query.get('pageSize') || undefined;
    couponQueryModel.localBusinessId = (query.get(KEYS.LOCAL_BUSINESS_ID) || undefined);
    couponQueryModel.applicationCode = (query.get(KEYS.APPLICATION_CODE) || undefined);
    couponQueryModel.localBusinessName = (query.get(KEYS.LOCAL_BUSINESS_NAME) || undefined);
    return couponQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: (!query || !query.current || query.current === 0) ? 1 : +query.current,
      limit: query?.pageSize || 10,
      localBusinessId: query?.localBusinessId,
      applicationCode: query?.applicationCode,
      sort: query?.sort ?? 'application_code,asc'
    };
    return payload;
  }
}
