import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { UsersAPI } from 'src/modules/admin/api/UsersAPI';
import { useFilters } from 'src/hooks/useFilters';
import { useLocation, useParams } from 'react-router-dom';
import { UsersMapper } from 'src/modules/admin/domain/Users.mapper';
import { usePagination } from 'src/hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { sortByProperty } from 'src/utils/utils';

const sortByLabel = sortByProperty('label');

const UsersContext = createContext();

const useProvideUsers = (text) => {
  const params = useParams();
  const location = useLocation();
  const { query, onChangeQuery } = useFilters(UsersMapper);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [userEmailVerificationStatus, setUserEmailVerificationStatus] = useState(null);
  const [roles, setRoles] = useState([]);
  const [areUsersLoading, setAreUsersLoading] = useState(false);
  const [pagination, setPagination] = usePagination();

  const getUsers = useCallback(async () => {
    setAreUsersLoading(true);
    try {
      const userQuery = {
        ...query
      };
      const res = await UsersAPI.getUsers(UsersMapper.fromQueryToPayload(userQuery));
      const users = res?.data?.data?.items || [];
      setUsers(users);
      setPagination(res.data.data);
      return Promise.resolve();
    } catch (e) {
      message.error(text('usersRetrievalError'));
      return Promise.reject(e);
    } finally {
      setAreUsersLoading(false);
    }
  }, [setPagination, location, query.user]);

  const getRoles = useCallback(async () => {
    try {
      const res = await UsersAPI.getRoles();
      setRoles(res?.data?.data?.sort(sortByLabel) || []);
      return Promise.resolve();
    } catch (e) {
      message.error(text('rolesRetrievalError'));
      return Promise.reject(e);
    }
  }, []);

  const getUserDetails = useCallback(async (userId) => {
    try {
      const res = await UsersAPI.getUserDetails(userId);
      setUser(res.data.data);
      return Promise.resolve();
    } catch (e) {
      message.error(e);
      return Promise.reject(e);
    }
  }, []);

  const getUserEmailVerificationStatus = useCallback(async (userId) => {
    try {
      const res = await UsersAPI.getUserEmailVerificationStatus(userId);
      setUserEmailVerificationStatus(res?.data?.data);
      return Promise.resolve();
    } catch (e) {
      message.error(e);
      return Promise.reject(e);
    }
  }, []);

  const requestVerificationEmail = async (user) => {
    try {
      await UsersAPI.requestVerificationEmail(user.id);
      message.success(text('verificationEmailRequestOK'));
    } catch (e) {
      message.error(text('verificationEmailRequestKO'));
    }
  };

  useEffect(() => {
    if (params?.userId) {
      getUserDetails(params.userId);
      getUserEmailVerificationStatus(params.userId);
    }
  }, [params.userId]);

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getUsers(query);
  }, [location.search, getUsers]);

  return {
    query,
    users,
    user,
    userEmailVerificationStatus,
    roles,
    pagination,
    setUsers,
    onChangeQuery,
    refreshData: getUsers,
    requestVerificationEmail,
    areUsersLoading
  };
};

export const UsersProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.users', t);
  const users = useProvideUsers(text);
  return (
    <UsersContext.Provider value={users}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);
