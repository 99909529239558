import { SEPARATOR } from 'src/utils/constants';
import { isObject, isArrayOfStrings } from './utils';

/**
 * @Description Turn a nested object into a flat object. Applies to all the keys of the object if no keys are passed.
 * @Example
 * @SEPARATOR '-'
 * @input_obj { a: { b: 'c' }, d: { e: 'f' }, g: { h: 'i' } }
 * @input_keys ['a', 'd']
 * @output { 'a-b': 'c', 'd-e': 'f' }
*/
export function flatten (obj, keys) {
  if (keys) {
    if (isObject(obj) && isArrayOfStrings(keys)) {
      return _flatten(obj, keys);
    }
  } else {
    const allKeys = Object.keys(obj);
    if (isObject(obj)) {
      return _flatten(obj, allKeys);
    }
  }
}

/**
 * @Description Turn a flat object into a nested object. Only applies to the list of keys whose prefix is passed as a second parameter.
 * @Example
 * @SEPARATOR '-'
 * @input_obj { 'a-b': 'c', 'd-e': 'f', 'g-h': 'i' }
 * @input_keys ['a', 'd']
 * @output { a: { b: 'c' }, d: { e: 'f' } }
*/
export function nestify (obj, keys) {
  if (isObject(obj) && isArrayOfStrings(keys)) {
    return _nestify(obj, keys);
  }
}

function _flatten (obj, keys) {
  return flattenObj(makePartialCopy(obj, keys));
}

function makePartialCopy (obj, keys) {
  const copy = {};
  for (const key of keys) {
    copy[key] = obj[key];
  }
  return copy;
}

function flattenObj (obj, parent, res = {}) {
  for (const key in obj) {
    const propName = parent ? parent + SEPARATOR + key : key;
    if (typeof obj[key] === 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}

function _nestify (obj, keys) {
  const oneOfKeys = keys.join('|');
  const prefixRegex = new RegExp(`^(${oneOfKeys})${SEPARATOR}`); // Keep the keys that start with one of the searched keys followed by the separator

  return (
    Object
      .keys(obj)
      .filter(key => prefixRegex.test(key))
      .reduce(addKeyToNestedObject, { objFlat: obj, objNested: {} })
      .objNested
  );
}

function addKeyToNestedObject ({ objFlat, objNested }, key) {
  const subKeys = key.split(SEPARATOR);
  return {
    objFlat,
    objNested: makeNestedObject(objNested, subKeys, objFlat[key])
  };
}

function makeNestedObject (obj, subKeys, val) {
  const subKey = subKeys.shift();
  if (subKeys.length > 0) {
    if (!obj[subKey]) {
      obj[subKey] = makeNestedObject({}, subKeys, val);
    } else {
      obj[subKey] = makeNestedObject(obj[subKey], subKeys, val);
    }
    return obj;
  }
  obj[subKey] = val;
  return obj;
}
