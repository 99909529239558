import React, { useEffect, useState } from 'react';
import { Row, Col, Drawer } from 'antd';
import { message } from 'src/services/Messages.service';
import { useProductDetails } from 'src/modules/products/provider/productDetails.provider';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { VariantDetails } from 'src/modules/products/components/VariantDetails';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { FashionVariantTable } from './FashionVariantTable';

export const ProductVariantsFashion = ({ variants, productId, onEditVariant, isUpdatePending, language }) => {
  const { t } = useTranslation();
  const text = textFrom('components.productVariantsFashion', t);

  const { onFiltersChange } = useProductDetails();
  const [selectedVariant, setSelectedVariant] = useImmer(null);
  const [selectedVariantAvailabilities, setSelectedVariantAvailabilities] =
    useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { getVariantAvailabilities, refreshProductVariants } = useProductDetails();

  const [variantGalleryFiles, setVariantGalleryFiles] = useState([]);
  const [addedVariantGalleryFiles, setAddedVariantGalleryFiles] = useState([]);
  const [deletedVariantGalleryFiles, setDeletedVariantGalleryFiles] = useState([]);

  useEffect(() => {
    setAddedVariantGalleryFiles([]);
    setDeletedVariantGalleryFiles([]);
  }, [drawerVisible]);

  const handleVariantSearch = (value) => {
    const lookupList = ['manufacturerCode', 'attributeVariants.color.label', 'attributeVariants.sizeScale.businessKey', 'attributeVariants.size.businessKey', 'prices.sellingPrice', 'updateDate'];
    onFiltersChange(lookupList, value);
  };

  const handleOpenVariantDrawer = async (variant) => {
    setSelectedVariant(() => variant);
    setVariantGalleryFiles(variant?.photoGallery?.images || []);
    setDrawerVisible(true);
    try {
      const res = await getVariantAvailabilities(productId, variant.id);
      setSelectedVariantAvailabilities(res.data?.data?.items);
    } catch (e) {
      message.error(e);
    }
  };

  /**
  * @description Handle delete image from gallery method
  * @param file
  */
  const handleDeleteVariantGalleryFile = (file) => {
    if (addedVariantGalleryFiles.find(addedFile => addedFile.uid === file.uid)) {
      setAddedVariantGalleryFiles((oldAddedFileList) => oldAddedFileList.filter(addedFile => addedFile.uid !== file.uid));
    }
    if (file?.uid?.includes('rc-upload')) {
      return;
    }
    setDeletedVariantGalleryFiles((oldDeletedFileList) => [...oldDeletedFileList, file]);
  };

  /**
     * @description Handle add image to gallery method
     * @param file
     */
  const handleAddedVariantGalleryFile = (file) => {
    setAddedVariantGalleryFiles((oldAddedVariantFileList) => [...oldAddedVariantFileList, file]);
  };

  /**
     * @description Handle Gallery update
     * @param file
     * @param fileList
     */
  const handleGalleryUpdate = (file, fileList, type) => {
    let newGallery = [];
    if (type === 'cover') {
      const detailGalleryFiles = variantGalleryFiles.filter(galleryFile => galleryFile.info.type !== 'cover');
      const newCover = {
        ...fileList[0],
        info: {
          type: type
        }
      };
      newGallery = [...[newCover], ...detailGalleryFiles];
      const toDeleteCovers = variantGalleryFiles.filter(file => file.info.type === 'cover');
      toDeleteCovers.forEach(toDeleteCover => {
        handleDeleteVariantGalleryFile(toDeleteCover);
      });
    } else {
      const coverGalleryFiles = variantGalleryFiles.filter(galleryFile => galleryFile.info && galleryFile.info.type === 'cover');
      const newDetailGalleryFiles = fileList.map(detailFileItem => {
        return {
          ...detailFileItem,
          info: {
            type: type
          }
        };
      });
      newGallery = [...coverGalleryFiles, ...newDetailGalleryFiles];
      if (file?.status === 'removed') {
        handleDeleteVariantGalleryFile(file);
      }
    }
    setVariantGalleryFiles(newGallery);
    if (file?.status !== 'removed') {
      handleAddedVariantGalleryFile(file);
    }
  };

  const handleGalleryOrderUpdate = (fileList) => {
    console.log('Ordered file list', fileList);
  };

  return (
    <div>
      <Row gutter={[16, 16]} className='pb-4'>
        <Col span={6}>
          <SearchInput
            autoFocus
            placeholder={text('EANPlaceholder')}
            onChangeText={handleVariantSearch}
          />
        </Col>
      </Row>

      <FashionVariantTable
        data={variants}
        pagination={false}
        onEdit={handleOpenVariantDrawer}
        refreshProductVariants={refreshProductVariants}
      />

      <Drawer
        visible={!!drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
        placement='right'
        className='jc-drawer'
        title={text('variantDetails')}
      >
        <VariantDetails
          language={language}
          variant={selectedVariant}
          productId={productId}
          selectedVariantAvailabilities={selectedVariantAvailabilities}
          onEditVariant={async (formData) => {
            await onEditVariant(selectedVariant, formData, variantGalleryFiles, addedVariantGalleryFiles, deletedVariantGalleryFiles, selectedVariantAvailabilities);
            setDrawerVisible(false);
          }}
          onGalleryOrderUpdate={(fileList) => { handleGalleryOrderUpdate(fileList); }}
          onGalleryUpdate={(file, fileList, type) => { handleGalleryUpdate(file, fileList, type); }}
          variantGalleryFiles={variantGalleryFiles}
          closeDrawer={() => setDrawerVisible(false)}
          isUpdatePending={isUpdatePending}
        />
      </Drawer>
    </div>
  );
};
