import React from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { Table } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { NumericInput } from './NumericInput';
import { useTranslation } from 'react-i18next';

const makeColumns = (text, onStockAvailabilityChange) => [
  {
    title: '',
    dataIndex: ['coverPhotoUrl'],
    width: '5%',
    render: (_, record) => (
      <div className='flex items-center'>
        <img
          className='w-8 h-8 object-cover object-center'
          src={record.coverPhotoUrl}
          alt={record.name}
        />
      </div>
    )
  },
  {
    title: text('name'),
    width: '25%',
    dataIndex: ['name'],
    editable: true,
    inputType: 'text'
  },
  {
    title: text('EAN'),
    dataIndex: ['manufacturerCode']
  },
  {
    title: text('price'),
    width: '10%',
    dataIndex: ['sellingPrice'],
    align: 'right'
  },
  {
    title: text('warehouse'),
    dataIndex: ['warehouse', 'name']
  },
  {
    title: text('lastEdit'),
    dataIndex: ['updateDate'],
    key: 'updateDate',
    defaultSortOrder: 'descend',
    sorter: (a, b) =>
      moment(a.updateDate).unix() - moment(b.updateDate).unix(),
    render: (text) => <Moment format='DD MMM YYYY' date={text} />
  },
  {
    title: text('stockQuantity'),
    dataIndex: ['availabilityTotal'],
    render: (value, row) => {
      return (
        <div>
          <NumericInput value={value} onSubmit={(value) => onStockAvailabilityChange(row, value)} placeholder={text('stockQuantity')} maxLength={6} />
        </div>
      );
    }
  }
];

export const StockTable = ({
  loading,
  stocks,
  onStockAvailabilityChange,
  pagination,
  onTableChange,
  scroll
}) => {
  const { t } = useTranslation();
  const text = textFrom('pages.stocks', t);

  const COLUMNS = makeColumns(text, onStockAvailabilityChange);

  return (
    <Table
      size='middle'
      rowKey='id'
      loading={loading}
      columns={COLUMNS}
      dataSource={stocks}
      onChange={onTableChange}
      scroll={scroll}
      pagination={{ ...pagination, showSizeChanger: false }}
      rowClassName={(record) => (record.isUpdatePending ? 'opacity-50' : '')}
    />
  );
};
