export class LocationPayload {

    constructor(data) {
        this.name = data.name
        this.key = data.key
        this.description=data.description
        this.address= {
          city: data.city, 
          country: data.country, 
          streetAddress: data.address,
          postalCode:data.zip, 
          streetNumber:data.number, 
        }
        this.attributes = data.attributes
        this.position = {
          x: data.positionX,
          y: data.positionY,
        }
    }
}
