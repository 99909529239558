import { AxiosClient, AxiosMSClient } from 'src/api/AxiosClient';
import { ProductUpdatePayload } from 'src/modules/products/api/payloads/ProductUpdatePayload';
import { ImageModel } from 'src/modules/products/domain/models/ImageModel';
import { Product } from 'src/services/Product.service';
import { VariantUpdatePayload } from 'src/modules/products/api/payloads/VariantUpdatePayload';
import { EventVariantModel } from 'src/modules/products/domain/models/EventVariantModel';
import { AvailabilityPayload } from 'src/modules/products/api/payloads/AvailabilityPayload';
import { ProductDetailsUpdatePayload } from 'src/modules/products/api/payloads/ProductDetailsUpdatePayload';

export class EventsAPI {
  // ----------------------------------PRODUCT CRUD --------------------------------------------
  /**
   * @description Search on Products
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async searchProducts (data) {
    return AxiosClient.post('/backoffice/products/filter', data);
  }

  // TODO this is currently not used, it should be in DurationPicker
  static async getAvailableDurations () {
    return AxiosClient.get(
      '/attributes?pageNumber=1&limit=1000&attributeCode=durationInMin'
    );
  }

  static async getAvailableStartDates () {
    return AxiosClient.get(
      '/attributes?pageNumber=1&limit=1000&attributeCode=start_date'
    );
  }

  static async getAvailableEventMicroCategories () {
    return AxiosClient.get(
      '/categories?pageNumber=1&limit=1000&department=event&type=child'
    );
  }

  static async createEvent (data) {
    return AxiosMSClient.post(
      '/event',
      data
    );
  }

  static async updateEvent (data) {
    return AxiosMSClient.put(
      '/event',
      data
    );
  }

  static async getProductVariant (productId) {
    return AxiosClient.get(`/products/${productId}/variants`);
  }

  static async getProductDetails (productId, variantId) {
    return AxiosMSClient.get(
      `/event/${productId}/variant/${variantId}`
    );
  }

  /**
   * @description Inline editing of a product
   * @param id
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProduct (id, data) {
    return AxiosClient.put(`/products/${id}`, new ProductUpdatePayload(data))
      .then((res) => res.data.data)
      .then((apiData) => ({
        ...apiData,
        macroProductCategory: {
          ...apiData.macroCategory,
          key: apiData.macroCategory.key,
          label: apiData.macroCategory.localeName
        },
        microProductCategory: {
          ...apiData.microCategory,
          key: apiData.microCategory.key,
          label: apiData.microCategory.localeName
        }
      }));
  }

  /**
   * @description Update product - product details page
   * @param productId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateProductDetails (productId, data) {
    return AxiosClient.put(
      `/products/${productId}`,
      new ProductDetailsUpdatePayload(data)
    ).then((res) => res.data.data);
  }

  /**
   * @description Delete product
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteProduct (productId) {
    return AxiosClient.delete(`/products/${productId}`,
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      }
    );
  }

  static async updateProductStatus (productId, status) {
    const productStatus = Product.getStatusBooleanValue(status);
    return AxiosClient.put(`/products/${productId}/publish/${productStatus}`, {
      productId: productId
    });
  }

  /**
   * @description Add ProductGalleryImage
   * @param productId
   * @param file
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async addProductGalleryImage (productId, file) {
    const fmData = new window.FormData();
    fmData.append('fileUpload1', file);
    try {
      const { data } = await AxiosClient.post(
        `/products/${productId}/product-photo/details`,
        fmData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
      return data.data.photoGallery.images.map((img) => new ImageModel(img));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // ---------------------------VARIANT CRUD -------------------------------

  /**
   * @description Get product variants
   * @param productId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getProductVariants (productId) {
    return AxiosClient.get(`/products/${productId}/variants`);
  }

  /**
   * @description Delete a single variant
   * @param productId
   * @param variantId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async deleteVariant (productId, variantId) {
    return AxiosClient.delete(`/products/${productId}/variants/${variantId}`,
      {
        headers: {
          Accept: 'application/stentle.api-v0.2+json'
        }
      }
    );
  }

  static async addVariantCoverImage (productId, variantId, file) {
    const fmData = new window.FormData();
    fmData.append('fileUpload1', file);
    try {
      await AxiosClient.post(
        `/products/${productId}/variants/${variantId}/variant-photo/cover`,
        fmData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            accept: 'application/stentle.api-v0.2+json'
          }
        }
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  static async deleteVariantCoverImage (productId, variantId) {
    try {
      await AxiosClient.delete(
        `/products/${productId}/variants/${variantId}/variant-photo/cover`
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * @description Get variant availabilities
   * @param productId
   * @param variantId
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async getVariantAvailabilities (productId, variantId) {
    return AxiosClient.get(
      `/products/${productId}/variants/${variantId}/availabilities`
    );
  }

  /**
   * @description Update variant request
   * @param productId
   * @param variantId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariant (productId, selectedVariant, variantFormData) {
    return AxiosClient.put(
      `/products/${productId}/variants/${selectedVariant.id}`,
      new VariantUpdatePayload(selectedVariant, variantFormData)
    )
      .then((res) => res.data.data)
      .then((apiData) => new EventVariantModel(apiData));
  }

  /**
   * @description Update Variant Availability
   * @param productId
   * @param variantId
   * @param warehouseAvailabilityId
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async updateVariantAvailability (
    productId,
    variantId,
    availabilities,
    originalAvailabilities
  ) {
    const mergedAvailabilities = originalAvailabilities.map((a) => {
      const [id, value] =
        Object.entries(availabilities).find(([id, value]) => id === a.id) || [];
      if (!id) return a;
      return { ...a, availability: value };
    });

    const promises = mergedAvailabilities.map((data) => {
      return AxiosClient.put(
        `/products/${productId}/variants/${variantId}/availabilities/${data.id}`,
        new AvailabilityPayload(data, variantId)
      ).then((res) => res.data.data);
    });

    return Promise.all(promises);
  }

  static async uploadImages (files) {
    const promises = files.map((file) => this.uploadImage(file));
    return Promise.all(promises);
  }

  static async uploadImage (file) {
    const fmData = new window.FormData();
    fmData.append('file', file);
    return AxiosClient.post('/files/images',
      fmData
      , {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }).then((res) => res.data);
  }
}
