import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Form, Select } from 'antd';
import { hasReachedScrollBottom } from 'src/utils/utils';

function hasMoreItems (pagination) {
  return pagination.startIndex + pagination.itemsPerPage < pagination.totalItems;
}

export const SelectWithLoadMore = ({
  firstPage = 1,
  formItemName,
  items,
  getItemsAtPage,
  pagination,
  toOption,
  defaultOptions = [],
  label,
  rules,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(firstPage);
  const [searchedText, setSearchedText] = useState();

  const OPTIONS = useMemo(() => (
    defaultOptions.concat(
      items.map(toOption)
    )
  ), [items]);

  useEffect(async () => {
    setIsLoading(true);
    await getItemsAtPage(currentPage, searchedText);
    setIsLoading(false);
  }, [currentPage, searchedText]);

  const handleScroll = async e => {
    if (hasReachedScrollBottom(e)) {
      if (!isLoading && hasMoreItems(pagination)) {
        setCurrentPage(p => p + 1);
      }
    }
  };

  const searchText = async (text) => {
    setSearchedText(text);
    setCurrentPage(firstPage);
  };

  const handleClear = () => {
    if (props.onClear) {
      props.onClear();
    }
    setSearchedText(undefined);
    setCurrentPage(firstPage);
  };

  return (
    <Form.Item
      label={label}
      rules={rules}
      name={formItemName}
    >
      <Select
        allowClear
        optionFilterProp='label'
        filterOption={() => (true)}
        className='w-full'
        options={OPTIONS}
        onPopupScroll={handleScroll}
        onClear={handleClear}
        onSearch={debounce(txt => searchText(txt), 500)}
        {...props}
      />
    </Form.Item>
  );
};
