import { AxiosClient } from 'src/api/AxiosClient';

export class LocalBusinessAPI {
  static async getLocalBusinesses (payload) {
    return AxiosClient.post('/localBusinesses/filter', payload, {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    });
  }

  static async getLocalBusinessDetails (id) {
    return AxiosClient.get(`/localBusinesses/${id}`, {
      headers: {
        Accept: 'application/stentle.api-v0.3+json'
      }
    });
  }

  /**
   * @description create local business
   * @returns {Promise<*>}
   */
  static async createLocalBusiness (payload) {
    return AxiosClient.post('/localBusinesses', payload, {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    });
  }

  /**
   * @description update local business
   * @returns {Promise<*>}
   */
  static async updateLocalBusiness (localBusinessId, payload) {
    return AxiosClient.put(`/localBusinesses/${localBusinessId}`, payload, {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    });
  }

  /**
  * @description Get local businesses categories
  * @returns {Promise<*>}
  */
  static async getLocalBusinessCategories () {
    return AxiosClient.get('/businessCategories');
  }

  static async getLocalBusinessDepartments () {
    return AxiosClient.get('/departments', {
      params: {
        limit: 10,
        pageNumber: 1,
        sort: 'key,asc'
      }
    });
  }

  /**
  * @description Get local businesses categories
  * @returns {Promise<*>}
  */
  static async getLocalBusinessTags () {
    return AxiosClient.get('/localbusiness-tag');
  }
}
