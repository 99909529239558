import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { textFrom } from 'src/utils/textFrom';
import { PageSection } from 'src/components/PageSection';
import { useTranslation } from 'react-i18next';
import { AuthorizationSelector } from './AuthorizationSelector';

const ENABLED_OPTIONS = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];

export const PermissionSetForm = ({
  permissionSet,
  selectedAuths,
  setSelectedAuths,
  onFinish,
  children
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.permissionSetForm', t);
  const [form] = useForm();
  const [isApiNameDisabled, setIsApiNameDisabled] = useState(false);

  const initializeForm = ({ localizedNames, code, enabledByDefault }) => {
    form.setFieldsValue({
      setName: localizedNames?.en,
      apiName: code,
      enabledByDefault: enabledByDefault
    });
  };

  useEffect(() => {
    if (permissionSet) {
      initializeForm(permissionSet);
      setIsApiNameDisabled(true);
      setSelectedAuths(permissionSet.permissions);
    }
  }, [permissionSet]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      scrollToFirstError
    >
      <Row gutter={20} className='mb-8'>
        <Col xs={6}>
          <Form.Item
            name='setName'
            label={text('setName')}
            rules={[{ required: true, message: text('setNameRequired') }]}
          >
            <Input placeholder={text('setNamePlaceholder')} />
          </Form.Item>
        </Col>

        <Col xs={6}>
          <Form.Item
            name='apiName'
            label={text('apiName')}
            rules={[{ required: true, message: text('apiNameRequired') }]}
          >
            <Input
              disabled={isApiNameDisabled}
              placeholder={text('apiNamePlaceholder')}
            />
          </Form.Item>
        </Col>

        <Col xs={6}>
          <Form.Item
            name='enabledByDefault'
            label={text('enabledByDefault')}
            rules={[{ required: true, message: text('enabledByDefaultRequired') }]}
          >
            <Select
              options={ENABLED_OPTIONS}
              placeholder={text('enabledByDefaultPlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>

      <PageSection>{text('enableAuthorizations')}</PageSection>
      <AuthorizationSelector
        selectedAuths={selectedAuths}
        setSelectedAuths={setSelectedAuths}
      />

      {children}

    </Form>
  );
};
