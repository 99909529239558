import React from 'react';
import { Modal } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

export const AnonymizationModal = React.forwardRef(({
  customer,
  onOk,
  onCancel,
  isPending
}, checkboxRef) => {
  const { t } = useTranslation();
  const text = textFrom('components.anonymizationModal', t);

  const { canAnonymizeOrderData } = useAuth();

  const ConfirmAnonymizeCustomerAndOrders = () => (
    <>
      <div>{text('confirmAnonymizeCustomerAndOrders')}</div>
      <div className='mt-2'>
        <input ref={checkboxRef} type='checkbox' />
        <span className='ml-2'>{text('anonymizeOrders')}</span>
      </div>
    </>
  );

  const ConfirmAnonymizeCustomer = () => (
    <div>{text('confirmAnonymizeCustomer')}</div>
  );

  return (
    <Modal
      visible={!!customer}
      title={text('title', { customerName: `${customer?.givenName} ${customer?.familyName}` })}
      onOk={onOk}
      onCancel={onCancel}
      okText={text('okText')}
      cancelText={text('cancelText')}
      confirmLoading={isPending}
    >
      <div className='horizontally-centered'>
        {canAnonymizeOrderData
          ? <ConfirmAnonymizeCustomerAndOrders checkboxRef={checkboxRef} />
          : <ConfirmAnonymizeCustomer />}
      </div>
    </Modal>
  );
});
