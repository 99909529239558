import React, { useMemo } from 'react';
import { Row, Col, Select, Form, Input, Checkbox } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { useUsers } from 'src/modules/admin/provider/users.provider';
import { DefaultButton } from 'src/components/buttons/DefaultButton';
import { useTranslation } from 'react-i18next';
import { optionMatchesInput, isVerified } from 'src/utils/utils';
import { toRoleOption, isRolePermitted, isUserTypeFullyEditable } from '../utils/utils';
import { SelectLocalBusinessWithLoadMore } from 'src/components/SelectLocalBusinessWithLoadMore';

export const UserForm = ({
  form,
  mode,
  buttons,
  initialUserIsActive,
  onFinish
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.userForm', t);

  const { roles } = useUsers();
  const { canManageUsers, canFullyEditUsers } = useAuth();
  const { user, userEmailVerificationStatus, requestVerificationEmail } = useUsers();

  const isFullEditEnabled = canFullyEditUsers || isUserTypeFullyEditable(user?.type);

  const ROLE_OPTIONS = useMemo(() => (
    roles
      .filter(isRolePermitted)
      .map(toRoleOption)
  ), [roles]);

  return (
    <Form
      form={form}
      layout='vertical'
      autoComplete='off'
      scrollToFirstError
      onFinish={onFinish}
    >
      <Row gutter={20} className='pt-2 mb-4'>
        <Col xs={8}>
          <Form.Item
            name='userFirstName'
            label={text('firstName')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('firstNameRequired') }]}
          >
            <Input placeholder={text('firstNamePlaceholder')} />
          </Form.Item>
        </Col>

        <Col xs={8}>
          <Form.Item
            name='userLastName'
            label={text('lastName')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('lastNameRequired') }]}
          >
            <Input placeholder={text('lastNamePlaceholder')} />
          </Form.Item>
        </Col>

        {(mode === 'create' || isFullEditEnabled) &&
          <Col xs={8}>
            <Form.Item
              name='username'
              label={text('username')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('usernameRequired') }]}
            >
              <Input placeholder={text('usernamePlaceholder')} />
            </Form.Item>
          </Col>}
      </Row>

      {(mode === 'create' || isFullEditEnabled) &&
        <Row gutter={20} className='mb-4'>
          <Col xs={8}>
            <Form.Item
              name='userEmail'
              label={text('email')}
              className='w-full px-4 pb-4'
              rules={[
                { required: true, message: text('emailRequired') },
                { type: 'email', message: text('emailInvalid') }
              ]}
            >
              <Input placeholder={text('emailPlaceholder')} />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <SelectLocalBusinessWithLoadMore
              labelInValue
              disabled={!canManageUsers}
              formItemName='userLocalBusiness'
              label={text('localBusiness')}
              placeholder={text('localBusinessPlaceholder')}
              rules={[{ required: true, message: text('localBusinessRequired') }]}
            />
          </Col>

          <Col xs={8} className='mb-4'>
            <Form.Item
              name='userRole'
              label={text('role')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('roleRequired') }]}
            >
              <Select
                showSearch
                bordered
                allowClear
                className='w-full'
                label={text('role')}
                placeholder={text('rolePlaceholder')}
                options={ROLE_OPTIONS}
                filterOption={optionMatchesInput}
              />
            </Form.Item>
          </Col>
        </Row>}

      {(mode === 'edit' && isFullEditEnabled) &&
        <Row gutter={20} align='bottom'>
          <Col xs={8}>
            {typeof initialUserIsActive === 'boolean' &&
              <Form.Item
                label=' '
                name='userIsActive'
                valuePropName='checked'
              >
                <div className='checkbox-one-line'>
                  <div>{text('active')}</div>
                  <Checkbox defaultChecked={initialUserIsActive} />
                </div>
              </Form.Item>}
          </Col>

          {!isVerified(userEmailVerificationStatus) &&
            <Col xs={8}>
              <DefaultButton
                label={text('requestVerification')}
                onClick={() => requestVerificationEmail(user)}
              />
            </Col>}
        </Row>}

      {buttons}

    </Form>
  );
};
