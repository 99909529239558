import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Button, Drawer, Tag } from 'antd';
import { useFoodProducts } from 'src/modules/products/provider/foodProducts.provider';
import { useFilterTags } from 'src/hooks/useFilterTags';
// import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { ProductSideFilters } from 'src/modules/products/components/ProductSideFilters';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { FoodTable } from 'src/modules/products/components/FoodTable';
import { Routes } from 'src/router/Routes.helper';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { Product } from 'src/services/Product.service';
import { message } from 'src/services/Messages.service';
import { PageTitle } from 'src/components/PageTitle';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { useHistory, Link } from 'react-router-dom';
import { CenteredText } from 'src/components/CenteredText';
import { SCOPE } from 'src/utils/scope-utils';
import { DeletionModal } from 'src/components/DeletionModal';
import { tagLabel } from 'src/utils/utils';

const { PLATFORM, TENANT } = SCOPE;

const invertStatus = (status) => {
  if (status === 'Draft') {
    return 'Published';
  }
  return 'Draft';
};

export const PageProductsFood = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.products', t);
  const textFilter = textFrom('constants.filter', t);

  const [foodToBeDeleted, setFoodToBeDeleted] = useState(undefined);

  const history = useHistory();

  const header = useRef();
  const { selectedUserScope } = useAuth();
  // Enable once API is fixed
  // const downloadFile = useDownloadFile({
  //   onError (err) {
  //     message.error(
  //       err.response?.data?.detail || err.response?.data || err.message
  //     );
  //   }
  // });
  const {
    query,
    products,
    availableFilters,
    pagination,
    onChangeQuery,
    refreshData,
    setProducts,
    areProductsLoading
  } = useFoodProducts();
  const [isCreateProductVisible, setIsCreateProductVisible] = useState(false);

  const searchParameters = ['current', 'pageSize'];
  const filterTags = useFilterTags(
    query,
    searchParameters,
    availableFilters?.filters || {}
  );
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    if (selectedUserScope === PLATFORM || selectedUserScope === TENANT) {
      setIsCreateProductVisible(false);
    } else {
      setIsCreateProductVisible(true);
    }
  }, [selectedUserScope, setIsCreateProductVisible]);

  /**
  /**
   * @description This method handle the table data in case user changes the page or change the total items displayed on that page
   * @param {{
   *  current: string
   *  pageSize: number
   * }} config
   * @returns {string}
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  /**
   * @description This method handle the click on apply filter button inside the drawer. In the drawer are being added all the filters including even category and subcategory (apart ean)
   * @param filters
   * @param closeDrawer
   */
  const onApplyFilters = (filters, closeDrawer = false) => {
    const newFilters = { ...filters };

    if (filters.published !== undefined && filters.published !== null) {
      newFilters.published = Product.getStatusBooleanValue(filters.published);
    }
    newFilters.current = 1;

    onChangeQuery(newFilters);
    closeDrawer && setDrawerVisible(false);
  };

  /**
   * This method is being called when are being applied the multiselect filter (category and subcategory) - Check the buttons inside the multiselect dropdown
   * @param key
   * @param values
   */
  // const onChangeFilter = (key, values) => {
  //   onChangeQuery({
  //     ...query,
  //     [key]: values,
  //     current: 1
  //   });
  // };

  /**
   * @description This method handle the remove of applied filters on Filters applied section - On the click of 'X' button this method is being called
   * @param tag
   */
  const onTagRemove = (tag) => {
    if (Array.isArray(query[tag.key])) {
      const updatedValues = query[tag.key].filter(
        (value) => tag.value !== value
      );
      onChangeQuery({
        ...query,
        [tag.key]: updatedValues.length ? updatedValues : null
      });
    } else {
      onChangeQuery({
        ...query,
        [tag.key]: null
      });
    }
  };

  const handleEdit = (record) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.EDIT_PRODUCT_FOOD, {
      id: record.id
    }));
  };

  /**
   * @description This method handles delete product - Inline delete - check table actions
   * @param record
   * @returns {Promise<void>}
   */
  const handleDelete = async (record) => {
    try {
      await ProductsAPI.deleteProduct(record.id);
      message.success(text('onDeleteOk'));
    } catch (e) {
      message.error(e.response?.data?.detail);
    }
  };

  /**
   * @description Handle the inline update
   * @param form
   * @param recordID
   * @returns {Promise<void>}
   */
  const handleUpdate = async (form, record) => {
    // Add also brand when update inline
    const payload = {
      ...form,
      brand: {
        id: record.brandDetails.id,
        key: record.brandDetails.key
      }
    };
    try {
      await ProductsAPI.updateProductStatus(record.id, form.published);
      const obj = await ProductsAPI.updateProduct(record.id, payload);
      const newData = [...products];
      const itemIndex = products.findIndex((el) => el.id === record.id);
      newData[itemIndex] = { ...newData[itemIndex], ...obj };
      setProducts(newData);
      message.success(text('onUpdateOk'));
    } catch (e) {
      message.error(e.response?.data?.detail);
    }
  };

  const handleToggleAvailability = async (ids, boolean) => {
    try {
      await ProductsAPI.toggleAvailability(ids, boolean);
      const newData = [...products];
      ids.forEach(singleId => {
        const itemIndex = products.findIndex((el) => el.id === singleId);
        newData[itemIndex].availabilityTotal = boolean ? +true : +false;
      });
      setProducts(newData);
      message.success(text('onUpdateOk'));
    } catch (e) {
      message.error(e.response?.data?.detail);
    }
  };

  const handleToggleStatus = async (id, status) => {
    const newStatus = invertStatus(status);
    try {
      await ProductsAPI.updateProductStatus(id, newStatus);
      const newData = [...products];
      const itemIndex = products.findIndex((el) => el.id === id);
      newData[itemIndex].published = newStatus;
      setProducts(newData);
      message.success(text('onUpdateOk'));
    } catch (e) {
      message.error(e.response?.data?.detail);
    }
  };

  /**
   * @description Full search - for the moment handles only ean and it is not an autocomplete
   * @param value
   */
  const handleFullTextSearch = (value) => {
    onChangeQuery({
      ...query,
      text: value,
      current: 1
    });
  };

  const onDelete = (food) => {
    setFoodToBeDeleted(food);
  };

  const resetFoodToBeDeleted = () => {
    setFoodToBeDeleted(undefined);
  };

  const confirmFoodDeletion = async () => {
    try {
      await ProductsAPI.deleteProduct(foodToBeDeleted.id);
      message.success(text('onDeleteOk'));
      refreshData();
    } catch (e) {
      message.error(e.response?.data?.detail);
    } finally {
      resetFoodToBeDeleted();
    }
  };

  return (
    <div>
      <div ref={header} className='py-4 bg-gray-100'>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          {/* EAN Search */}
          <Col span={6}>
            <SearchInput
              autoFocus
              placeholder={text('searchPlaceholder')}
              onChangeText={handleFullTextSearch}
              defaultValue={query.text}
            />
          </Col>
          {/* Macrocategory */
          /*
          <Col span={6}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MACRO_CATEGORY]}
              values={query[KEYS.MACRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MACRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MACRO_CATEGORY, null)}
              placeholder="Category"
              className="m-2"
              keyProperty="key"
              labelProperty="value"
            />
          </Col> */}

          {/* Microcategory */
          /*
          <Col span={6}>
            <CustomMultiselect
              items={availableFilters.filters[API_FILTERS_KEYS.MICRO_CATEGORY]}
              values={query[KEYS.MICRO_CATEGORY]}
              onApply={(values) => onChangeFilter(KEYS.MICRO_CATEGORY, values)}
              onReset={() => onChangeFilter(KEYS.MICRO_CATEGORY, null)}
              placeholder="Subcategory"
              className="m-2"
            />
          </Col> */}
          <Col span={6}>
            <Button onClick={() => setDrawerVisible(true)}>
              {text('allFiltersButton')}
            </Button>
          </Col>
          {isCreateProductVisible &&
            <Col className='last-column'>
              <div>
                {/* TODO enable once API fixed
                <Button
                  loading={downloadFile.isLoading}
                  onClick={() =>
                    downloadFile.call('/backoffice/export', 'catalog.xlsx')
                  }
                  type='primary'
                  className='uppercase'
                >
                  Download .xls
                </Button> */}
                <Button
                  onClick={() => { }}
                  type='primary'
                  className='uppercase'
                >
                  <Link
                    to={Routes.PATHS.CREATE_PRODUCT_FOOD}
                  >
                    {text('createNewProductButton')}
                  </Link>
                </Button>
              </div>
            </Col>}
        </Row>
        {filterTags?.length > 0 &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: filterTags.length })}:</div>
            {filterTags.map((tag, i) => (
              <Tag
                key={`${tag.key}-${tag.label}-${i}`}
                closable
                onClose={() => onTagRemove(tag)}
              >
                {tagLabel(tag, Product)}
              </Tag>
            ))}
          </Row>}
      </div>

      {!areProductsLoading && !products?.length
        ? (<CenteredText>{text('noProductsFound')}</CenteredText>)
        : (<FoodTable
            loading={areProductsLoading}
            products={products}
            availableFilters={availableFilters}
            pagination={pagination}
            onTableChange={onTableChange}
            delete={handleDelete}
            onUpdateRecord={handleUpdate}
            handleToggleAvailability={handleToggleAvailability}
            handleToggleStatus={handleToggleStatus}
            deleteMessage={text('deleteMessage')}
            onEdit={handleEdit}
            onDelete={onDelete}
           />)}

      <Drawer
        visible={!!drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
        placement='right'
        className='jc-drawer'
        title={text('addFilters')}
      >
        <ProductSideFilters
          currentFilters={query}
          availableFilters={availableFilters}
          onApply={onApplyFilters}
        />
      </Drawer>

      <DeletionModal
        visible={!!foodToBeDeleted}
        onOk={confirmFoodDeletion}
        onCancel={resetFoodToBeDeleted}
        msgOnSuccess={text('onDeleteOk')}
        msgOnError={text('onDeleteErr')}
      >
        <div>{text('confirmDeletion', { name: foodToBeDeleted?.name })}</div>
      </DeletionModal>

    </div>
  );
};
