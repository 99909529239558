import React, { useEffect } from 'react';
import { Typography, Col, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

const { Title } = Typography;

export const ContactsAndSocials = ({ form, userInfo }) => {
  const { t } = useTranslation();
  const text = textFrom('components.contactsAndSocials', t);

  const facebookLink = userInfo.localBusiness?.socialServices?.find(
    (item) => item.key === 'FACEBOOK'
  );
  const instagramLink = userInfo.localBusiness?.socialServices?.find(
    (item) => item.key === 'INSTAGRAM'
  );

  useEffect(() => {
    form.setFieldsValue({
      website: userInfo.localBusiness.websiteUrl,
      email: userInfo.localBusiness.email,
      facebook: facebookLink ? facebookLink.value : '',
      phoneNumber: userInfo.localBusiness.telephone,
      instagram: instagramLink ? instagramLink.value : ''
    });
  }, [userInfo]);

  return (
    <div form={form}>
      <div className='border-b border-gray-300 pb-8'>
        <Col xs={12}>
          <Title level={5} className='pb-6 py-8'>
            {text('contacts')}
          </Title>
          <Form.Item
            label={<span>{text('website')}</span>}
            name='website'
            required={false}
            rules={[
              {
                type: 'url',
                message: text('urlInvalid')
              }
            ]}
          >
            <Input placeholder='Website' />
          </Form.Item>
          <Form.Item
            label={<span>{text('email')}</span>}
            name='email'
            required={false}
            rules={[
              {
                type: 'email',
                message: text('emailInvalid')
              }
            ]}
          >
            <Input placeholder='Email' />
          </Form.Item>
          <Form.Item
            label={<span>{text('phoneNumber')}</span>}
            name='phoneNumber'
            required={false}
            rules={[
              { min: 8, message: text('phoneNumberInvalid') }
            ]}
          >
            <Input placeholder='02 49 574466' />
          </Form.Item>
        </Col>
      </div>
      <div className='border-b border-gray-300 pb-8'>
        <Col xs={12}>
          <Title level={5} className='py-6'>
            Social networks
          </Title>
          <Form.Item
            label={<span>Facebook</span>}
            name='facebook'
            required={false}
          >
            <Input placeholder='Facebook' />
          </Form.Item>
          <Form.Item
            label={<span>Instagram</span>}
            name='instagram'
            required={false}
          >
            <Input placeholder='Instagram' />
          </Form.Item>
        </Col>
      </div>
    </div>
  );
};
