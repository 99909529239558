import { PayloadListAbstract } from "src/domain/PayloadListAbstract";

export class StocksListPayload extends PayloadListAbstract {
    static get API_FILTERS_KEYS() {
        return {
            EAN: "ean",
            WAREHOUSE: "warehouse",
            AVAILABILITY: "availability",
        };
    }

    filters = {
        ean: null,
        [StocksListPayload.API_FILTERS_KEYS.WAREHOUSE]: null,
        [StocksListPayload.API_FILTERS_KEYS.AVALIABILITY]: null,
    };
}
