import React, { useCallback, useState } from 'react';
import { Row, Col } from 'antd';
import { Routes } from 'src/router/Routes.helper';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { CenteredText } from 'src/components/CenteredText';
import { DeletionModal } from 'src/components/DeletionModal';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { PermissionSetsAPI } from '../api/PermissionSetsAPI';
import { usePermissionSets } from '../provider/permissionSets.provider';
import { PermissionSetTable } from '../component/PermissionSetTable';

export const PagePermissionSets = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.permission-sets', t);

  const history = useHistory();
  const {
    pagination,
    onChangeQuery,
    permissionSets,
    getPermissionSets,
    arePermissionSetsLoading
  } = usePermissionSets();
  const [permissionSetToBeDeleted, setPermissionSetToBeDeleted] = useState(undefined);

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const goToCreatePermissionSet = useCallback(() => {
    history.push(Routes.PATHS.PERMISSION_SETS_CREATE);
  }, []);

  const goToEditPermissionSet = useCallback((permissionSet) => {
    history.push(Routes.parseRouteParams(Routes.PATHS.PERMISSION_SETS_EDIT, { id: permissionSet.code }));
  }, []);

  const openDeletionModal = (permissionSet) => {
    setPermissionSetToBeDeleted(permissionSet);
  };

  const resetPermissionSetToBeDeleted = () => {
    setPermissionSetToBeDeleted(undefined);
  };

  const confirmPermissionSetDeletion = async () => {
    await PermissionSetsAPI.deletePermissionSet(permissionSetToBeDeleted.code);
    resetPermissionSetToBeDeleted();
    getPermissionSets();
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <Row>
        <Col className='last-column'>
          <PrimaryButton
            label={text('createNewPermissionSet')}
            onClick={goToCreatePermissionSet}
          />
        </Col>
      </Row>

      {!arePermissionSetsLoading && !permissionSets?.length
        ? (<CenteredText>{text('noPermissionSetsFound')}</CenteredText>)
        : (
          <div className='pt-8'>
            <PermissionSetTable
              loading={arePermissionSetsLoading}
              onEdit={goToEditPermissionSet}
              onDelete={openDeletionModal}
              permissionSets={permissionSets}
              pagination={pagination}
              onTableChange={onTableChange}
            />
          </div>)}

      <DeletionModal
        visible={!!permissionSetToBeDeleted}
        onOk={confirmPermissionSetDeletion}
        onCancel={resetPermissionSetToBeDeleted}
        msgOnSuccess={text('onDeleteOk')}
        msgOnError={text('onDeleteErr')}
      >
        <div>{text('confirmDeletion', { code: permissionSetToBeDeleted?.code })}</div>
      </DeletionModal>
    </div>
  );
};
