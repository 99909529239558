import { AxiosClient } from 'src/api/AxiosClient';

export class WebhookAPI {
  static async createWebhook (payload) {
    return AxiosClient.post('/webhook', payload);
  }

  static async getWebhooks () {
    return AxiosClient.get('/webhook');
  }

  static async getWebhookbyId (webhookId) {
    return AxiosClient.get(`/webhook/${webhookId}`);
  }

  static async updateWebhook (payload) {
    return AxiosClient.put('/webhook', payload);
  }

  static async deleteWebhook (webhookId) {
    const config = {
      data: {
        clientId: webhookId
      }
    };
    return AxiosClient.delete('/webhook', config);
  }
}
