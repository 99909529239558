import { Filters } from 'src/services/Filters.service';

export class PermissionSetsMapper {
  static fromQueryToQueryString (query) {
    const search = Filters.removeFalsyValues(query);
    return new URLSearchParams(search).toString();
  }

  static fromQueryStringToQuery (qs) {
    const query = new URLSearchParams(qs);
    const permissionSetQueryModel = {
      current: query?.get('current'),
      pageSize: query?.get('pageSize')
    };
    return permissionSetQueryModel;
  }

  static fromQueryToPayload (query) {
    const payload = {
      pageNumber: query?.current > 0 ? +query.current : 1,
      limit: query?.pageSize || 10
    };
    return payload;
  }
}
