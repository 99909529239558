import { PayloadListAbstract } from "src/domain/PayloadListAbstract";

export class ProductsListPayload extends PayloadListAbstract {
  static get API_FILTERS_KEYS() {
    return {
      MACRO_CATEGORY: "macro_category.key",
      MICRO_CATEGORY: "micro_category.key",
      BRAND: 'brand',
      PUBLISHED: 'published',
    };
  }

  filters = {
    published: null,
    brand: null,
    [ProductsListPayload.API_FILTERS_KEYS.MACRO_CATEGORY]: null,
    [ProductsListPayload.API_FILTERS_KEYS.MICRO_CATEGORY]: null,
  };
}
