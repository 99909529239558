import { AxiosClient } from 'src/api/AxiosClient';

export class WarehousesAPI {
  static async getWarehouses (data) {
    const acceptHeader = data.filters.department ? 'application/stentle.api-v0.2+json' : 'application/stentle.api-v0.1+json';
    return AxiosClient.get('/warehouses',
      {
        params: {
          limit: data.limit,
          pageNumber: data.pageNumber,
          name: data.filters.name,
          type: data.filters.type,
          department: data.filters.department
        },
        headers: {
          Accept: acceptHeader
        }
      }
    );
  }

  static async getWarehouse (id) {
    return AxiosClient.get(`/warehouses/${id}`);
  }

  static async updateWarehouse (id, data) {
    const opts = {
      headers: {
        Accept: 'application/stentle.api-v0.2+json'
      }
    };
    return AxiosClient.put(`/warehouses/${id}`, data, opts);
  }

  static async createWarehouse (data) {
    return AxiosClient.post('/warehouses', data);
  }

  static async deleteWarehouse (id) {
    return AxiosClient.delete(`/warehouses/${id}?force=false`);
  }
}
