import React, { useEffect, useState, useMemo } from 'react';
import { message } from 'src/services/Messages.service';
import { Row, Col, Input, Form, Divider, Button, Select, Radio, Space, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { useProductDetailsFood as useProductDetails } from 'src/modules/products/provider/productDetailsFood.provider';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { BackButton } from 'src/components/BackButton';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { WysiwygEditor } from 'src/components/inputs/WysiwygEditor';
import { ProductVariantsFood } from 'src/modules/products/components/ProductVariantsFood';
import { CategoriesFood as Categories } from 'src/modules/products/components/CategoriesFood';
import { ImageGalleryFood } from 'src/modules/products/components/ImageGalleryFood';
import { ProductsAPI } from 'src/modules/products/api/ProductsAPI';
import { Routes } from 'src/router/Routes.helper';
import { Product } from 'src/services/Product.service';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { StorageService } from 'src/services/Storage.service';
import { LanguageSelect } from 'src/components/inputs/LanguageSelect';
import { nestify } from 'src/utils/objectManipulation';
import { PageTitle } from 'src/components/PageTitle';
import { SEPARATOR } from 'src/utils/constants';

const { Option } = Select;

/**
 * @example: If the available languages are Italian and English, this function returns the following object: { it: { value: '' }, en: {value: ''} }
 */
const prepareFields = (languages) => {
  const fields = {};
  languages.forEach(({ language }) => {
    fields[language] = { value: '' };
  });
  return fields;
};

export const PageCreateFoodProduct = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.create-product', t);

  const history = useHistory();
  const [form] = Form.useForm();
  const {
    // product,
    // filteredProductVariants,
    sizeAttributes,
    productVariants,
    setProductVariants
  } = useProductDetails();

  const [isSingleVariant, setIsSingleVariant] = useState(true);
  const availabilityOptions = ['available', 'unavailable'];
  const [productGalleryFiles, setProductGalleryFiles] = useState([]);
  const [addedProductGalleryFiles, setAddedProductGalleryFiles] = useState([]);
  const [deletedProductGalleryFiles, setDeletedProductGalleryFiles] = useState([]);
  const [isCreatingProduct, setIsCreatingProduct] = useState(false);
  const [user, setUser] = useState(null);
  const { languages, defaultLanguage } = useLanguages();
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    setSelectedLanguage(defaultLanguage.language);
  }, [defaultLanguage]);

  const handleLanguageSelection = (languageId) => {
    setSelectedLanguage(languageId);
  };

  const requiredLanguage = useMemo(() => {
    return languages.find(language => language.defaultLanguage);
  }, [languages]);

  useEffect(() => {
    form.setFieldsValue({
      names: prepareFields(languages),
      descriptions: prepareFields(languages)
    });
  }, []);

  useEffect(() => {
    if (!defaultLanguage) {
      return;
    }
    form.setFieldsValue({ language: defaultLanguage.label });
  }, [form, defaultLanguage]);

  useEffect(async () => {
    const user = await StorageService.getUser();
    setUser(user);
  }, []);

  const updatePricesAndAvailabilitiesOfAllVariants = async (product, variants, availability, user) => {
    const availabilitiesRes = await ProductsAPI.getVariantAvailabilities(product.id, variants.id);
    await ProductsAPI.updateVariantPrice(product, variants, availability, availabilitiesRes.data.data.items[0].id, user.warehousesManaged[0]);
  };

  /**
   * @description Handle add image to gallery method
   * @param file
   */
  const handleAddedProductGalleryFile = (file) => {
    setAddedProductGalleryFiles((oldAddedProductFileList) => [...oldAddedProductFileList, file]);
  };

  /**
   * @description Handle delete image from gallery method
   * @param file
   */
  const handleDeleteProductGalleryFile = (file) => {
    if (addedProductGalleryFiles.find(addedFile => addedFile.uid === file.uid)) {
      setAddedProductGalleryFiles((oldAddedFileList) => oldAddedFileList.filter(addedFile => addedFile.uid !== file.uid));
    }
    if (file.uid.includes('rc-upload')) {
      return;
    }
    setDeletedProductGalleryFiles((oldDeletedFileList) => [...oldDeletedFileList, file]);
  };

  const handleGalleryUpdate = (file, fileList, type) => {
    let newGallery = [];
    if (type === 'cover') {
      const detailGalleryFiles = productGalleryFiles.filter(galleryFile => galleryFile.info.type !== 'cover');
      const newCover = {
        ...fileList[0],
        info: {
          active: true,
          type: type
        }
      };
      newGallery = [...[newCover], ...detailGalleryFiles];
      const toDeleteCovers = productGalleryFiles.filter(file => !file.info.active && file.info.type === 'cover');
      toDeleteCovers.forEach(toDeleteCover => {
        handleDeleteProductGalleryFile(toDeleteCover);
      });
    } else {
      const coverGalleryFiles = productGalleryFiles.filter(galleryFile => galleryFile.info.type === 'cover');
      const newDetailGalleryFiles = fileList.map(detailFileItem => (
        detailFileItem = {
          ...detailFileItem,
          info: {
            active: true,
            type: type
          }
        })
      );
      newGallery = [...coverGalleryFiles, ...newDetailGalleryFiles];
    }
    setProductGalleryFiles(newGallery);
    if (file?.status === 'removed') {
      handleDeleteProductGalleryFile(file);
    } else {
      handleAddedProductGalleryFile(file);
    }
  };

  const handleRadioChange = (event) => {
    setIsSingleVariant(event.target.value);
  };

  const handleCancelCreateProduct = () => {
    goToProductsListPage();
  };

  const handleCreateProduct = async (values) => {
    const formValues = form.getFieldsValue(true); // Get all the values, even the unmounted ones
    const {
      names,
      descriptions
    } = nestify(formValues, ['names', 'descriptions']);

    if (!names?.[requiredLanguage.language]?.value) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('name') }));
      return;
    }

    if (!descriptions?.[requiredLanguage.language]?.value) {
      message.error(text('onMissingLocalizedValue', { language: requiredLanguage.language, value: text('description') }));
      return;
    }

    if (!isSingleVariant && productVariants.length < 2) {
      message.error(text('onMissingVariants'));
      return;
    }

    if (addedProductGalleryFiles.length === 0) {
      message.error(text('onMissingGalleryFiles'));
      return;
    }

    setIsCreatingProduct(true);
    const {
      price,
      status,
      availability,
      categories: { micro, macro }
    } = values;

    // TODO refactor me
    const minVariantPrice = !(isSingleVariant || productVariants.length === 0) ? +productVariants.filter(item => item.prices.sellingPrice).reduce((prev, curr) => +prev.prices.sellingPrice < +curr.prices.sellingPrice ? prev : curr).prices.sellingPrice : price;
    const payload = {
      names: names,
      descriptions: descriptions,
      macroCategory: {
        key: macro.key.key
      },
      microCategory: {
        key: micro.key.key
      },
      brand: {
        key: 'no-brand'
      },
      department: {
        key: 'food'
      },
      prices: {
        sellingPrice: isSingleVariant || productVariants.length === 0 ? +price : minVariantPrice,
        retailPrice: isSingleVariant || productVariants.length === 0 ? +price : minVariantPrice
      }
    };
    try {
      const res = await ProductsAPI.createProduct(payload);
      await ProductsAPI.authorizeProduct(res.data.data.id);
      const coverImage = productGalleryFiles.find(galleryFile => galleryFile.info.type === 'cover');
      await ProductsAPI.updateProductCoverImage(res.data.data.id, coverImage, addedProductGalleryFiles, deletedProductGalleryFiles);
      const galleryImages = productGalleryFiles.filter(galleryFile => galleryFile.info.type !== 'cover');
      await ProductsAPI.updateProductGallery(res.data.data.id, galleryImages, addedProductGalleryFiles, deletedProductGalleryFiles);
      if (isSingleVariant || productVariants.length === 0) {
        // create default variant
        const variantRes = await ProductsAPI.createVariant(res.data.data.id, {
          names: names,
          attributeVariants: {
            size: {
              attributeCode: 'size',
              key: 'no-size-food'
            }
          },
          prices: {
            sellingPrice: +price,
            retailPrice: +price
          }
        });
        await updatePricesAndAvailabilitiesOfAllVariants(res.data.data, variantRes.data.data, availability === 'available' ? 1 : 0, user);
        await ProductsAPI.updateVariantGalleryFromProduct(res.data.data.id, variantRes.data.data.id);
        await ProductsAPI.updateProductStatus(res.data.data.id, status);
        await ProductsAPI.updateProductVariantStatus(res.data.data.id, variantRes.data.data.id, status);
        await ProductsAPI.updateProductAvailability(variantRes.data.data.id, availability === 'available');
      } else {
        // create multiple variants
        productVariants.forEach(async (variant) => {
          try {
            variant.names = names;
            const variantRes = await ProductsAPI.createVariant(res.data.data.id, {
              ...variant
            });
            await updatePricesAndAvailabilitiesOfAllVariants(res.data.data, variantRes.data.data, availability === 'available' ? 1 : 0, user);
            await ProductsAPI.updateVariantGalleryFromProduct(res.data.data.id, variantRes.data.data.id);
            await ProductsAPI.updateProductVariantStatus(res.data.data.id, variantRes.data.data.id, status);
            await ProductsAPI.updateProductAvailability(variantRes.data.data.id, availability === 'available');
          } catch (error) {
            setIsCreatingProduct(false);
            message.error(text('onCreateProductErr'));
          }
        });
      }
      setIsCreatingProduct(false);
      message.success(text('onCreateProductOk'));
      goToProductsListPage();
    } catch (e) {
      setIsCreatingProduct(false);
      message.error(text('onCreateProductErr'));
    }
  };

  /**
   * @description Redirect to products list method
   */
  const goToProductsListPage = () => {
    history.push(Routes.PATHS.PRODUCTS_FOOD);
  };

  const handleGalleryOrderUpdate = (fileList) => {
    // console.log('Ordered file list', fileList)
  };

  /**
   * @description Form save
   * @param values
   */
  const onSubmit = (values) => {
    handleCreateProduct(values);
  };

  return (
    <div className='py-4'>
      {/* Product Information Section */}
      <Form
        onFinish={onSubmit}
        form={form}
        initialValues={{
          name: null,
          description: null,
          price: null,
          categories: {
            macro: { key: null, label: null },
            micro: { key: null, label: null },
            brand: {
              value: null,
              label: null
            }
          },
          availability: '',
          status: null
        }}
        layout='vertical'
        autoComplete='off'
        scrollToFirstError
      >
        {/* Product Name */}
        <Row gutter={[23, 23]}>
          <Col span={18}>
            <PageTitle>{text('createNewProduct')}</PageTitle>
            <BackButton
              iconChevron
              className='mt-4 mb-8'
              onClick={goToProductsListPage}
              title={text('backButton')}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={6}>
            <LanguageSelect
              onSelect={handleLanguageSelection}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]} className='pt-4'>
          <Col span={6}>
            <NameInput selectedLanguage={selectedLanguage} />
            <DescriptionInput selectedLanguage={selectedLanguage} form={form} />
          </Col>
          {/* Status */}
          <Col span={18}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item name='status' label={text('status')} rules={[{ required: true, message: text('statusRequired') }]}>
                  <Select getPopupContainer={trigger => trigger.parentNode}>
                    {Product.statusOptions.map((status, i) => (
                      <Option key={`${status}-${i}`} value={status}>
                        {status}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='availability' label={text('availability')} rules={[{ required: true }]}>
                  <Select getPopupContainer={trigger => trigger.parentNode}>
                    {availabilityOptions.map((availabilityOption, i) => (
                      <Option key={`${availabilityOption}-${i}`} value={availabilityOption}>
                        {availabilityOption}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className='pt-4'>
              <Col span={24}>
                <Categories form={form} />
              </Col>
            </Row>
            <Row gutter={[16, 16]} className='pt-4'>
              <Col span={8}>
                {isSingleVariant &&
                  <Form.Item
                    name='price'
                    label={text('price')}
                    rules={[{ required: isSingleVariant }, { type: 'number', min: 0 }]}
                  >
                    <InputNumber
                      addonBefore='€'
                      parser={(value) => value.replace(/€\s?|(,*)/g, '')}
                      disabled={!isSingleVariant}
                      minValue={0}
                    />
                  </Form.Item>}
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Categories */}
        <Row gutter={[16, 16]} className='pt-4'>
          <Col span={16} />
        </Row>
        {/* Prices */}
        <Row gutter={[16, 16]} className='pt-4'>
          {/* Description */}
        </Row>
        {/* Variants */}
        <Divider />
        <SectionTitle title={text('sizeSection.title')} className='pb-4' />
        <HelperText text={text('sizeSection.helperText')} className='mb-2' />
        <Radio.Group onChange={handleRadioChange} value={isSingleVariant}>
          <Space direction='vertical'>
            <Radio value>{text('sizeSection.radioOne')}</Radio>
            <Radio value={false}>{text('sizeSection.radioMany')}</Radio>
          </Space>
        </Radio.Group>
        {!isSingleVariant &&
          <ProductVariantsFood
            variants={sizeAttributes}
            productId='product.id'
            onDeleteVariant={() => { }}
            onEditVariant={() => { }}
            productVariants={productVariants}
            setProductVariants={setProductVariants}
          />}
        {/* Gallery */}
        <Divider />
        <SectionTitle title={text('gallerySection.title')} className='pb-4' />
        <HelperText text={text('gallerySection.helperText')} />
        {/* ImageGallery */}
        <ImageGalleryFood
          fileList={productGalleryFiles.reverse().filter(img => (img?.info?.active && img?.info?.type === 'cover'))}
          detailFileList={productGalleryFiles.reverse().filter(img => (!img.info || img?.info?.type !== 'cover'))}
          onGalleryUpdate={(file, fileList, type) => handleGalleryUpdate(file, fileList, type)}
          onGalleryOrderUpdate={(fileList) => handleGalleryOrderUpdate(fileList)}
        />
        <Divider />
        <Row gutter={[23, 23]} className='pt-8'>
          <Col span={16} />
          <Col span={4}>
            {/* Cancel Edit Product */}
            <Button
              type='default'
              htmlType='submit'
              className='uppercase mr-2 w-100'
              onClick={handleCancelCreateProduct}
            >
              {text('cancelButton')}
            </Button>
          </Col>
          <Col span={4}>
            {/* Update Product */}
            <Button
              type='primary'
              htmlType='submit'
              className='uppercase w-100'
              loading={isCreatingProduct}
              disabled={isCreatingProduct}
            >
              {text('createProductButton')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const NameInput = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.create-product', t);

  return (
    <Form.Item
      label={text('name')}
      name={`names${SEPARATOR}${selectedLanguage}${SEPARATOR}value`}
    >
      <Input placeholder={text('name')} />
    </Form.Item>
  );
};

const DescriptionInput = ({ selectedLanguage, form }) => {
  const { t } = useTranslation();
  const text = textFrom('pages.create-product', t);

  return (
    <Form.Item
      name={`descriptions${SEPARATOR}${selectedLanguage}${SEPARATOR}value`}
      label={text('description')}
    >
      <WysiwygEditor
        name={`descriptions${SEPARATOR}${selectedLanguage}${SEPARATOR}value`}
        onChange={(name, val) => { form.setFieldsValue({ [name]: val }); }}
        initialValue={form.getFieldValue(`descriptions${SEPARATOR}${selectedLanguage}${SEPARATOR}value`)}
      />
    </Form.Item>
  );
};
