import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import { WebhookAPI } from '../api/webhookAPI';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { usePagination } from 'src/hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

export const WebhookContext = createContext();

const useProvideWebhook = () => {
  const { t } = useTranslation();
  const text = textFrom('providers.webhook', t);
  const [webhooks, setWebhooks] = useState([]);
  const [webhook, setWebhook] = useState(null);
  const [areWebhooksLoading, setAreWebhooksLoading] = useState(false);
  const [pagination, setPagination] = usePagination();

  const params = useParams();

  const getWebhooks = useCallback(async () => {
    setAreWebhooksLoading(true);
    try {
      const getWebHooksRes = await WebhookAPI.getWebhooks();
      setWebhooks(getWebHooksRes?.data);
      // FIXME This pagination is mocked
      // it could surely be improved with the proper BE support
      setPagination({
        itemsPerPage: 100,
        position: ['topRight']
      });
      Promise.resolve();
    } catch (e) {
      message.error(text('getWebhooksKO'));
      Promise.reject(e);
    } finally {
      setAreWebhooksLoading(false);
    }
  }, []);

  const getWebhookDetails = useCallback(async (webhookId) => {
    try {
      const getWebhookByIdRes = await WebhookAPI.getWebhookbyId(webhookId);
      setWebhook(getWebhookByIdRes?.data);
      Promise.resolve();
    } catch (e) {
      message.error(text('getWebhookDetailsKO'));
      Promise.reject(e);
    }
  }, []);

  useEffect(() => {
    getWebhooks();
  }, []);

  useEffect(() => {
    if (params?.id) {
      getWebhookDetails(params.id);
    }
  }, [params.id]);

  return {
    webhooks,
    webhook,
    pagination,
    refreshData: getWebhooks,
    areWebhooksLoading
  };
};

export const WebhookProvider = ({ children }) => {
  const webhooks = useProvideWebhook();

  return (
    <WebhookContext.Provider value={webhooks}>
      {children}
    </WebhookContext.Provider>
  );
};

export const useWebhooks = () => useContext(WebhookContext);
