import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { useOutfits } from 'src/modules/outfits/provider/outfits.provider';
import { Collapsible } from 'src/components/Collapsible';
import { DangerButton } from 'src/components/buttons/DangerButton';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { OutfitSelector } from '../component/OutfitSelector';
import { VariantSelector } from '../component/VariantSelector';
import { CategorySelector } from '../component/CategorySelector';
import { LookbookHomepageAPI } from '../api/LookbookHomepageAPI';
import { useLookbookHomepage } from '../provider/lookbookHomepage.provider';
import { useCollectionGroups } from 'src/modules/collections/provider/collectionGroups.provider';
import { useOutfitCollections } from 'src/modules/collections/provider/outfitCollections.provider';
import { LookbookHomepageTable } from '../component/LookbookHomepageTable';
import { CollectionGroupSelector } from '../component/CollectionGroupSelector';
import { OutfitCollectionSelector } from '../component/OutfitCollectionSelector';

const { TabPane } = Tabs;

export const PageLookbookHomepage = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.lookbookHomepage', t);

  const { userInfo } = useAuth();
  const { homepageSections, areHomepageSectionsLoading } = useLookbookHomepage();
  const { outfits, outfitVariants } = useOutfits();
  const { collectionGroups } = useCollectionGroups();
  const { outfitCollections } = useOutfitCollections();
  const history = useHistory();

  const clearUrlParams = () => {
    history.replace({
      search: undefined
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedSectionKeys, setSelectedSectionKeys] = useState([]);

  useEffect(() => {
    setSections(homepageSections);
  }, [homepageSections]);

  const removeSections = (selectedSectionKeys) => {
    setSections(sections => sections.filter(section => !selectedSectionKeys.includes(section.id)));
    setSelectedSectionKeys([]);
  };

  const rowSelectionConfig = {
    selectedRowKeys: selectedSectionKeys,
    onChange: keys => setSelectedSectionKeys(keys)
  };

  useEffect(() => {
    setSections(oldSections => [...oldSections, ...outfitVariants.map(outfitVariantToSection)]);
  }, [outfitVariants]);

  const saveHomepageSetup = async () => {
    setIsLoading(true);
    const localBusinessId = userInfo?.localBusiness?.id;
    try {
      await LookbookHomepageAPI.saveSections(localBusinessId, sections);
      message.success(text('onSaveOk'));
    } catch (e) {
      message.error(text('onSaveErr'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='py-4'>
      <PageTitle>{text('pageTitle')}</PageTitle>

      <Collapsible
        className='pt-6'
        title={text('homepageNavigation')}
      >
        <Row gutter={16} className='py-2'>
          <Col className='last-column'>
            <DangerButton
              label={text('removeSections')}
              onClick={removeSections.bind(undefined, selectedSectionKeys)}
              disabled={!selectedSectionKeys.length}
            />
          </Col>
        </Row>
        <LookbookHomepageTable
          sections={sections}
          loading={areHomepageSectionsLoading}
          rowSelection={rowSelectionConfig}
        />
      </Collapsible>

      <div className='pt-8'>
        <Tabs
          type='card'
          defaultActiveKey='1'
          onChange={clearUrlParams}
        >

          <TabPane key='1' tab={text('tabs.collectionGroups')}>
            <CollectionGroupSelector
              collectionGroups={collectionGroups}
              linkedCollectionGroups={sections}
              setLinkedCollectionGroups={setSections}
            />
          </TabPane>

          <TabPane key='2' tab={text('tabs.outfitCollections')}>
            <OutfitCollectionSelector
              collections={outfitCollections}
              linkedCollections={sections}
              setLinkedCollections={setSections}
            />
          </TabPane>

          <TabPane key='3' tab={text('tabs.outfits')}>
            <OutfitSelector
              outfits={outfits}
              linkedOutfits={sections}
              setLinkedOutfits={setSections}
            />
          </TabPane>

          <TabPane key='4' tab={text('tabs.variants')}>
            <VariantSelector />
          </TabPane>

          <TabPane key='5' tab={text('tabs.macrocategories')}>
            <CategorySelector
              type='macro'
              selectedItems={sections}
              setSelectedItems={setSections}
            />
          </TabPane>

          <TabPane key='6' tab={text('tabs.microcategories')}>
            <CategorySelector
              type='micro'
              selectedItems={sections}
              setSelectedItems={setSections}
            />
          </TabPane>

        </Tabs>
      </div>

      <Row gutter={20} justify='end' className='mt-12'>
        <Col>
          <PrimaryButton label={text('save')} onClick={saveHomepageSetup} loading={isLoading} />
        </Col>
      </Row>
    </div>
  );
};

function outfitVariantToSection (outfitVariant) {
  return {
    id: outfitVariant.id,
    code: outfitVariant.id,
    type: 'PRODUCT_DETAILS',
    names: outfitVariant.localizedNames,
    bannerMobile: { imageURL: outfitVariant.photoGallery?.images?.find(image => image.info?.type === 'cover')?.url }
  };
}
