import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext
} from 'react';
import { message } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { useFilters } from 'src/hooks/useFilters';
import { useLocation, useParams } from 'react-router-dom';
import { CustomersAPI } from 'src/modules/admin/api/CustomersAPI';
import { usePagination } from 'src/hooks/usePagination';
import { useTranslation } from 'react-i18next';
import { CustomersMapper } from 'src/modules/admin/domain/Customers.mapper';

const CustomersContext = createContext();

const useProvideCustomers = (text) => {
  const location = useLocation();
  const { query, onChangeQuery } = useFilters(CustomersMapper);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = usePagination();
  const [areCustomersLoading, setAreCustomersLoading] = useState(false);
  const [customer, setCustomer] = useState(null);
  const params = useParams();

  const getCustomers = useCallback(async () => {
    setAreCustomersLoading(true);
    const customerQuery = { ...query };
    try {
      const res = await CustomersAPI.getCustomers(CustomersMapper.fromQueryToPayload(customerQuery));
      setCustomers(res?.data?.items || []);
      setPagination(res?.data || {});
      return Promise.resolve();
    } catch (e) {
      message.error(text('customersListError'));
      return Promise.reject(e);
    } finally {
      setAreCustomersLoading(false);
    }
  }, [query, setPagination, location]);

  const getCustomerDetails = useCallback(async () => {
    try {
      const res = await CustomersAPI.getCustomerDetails(params.id);
      const customerDetails = res?.data?.data;
      setCustomer(customerDetails);
    } catch (e) {
      message.error(text('customersDetailsError'));
    }
  }, [params.id]);

  useEffect(() => {
    getCustomers(query);
  }, [location.search, getCustomers]);

  useEffect(() => {
    if (params.id) {
      getCustomerDetails();
    }
  }, [params.id, getCustomerDetails]);

  return {
    query,
    customer,
    customers,
    pagination,
    setCustomers,
    onChangeQuery,
    refreshData: getCustomers,
    areCustomersLoading
  };
};

export const CustomersProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.customers', t);

  const customers = useProvideCustomers(text);

  return (
    <CustomersContext.Provider value={customers}>
      {children}
    </CustomersContext.Provider>
  );
};

export const useCustomers = () => useContext(CustomersContext);
