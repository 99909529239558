import moment from 'moment';
import { LocalBusinessAPI } from 'src/modules/admin/api/localBusinessAPI';

function secondsToDDMMYYYY (seconds) {
  return moment.utc(seconds * 1000).format('DD-MM-YYYY');
}

function makeTimeRange (startSeconds, endSeconds) {
  if (startSeconds && endSeconds) {
    return `From ${secondsToDDMMYYYY(startSeconds)} to ${secondsToDDMMYYYY(endSeconds)}`;
  } else if (startSeconds && !endSeconds) {
    return `From ${secondsToDDMMYYYY(startSeconds)}`;
  } else if (!startSeconds && endSeconds) {
    return `Until ${secondsToDDMMYYYY(endSeconds)}`;
  } else {
    return '';
  }
}

export async function filtersArrayMapper (filtersObj) {
  const {
    startTimestampInSeconds,
    endTimestampInSeconds,
    localBusinessIds,
    codes,
    type
  } = filtersObj;

  const filtersArray = [];

  if (startTimestampInSeconds || endTimestampInSeconds) {
    const timeRangeFilter = makeTimeRange(startTimestampInSeconds, endTimestampInSeconds);
    filtersArray.push(timeRangeFilter);
  }

  if (type) {
    const typeFilter = `Type: ${type}`;
    filtersArray.push(typeFilter);
  }

  if (codes) {
    codes.forEach(code => {
      const codeFilter = `Code: ${code}`;
      filtersArray.push(codeFilter);
    });
  }

  if (localBusinessIds) {
    for (const lbId of localBusinessIds) {
      try {
        const res = await LocalBusinessAPI.getLocalBusinessDetails(lbId);
        const lbName = res?.data?.name;
        const lbFilter = `Local Business: ${lbName || lbId}`;
        filtersArray.push(lbFilter);
      } catch (e) {
        const lbFilter = `Local Business: ${lbId}`;
        filtersArray.push(lbFilter);
      }
    }
  }

  return filtersArray;
}
