import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useAccounts } from 'src/modules/accounts/provider/accounts.provider';
import { useLanguages } from 'src/modules/global/provider/languages.provider';
import { useForm } from 'antd/lib/form/Form';
import { BACKOFFICE_TIMEZONES } from 'src/utils/constants';
import i18next from 'i18next';

function getLanguageOptions (getLabel) {
  return (
    Object
      .keys(i18next.options.resources)
      .map(langCode => ({ value: langCode, label: getLabel(langCode) }))
      .sort((a, b) => a.label.localeCompare(b.label))
  );
}

export const PageUser = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.user', t);
  const getLanguageLabelInEnglish = (langCode) => textFrom('languages', t)(langCode, { lng: 'en' });

  const [isLoading, setIsLoading] = useState(false);

  const { userInfo, updateLoggedinUserInfo } = useAccounts();
  const { defaultLanguage } = useLanguages();
  const [form] = useForm();

  const { Option } = Select;

  const LANGUAGE_OPTIONS = getLanguageOptions(getLanguageLabelInEnglish);

  useEffect(() => {
    if (userInfo?.backofficeLanguage && form && defaultLanguage) {
      form.setFieldsValue({
        backofficeLanguage: userInfo?.backofficeLanguage || i18next.options.lng,
        timeZone: 'utc'
      });
    }
  }, [userInfo, form, defaultLanguage]);

  const onFinish = async () => {
    const values = form.getFieldsValue();

    // TODO IMPLEMENT TIMEZONE
    const payload = {
      backofficeLanguage: values?.backofficeLanguage
    };

    setIsLoading(true);
    await updateLoggedinUserInfo(userInfo.id, payload);
    setIsLoading(false);
  };

  return (
    <>
      <div className='py-4'>
        <Form
          form={form}
          layout='vertical'
          autoComplete='off'
          onFinish={onFinish}
        >
          <PageTitle>{text('pageTitle')}</PageTitle>
          <Row gutter={20} className='pt-2 mb-4 pt-8'>
            <Col xs={8}>
              {/* Name */}
              <Form.Item
                name='backofficeLanguage'
                label={text('form.backofficeLanguage')}
                className='w-full px-4 pb-4'
              >
                <Select
                  showSearch
                  options={LANGUAGE_OPTIONS}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className='pt-2 mb-4'>
            <Col xs={8}>
              {/* Name */}
              <Form.Item
                name='timeZone'
                label={text('form.timeZone')}
                className='w-full px-4 pb-4'
              >
                <Select
                  value={userInfo?.backofficeLanguage}
                  disabled
                >
                  {BACKOFFICE_TIMEZONES.map(el =>
                    <Option
                      key={el?.value}
                      value={el?.value}
                    >
                      {el?.label}
                    </Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <div className='flex justify-end w-full '>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type='primary'
                htmlType='submit'
                className='uppercase ml-2 mt-40'
              >
                {text('saveAccount')}
              </Button>
            </div>
          </Row>

          {/* Actions */}
        </Form>
      </div>
    </>
  );
};
