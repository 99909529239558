import React, { createContext, useContext, useState, useEffect } from 'react';
import { AuthAPI } from 'src/modules/auth/api/AuthAPI';
import { AccountsAPI } from 'src/modules/accounts/api/AccountsApi';
import { StorageService } from 'src/services/Storage.service';
import { buildAxiosClients } from 'src/api/AxiosClient';
import { checkIfArrayAIsSubsetOfArrayB } from 'src/utils/utils';
import { USE_CENTRALIZED_LOGIN } from 'src/config';

export const authContext = createContext();

const useProvideAuth = () => {
  // TODO if we can get all the data from user we should only rely on that as a source of truth while fetching from localstorage
  const [user, setUser] = useState(StorageService.getUser());
  const [userInfo, setUserInfo] = useState(StorageService.getUserInfo());
  const [userDepartments, setUserDepartments] = useState(StorageService.getUserDepartments());
  const [userRole, setUserRole] = useState(StorageService.getUserRole());
  const [userAuthorities, setUserAuthorities] = useState(StorageService.getUserAuthorities());
  const [userScope, setUserScope] = useState(null);
  const [selectedUserScope, setSelectedUserScope] = useState(null);

  const updateSelectedUserScope = (userScope) => {
    setSelectedUserScope(userScope);
    StorageService.setSelectedUserScope(userScope);
  };

  useEffect(() => {
    if (!userScope) {
      const defaultUserScope = StorageService.getUserScope();
      setUserScope(defaultUserScope);
      updateSelectedUserScope(defaultUserScope);
    }
  }, [userScope, setUserScope, StorageService.getUserScope, updateSelectedUserScope]);

  const promisifiedSetUser = (user) => (
    new Promise((resolve, reject) => {
      setUser(user);
      StorageService.setUser(user);
      resolve();
    })
  );

  const promisifiedSetUserInfo = (userInfo) => (
    new Promise((resolve, reject) => {
      setUserInfo(userInfo);
      StorageService.setUserInfo(userInfo);
      resolve();
    })
  );

  const promisifiedSetUserDepartments = (userDepartments) => (
    new Promise((resolve, reject) => {
      setUserDepartments(userDepartments);
      StorageService.setUserDepartments(userDepartments);
      resolve();
    })
  );

  const promisifiedSetUserRole = (userRole) => (
    new Promise((resolve, reject) => {
      setUserRole(userRole);
      StorageService.setUserRole(userRole);
      resolve();
    })
  );

  const promisifiedSetUserAuthorities = (userAuthorities) => (
    new Promise((resolve, reject) => {
      setUserAuthorities(userAuthorities);
      StorageService.setUserAuthorities(userAuthorities);
      resolve();
    })
  );

  const promisifiedSetUserScope = (userScope) => (
    new Promise((resolve, reject) => {
      setUserScope(userScope);
      StorageService.setUserScope(userScope);
      setSelectedUserScope(userScope);
      StorageService.setSelectedUserScope(userScope);
      resolve();
    })
  );

  const promisifiedSetUserIsLogged = (userIsLogged) => (
    new Promise((resolve, reject) => {
      StorageService.setUserIsLogged(userIsLogged);
      resolve();
    })
  );

  const promisifiedSetRestConfig = (restConfig) => (
    new Promise((resolve, reject) => {
      StorageService.setRestConfig(restConfig);
      resolve();
    })
  );

  const afterLoginSetup = async (loginRes) => {
    const loginResData = loginRes?.data?.data;
    const userInfo = await AccountsAPI.getUserInfo();
    const userInfoData = userInfo?.data?.data;
    let userDepartments = [];
    if (userInfoData?.localBusiness?.departments?.length > 0) {
      userDepartments = userInfoData.localBusiness.departments;
    }

    let userRole = '';
    const receivedRole = userInfoData.type;
    const receivedLocalBusiness = userInfoData.localBusiness;
    if (['ROLE_ADMIN', 'ROLE_TENANT_ADMIN', 'ROLE_TENANT_MANAGER'].includes(receivedRole)) {
      userRole = receivedLocalBusiness === null ? 'superAdmin' : 'localBusinessAdmin';
    } else if (['ROLE_LOCAL_BUSINESS_ADMIN', 'ROLE_CUSTOM'].includes(receivedRole)) {
      userRole = 'localbusinessAdmin';
    } else {
      userRole = 'localBusinessRetailer';
    }
    const userAuthorities = userInfoData.authorities || [];
    const pSetUser = promisifiedSetUser(loginResData);
    const pSetUserInfo = promisifiedSetUserInfo(userInfoData);
    const pSetUserDepartments = promisifiedSetUserDepartments(userDepartments);
    const pSetUserRole = promisifiedSetUserRole(userRole);
    const pSetUserAuthorities = promisifiedSetUserAuthorities(userAuthorities);
    const pSetUserScope = promisifiedSetUserScope(userInfoData?.scope);
    await Promise.all([pSetUser, pSetUserInfo, pSetUserDepartments, pSetUserRole, pSetUserAuthorities, pSetUserScope]).then(result => {
      const userIsLogged = true;
      promisifiedSetUserIsLogged(userIsLogged);
      return Promise.resolve();
    }).catch(e => {
      return Promise.reject(e);
    });
  };

  const signIn = async (data) => {
    try {
      if (USE_CENTRALIZED_LOGIN === 'true') {
        const getConfigRes = await AuthAPI.getConfig(data);
        buildAxiosClients(getConfigRes.data);
        promisifiedSetRestConfig(getConfigRes.data);
      }
      const loginRes = await AuthAPI.login(data);
      await afterLoginSetup(loginRes);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const loginAs = async (targetEmail) => {
    try {
      const loginAsRes = await AuthAPI.loginAs(targetEmail);
      await afterLoginSetup(loginAsRes);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const signOut = () => {
    StorageService.deleteUserIsLogged();
    StorageService.deleteUser();
    StorageService.deleteUserInfo();
    StorageService.deleteUserDepartments();
    StorageService.deleteUserRole();
    StorageService.deleteUserAuthorities();
    StorageService.deleteUserScope();
    StorageService.deleteSelectedUserScope();
    setUser(null);
  };

  const checkIfUserHasAuthorities = (neededAuthorities) => {
    return checkIfArrayAIsSubsetOfArrayB(neededAuthorities, userAuthorities);
  };

  const canManageUsers = checkIfUserHasAuthorities(['BO_HL_USER_REASSIGN']);
  const canFullyEditUsers = checkIfUserHasAuthorities(['BO_HL_ENABLE_TENANT_USER_FULL_UPDATE']);
  const canManageCoupons = checkIfUserHasAuthorities(['BO_HL_COUPON_REASSIGN']);
  const canActivateCoupons = checkIfUserHasAuthorities(['BO_HL_COUPON_TOGGLE']);
  const canSeeCustomerData = checkIfUserHasAuthorities(['ACCESS_ORDER_CUSTOMER_DATA']);
  const canCreateEvents = checkIfUserHasAuthorities(['BO_HL_EVENT_CREATE']);
  const canManageEventAreas = checkIfUserHasAuthorities(['BO_HL_EVENT_LOCATION_D']);
  const canLoginAs = checkIfUserHasAuthorities(['BO_HL_USER_LOGIN_AS_RW']);
  const canSeeFullDashboard = checkIfUserHasAuthorities(['BO_HL_DASHBOARD_RW']);
  const canAnonymizeCustomerData = checkIfUserHasAuthorities(['BO_HL_CUSTOMER_ANONYMIZE']);
  const canAnonymizeOrderData = checkIfUserHasAuthorities(['BO_HL_ORDER_ANONYMIZE']);
  const canDownloadCouponUsageReport = checkIfUserHasAuthorities(['BO_HL_DOWNLOAD_COUPON_USAGE_REPORT_RW']);
  const canCreateMacrocategory = checkIfUserHasAuthorities(['BO_HL_MACROCATEGORY_CREATE']);
  const canCreateMicrocategory = checkIfUserHasAuthorities(['BO_HL_MICROCATEGORY_CREATE']);
  const canAddNewVariantAttributeCodes = checkIfUserHasAuthorities(['BO_HL_VARIANT_ATTRIBUTE_CODE_RW']);
  const canAddNewProductAttributeCodes = checkIfUserHasAuthorities(['BO_HL_PRODUCT_ATTRIBUTE_CODE_RW']);

  return {
    user,
    userInfo,
    userDepartments,
    userRole,
    userAuthorities,
    canManageUsers,
    canFullyEditUsers,
    canManageCoupons,
    canActivateCoupons,
    canSeeCustomerData,
    canCreateEvents,
    canManageEventAreas,
    canLoginAs,
    canSeeFullDashboard,
    canAnonymizeCustomerData,
    canAnonymizeOrderData,
    canDownloadCouponUsageReport,
    canCreateMacrocategory,
    canCreateMicrocategory,
    canAddNewVariantAttributeCodes,
    canAddNewProductAttributeCodes,
    userScope,
    selectedUserScope,
    signIn,
    signOut,
    loginAs,
    updateSelectedUserScope,
    checkIfUserHasAuthorities
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);
