import React, {
  useState,
  createContext,
  useCallback,
  useEffect,
  useContext
} from 'react';
import { AccountsAPI } from '../api/AccountsApi';
import { message } from 'src/services/Messages.service';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import i18next from 'i18next';

export const accountsContext = createContext();

// TODO clean this up, it has duplicated method and functions, check auth provider

const useProvideUsers = (text) => {
  const [userInfo, setUserInfo] = useState(null);

  /**
   * @description get logged in user info
   * @type {(function(): Promise<undefined>)|*}
   */

  const updateLoggedinUserInfo = useCallback(async (userId, payload) => {
    const { backofficeLanguage } = payload;
    try {
      const res = await AccountsAPI.updateUser(userId, payload);
      if (backofficeLanguage) {
        i18next.changeLanguage(backofficeLanguage);
      }
      message.success(text('updateUserOK'));
      setUserInfo(res.data.data);
    } catch (e) {
      message.error(text('updateUserKO'));
      console.log(e);
    }
  }, []);

  /**
   * @description get logged in user info
   * @type {(function(): Promise<undefined>)|*}
   */

  const getLoggedinUserInfo = useCallback(async () => {
    try {
      const res = await AccountsAPI.getUserInfo();
      const userInfo = { ...res.data.data };

      const { backofficeLanguage } = userInfo;
      i18next.changeLanguage(backofficeLanguage);

      setUserInfo(userInfo);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getLoggedinUserInfo();
  }, [getLoggedinUserInfo]);

  return {
    userInfo,
    updateLoggedinUserInfo
  };
};

export const AccountsProvider = ({ children }) => {
  const { t } = useTranslation();
  const text = textFrom('providers.accounts', t);
  const usersData = useProvideUsers(text);

  return (
    <accountsContext.Provider value={usersData}>
      {children}
    </accountsContext.Provider>
  );
};

export const useAccounts = () => useContext(accountsContext);
