// TODO uniform export and imports
import React from 'react';
import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from 'src/router/Routes.helper';
import { SideBar } from 'src/components/navigation/SideBar';
import { AuthoritiesChecker } from 'src/router/AuthoritiesChecker';
import { PageProductsFood } from 'src/modules/products/pages/PageProductsFood';
import { PageProductDetailsFood } from 'src/modules/products/pages/PageProductDetailsFood';
import { PageDashboard } from 'src/modules/dashboard/pages/PageDashboard';
import { PageLocalBusinesses } from 'src/modules/admin/pages/PageLocalBusinesses';
import { PageCreateLocalBusiness } from 'src/modules/admin/pages/PageCreateLocalBusiness';
import { PageEditLocalBusiness } from 'src/modules/admin/pages/PageEditLocalBusiness';
import { PageUser } from 'src/modules/admin/pages/PageUser';
import { PageProducts } from 'src/modules/products/pages/PageProducts';
import { PageLocations } from 'src/modules/locations/pages/PageLocations';
import { PageCreateLocations } from 'src/modules/locations/pages/PageCreateLocations';
import { PageEditLocations } from 'src/modules/locations/pages/PageEditLocations';
import { PageProductDetails } from 'src/modules/products/pages/PageProductDetails';
import { LocalBusinessProvider } from 'src/modules/admin/provider/localBusiness.provider';
import { FoodProductsProvider } from 'src/modules/products/provider/foodProducts.provider';
import { FashionProductsProvider } from 'src/modules/products/provider/fashionProducts.provider';
import { LocationProvider } from 'src/modules/locations/provider/location.provider';
import { LocationsDetailsProvider } from 'src/modules/locations/provider/locationDetails.provider.js';
import { ProductDetailsProvider } from 'src/modules/products/provider/productDetails.provider';
import { ProductDetailsFoodProvider } from 'src/modules/products/provider/productDetailsFood.provider';
import { PageStocks } from 'src/modules/warehouses/pages/PageStocks';
import { StocksProvider } from 'src/modules/warehouses/provider/stocks.provider';
import { WarehousesProvider } from 'src/modules/warehouses/provider/warehouses.provider';
import { PageWarehouses } from 'src/modules/warehouses/pages/PageWarehouses';
import { PageCreateWarehouse } from 'src/modules/warehouses/pages/PageCreateWarehouse';
import { PageEditWarehouse } from 'src/modules/warehouses/pages/PageEditWarehouse';
import { AttributesProvider } from 'src/hooks/useProvideAttributes';
import { PageDownloads } from 'src/modules/downloads/pages/PageDownloads';
import { PageDownloadDetails } from 'src/modules/downloads/pages/PageDownloadDetails';
import { PageCreateDownload } from 'src/modules/downloads/pages/PageCreateDownload';
import { DownloadsProvider } from 'src/modules/downloads/provider/downloads.provider';
import { PageUploadsList } from 'src/modules/upload/pages/PageUploadsList';
import { UploadsListProvider } from 'src/modules/upload/provider/uploadsList.provider';
import { PageUploadTypeDetails } from 'src/modules/upload/pages/PageUploadTypeDetails';
import { UploadTypeDetailsProvider } from 'src/modules/upload/provider/uploadTypeDetails.provider';
import { UploadTypeStatusProvider } from 'src/modules/upload/provider/uploadTypeStatus.provider';
import { PageUploadTypeStatus } from 'src/modules/upload/pages/PageUploadTypeStatus';
import { VisualMerchandisingProvider } from 'src/modules/visualMerchandising/provider/visualMerchandisingList.provider';
import { PageVisualMerchandisingList } from 'src/modules/visualMerchandising/pages/PageVisualMerchandisingList';
import { VisualMerchandisingProductsProvider } from 'src/modules/visualMerchandising/provider/visualMerchandisingProducts.provider';
import { PageVisualMerchandisingProducts } from 'src/modules/visualMerchandising/pages/PageVisualMerchandisingProducts';
import { PageUpcomingEvents } from 'src/modules/products/pages/PageUpcomingEvents';
import { PagePastEvents } from 'src/modules/products/pages/PagePastEvents';
import { PageCreateEvent } from 'src/modules/products/pages/PageCreateEvent';
import { PageEditEvent } from 'src/modules/products/pages/PageEditEvent';
import { EventsProvider } from 'src/modules/products/provider/events.provider';
import { EventDetailsProvider } from 'src/modules/products/provider/eventDetails.provider';
import { PageAccount } from 'src/modules/accounts/pages/PageAccount';
import { AccountsProvider } from 'src/modules/accounts/provider/accounts.provider';
import { OrdersProvider as OrdersProviderEvents } from 'src/modules/orders/provider/ordersEvents.provider';
import { OrdersProvider as OrdersProviderFood } from 'src/modules/orders/provider/ordersFood.provider';
import { PageUpcomingOrders as PageUpcomingOrdersFood } from 'src/modules/orders/pages/PageUpcomingOrdersFood';
import { PagePastOrders as PagePastOrdersFood } from 'src/modules/orders/pages/PagePastOrdersFood';
import { PageUpcomingOrders as PageUpcomingOrdersEvents } from 'src/modules/orders/pages/PageUpcomingOrdersEvents';
import { PagePastOrders as PagePastOrdersEvents } from 'src/modules/orders/pages/PagePastOrdersEvents';
import { PageOrdersFashion } from 'src/modules/orders/pages/PageOrdersFashion';
import { FashionOrdersProvider } from 'src/modules/orders/provider/ordersFashion.provider';
import { DashboardProvider } from 'src/modules/dashboard/provider/dashboard.provider';
import { PageCreateFoodProduct } from 'src/modules/products/pages/PageCreateFoodProduct';
import { PageFlagFeaturesConfig } from 'src/modules/admin/pages/PageFeatureFlagsConfig';
import { CouponsProvider } from 'src/modules/coupons/provider/coupons.provider';
import { PageCoupons } from 'src/modules/coupons/pages/PageCoupons';
import { PageCreateCoupon } from 'src/modules/coupons/pages/PageCreateCoupon';
import { PageCreateMacroCategories } from 'src/modules/categories/pages/PageCreateMacroCategories';
import { PageCreateMicroCategories } from 'src/modules/categories/pages/PageCreateMicroCategories';
import { PageAssignmentCategories } from 'src/modules/categories/pages/PageAssignmentCategories';
import { PageUsers } from 'src/modules/admin/pages/PageUsers';
import { PageCreateUser } from 'src/modules/admin/pages/PageCreateUser';
import { PageEditUser } from 'src/modules/admin/pages/PageEditUser';
import { PagePermissionSets } from 'src/modules/admin/pages/PagePermissionSets';
import { PageCreatePermissionSet } from 'src/modules/admin/pages/PageCreatePermissionSet';
import { PageEditPermissionSet } from 'src/modules/admin/pages/PageEditPermissionSet';
import { PermissionSetsProvider } from 'src/modules/admin/provider/permissionSets.provider';
import { UsersProvider } from 'src/modules/admin/provider/users.provider';
import { PageBrands } from 'src/modules/brands/pages/PageBrands';
import { DepartmentProvider } from 'src/modules/admin/provider/department.provider';
import { PageCreateBrand } from 'src/modules/brands/pages/PageCreateBrand';
import { PageEditBrand } from 'src/modules/brands/pages/PageEditBrand';
import { BrandsProvider } from 'src/modules/brands/provider/brands.provider';
import { PageWebhooks } from 'src/modules/admin/pages/PageWebhooks';
import { PageCreateWebhook } from 'src/modules/admin/pages/PageCreateWebhook';
import { PageEditWebhook } from 'src/modules/admin/pages/PageEditWebhook';
import { WebhookProvider } from 'src/modules/admin/provider/webhook.provider';
import { TenantProvider } from 'src/modules/admin/provider/tenant.provider';
import { PageGeneralSettings } from 'src/modules/generalSettings/pages/PageGeneralSettings';
import { GeneralSettingsProvider } from 'src/modules/generalSettings/provider/generalSettings.provider';
import { CustomersProvider } from 'src/modules/admin/provider/customers.provider';
import { PageCustomers } from 'src/modules/admin/pages/PageCustomers';
import { PageCreateCustomer } from 'src/modules/admin/pages/PageCreateCustomer';
import { PageEditCustomer } from 'src/modules/admin/pages/PageEditCustomer';
import { PageLookbookConfig } from 'src/modules/admin/pages/PageLookbookConfig';
import { LookbookConfigProvider } from 'src/modules/admin/provider/lookbookConfig.provider';
import { PageLookbookHomepage } from 'src/modules/admin/pages/PageLookbookHomepage';
import { LookbookHomepageProvider } from 'src/modules/admin/provider/lookbookHomepage.provider';
import { PagePresentationConfig } from 'src/modules/admin/pages/PagePresentationConfig';
import { PresentationConfigProvider } from 'src/modules/admin/provider/presentationConfig.provider';
import { PageVariantAttributes } from 'src/modules/attributes/pages/PageVariantAttributes';
import { PageProductAttributes } from 'src/modules/attributes/pages/PageProductAttributes';
import { PageLanguages } from 'src/modules/admin/pages/PageLanguages';
import { PageCreateLanguages } from 'src/modules/admin/pages/PageCreateLanguages';
import { PageOutfits } from 'src/modules/outfits/pages/PageOutfits';
import { PageCreateOutfits } from 'src/modules/outfits/pages/PageCreateOutfits';
import { PageEditOutfits } from 'src/modules/outfits/pages/PageEditOutfits';
import { OutfitsProvider } from 'src/modules/outfits/provider/outfits.provider';
import { OutfitCollectionsProvider } from 'src/modules/collections/provider/outfitCollections.provider.js';
import { CollectionGroupsProvider } from 'src/modules/collections/provider/collectionGroups.provider.js';
import { PageCollectionGroup } from 'src/modules/collections/pages/collection-group/PageCollectionGroup';
import { PageCreateCollectionGroup } from 'src/modules/collections/pages/collection-group/PageCreateCollectionGroup';
import { PageEditCollectionGroup } from 'src/modules/collections/pages/collection-group/PageEditCollectionGroup';
import { PageOutfitCollection } from 'src/modules/collections/pages/outfit-collection/PageOutfitCollection';
import { PageCreateOutfitCollection } from 'src/modules/collections/pages/outfit-collection/PageCreateOutfitCollection';
import { PageEditOutfitCollection } from 'src/modules/collections/pages/outfit-collection/PageEditOutfitCollection';
import { CateogoryProvider } from 'src/modules/admin/provider/categories.provider';

const { Content } = Layout;

export const AppLayout = () => {
  // TODO split into sections by topic

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AccountsProvider>
        <SideBar />
      </AccountsProvider>
      <Layout className='site-layout'>
        <Content style={{ margin: '0 16px' }}>
          <Switch>
            <Route exact path='/'>
              <DashboardProvider>
                <PageDashboard />
              </DashboardProvider>
            </Route>
            <Route exact path={Routes.PATHS.DASHBOARD}>
              <DashboardProvider>
                {/* TODO enable dashboard when needed */}
                <PageDashboard />
              </DashboardProvider>
            </Route>
            <Switch>
              <Route exact path={Routes.PATHS.LOCAL_BUSINESSES}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOCALBUSINESS_RW']}>
                  <LocalBusinessProvider>
                    <PageLocalBusinesses />
                  </LocalBusinessProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.LOCAL_BUSINESSES_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOCALBUSINESS_RW']}>
                  <DepartmentProvider>
                    <LocalBusinessProvider>
                      <PageCreateLocalBusiness />
                    </LocalBusinessProvider>
                  </DepartmentProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.LOCAL_BUSINESSES_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOCALBUSINESS_RW']}>
                  <LocalBusinessProvider>
                    <PageEditLocalBusiness />
                  </LocalBusinessProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PRODUCTS_FASHION}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_RETAIL_PRODUCT_RW']}>
                  <AttributesProvider>
                    <FashionProductsProvider>
                      <PageProducts />
                    </FashionProductsProvider>
                  </AttributesProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.EDIT_PRODUCT_FASHION}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_RETAIL_PRODUCT_RW']}>
                  <AttributesProvider>
                    <ProductDetailsProvider>
                      <PageProductDetails />
                    </ProductDetailsProvider>
                  </AttributesProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PRODUCTS_FOOD}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_FOOD_PRODUCT_RW']}>
                  <FoodProductsProvider>
                    <PageProductsFood />
                  </FoodProductsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.CREATE_PRODUCT_FOOD}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_FOOD_PRODUCT_RW']}>
                  <ProductDetailsFoodProvider>
                    <PageCreateFoodProduct />
                  </ProductDetailsFoodProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.EDIT_PRODUCT_FOOD}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_FOOD_PRODUCT_RW']}>
                  <ProductDetailsFoodProvider>
                    <PageProductDetailsFood />
                  </ProductDetailsFoodProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PRODUCTS_EVENT_UPCOMING}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_EVENT_UPCOMING_EVENT_RW']}>
                  <EventsProvider>
                    <PageUpcomingEvents />
                  </EventsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PRODUCTS_EVENT_PAST}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_EVENT_PAST_EVENT_RW']}>
                  <EventsProvider>
                    <PagePastEvents />
                  </EventsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.CREATE_PRODUCT_EVENT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_EVENT_CREATE']}>
                  <AttributesProvider>
                    <EventDetailsProvider>
                      <PageCreateEvent />
                    </EventDetailsProvider>
                  </AttributesProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.EDIT_PRODUCT_EVENT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_EVENT_PRODUCT_RW']}>
                  <AttributesProvider>
                    <EventDetailsProvider>
                      <PageEditEvent />
                    </EventDetailsProvider>
                  </AttributesProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.OUTFITS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_OUTFIT_RW']}>
                  <OutfitsProvider>
                    <PageOutfits />
                  </OutfitsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.OUTFIT_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_OUTFIT_RW']}>
                  <FashionProductsProvider>
                    <OutfitsProvider>
                      <PageCreateOutfits />
                    </OutfitsProvider>
                  </FashionProductsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.EDIT_OUTFIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_OUTFIT_RW']}>
                  <FashionProductsProvider>
                    <OutfitsProvider>
                      <PageEditOutfits />
                    </OutfitsProvider>
                  </FashionProductsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.LOCATIONS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOCATION_RW']}>
                  <LocationProvider>
                    <PageLocations />
                  </LocationProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.CREATE_LOCATION}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOCATION_RW']}>
                  <LocationProvider>
                    <PageCreateLocations />
                  </LocationProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.LOCATIONS_DETAILS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOCATION_RW']}>
                  <LocationsDetailsProvider>
                    <PageEditLocations />
                  </LocationsDetailsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.VARIANT_ATTRIBUTES}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_VARIANT_ATTRIBUTE_RW']}>
                  <DepartmentProvider>
                    <PageVariantAttributes />
                  </DepartmentProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PRODUCT_ATTRIBUTES}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_PRODUCT_ATTRIBUTE_RW']}>
                  <DepartmentProvider>
                    <PageProductAttributes />
                  </DepartmentProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.WAREHOUSES}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_WAREHOUSE_RW']}>
                  <WarehousesProvider>
                    <PageWarehouses />
                  </WarehousesProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.WAREHOUSES_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_WAREHOUSE_RW']}>
                  <WarehousesProvider>
                    <PageCreateWarehouse />
                  </WarehousesProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.WAREHOUSES_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_WAREHOUSE_RW']}>
                  <WarehousesProvider>
                    <PageEditWarehouse />
                  </WarehousesProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.STOCKS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_STOCK_RW']}>
                  <StocksProvider>
                    <PageStocks />
                  </StocksProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.UPLOADS_LIST}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_UPLOAD_MENU']}>
                  <UploadsListProvider>
                    <PageUploadsList />
                  </UploadsListProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.UPLOAD_TYPE_DETAILS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_UPLOAD_MENU']}>
                  <DepartmentProvider>
                    <UploadTypeDetailsProvider>
                      <PageUploadTypeDetails />
                    </UploadTypeDetailsProvider>
                  </DepartmentProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PAGE_UPLOAD_TYPE_STATUS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_UPLOAD_MENU']}>
                  <UploadTypeStatusProvider>
                    <PageUploadTypeStatus />
                  </UploadTypeStatusProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.DOWNLOADS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_DOWNLOAD_MENU']}>
                  <DownloadsProvider>
                    <PageDownloads />
                  </DownloadsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.DOWNLOAD_DETAILS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_DOWNLOAD_MENU']}>
                  <DownloadsProvider>
                    <PageDownloadDetails />
                  </DownloadsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.DOWNLOAD_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_DOWNLOAD_MENU']}>
                  <LocalBusinessProvider>
                    <CouponsProvider>
                      <PageCreateDownload />
                    </CouponsProvider>
                  </LocalBusinessProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.VISUAL_MERCHANDISING}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_VISUAL_MERCHANDISING_RW']}>
                  <VisualMerchandisingProvider>
                    <PageVisualMerchandisingList />
                  </VisualMerchandisingProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.VISUAL_MERCHANDISING_PRODUCTS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_VISUAL_MERCHANDISING_RW']}>
                  <VisualMerchandisingProductsProvider>
                    <PageVisualMerchandisingProducts />
                  </VisualMerchandisingProductsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.ACCOUNTS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_ACCOUNT_RW']}>
                  <AccountsProvider>
                    <PageAccount />
                  </AccountsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.ORDERS_FASHION}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_RETAIL_ORDER_RW']}>
                  <FashionOrdersProvider>
                    <PageOrdersFashion />
                  </FashionOrdersProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.UPCOMING_ORDERS_EVENT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_EVENT_UPCOMING_ORDER_RW']}>
                  <OrdersProviderEvents>
                    <PageUpcomingOrdersEvents />
                  </OrdersProviderEvents>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PAST_ORDERS_EVENT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_EVENT_PAST_ORDER_RW']}>
                  <OrdersProviderEvents>
                    <PagePastOrdersEvents />
                  </OrdersProviderEvents>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.UPCOMING_ORDERS_FOOD}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_FOOD_UPCOMING_ORDER_RW']}>
                  <OrdersProviderFood>
                    <PageUpcomingOrdersFood />
                  </OrdersProviderFood>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PAST_ORDERS_FOOD}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_FOOD_PAST_ORDER_RW']}>
                  <OrdersProviderFood>
                    <PagePastOrdersFood />
                  </OrdersProviderFood>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.BRANDS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_BRAND_RW']}>
                  <DepartmentProvider>
                    <BrandsProvider>
                      <PageBrands />
                    </BrandsProvider>
                  </DepartmentProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.LOOKBOOK_HOMEPAGE_SETUP}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOOKBOOK_HOMEPAGE_SETUP_RW']}>
                  <CollectionGroupsProvider>
                    <OutfitCollectionsProvider>
                      <OutfitsProvider>
                        <FashionProductsProvider>
                          <CateogoryProvider>
                            <LookbookHomepageProvider>
                              <PageLookbookHomepage />
                            </LookbookHomepageProvider>
                          </CateogoryProvider>
                        </FashionProductsProvider>
                      </OutfitsProvider>
                    </OutfitCollectionsProvider>
                  </CollectionGroupsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PRESENTATION_CONFIG}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_PRESENT_LOOK_AND_FEEL_RW']}>
                  <PresentationConfigProvider>
                    <PagePresentationConfig />
                  </PresentationConfigProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.BRAND_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_BRAND_RW']}>
                  <DepartmentProvider>
                    <BrandsProvider>
                      <PageCreateBrand />
                    </BrandsProvider>
                  </DepartmentProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.BRAND_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_BRAND_RW']}>
                  <DepartmentProvider>
                    <BrandsProvider>
                      <PageEditBrand />
                    </BrandsProvider>
                  </DepartmentProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.COUPONS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_COUPON_RW']}>
                  <CouponsProvider>
                    <PageCoupons />
                  </CouponsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.COUPON_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_COUPON_RW']}>
                  <CouponsProvider>
                    <PageCreateCoupon />
                  </CouponsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.USERS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_USER_RW']}>
                  <UsersProvider>
                    <PageUsers />
                  </UsersProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.USERS_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_USER_RW']}>
                  <LocalBusinessProvider>
                    <UsersProvider>
                      <PageCreateUser />
                    </UsersProvider>
                  </LocalBusinessProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.USERS_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_USER_RW']}>
                  <LocalBusinessProvider>
                    <UsersProvider>
                      <PageEditUser />
                    </UsersProvider>
                  </LocalBusinessProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PERMISSION_SETS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_PERMISSION_SET_RW']}>
                  <PermissionSetsProvider>
                    <PagePermissionSets />
                  </PermissionSetsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PERMISSION_SETS_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_PERMISSION_SET_RW']}>
                  <PermissionSetsProvider>
                    <PageCreatePermissionSet />
                  </PermissionSetsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PERMISSION_SETS_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_PERMISSION_SET_RW']}>
                  <PermissionSetsProvider>
                    <PageEditPermissionSet />
                  </PermissionSetsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.USER}>
                <AccountsProvider>
                  <PageUser />
                </AccountsProvider>
              </Route>

              <Route exact path={Routes.PATHS.LANGUAGES}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LANGUAGE_RW']}>
                  <PageLanguages />
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.LANGUAGES_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LANGUAGE_RW']}>
                  <PageCreateLanguages />
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.FLAG}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_FEATURE_FLAGS_CONFIG_RW']}>
                  <TenantProvider>
                    <PageFlagFeaturesConfig />
                  </TenantProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.WEBHOOKS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_WEBHOOK_RW']}>
                  <WebhookProvider>
                    <PageWebhooks />
                  </WebhookProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.WEBHOOKS_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_WEBHOOK_RW']}>
                  <WebhookProvider>
                    <PageCreateWebhook />
                  </WebhookProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.WEBHOOKS_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_WEBHOOK_RW']}>
                  <WebhookProvider>
                    <PageEditWebhook />
                  </WebhookProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.GENERAL_SETTINGS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_SETTING_RW']}>
                  <GeneralSettingsProvider>
                    <PageGeneralSettings />
                  </GeneralSettingsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.MACROCATEGORY_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_MACROCATEGORY_RW']}>
                  <GeneralSettingsProvider>
                    <PageCreateMacroCategories />
                  </GeneralSettingsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.MICROCATEGORY_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_MICROCATEGORY_RW']}>
                  <GeneralSettingsProvider>
                    <PageCreateMicroCategories />
                  </GeneralSettingsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.MICROCATEGORY_ASSIGN}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_MICROCATEGORY_ASSIGN']}>
                  <GeneralSettingsProvider>
                    <PageAssignmentCategories />
                  </GeneralSettingsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.LOOKBOOK_CONFIG}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_LOOKBOOK_LOOK_AND_FEEL_RW']}>
                  <LocalBusinessProvider>
                    <LookbookConfigProvider>
                      <PageLookbookConfig />
                    </LookbookConfigProvider>
                  </LocalBusinessProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.PRESENTATION_CONFIG}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_PRESENT_LOOK_AND_FEEL_RW']}>
                  <PresentationConfigProvider>
                    <PagePresentationConfig />
                  </PresentationConfigProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.CUSTOMERS}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_CUSTOMER_RW']}>
                  <CustomersProvider>
                    <PageCustomers />
                  </CustomersProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.CUSTOMER_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_CUSTOMER_RW']}>
                  <CustomersProvider>
                    <PageCreateCustomer />
                  </CustomersProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.CUSTOMER_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_CUSTOMER_RW']}>
                  <CustomersProvider>
                    <PageEditCustomer />
                  </CustomersProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.OUTFIT_COLLECTION}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_OUTFIT_COLLECTION_RW']}>
                  <OutfitCollectionsProvider>
                    <PageOutfitCollection />
                  </OutfitCollectionsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.OUTFIT_COLLECTION_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_OUTFIT_COLLECTION_RW']}>
                  <OutfitCollectionsProvider>
                    <OutfitsProvider>
                      <PageCreateOutfitCollection />
                    </OutfitsProvider>
                  </OutfitCollectionsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.OUTFIT_COLLECTION_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_OUTFIT_COLLECTION_RW']}>
                  <OutfitCollectionsProvider>
                    <OutfitsProvider>
                      <PageEditOutfitCollection />
                    </OutfitsProvider>
                  </OutfitCollectionsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.COLLECTION_GROUP}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_COLLECTION_GROUP_RW']}>
                  <CollectionGroupsProvider>
                    <PageCollectionGroup />
                  </CollectionGroupsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.COLLECTION_GROUP_CREATE}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_COLLECTION_GROUP_RW']}>
                  <CollectionGroupsProvider>
                    <OutfitCollectionsProvider>
                      <PageCreateCollectionGroup />
                    </OutfitCollectionsProvider>
                  </CollectionGroupsProvider>
                </AuthoritiesChecker>
              </Route>

              <Route exact path={Routes.PATHS.COLLECTION_GROUP_EDIT}>
                <AuthoritiesChecker neededAuthorities={['BO_HL_COLLECTION_GROUP_RW']}>
                  <CollectionGroupsProvider>
                    <OutfitCollectionsProvider>
                      <PageEditCollectionGroup />
                    </OutfitCollectionsProvider>
                  </CollectionGroupsProvider>
                </AuthoritiesChecker>
              </Route>

              <Redirect to={Routes.PATHS.DASHBOARD} />
            </Switch>

          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
