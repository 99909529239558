import React from 'react';
import { Routes } from 'src/router/Routes.helper';
import { useAuth } from 'src/modules/auth/provider/auth.provider';
import { Row, Col } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { useHistory } from 'react-router-dom';
import { HelperText } from 'src/components/forms/HelperText';
import { DownloadType } from '../components/DownloadType';
import { CenteredText } from 'src/components/CenteredText';
import { useDownloads } from '../provider/downloads.provider';
import { PrimaryButton } from 'src/components/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { PercentageOutlined } from '@ant-design/icons';
import { DownloadedFileTable } from '../components/DownloadedFileTable';

const DOWNLOAD_TYPE_1 = { name: 'Coupon usage report', icon: <PercentageOutlined /> };

export const PageDownloads = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.downloads', t);

  const history = useHistory();
  const { canDownloadCouponUsageReport } = useAuth();
  const {
    downloads,
    pagination,
    getDownloads,
    onChangeQuery,
    areDownloadsLoading
  } = useDownloads();

  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  const goToCreateDownloadPage = () => {
    history.push(Routes.PATHS.DOWNLOAD_CREATE);
  };

  return (
    <div className='py-4'>
      <Row gutter={16}>

        <Col xs={8}>
          <PageTitle>{text('title-1')}</PageTitle>
          <HelperText text={text('description-1')} className='py-2' />
          {canDownloadCouponUsageReport &&
            <DownloadType
              type={DOWNLOAD_TYPE_1}
              onClick={goToCreateDownloadPage}
            />}
        </Col>

        <Col xs={16}>
          <PageTitle>{text('title-2')}</PageTitle>
          <Row justify='end'>
            <Col flex='auto'>
              <HelperText
                className='py-2'
                text={text('description-2')}
              />
            </Col>
            <Col>
              <PrimaryButton
                label={text('refresh')}
                onClick={getDownloads}
                loading={areDownloadsLoading}
              />
            </Col>
          </Row>
          {!areDownloadsLoading && !downloads?.length > 0
            ? (<CenteredText>{text('noDownloadsFound')}</CenteredText>)
            : (
              <div className='pt-8'>
                <DownloadedFileTable
                  loading={areDownloadsLoading}
                  downloadedFiles={downloads}
                  pagination={pagination}
                  onTableChange={onTableChange}
                />
              </div>)}
        </Col>

      </Row>
    </div>
  );
};
