import React from 'react';
import { UploadTypes } from 'src/modules/upload/domain/models/UploadTypes';
import { StockOutlined, AppstoreOutlined, GroupOutlined, DatabaseOutlined, PictureOutlined, SkinOutlined, TagOutlined, PercentageOutlined, FolderOpenOutlined } from '@ant-design/icons';
const { TYPE_KEYS } = UploadTypes;

export const UPLOAD_TYPES = [
  {
    id: 1,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_STOCK_RW'],
    icon: <StockOutlined />,
    type_key: TYPE_KEYS.STOCK
  },
  {
    id: 2,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_RETAIL_PRODUCT_RW'],
    icon: <AppstoreOutlined />,
    type_key: TYPE_KEYS.PRODUCT_FASHION
  },
  {
    id: 3,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_FOOD_PRODUCT_RW'],
    icon: <AppstoreOutlined />,
    type_key: TYPE_KEYS.PRODUCT_FOOD
  },
  {
    id: 4,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_EVENT_PRODUCT_RW'],
    icon: <AppstoreOutlined />,
    type_key: TYPE_KEYS.PRODUCT_EVENT
  },
  {
    id: 5,
    description: '',
    avatar: 'image.jpg',
    formats: ['zip'],
    max_wight: 300,
    template_link: '',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_IMAGE_RW'],
    icon: <PictureOutlined />,
    type_key: TYPE_KEYS.CATALOG_IMAGES
  },
  {
    id: 6,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_CATEGORY_RW'],
    icon: <GroupOutlined />,
    type_key: TYPE_KEYS.CATEGORY
  },
  {
    id: 7,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_ATTRIBUTE_RW'],
    icon: <DatabaseOutlined />,
    type_key: TYPE_KEYS.ATTRIBUTE
  },
  {
    id: 8,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_OUTFIT_RW'],
    icon: <SkinOutlined />,
    type_key: TYPE_KEYS.OUTFIT
  },
  {
    id: 9,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_COLLECTION_RW'],
    icon: <FolderOpenOutlined />,
    type_key: TYPE_KEYS.COLLECTION
  },
  {
    id: 10,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_BRAND_RW'],
    icon: <TagOutlined />,
    type_key: TYPE_KEYS.BRAND
  },
  {
    id: 11,
    description: '',
    avatar: 'image.jpg',
    formats: ['csv'],
    max_wight: 300,
    template_link: 'sample.template.csv',
    disabled: false,
    neededAuthorities: ['BO_HL_UPLOAD_COUPON_RW'],
    icon: <PercentageOutlined />,
    type_key: TYPE_KEYS.COUPON
  }
  // {
  //   id: 8,
  //   description: '',
  //   avatar: 'image.jpg',
  //   formats: ['csv'],
  //   max_wight: 300,
  //   template_link: 'sample.template.csv',
  //   disabled: false,
  //   icon: <PercentageOutlined />,
  //   type_key: TYPE_KEYS.COUPON
  // },
  // {
  //   id: 9,
  //   description: '',
  //   avatar: 'image.jpg',
  //   formats: ['csv'],
  //   max_wight: 300,
  //   template_link: 'sample.template.csv',
  //   disabled: false,
  //   icon: <PartitionOutlined />,
  //   type_key: TYPE_KEYS.ANCILLARIES
  // },
];
