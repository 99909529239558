import React from 'react';
import { Row, Col, Form, Input, Select, Checkbox } from 'antd';
import { textFrom } from 'src/utils/textFrom';
import { toOption } from 'src/utils/utils';
import { useTranslation } from 'react-i18next';
import { WAREHOUSE_TYPES } from '../domain/models/warehouseTypes';

const WAREHOUSE_TYPE_OPTIONS = WAREHOUSE_TYPES.map(toOption);
const PRIORITY_OPTIONS = [
  { value: 1, label: '1 High priority' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5 Medium priority' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 0, label: '0 No priority' }
];

export const WarehouseForm = ({
  form,
  buttons,
  mode = 'create',
  initialEcommerceIsChecked,
  onFinish
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.warehouseForm', t);

  return (
    <Form
      form={form}
      layout='vertical'
      autoComplete='off'
      scrollToFirstError
      onFinish={onFinish}
    >
      <Row gutter={20} className='mb-4'>
        <Col xs={7}>
          <Form.Item
            name='name'
            label={text('name')}
            rules={[{ required: true, message: text('nameRequired') }]}
          >
            <Input placeholder={text('namePlaceholder')} />
          </Form.Item>
        </Col>

        <Col xs={5}>
          <Form.Item
            name='type'
            label={text('type')}
            rules={[{ required: true, message: text('typeRequired') }]}
          >
            <Select
              disabled
              options={WAREHOUSE_TYPE_OPTIONS}
              placeholder={text('typePlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20} className='mb-4'>
        <Col xs={7}>
          <Form.Item
            name='companyName'
            label={text('companyName')}
          >
            <Input placeholder={text('companyNamePlaceholder')} />
          </Form.Item>
        </Col>

        <Col xs={5}>
          <Form.Item
            name='taxId'
            label={text('taxId')}
          >
            <Input placeholder={text('taxIdPlaceholder')} />
          </Form.Item>
        </Col>

        <Col xs={7}>
          <Form.Item
            name='companyAddress'
            label={text('companyAddress')}
          >
            <Input placeholder={text('companyAddressPlaceholder')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20} className='mb-4'>
        <Col xs={7}>
          <Form.Item
            name='companyEmail'
            label={text('companyEmail')}
          >
            <Input placeholder={text('companyEmailPlaceholder')} />
          </Form.Item>
        </Col>

        <Col xs={5}>
          {typeof initialEcommerceIsChecked === 'boolean' &&
            <Form.Item
              name='ecommerce'
              label=' '
              valuePropName='checked'
            >
              <div className='checkbox-one-line'>
                <div>{text('eCommerce')}</div>
                <Checkbox
                  disabled
                  defaultChecked={initialEcommerceIsChecked}
                />
              </div>
            </Form.Item>}
        </Col>

        <Col xs={7}>
          <Form.Item
            name='priority'
            label={text('priority')}
          >
            <Select
              defaultValue={{ value: 0, label: '0 No priority' }}
              options={PRIORITY_OPTIONS}
              placeholder={text('priorityPlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20} className='mb-4'>
        <Col xs={7}>
          <Form.Item
            name='externalWarehouseIdentifier'
            label={text('externalWarehouseIdentifier')}
          >
            <Input placeholder={text('externalWarehouseIdentifierPlaceholder')} />
          </Form.Item>
        </Col>

        {mode === 'edit' &&
          <Col xs={5}>
            <Form.Item
              name='warehouseIdentifier'
              label={text('warehouseIdentifier')}
            >
              <Input placeholder={text('warehouseIdentifierPlaceholder')} disabled />
            </Form.Item>
          </Col>}
      </Row>

      {buttons}

    </Form>
  );
};
