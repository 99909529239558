import axios from 'axios';
import { StorageService } from 'src/services/Storage.service';
import { history } from 'src/App';
import { Routes } from 'src/router/Routes.helper';
import i18next from '../i18n-config';
import { API_BASE_URL, LOGIN_API_BASE_URL, API_STENTLE_BASE_SUFFIX, API_MICROSERVICE_BASE_SUFFIX, API_TENANT, LOGIN_X_API_KEY, USE_CENTRALIZED_LOGIN } from 'src/config';

const NO_CREDENTIAL = 'NO_CREDENTIAL';
const MS = 'MS';

const forceLogout = () => {
  StorageService.deleteUserIsLogged();
  StorageService.deleteUser();
  StorageService.deleteUserInfo();
  StorageService.deleteUserDepartments();
  StorageService.deleteUserRole();
  StorageService.deleteRestConfig();
  StorageService.deleteUserScope();
  StorageService.deleteSelectedUserScope();
};

const handleError = (error) => {
  const shouldForceLogout = [401, 403].includes(error?.response?.status);
  if (shouldForceLogout) {
    forceLogout();
    history.push(Routes.PATHS.LOGIN);
  }
  return Promise.reject(error);
};

const setJCScopeHeaderInConfig = (config) => {
  const selectedUserScope = StorageService.getSelectedUserScope();
  if (selectedUserScope) {
    config.headers['JC-scope'] = `${selectedUserScope}`;
  }
  return config;
};

export let AxiosClient = axios.create({
  baseURL: `${API_BASE_URL}${API_STENTLE_BASE_SUFFIX}`,
  withCredentials: true,
  headers: {
    'X-Domain': API_TENANT,
    'accept-language': i18next.language
  }
});

AxiosClient.interceptors.request.use(config => setJCScopeHeaderInConfig(config));

AxiosClient.interceptors.response.use(
  response => Promise.resolve(response),
  error => handleError(error)
);

export let AxiosMSClient = axios.create({
  baseURL: `${API_BASE_URL}${API_MICROSERVICE_BASE_SUFFIX}`,
  withCredentials: true,
  headers: {
    'X-Domain': API_TENANT,
    'accept-language': i18next.language
  }
});

AxiosMSClient.interceptors.request.use(config => setJCScopeHeaderInConfig(config));

AxiosMSClient.interceptors.response.use(
  response => Promise.resolve(response),
  error => handleError(error)
);

export let AxiosNoCredentialClient = axios.create({
  baseURL: `${API_BASE_URL}${API_STENTLE_BASE_SUFFIX}`,
  withCredentials: false,
  headers: {
    'X-Domain': API_TENANT,
    'accept-language': i18next.language
  }
});

AxiosNoCredentialClient.interceptors.request.use(config => setJCScopeHeaderInConfig(config));

AxiosNoCredentialClient.interceptors.response.use(
  response => Promise.resolve(response),
  error => handleError(error)
);

const AxiosClientFactory = (type, baseUrl, xDomain) => {
  let withCredentials = true;
  let builtBaseUrl;
  switch (type) {
    case NO_CREDENTIAL:
      builtBaseUrl = `${baseUrl}/${API_STENTLE_BASE_SUFFIX}`;
      withCredentials = false;
      break;
    case MS:
      builtBaseUrl = `${baseUrl}/${API_MICROSERVICE_BASE_SUFFIX}`;
      break;
    default:
      builtBaseUrl = `${baseUrl}/${API_STENTLE_BASE_SUFFIX}`;
  }
  const customAxiosClient = axios.create({
    baseURL: builtBaseUrl,
    withCredentials: withCredentials,
    headers: {
      'X-Domain': xDomain,
      'accept-language': i18next.language
    }
  });
  customAxiosClient.interceptors.request.use(config => setJCScopeHeaderInConfig(config));
  customAxiosClient.interceptors.response.use(
    response => Promise.resolve(response),
    error => handleError(error)
  );
  return customAxiosClient;
};

export const buildAxiosClients = (config) => {
  AxiosClient = AxiosClientFactory('', config.apiEndpoint, config.tenant);
  AxiosMSClient = AxiosClientFactory(MS, config.apiEndpoint, config.tenant);
  AxiosNoCredentialClient = AxiosClientFactory(NO_CREDENTIAL, config.apiEndpoint, config.tenant);
};

export const buildAccountRecoveryAxiosClient = (config) => {
  AxiosNoCredentialClient = AxiosClientFactory(NO_CREDENTIAL, config.apiEndpoint, config.tenant);
};

export const AxiosLoginClient = axios.create({
  baseURL: `${LOGIN_API_BASE_URL}`,
  withCredentials: false,
  headers: {
    'X-Api-Key': LOGIN_X_API_KEY
  }
});

AxiosLoginClient.interceptors.response.use(
  response => Promise.resolve(response),
  error => handleError(error)
);

// If the user is already logged, use stored config
if (USE_CENTRALIZED_LOGIN === 'true') {
  const restConfig = StorageService.getRestConfig();
  if (restConfig) {
    buildAxiosClients(restConfig);
  }
}
