import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { message } from 'src/services/Messages.service';
import { useParams } from 'react-router-dom';
import { UploadsAPI } from 'src/modules/upload/api/UploadsApi';
import { WarehousesAPI } from 'src/modules/warehouses/api/WarehousesAPI';

export const uploadTypeDetailsContext = createContext();

const useProvideUploadTypeDetails = () => {
  const params = useParams();
  const [uploadTypeDetails, setUploadTypeDetails] = useState({});
  const [warehousesList, setWarehousesList] = useState([]);

  /**
   * Get the details of the selected type using the param - for the moment data is static check the file UploadTypes.js
   * @type {(function(): void)|*}
   */
  const getUploadTypeDetails = useCallback(() => {
    const res = UploadsAPI.getUploadTypeDetails(params.type);
    setUploadTypeDetails(res);
  }, [params.type]);

  /**
   * @description Upload file for the selected type - information needed is warehouseId and uploaded file
   * In case of product and stock type warehouseId is warehouseId (warehouse selected - input) and in the other cases the
   * warehouseId respective values are:
   * category -> warehouseId = 'category'
   * outfit -> warehouseId = 'outfit'
   * attribute -> warehouseId = 'attribute';
   * coupon -> warehouseId = 'coupon';
   * Change the filename in case of stock adding stock_file name
   * @type {(function(*=, *=): Promise<any|undefined>)|*}
   */
  const uploadFile = useCallback(async (warehouseId, file) => {
    try {
      return await UploadsAPI.uploadFile(warehouseId, file);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const uploadZip = useCallback(async (file, folder) => {
    try {
      return await UploadsAPI.uploadZip(file, folder);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  /**
   * Get Warehouse List - data needed in case type selected is product or stock
   * @type {(function(): Promise<undefined>)|*}
   */
  const getWarehousesList = useCallback(async (department) => {
    const query = { limit: 1000, filters: { department } };
    try {
      const res = await WarehousesAPI.getWarehouses(query);
      setWarehousesList(res.data.data.items);
    } catch (e) {
      message.error(e);
    }
  }, []);

  useEffect(() => {
    getUploadTypeDetails();
  }, [getUploadTypeDetails]);

  return {
    uploadTypeDetails,
    uploadFile,
    uploadZip,
    warehousesList,
    getWarehousesList
  };
};

export const UploadTypeDetailsProvider = ({ children }) => {
  const uploadTypeDetails = useProvideUploadTypeDetails();
  return (
    <uploadTypeDetailsContext.Provider value={uploadTypeDetails}>
      {children}
    </uploadTypeDetailsContext.Provider>
  );
};

export const useUploadTypeDetails = () => useContext(uploadTypeDetailsContext);
