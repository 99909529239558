import React, { useState } from 'react';
import Moment from 'react-moment';
import { Table, Modal, message } from 'antd';
import { Link } from 'react-router-dom';
import { Routes } from 'src/router/Routes.helper';
import { textFrom } from 'src/utils/textFrom';
import { getIdSuffix } from '../utils/utils';
import { DownloadsAPI } from '../api/DownloadsAPI';
import { useTranslation } from 'react-i18next';
import { useTableHeight } from 'src/hooks/useTableHeight';
import { useDownloads } from '../provider/downloads.provider';

export const DownloadedFileTable = ({
  downloadedFiles,
  loading,
  pagination,
  onTableChange
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.downloadedFileTable', t);
  const statusText = textFrom('constants.downloadedFileStatus', t);

  const tableHeight = useTableHeight();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportIdToBeRetried, setReportIdToBeRetried] = useState(undefined);

  const openModal = (id) => {
    setIsModalVisible(true);
    setReportIdToBeRetried(id);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setReportIdToBeRetried(undefined);
  };

  const COLUMNS = [
    {
      title: text('fileName'),
      dataIndex: 'id',
      render: id => `${getIdSuffix(id)}.csv`
    },
    {
      title: text('type'),
      render: () => text('coupon')
    },
    {
      title: text('createdOn'),
      dataIndex: 'startedAt',
      render: seconds => <Moment utc format='HH:mm DD MMM YYYY' date={seconds * 1000} />
    },
    {
      title: text('status'),
      render: (_, download) =>
        download.status === 'ERROR'
          ? <ModalOpener
              openModal={openModal.bind(_, download.id)}
              text={statusText(download.status)}
            />
          : <LinkToDetailsPage
              downloadId={download.id}
              downloadStatus={download.status}
            />
    }
  ];

  return (
    <>
      <Table
        size='middle'
        scroll={{ y: tableHeight }}
        loading={loading}
        columns={COLUMNS}
        dataSource={downloadedFiles}
        onChange={onTableChange}
        pagination={{ ...pagination, showSizeChanger: false, position: ['topRight'] }}
      />
      <RetryModal
        visible={isModalVisible}
        closeModal={closeModal}
        reportId={reportIdToBeRetried}
      />
    </>
  );
};

const ModalOpener = ({ openModal, text }) => (
  <div
    onClick={openModal}
    className='text-red-500 cursor-pointer hover:underline'
  >
    {text}
  </div>
);

const RetryModal = ({ visible, closeModal, reportId }) => {
  const { t } = useTranslation();
  const text = textFrom('components.downloadedFileTable', t);

  const { getDownloads } = useDownloads();

  const [isRetryLoading, setIsRetryLoading] = useState(false);

  const retryToCreateReport = async () => {
    setIsRetryLoading(true);
    try {
      await DownloadsAPI.retryToCreateReport(reportId);
      message.success(text('onRetryOk'));
      getDownloads();
    } catch (e) {
      message.error(text('onRetryErr'));
    } finally {
      closeModal();
      setIsRetryLoading(false);
    }
  };

  return (
    <Modal
      onOk={retryToCreateReport}
      onCancel={closeModal}
      visible={visible}
      confirmLoading={isRetryLoading}
    >
      {text('confirmRetry')}
    </Modal>
  );
};

const LinkToDetailsPage = ({ downloadId, downloadStatus }) => {
  const { t } = useTranslation();
  const text = textFrom('constants.downloadedFileStatus', t);

  const status = text(downloadStatus);
  const route = Routes.parseRouteParams(Routes.PATHS.DOWNLOAD_DETAILS, { id: downloadId });

  return (
    <Link
      to={route}
      className={downloadStatus === 'PROCESSING' ? 'text-gray-300 hover:text-gray-300' : ''}
    >
      {status}
    </Link>
  );
};
