import React, { useMemo, useState } from 'react';
import { Row, Col, Button, Tag } from 'antd';
import { SearchInput } from 'src/components/inputs/SearchInput';
import { useHistory, useParams } from 'react-router-dom';
import { useVisualMerchandisingProducts } from 'src/modules/visualMerchandising/provider/visualMerchandisingProducts.provider';
import { BackButton } from 'src/components/BackButton';
import { PageTitle } from 'src/components/PageTitle';
import { HelperText } from 'src/components/forms/HelperText';
import { SortableTable } from 'src/modules/visualMerchandising/components/SortableTable';
import { SortableList } from 'src/modules/visualMerchandising/components/SortableList';
import { message } from 'src/services/Messages.service';
import { useTableHeight } from 'src/hooks/useTableHeight';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { Routes } from 'src/router/Routes.helper';

export const PageVisualMerchandisingProducts = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.pageVisualMerchandisingProducts', t);
  const textFilter = textFrom('constants.filter', t);

  const {
    isUpdatePending,
    filteredVisualMerchandisingProducts,
    setFilteredVisualMerchandisingProducts,
    onFiltersChange,
    updateProductsOrder,
    visualMerchandisingItemCount,
    pendingGetVisualMerchandisingProducts,
    filterValue,
    setFilterValue
  } = useVisualMerchandisingProducts();

  const params = useParams();
  const history = useHistory();
  const [tableView, setTableView] = useState(true);
  const tableHeight = useTableHeight();

  /**
  * @description
  * @param value: String
  */
  const handleVisualMerchandisingProductsSearch = (value) => {
    setFilterValue(value);
    onFiltersChange('name', value);
  };

  const handleSave = () => {
    updateProductsOrder().catch((e) => message.error(e));
  };

  const onTagRemove = () => {
    handleVisualMerchandisingProductsSearch('');
  };

  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: ['coverPhotoUrl'],
        width: '5%',
        render: (_, record) => (
          <div className='flex items-center'>
            <img
              className='w-8 h-8 object-cover object-center'
              src={record.coverPhotoUrl}
              alt={record.name}
            />
          </div>
        )
      },
      {
        title: text('name'),
        dataIndex: ['name']
      },
      {
        title: text('color'),
        dataIndex: ['attributeVariants', 'color'],
        render: (_, record) => record.attributeVariants.color.localeName
      },
      {
        title: text('macro'),
        dataIndex: ['macroProductCategory', 'key'],
        render: (_, record) => record.macroProductCategory.localeName
      },
      {
        title: text('micro'),
        dataIndex: ['microProductCategory', 'key'],
        render: (_, record) => record.microProductCategory.localeName
      },
      {
        title: text('price'),
        dataIndex: ['currency', 'sellingPrice'],
        render: (_, record) => (
          <div>
            {record.currency} {record.sellingPrice}
          </div>
        )
      }
    ],
    []
  );

  const goBack = () => {
    history.push(Routes.PATHS.VISUAL_MERCHANDISING);
  };

  return (
    <>
      <div className='py-4'>
        <Row className='py-4'>
          <Col span={12}>
            <PageTitle>{params.visualMerchandising}</PageTitle>
            <BackButton
              iconChevron
              className='mt-4 mb-8'
              onClick={goBack}
              title={text('backToVisualMerchandising')}
            />
            <HelperText
              className='pt-2 pb-4'
              text={text('pageDescription')}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='pb-4'>
          <Col span={6}>
            <SearchInput
              autoFocus
              placeholder={text('name')}
              onChangeText={handleVisualMerchandisingProductsSearch}
            />
          </Col>
          <Col span={6} offset={12}>
            <div className='flex justify-end'>
              <Row gutter={[16, 16]}>
                <Col>
                  <Button
                    className='w-full uppercase'
                    onClick={handleSave}
                    loading={isUpdatePending}
                    type='primary'
                  >
                    {text('save')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    className='w-full uppercase'
                    onClick={() => setTableView(!tableView)}
                  >
                    {`${tableView ? 'List' : 'Table'} View`}
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* Filter Chips */}
        {filterValue &&
          <Row className='py-4'>
            <div className='pr-2'>{textFilter('appliedFilter', { count: 1 })}:</div>
            <Tag
              className='cursorTag'
              closable
              onClose={onTagRemove}
              onClick={onTagRemove}
            >
              {filterValue}
            </Tag>
          </Row>}
      </div>
      <div>
        {tableView
          ? (
            <SortableTable
              isLoading={pendingGetVisualMerchandisingProducts || isUpdatePending}
              data={filteredVisualMerchandisingProducts}
              count={visualMerchandisingItemCount}
              columns={columns}
              onReorder={setFilteredVisualMerchandisingProducts}
              scroll={{ y: tableHeight }}
            />)
          : (
            <SortableList
              data={filteredVisualMerchandisingProducts}
              onReorder={setFilteredVisualMerchandisingProducts}
              isLoading={pendingGetVisualMerchandisingProducts || isUpdatePending}
            />)}
      </div>
    </>
  );
};
