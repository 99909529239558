import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';
import { BackButton } from 'src/components/BackButton';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { BrandsAPI } from '../api/BrandsAPI';
import { Routes } from 'src/router/Routes.helper';
import { PageTitle } from 'src/components/PageTitle';
import { BrandForm } from '../components/BrandForm';

export const PageCreateBrand = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.brands/create', t);

  const history = useHistory();

  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [imageGallery, setImageGallery] = useState([]);

  const navigateToBrandsPage = () => {
    history.push(Routes.PATHS.BRANDS);
  };

  const onFinish = async (values) => {
    const {
      brandName,
      department,
      description,
      gallery,
      logoLink,
      key
    } = values;
    const brandData = {
      key,
      name: brandName,
      departments: [
        department
      ],
      link: (!gallery || gallery?.length === 0) ? logoLink : null,
      shortDescription: null,
      longDescription: description,
      categories: null
    };
    try {
      setIsLoading(true);
      const createBrandRes = await BrandsAPI.createBrand(brandData);
      const createdBrandId = createBrandRes.data.data.id;
      if (gallery?.length > 0) {
        await BrandsAPI.createBrandLogo(createdBrandId, gallery[0]);
      }
      message.success(text('createOK'));
      setIsLoading(false);
      navigateToBrandsPage();
    } catch (e) {
      setIsLoading(false);
      message.error(text('createKO'));
      if (e?.response?.data?.status === 409) {
        form.setFields([
          {
            name: 'key',
            errors: [text('createKO409')]
          }
        ]);
      }
    }
  };

  const buttons = (
    <div className='flex items-center w-full mt-20'>
      <Button
        type='secondary'
        htmlType='button'
        className='uppercase mr-2 ml-auto'
        onClick={navigateToBrandsPage}
      >
        {text('cancel')}
      </Button>
      <Button
        loading={isLoading}
        disabled={isLoading}
        type='primary'
        htmlType='submit'
        className='uppercase ml-2'
      >
        {text('createBrand')}
      </Button>
    </div>
  );

  return (
    <div className='py-4'>
      <PageTitle>{text('createNewBrand')}</PageTitle>
      <BackButton
        iconChevron
        className='mt-4 mb-8'
        onClick={navigateToBrandsPage}
        title={text('backButton')}
      />

      <BrandForm
        form={form}
        buttons={buttons}
        onFinish={onFinish}
        imageGallery={imageGallery}
        setImageGallery={setImageGallery}
      />

    </div>
  );
};
