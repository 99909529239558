import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { LanguagesAPI } from '../api/LanguagesAPI';
import { usePagination } from 'src/hooks/usePagination';
import { regionNamesTranslated, sortByProperty } from 'src/utils/utils';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';

export const languagesContext = createContext();

const useProvideLanguages = () => {
  const [languages, setLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState([]);
  const [areLanguagesLoading, setAreLanguagesLoading] = useState(false);
  const [pagination, setPagination] = usePagination();
  const { t, i18n } = useTranslation();
  const translateLanguage = textFrom('languages', t);

  const addLabel = language => ({
    ...language,
    label: regionNamesTranslated(i18n.language).of(language.language)
  });

  const getLanguages = useCallback(async () => {
    setAreLanguagesLoading(true);
    try {
      const res = await LanguagesAPI.getLanguages();
      const orderedLanguages =
        res.data.data
          .map(addLabel)
          .sort(sortByProperty('label'));
      setLanguages(orderedLanguages);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setAreLanguagesLoading(false);
    }
  }, [setLanguages, translateLanguage]);

  const deleteLanguage = useCallback(async (payload) => {
    try {
      const res = await LanguagesAPI.deleteLanguage(payload);
      const languageData = res.data.data.map((item) => ({
        ...item,
        label: translateLanguage(item.language)
      }));
      setLanguages(languageData);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, [setLanguages, translateLanguage]);

  const makeLanguageDefault = useCallback(async (payload) => {
    try {
      const res = await LanguagesAPI.makeLanguageDefault(payload);
      const languageData = res.data.data.map((item) => ({
        ...item,
        label: translateLanguage(item.language)
      }));
      setLanguages(languageData);
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, [setLanguages, translateLanguage]);

  const getDefaultLanguage = useCallback(() => {
    const dfLanguage = languages.find((language) => language.defaultLanguage) || { language: 'en', defaultLanguage: true, label: translateLanguage('en') };
    setDefaultLanguage(dfLanguage);
  }, [languages]);

  useEffect(() => {
    getLanguages();
  }, []);

  useEffect(() => {
    getDefaultLanguage();
  }, [languages]);

  return {
    languages,
    setLanguages,
    defaultLanguage,
    pagination,
    setDefaultLanguage,
    setPagination,
    refreshData: getLanguages,
    makeLanguageDefault,
    deleteLanguage,
    areLanguagesLoading
  };
};

export const LanguageProvider = ({ children }) => {
  const languages = useProvideLanguages();

  return (
    <languagesContext.Provider value={languages}>
      {children}
    </languagesContext.Provider>
  );
};

export const useLanguages = () => useContext(languagesContext);
