import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthProvider } from 'src/modules/auth/provider/auth.provider';
import { PageLogin } from 'src/modules/auth/pages/PageLogin';
import { PrivateRoute } from 'src/router/PrivateRoute';
import { AppLayout } from 'src/layouts/AppLayout';
import { Routes } from 'src/router/Routes.helper';
import { LanguageProvider } from './modules/global/provider/languages.provider';
import { UnauthenticatedRoute } from 'src/router/UnauthenticatedRoute';
import { PageEmailVerification } from 'src/modules/auth/pages/PageEmailVerification';
import { PageResetPassword } from 'src/modules/auth/pages/PageResetPassword';
import { PageForgotPassword } from 'src/modules/auth/pages/PageForgotPassword';
import { PageUnauthorized } from './modules/auth/pages/Unauthorized';

// styles
import './styles/tailwind.css';
import 'antd/dist/antd.css';
import './styles/index.css';

export const history = createBrowserHistory();

function App () {
  return (
    <AuthProvider>
      <Router history={history}>
        <Switch>
          <UnauthenticatedRoute path={Routes.PATHS.LOGIN}>
            <PageLogin />
          </UnauthenticatedRoute>
          <Route path={Routes.PATHS.EMAIL_VERIFICATION}>
            <PageEmailVerification />
          </Route>
          <Route path={Routes.PATHS.RESET_PASSWORD}>
            <PageResetPassword />
          </Route>
          <Route path={Routes.PATHS.FORGOT_PASSWORD}>
            <PageForgotPassword />
          </Route>
          <Route path={Routes.PATHS.UNAUTHORIZED}>
            <PageUnauthorized />
          </Route>
          <PrivateRoute path='/'>
            <LanguageProvider>
              <AppLayout />
            </LanguageProvider>
          </PrivateRoute>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
