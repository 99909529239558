import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Tag, Form, Input, Select } from 'antd';
import { LOCALBUSINESS_FORM } from '../utils/utils';
import { textFrom } from 'src/utils/textFrom';
import { SectionTitle } from 'src/components/forms/SectionTitle';
import { useTranslation } from 'react-i18next';
import { useLocalBusiness } from '../provider/localBusiness.provider';

const { CheckableTag } = Tag;

const ANY_OPTION = {
  key: 'any',
  name: ' - '
};

const initializeTags =
  (selectedTags, form) =>
    key =>
      allTags => {
        const values = allTags.map(tag => ({
          [LOCALBUSINESS_FORM.KEY]: tag.key,
          [LOCALBUSINESS_FORM.LABEL]: tag.values?.en?.value,
          [LOCALBUSINESS_FORM.CHECKED]: selectedTags?.some(t => t.key === tag.key)
        }));
        form.setFieldsValue({ [key]: values });
      };

export const LocalBusinessForm = ({
  form,
  mode,
  buttons,
  departmentOptions,
  originalTags,
  onFinish
}) => {
  const { t } = useTranslation();
  const text = textFrom('components.localBusinessForm', t);

  const { tags, categories } = useLocalBusiness();
  const [parentCategories, setParentCategories] = useState([]);
  const [affiliatedCategories, setAffiliatedCategories] = useState([]);
  const parentCategory = Form.useWatch('parentCategory', form);
  const affiliatedCategory = Form.useWatch('affiliatedCategory', form);

  const getTags = (category) => (
    category
      ? tags.filter(tag => tag.businessCategoryKey === category)
      : tags.filter(tag => !tag.businessCategoryKey)
  );

  const initializeTagsAt = initializeTags(originalTags, form);

  const initializeGeneralTags = initializeTagsAt(LOCALBUSINESS_FORM.GENERAL_TAGS);
  const initializeMacroTags = initializeTagsAt(LOCALBUSINESS_FORM.MACRO_TAGS);
  const initializeMicroTags = initializeTagsAt(LOCALBUSINESS_FORM.MICRO_TAGS);

  useEffect(() => {
    initializeGeneralTags(getTags());
  }, [tags, originalTags]);

  useEffect(() => {
    initializeParentCategories(categories);
  }, [categories]);

  useEffect(() => {
    const isParentCategoryValid = parentCategory && parentCategory !== ANY_OPTION.key && parentCategories.some(cat => cat.key === parentCategory);
    const newTags = isParentCategoryValid ? getTags(parentCategory) : [];
    initializeMacroTags(newTags);
    initializeAffiliatedCategories(categories, parentCategory);
  }, [parentCategory, parentCategories, tags, originalTags]);

  useEffect(() => {
    const isAffiliatedCategoryValid = affiliatedCategory && affiliatedCategory !== ANY_OPTION.key && affiliatedCategories.some(cat => cat.key === affiliatedCategory);
    const newTags = isAffiliatedCategoryValid ? getTags(affiliatedCategory) : [];
    initializeMicroTags(newTags);
  }, [affiliatedCategory, affiliatedCategories, tags, originalTags]);

  const initializeParentCategories = (categories) => {
    const macros = categories.filter(category => category.type === 'MACRO');
    setParentCategories([ANY_OPTION].concat(macros));
  };

  const initializeAffiliatedCategories = (categories, parentCategoryKey) => {
    const micros = categories.filter(category => category.type === 'MICRO');
    const parentCategory = parentCategories.find(cat => cat.key === parentCategoryKey);
    let newAffiliatedCategories = micros;
    if (parentCategory?.children) {
      const subKeys = parentCategory.children.map(child => child.key);
      newAffiliatedCategories = micros.filter(micro => subKeys.includes(micro.key));
    }
    setAffiliatedCategories([ANY_OPTION].concat(newAffiliatedCategories));
  };

  const resetAffiliatedCategory = () => {
    form.setFieldsValue({ affiliatedCategory: undefined });
  };

  const toCategoryOption = category => ({
    key: category.key,
    value: category.key,
    label: category.name
  });

  const parentCategoryOptions = useMemo(() => (
    parentCategories?.map(toCategoryOption) || []
  ), [parentCategories]);

  const affiliatedCategoryOptions = useMemo(() => (
    affiliatedCategories?.map(toCategoryOption) || []
  ), [affiliatedCategories]);

  return (
    <Form
      form={form}
      layout='vertical'
      autoComplete='off'
      scrollToFirstError
      onFinish={onFinish}
    >
      <Row gutter={20} className='pt-2 mb-4'>
        <Col xs={8}>
          <Form.Item
            name='department'
            label={text('profile')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('profileRequired') }]}
          >
            <Select
              bordered
              allowClear
              className='w-full'
              disabled={mode === 'edit'}
              options={departmentOptions}
              placeholder={text('profilePlaceholder')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={8}>
          <Form.Item
            name='localBusinessName'
            label={text('name')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('nameRequired') }]}
          >
            <Input placeholder={text('namePlaceholder')} />
          </Form.Item>

          {mode === 'create' &&
            <Form.Item
              name='username'
              label={text('username')}
              className='w-full px-4 pb-4'
              rules={[{ required: true, message: text('usernameRequired') }]}
            >
              <Input placeholder={text('usernamePlaceholder')} />
            </Form.Item>}

          <Form.Item
            name='zipCode'
            label={text('zipCode')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('zipCodeRequired') }]}
          >
            <Input placeholder={text('zipCodePlaceholder')} />
          </Form.Item>
        </Col>

        <Col xs={8}>
          <Form.Item
            name='taxID'
            label={text('taxID')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('taxIDRequired') }]}
          >
            <Input placeholder={text('taxIDPlaceholder')} />
          </Form.Item>

          <Form.Item
            name='city'
            label={text('city')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('cityRequired') }]}
          >
            <Input placeholder={text('cityPlaceholder')} />
          </Form.Item>

          {mode === 'create' &&
            <Form.Item
              name='stripeId'
              label={text('stripeCode')}
              className='w-full px-4 pb-4'
            >
              <Input placeholder={text('stripeCodePlaceholder')} />
            </Form.Item>}
        </Col>

        <Col xs={8}>
          {mode === 'create' &&
            <Form.Item
              name='backofficeUserEmail'
              label={text('invitationEmail')}
              className='w-full px-4 pb-4'
              rules={[
                { required: true, message: text('emailRequired') },
                { type: 'email', message: text('emailInvalid') }
              ]}
            >
              <Input placeholder={text('emailPlaceholder')} />
            </Form.Item>}

          <Form.Item
            name='streetAddress'
            label={text('address')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('addressRequired') }]}
          >
            <Input placeholder={text('addressPlaceholder')} />
          </Form.Item>

          <Form.Item
            name='externalBusinessId'
            label={text('externalBusinessId')}
            className='w-full px-4 pb-4'
            rules={[{ required: true, message: text('externalBusinessIdRequired') }]}
          >
            <Input placeholder={text('externalBusinessIdPlaceholder')} />
          </Form.Item>
        </Col>
      </Row>

      <SectionTitle title={text('categories')} className='pt-4' />
      <Row gutter={20}>
        <Col xs={8}>
          <SelectFormItem
            name='parentCategory'
            label={text('parentCategory')}
            options={parentCategoryOptions}
            onChange={resetAffiliatedCategory}
            placeholder={text('parentCategoryPlaceholder')}
          />
        </Col>

        <Col xs={8}>
          <SelectFormItem
            name='affiliatedCategory'
            label={text('affiliatedCategory')}
            options={affiliatedCategoryOptions}
            disabled={!parentCategory || parentCategory === ANY_OPTION.key}
            placeholder={text('affiliatedCategoryPlaceholder')}
          />
        </Col>
      </Row>

      <Row gutter={20}>
        <Col xs={8}>

          <SectionTitle title={text('generalTags')} className='pt-4' />
          <TagList
            form={form}
            listName={LOCALBUSINESS_FORM.GENERAL_TAGS}
          />

          <SectionTitle title={text('parentCategoryTags')} className='pt-4' />
          <TagList
            form={form}
            listName={LOCALBUSINESS_FORM.MACRO_TAGS}
          />

          <SectionTitle title={text('affiliatedCategoryTags')} className='pt-4' />
          <TagList
            form={form}
            listName={LOCALBUSINESS_FORM.MICRO_TAGS}
          />

        </Col>
      </Row>

      {buttons}

    </Form>
  );
};

const SelectFormItem = ({ name, label, options, disabled, onChange, placeholder }) => (
  <Form.Item
    name={name}
    label={label}
  >
    <Select
      showSearch
      bordered
      allowClear
      options={options}
      disabled={disabled}
      onChange={onChange}
      placeholder={placeholder}
    />
  </Form.Item>
);

const TagList = ({ form, listName }) => (
  <Form.List name={listName}>
    {fields => (
      <>
        {fields.map(({ key, name }) => (
          <Form.Item
            key={key}
            className='category-tag'
            name={[name, LOCALBUSINESS_FORM.CHECKED]}
            valuePropName={LOCALBUSINESS_FORM.CHECKED}
          >
            <CheckableTag>
              {form.getFieldsValue()[listName]?.[key]?.[LOCALBUSINESS_FORM.LABEL]}
            </CheckableTag>
          </Form.Item>
        )
        )}
      </>
    )}
  </Form.List>
);
