import React, { useRef, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Drawer
} from 'antd';
import { useHistory } from 'react-router-dom';
// TODO Enable once API is fixed
// import { useDownloadFile } from 'src/hooks/useDownloadFile';
import { ProductSideFilters } from 'src/modules/products/components/ProductSideFilters';
import { LocationTable } from 'src/modules/products/components/LocationTable';
import { Routes } from 'src/router/Routes.helper';
import { Product } from 'src/services/Product.service';
import { useLocations } from '../provider/location.provider';
import { useTranslation } from 'react-i18next';
import { textFrom } from 'src/utils/textFrom';
import { PageTitle } from 'src/components/PageTitle';
import { CenteredText } from 'src/components/CenteredText';

export const PageLocations = () => {
  const { t } = useTranslation();
  const text = textFrom('pages.locations/list', t);

  const header = useRef();
  const history = useHistory();

  // TODO Enable once API is fixed
  // const downloadFile = useDownloadFile({
  //   onError (err) {
  //     message.error(
  //       err.response?.data?.detail || err.response?.data || err.message
  //     );
  //   }
  // });

  const {
    query,
    locations,
    availableFilters,
    pagination,
    onChangeQuery,
    areLocationsLoading
  } = useLocations();

  const [drawerVisible, setDrawerVisible] = useState(false);

  /**
   * @description This method handle the table data in case user changes the page or change the total items displayed on that page
   * @param {{
   *  current: string
   *  pageSize: number
   * }} config
   * @returns {string}
   */
  const onTableChange = ({ current, pageSize }) => {
    onChangeQuery({ current, pageSize });
  };

  /**
   * @description This method handle the click on apply filter button inside the drawer. In the drawer are being added all the filters including even category and subcategory (apart ean)
   * @param filters
   * @param closeDrawer
   */
  const onApplyFilters = (filters, closeDrawer = false) => {
    const newFilters = { ...filters };

    if (filters.published !== undefined && filters.published !== null) {
      newFilters.published = Product.getStatusBooleanValue(filters.published);
    }
    newFilters.current = 1;

    onChangeQuery(newFilters);
    closeDrawer && setDrawerVisible(false);
  };

  return (
    <div>
      <div ref={header} className='py-4 bg-gray-100'>
        <PageTitle>{text('title')}</PageTitle>
        <Row gutter={[16, 16]} className='pt-2'>
          <Col span={14} />
          <Col className='last-column'>
            {/* TODO enable once API fixed */}
            {/* <Button
              loading={downloadFile.isLoading}
              onClick={() =>
                downloadFile.call('/backoffice/export', 'catalog.xlsx')
              }
              type='link'
              className='uppercase text-indio-500'
            >
              Download .xls
            </Button> */}
            <Button
              type='primary'
              className='uppercase'
              onClick={() => history.push(Routes.parseRouteParams(Routes.PATHS.CREATE_LOCATION))}
            >
              {text('createNewButton')}
            </Button>
          </Col>
        </Row>
      </div>

      {!areLocationsLoading && !locations?.length
        ? (<CenteredText>{text('noLocationsFound')}</CenteredText>)
        : (<LocationTable
            loading={areLocationsLoading}
            tableData={locations}
            pagination={{ ...pagination, showSizeChanger: false }}
            onTableChange={onTableChange}
           />)}

      <Drawer
        visible={!!drawerVisible}
        onClose={() => setDrawerVisible(false)}
        closable={false}
        placement='right'
        className='jc-drawer'
        title={text('addFilters')}
      >
        <ProductSideFilters
          currentFilters={query}
          availableFilters={availableFilters}
          onApply={onApplyFilters}
        />
      </Drawer>
    </div>
  );
};
