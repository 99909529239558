import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useFilters } from 'src/hooks/useFilters';
import { usePagination } from 'src/hooks/usePagination';
import { useLocation } from 'react-router-dom';
import { CouponsAPI } from '../api/CouponsAPI';
import { CouponsMapper } from '../domain/Coupons.mapper';
import { message } from 'antd';
import { useAuth } from 'src/modules/auth/provider/auth.provider';

export const couponsContext = createContext();

const useProvideCoupons = () => {
  const location = useLocation();
  const [coupons, setCoupons] = useState([]);
  const [areCouponsLoading, setAreCouponsLoading] = useState(false);
  const [pagination, setPagination] = usePagination();
  const { query, onChangeQuery } = useFilters(CouponsMapper);
  const { userRole, userInfo } = useAuth();
  const isSuperAdmin = ['superAdmin'].includes(userRole);

  const getCoupons = useCallback(async (query) => {
    setAreCouponsLoading(true);
    let couponQuery = query;
    try {
      if (!isSuperAdmin && userInfo) {
        couponQuery = {
          ...query,
          localBusinessId: userInfo.localBusiness.id
        };
      }
      const res = await CouponsAPI.searchCoupons(CouponsMapper.fromQueryToPayload(couponQuery));
      const { items = [] } = res?.data?.data;
      setCoupons(items);
      setPagination(res.data.data);
      return Promise.resolve();
    } catch (e) {
      message.error('Failed to fetch coupons!');
    } finally {
      setAreCouponsLoading(false);
    }
  }, [query, setPagination, location]);

  useEffect(() => {
    getCoupons(query);
  }, [location.search, getCoupons]);

  return {
    query,
    coupons,
    setCoupons,
    pagination,
    onChangeQuery,
    getCoupons,
    refreshData: getCoupons,
    areCouponsLoading
  };
};

export const CouponsProvider = ({ children }) => {
  const coupons = useProvideCoupons();
  return (
    <couponsContext.Provider value={coupons}>{children}</couponsContext.Provider>
  );
};

export const useCoupons = () => useContext(couponsContext);
